import { Box, Typography, useTheme } from '@mui/material';
import { numberWithPoint } from '../../../utils/utils';
import { BoxContentScenary, StageText } from './SimulationMuiComponents';

export const ScenarySection = ({
  escenario,
  bgColor,
  lineColor,
  textColor,
  formData,
  amount,
  fullInvesment,
  profit,
}) => {
  const theme = useTheme();
  return (
    <BoxContentScenary
      sx={{
        backgroundColor: bgColor,
        border: `1px solid ${lineColor}`,
        marginBottom: '32px',
      }}>
      <StageText sx={{ color: textColor }}>{`${escenario}`}</StageText>
      <Box
        sx={{
          width: '100%',
          minWidth: 'calc(100%-16px)',
          backgroundColor: theme.palette.white.main,
          padding: '16px',
          marginTop: '16px',
          borderRadius: '8px',
          boxSizing: 'border-box',
          display: 'flex',
          flexDirection: 'column',
          gap: '16px',
        }}>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Typography sx={{ textAlign: 'left', fontSize: theme.typography.customButton.fontSize }}>
            {`Saldo en ${formData.months}`}
          </Typography>
          <Typography sx={{ textAlign: 'right', fontSize: theme.typography.customButton.fontSize }}>
            Ganado{' '}
            <Typography variant='span' sx={{ color: theme.palette.secondary.main }}>
              {`(${profit > 0 ? '+' : ''}${numberWithPoint(
                Math.trunc(((Number.parseInt(amount) - fullInvesment) / fullInvesment) * 100),
              )}%)`}
            </Typography>
          </Typography>
        </Box>
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}>
          <Typography
            sx={{
              textAlign: 'left',
              fontSize: theme.typography.button.fontSize,
              fontWeight: 'bold',
            }}>
            {`$${numberWithPoint(Math.trunc(amount))}`}
          </Typography>
          <Typography
            sx={{
              textAlign: 'right',
              fontSize: theme.typography.button.fontSize,
              fontWeight: 'bold',
            }}>
            {`$${numberWithPoint(Math.trunc(amount - fullInvesment))}`}
          </Typography>
        </Box>
      </Box>
    </BoxContentScenary>
  );
};
