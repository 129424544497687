import { gql, useLazyQuery } from '@apollo/client';

const GET_SIMULATOR_LIST_QUERY = gql`
query getInvestmentSimulation($data: investmentParameter!) {
  getInvestmentSimulation(data: $data){
    simulationId
    simulationData{
      simulationType
      amount
      profit
    }
  }
}`;

export default () => useLazyQuery(GET_SIMULATOR_LIST_QUERY, { fetchPolicy: 'no-cache' });
