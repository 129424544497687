import { gql, useQuery } from '@apollo/client';

const GET_MODELS_PORTFOLIO_BY_ID = gql`
query GetModelById($modelId: String!) {
  getModelById(modelId: $modelId) {
    id
    fileAs
    name
    code
    legacyKey
    currency
    Targets {
      name
      percentage
      ID
    }
  }
}
`;

export const useGetModelsPortfolioById = variables => {
  return useQuery(GET_MODELS_PORTFOLIO_BY_ID, { variables }, { fetchPolicy: 'no-cache' });
};
