import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Box, Button, Link, Stack, Typography, styled, useTheme } from '@mui/material';
import { message } from 'antd';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LayoutProfile from '../../../components/Layout/InternalLayout/LayoutProfile';
import Spinner from '../../../components/Spinner';
import useGetQuestions from '../../../hooks/useGetQuestions';
import { updateQuestions } from '../../../redux/questions';
import { IconsRiskProfile } from './ProfileSelect';

const SelectorTimeInvertio = styled(Stack)(({ theme }) => ({
  background: theme.palette.white.main,
  backdropFilter: 'blur(48px)',
  WebkitBackdropFilter: 'blur(48px)',
  boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  boxSizing: 'border-box',
  color: theme.palette.primary.dark,
  transition: 'ease-in-out',
  transitionDuration: '100ms',
  cursor: 'pointer',
  '&:hover': {
    background: theme.palette.primary.profileGradient,
    backdropFilter: 'blur(48px)',
    WebkitBackdropFilter: 'blur(48px)',
    boxShadow: '0px 0px 8px rgba(0, 0, 0, 0.25)',
    borderRadius: '8px',
    color: theme.palette.white.main,
    transition: 'ease-in-out',
    transitionDuration: '100ms',
  },
}));

const TextTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  flex: '1',
  margin: '0px',
  color: 'inherit',
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.description.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.returnText.fontSize,
  },
}));

const ProfileQuestions = ({}) => {
  const selectedContent = '';
  const userProfileContent = 'nada';
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [questions, setQuestions] = React.useState([]);
  const [stepper, setstepper] = useState(0);
  const [userAnswers, setUserAnswers] = useState({});
  const [steppercolor, setSteppercolor] = useState({
    comp0: true,
    comp1: false,
    comp2: false,
    comp3: false,
    comp4: false,
    comp5: false,
    comp6: false,
    comp7: false,
    comp8: false,
  });
  const { user } = useSelector(state => state.auth);

  const [doGetQuestions, { data, error: errorGetQuestions, loading: loadingGetQuestions }] =
    useGetQuestions();

  const successGetQuestions = data?.getQuestions || [];

  useEffect(() => {
    doGetQuestions();
  }, []);

  useEffect(() => {
    if (errorGetQuestions && !loadingGetQuestions) {
      message.error('Ha ocurrido un error al obtener las preguntas');
    }
  }, [errorGetQuestions, loadingGetQuestions]);

  useEffect(() => {
    if (successGetQuestions && !loadingGetQuestions) {
      setQuestions(successGetQuestions);
    }
  }, [successGetQuestions, loadingGetQuestions]);

  const Handleback = () => {
    setstepper(stepper - 1);
    setSteppercolor({
      ...steppercolor,
      [`comp${stepper}`]: false,
    });
  };

  const HandleNext = () => {
    if (stepper < questions.length - 1) {
      setstepper(stepper + 1);
      // Limpiar el color seleccionado del paso anterior
      const previousStep = `comp${stepper}`;
      setSteppercolor({
        ...steppercolor,
        [previousStep]: false,
      });
    } else {
      // Si es la última pregunta, actualizar respuestas y navegar a la página de resultados
      dispatch(updateQuestions(userAnswers));
      navigate('/questionsresults');
    }
  };

  const Handlestep = data => {
    if (stepper === questions.length - 1) {
      dispatch(updateQuestions(data));
      navigate('/questionsresults');
      return;
    }

    // Si el dispositivo es móvil, no avanzar automáticamente al siguiente paso
    if (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
    ) {
      return;
    }

    // Avanzar al siguiente paso si no es la última pregunta y no es un dispositivo móvil
    setstepper(stepper + 1);
    // if (stepper === questions.length - 1) {
    //   dispatch(updateQuestions(data));
    //   navigate("/questionsresults");
    //   return;
    // }
    // if (stepper < questions.length - 1) {
    //   setstepper(stepper + 1);
    //   setSteppercolor({
    //     ...steppercolor,
    //     [`comp${stepper + 1}`]: true,
    //   });
    // }
  };
  const changeCurrentAnswer = (questionId, answerId) => {
    const data = {
      ...userAnswers,
      [stepper]: {
        questionId,
        answerId,
      },
    };
    setUserAnswers(data);
    Handlestep(data);
  };

  if (questions.length === 0 || loadingGetQuestions) {
    return <Spinner />;
  }

  return (
    <>
      <Stack
        sx={{
          boxSizing: 'border-box',
          width: '100%',
          minHeight: '100vh',
          position: 'relative',
          overflow: 'hidden',
          padding: 0,
          margin: 0,
        }}>
        <LayoutProfile
          user={user}
          stepProfile={true}
          selectedContent={selectedContent || userProfileContent}
          stepperQuestion={questions[stepper].question}
          stepper={stepper}
          stepperLong={successGetQuestions}>
          <Stack
            sx={{
              width: { md: '60%' },
              mx: 'auto',
              boxSizing: 'border-box',
              flexDirection: 'column',
              padding: 'clamp(1rem, -1.8571rem + 12.1905vw, 2rem);',
              bgcolor: theme.palette.white.main,
              borderRadius: '1rem',
              boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.1)',
              justifyContent: 'center',
              alignItems: 'center',
              marginBottom: '10rem',
            }}
            gap={1}>
            <Box>
              <IconsRiskProfile
                sx={{
                  width: 'clamp(5.1875rem, 1.4375rem + 16vw, 10.4375rem);',
                }}
                src={'/assets/images/perfil-noDefined.svg'}
              />
            </Box>
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'column',
                gap: 2,
                marginBottom: '1rem',
              }}>
              {questions[stepper].answers.map((answer, index) => {
                return (
                  <SelectorTimeInvertio
                    key={index}
                    onClick={() =>
                      changeCurrentAnswer(questions[stepper].questionId, answer.answerId)
                    }
                    sx={{
                      padding:
                        'clamp(1.25rem, -0.8333rem + 8.8889vw, 2.5rem) clamp(1rem, -0.4167rem + 4.4444vw, 1.25rem)',
                    }}>
                    <TextTitle>{answer.answer}</TextTitle>
                  </SelectorTimeInvertio>
                );
              })}
            </Stack>

            <Stack direction={'row'}>
              {stepper >= 1 && (
                <Link
                  component='button'
                  variant='body2'
                  onClick={Handleback}
                  sx={{
                    color: theme.palette.secondary.text,
                    fontWeight: theme.typography.h1.fontWeight,
                    marginTop: '1rem',
                  }}>
                  <Button
                    variant='outlined'
                    sx={{
                      borderRadius: '4px',
                      color: theme.palette.secondary.text,
                      border: 'none',
                      textTransform: 'none',
                      fontSize: theme.typography.button.fontSize,
                      fontWeight: theme.typography.button.fontWeight,
                      ':hover': {
                        color: theme.palette.secondary.text,
                        border: 'none',
                        backgroundColor: theme.palette.avatar.borderTransparent,
                      },
                    }}
                    startIcon={<ArrowBackIcon />}>
                    Volver
                  </Button>
                </Link>
              )}

              {stepper < questions.length - 1 && (
                <Link
                  onClick={HandleNext}
                  sx={{
                    color: theme.palette.secondary.text,
                    fontWeight: theme.typography.button.fontWeight,
                    marginTop: '1rem',
                  }}>
                  <Button
                    variant='outlined'
                    sx={{
                      display: { md: 'none', xs: 'flex' },
                      borderRadius: '4px',
                      color: theme.palette.secondary.text,
                      border: 'none',
                      textTransform: 'none',
                      fontSize: theme.typography.button.fontSize,
                      fontWeight: theme.typography.button.fontWeight,
                      ':hover': {
                        color: theme.palette.secondary.text,
                        border: 'none',
                        backgroundColor: theme.palette.avatar.borderTransparent,
                      },
                    }}
                    endIcon={<ArrowForwardIcon />}>
                    Siguiente
                  </Button>
                </Link>
              )}
            </Stack>
          </Stack>
        </LayoutProfile>
      </Stack>
    </>
  );
};

export default ProfileQuestions;
