import { Box, Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import {
  /*   BackgroundColor, */
  HeadTitle,
} from '../myTransactions/MyNewTransactionsMuiComponents';
import CoinFloating from './CoinFloating';
import { ErrorBoundaryXcala } from '../ErrorBoundaryXcala';
import { HeaderTotalWallet } from './HeaderTotalWallet';

const $minHeigth = '407px';

const HeaderWallet = ({ balanceClient, isWallet, loadingBalance, errorBalance }) => {
  const theme = useTheme();
  const { user } = useSelector(state => state.auth);

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;

  const fileAs = {
    currency: 'pesos',
    fileAs: 'file as',
    fileAsComplete: 'file as complete',
    risk: 'agresivo',
    profit: '10',
  };

  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            justifyContent={'center'}
            alignItems={{ md: 'center', xs: 'center' }}
            width={{ md: '50%', xs: '100%' }}
            gap={1}>
            <AvatarSection userRisk={userRisk} />
            <Box>
              <HeadTitle textAlign={{ md: 'left', xs: 'center' }} fontSize={'26px'}>
                {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                  lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                } `}
              </HeadTitle>
              <Typography
                color={theme.palette.white.main}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={theme.typography.mediumText.fontSize}>
                {emailUser}
              </Typography>
              <Typography
                color={theme.palette.white.main}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={theme.typography.mediumText.fontSize}>
                {hideNumbers(userDni)}
              </Typography>
            </Box>
          </Stack>

          <Stack>
            <ErrorBoundaryXcala>
              <HeaderTotalWallet
                balance={balanceClient?.totalAmount}
                loadingBalance={loadingBalance}
                errorBalance={errorBalance}
              />
            </ErrorBoundaryXcala>
          </Stack>
        </WrapperContentApp>
      </WrapperBackgroundBanner>

      {isWallet === true ? (
        <Box marginTop='-120px'>
          <CoinFloating
            balanceClient={balanceClient}
            loadingBalance={loadingBalance}
            errorBalance={errorBalance}
          />
        </Box>
      ) : null}
    </>
  );
};

export default HeaderWallet;
