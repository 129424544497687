import { Stack, useMediaQuery } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useGetCashBalancePortfolio } from '../../hooks/useGetCashBalancePortfolio';
import useGetClientInvestments from '../../hooks/useGetClientInvestments';
import useGetClientInvestmentsPortfolio from '../../hooks/useGetClientInvestmentsPortfolio';
import { useGetPorfolioSimulation } from '../../hooks/useGetPorfolioSimulation';
import useGetSimulatorList from '../../hooks/useGetSimulatorList';
import { useGetUserDividend } from '../../hooks/useGetUserDividend';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';
import Spinner from '../Spinner';
import SimulationInvestment from './SimulationInvestment';
import SimulatorForm from './SimulatorForm';
import SimulatorResult from './SimulatorResult';

const SimulationRenders = ({
  statusDividendFinfolio,
  securitySelected,
  isSimulatorFund,
  typePayment,
  userRisk,
  dataPortfolioSimulationById,
  dataChart,
}) => {
  const [send, setSend] = useState(false);
  const [formData, setFormData] = useState({});
  const [dataSimulation, setDataSimulation] = useState({});
  const [isInvesment, setIsInvestment] = useState(false);
  const [userNotOB, setUserNotOB] = useState(false);
  const { id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const { user } = useSelector(state => state.auth);

  const isLGRender = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const { profilePortfolio, profileName } = dataChart;

  //object to transform years to months
  const yearsToMonths = {
    '6 meses': 6,
    '1 año': 12,
    '2 años': 24,
    '3 años': 36,
    '5 años': 60,
  };

  //user risk condition depents of path fund or portfolio
  const userRiskCondition = isSimulatorFund
    ? profileName
        ?.replace(/\bCLP\b\s*/g, '')
        .trim()
        .toLowerCase() === userRisk
    : profilePortfolio
        ?.replace(/\bCLP\b\s*/g, '')
        .trim()
        .toLowerCase() === userRisk;

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
  } = useGetCashBalancePortfolio();

  //service to check user investment funds and portfolio
  // fund
  const {
    data: dataInvesment,
    error: errorInvesments,
    loading: loadingInvesments,
  } = useGetClientInvestments();

  //portfolio
  const {
    data: dataInvestmentPortfolio,
    loading: loadingInvestmentPortfolio,
    error: errorInvestmentPortfolio,
  } = useGetClientInvestmentsPortfolio();

  // service to simulate data funds
  const [
    doGetSimulatorList,
    { data: dataSimulationFunds, error: errorSimulationFunds, loading: loadingSimulationFunds },
  ] = useGetSimulatorList();

  const successGetSimulatorList = dataSimulationFunds?.getInvestmentSimulation;
  const simulationId = successGetSimulatorList?.simulationId;

  // service to simulate data portoflios
  const {
    data: dataSimulationPortfolio,
    loading: isLoadingSimulation,
    error: errorSimulation,
  } = useGetPorfolioSimulation({
    data: dataSimulation,
  });

  //handler conditions user not OB
  const handleInvesment = () => {
    if (!userNotOB) {
      return navigate('/completeregistration');
    }
    if (userNotOB) {
      setSend(false);
      setIsInvestment(true);
    }
  };

  //handler to go back from component PortfolioInvesment to PorfolioResult
  const handleBack = () => {
    setSend(true);
    setIsInvestment(false);
  };

  //handler submit of fund or portfolio
  const handleSubmit = (values, riskPortfolio) => {
    //handler data to be simulated for portfolios
    setFormData(values);
    setDataSimulation({
      ...values,
      initialInvestment: Number.parseInt(values.initialInvestment?.replace(/\D/g, '')),
      monthlyInvestment: Number.parseInt(values.monthlyInvestment?.replace(/\D/g, ''))
        ? Number.parseInt(values.monthlyInvestment?.replace(/\D/g, ''))
        : 0,
      months: yearsToMonths[values.months],
      portfolioName: riskPortfolio?.replace(/\sCLP$/, ''),
    });

    //condition of funds to simulate data
    if (isSimulatorFund) {
      doGetSimulatorList({
        variables: {
          data: {
            ...values,
            securityId: securitySelected.ID,
            initialInvestment: Number.parseInt(values.initialInvestment?.replace(/\D/g, '')),
            monthlyInvestment: Number.parseInt(values.monthlyInvestment?.replace(/\D/g, ''))
              ? Number.parseInt(values.monthlyInvestment?.replace(/\D/g, ''))
              : 0,
            months: yearsToMonths[values.months],
          },
        },
      });
    }
    setSend(true);
  };

  const { fundName } = dataChart;

  //Result of simulated data of funds or portfolios
  const dataResultSimulation = isSimulatorFund
    ? successGetSimulatorList?.simulationData
    : dataSimulationPortfolio?.getPortfolioSimulation?.simulationData;

  let result;
  if (isSimulatorFund) {
    result = { fileAsComplete: fundName };
  } else {
    result = portfolioNameAndDetails(fundName) || {};
  }
  const { fileAsComplete } = result;

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setUserNotOB(true);
    }
  }, [user]);

  if (
    loadingBalance ||
    isLoadingSimulation ||
    loadingSimulationFunds ||
    loadingInvesments ||
    loadingInvestmentPortfolio
  ) {
    return <Spinner />;
  }

  return (
    <>
      <Stack
        sx={{
          position: 'relative',
          margin: '0 auto',
          top: { md: 0, xs: '20px' },
          width: '80%',
        }}
        flexDirection={send && isLGRender ? 'row' : 'column'}
        justifyContent={'center'}
        alignItems={'center'}
        gap={send ? '4rem' : ''}>
        {location.pathname === `/portfolio-simulation/${id}/investment` ? (
          <SimulationInvestment
            dataInvesment={dataInvesment}
            dataInvestmentPortfolio={dataInvestmentPortfolio}
            errorInvesments={errorInvesments}
            statusDividendFinfolio={statusDividendFinfolio}
            errorInvestmentPortfolio={errorInvestmentPortfolio}
            balanceData={balanceData}
            loadingBalance={loadingBalance}
            errorBalance={errorBalance}
            typePayment={typePayment}
            profilePortfolio={profilePortfolio}
            profileName={profileName}
            portfolioName={fileAsComplete}
            userRiskCondition={userRiskCondition}
            userRisk={userRisk}
            dataPortfolioSimulationById={dataPortfolioSimulationById}
          />
        ) : null}
        {!isInvesment && !send && location.pathname !== `/portfolio-simulation/${id}/investment` ? (
          <SimulatorForm
            isSimulatorFund={isSimulatorFund}
            riskPortfolio={profilePortfolio}
            dataChart={dataChart}
            errorSimulation={errorSimulation}
            formData={formData}
            handleSubmit={handleSubmit}
            send={send}
            isLoadingSimulation={isLoadingSimulation || loadingSimulationFunds}
          />
        ) : null}

        {send && (
          <SimulatorResult
            riskPortfolio={profilePortfolio}
            dataChart={dataChart}
            handleSubmit={handleSubmit}
            send={send}
            isSimulatorFund={isSimulatorFund}
            errorSimulation={errorSimulation || errorSimulationFunds}
            formData={formData}
            isLoadingSimulation={isLoadingSimulation || loadingSimulationFunds}
            dataResultSimulation={dataResultSimulation}
            handleInvesment={handleInvesment}
          />
        )}
        {isInvesment && send === false ? (
          <SimulationInvestment
            dataInvesment={dataInvesment}
            dataInvestmentPortfolio={dataInvestmentPortfolio}
            errorInvesments={errorInvesments}
            statusDividendFinfolio={statusDividendFinfolio}
            errorInvestmentPortfolio={errorInvestmentPortfolio}
            isSimulatorFund={isSimulatorFund}
            simulationId={simulationId}
            balanceData={balanceData}
            loadingBalance={loadingBalance}
            errorBalance={errorBalance}
            typePayment={typePayment}
            profilePortfolio={profilePortfolio}
            profileName={profileName}
            userRiskCondition={userRiskCondition}
            userRisk={userRisk}
            dataPortfolioSimulationById={dataPortfolioSimulationById}
            formData={formData}
            portfolioName={fileAsComplete}
            handleBack={handleBack}
          />
        ) : null}
      </Stack>
    </>
  );
};

export default SimulationRenders;
