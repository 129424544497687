import { Box, Stack, styled, useTheme } from '@mui/material';
import { ButtonsPrimary } from '../ComponentApp/buttoms/ButtonsXcala';
import SuccessStep from '../UI/successStep';
import ConditionsPassword from './ConditionsPassword';
import NewPasswordInput from './NewPasswordInput';

const PasswordGif = styled('img')({
  height: '225px',
  width: '225px',
  objectFit: 'cover',
  position: 'absolute',
  top: '-175px',
  left: '20%',
});

const CardChangePassword = ({
  mustContainData,
  step,
  password,
  validatePassword,
  currentPassword,
  confirmPassword,
  loadingChangePassword,
  handleNewPassword,
  handleCurretPassword,
  handleConfirmPassword,
}) => {
  const theme = useTheme();
  let isCurrentPassword = currentPassword.length > 0;
  let isDisableStepTwo = mustContainData.every(item => item[1] || item[2]);
  return (
    <Stack
      sx={{
        backgroundColor: theme.palette.white.main,
        boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
        borderRadius: '8px',
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-evenly',
        padding: '16px 12px',
        boxSizing: 'border-box',
        minHeight: step === 1 || step === 3 ? '496px' : { md: '580px', xs: '650px' },
        width: '100%',
        maxWidth: 800,
        margin: '0px auto',
        paddingTop: '16px',
        top: -120,
      }}>
      {step === 1 ? (
        <>
          <Box
            sx={{
              position: 'relative',
              width: { md: '50%', xs: '100%' },
              boxSizing: 'border-box',
            }}>
            <PasswordGif
              sx={{
                width: { md: '200px', xs: '180px' },
                height: { md: '200px', xs: '180px' },
                top: { md: '-166px', xs: '-162px' },
              }}
              src='/assets/images/changepassword-gif.gif'
            />
          </Box>
          <Box sx={{ width: { md: '50%', xs: '90%' } }}>
            <NewPasswordInput
              title={'Contraseña actual'}
              password={currentPassword}
              validatePassword={handleCurretPassword}
            />

            {/* <Stack width={"100%"} justifyContent={"center"} sx={{ mt: 10 }}>
              <ButtonsPrimary
                fullwidth
                name="Cambiar contraseña"
                onClick={handleNewPassword}
                disabled={
                  step === 2
                    ? !isDisableStepTwo
                    : null || loadingChangePassword || !isCurrentPassword
                }
              />
            </Stack> */}
          </Box>
        </>
      ) : step === 2 ? (
        <>
          <ConditionsPassword
            password={password}
            confirmPassword={confirmPassword}
            mustContainData={mustContainData}
            validatePassword={validatePassword}
            handleConfirmPassword={handleConfirmPassword}
          />
        </>
      ) : step === 3 ? (
        <>
          <Box
            sx={{
              display: 'flex',
              width: '90%',
              flexDirection: 'column',

              alignItems: 'center',
            }}>
            <SuccessStep
              Success_heading=' Perfecto!'
              Success_subheading='Ahora puedes ingresar a tu cuenta de Xcala con tu nueva contraseña .'
              Success_btn='Volver al inicio'
              href='/'
              setBack={null}
            />
          </Box>
        </>
      ) : null}
      {step === 1 || step === 2 ? (
        <ButtonsPrimary
          name='Cambiar contraseña'
          onClick={handleNewPassword}
          disabled={
            step === 2 ? !isDisableStepTwo : null || loadingChangePassword || !isCurrentPassword
          }
          sx={{ width: { md: '50%', xs: '100%' } }}
        />
      ) : null}
    </Stack>
  );
};

export default CardChangePassword;
