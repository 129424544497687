import { gql, useMutation } from '@apollo/client';

export const REGISTER_USER_DATA_REGISTRATION = gql`
    mutation registerUserDataRegistration($data: dataRegistration!) {
    registerUserDataRegistration (data: $data)     
    }
`;
{
  /*Actualiza los datos de un usuario en la base de datos*/
}
export default () => useMutation(REGISTER_USER_DATA_REGISTRATION);
