import RutValidator from 'w2-rut-validator';

export const validateRut = value => {
  if (value && !RutValidator.validate(value)) {
    return false;
  } else {
    return true;
  }
};

export const formatRut = value => RutValidator.format(value);
