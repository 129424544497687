export const CircleIcon = ({ strokeColors = '#0D99FF' }) => {
  return (
    <>
      <svg
        width='14'
        height='14'
        viewBox='0 0 14 14'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <circle cx='7' cy='7' r='5.5' stroke={strokeColors} stroke-width='3' />
      </svg>
    </>
  );
};
