export const handleRiskProfile = riskProfile => {
  return riskProfile === 'conservador'
    ? '/assets/images/dashboard/perfiles/conservador.svg'
    : riskProfile === 'moderado'
      ? '/assets/images/dashboard/perfiles/moderado.svg'
      : riskProfile === 'portafolio'
        ? '/assets/images/dashboard/perfiles/portafolio.svg'
        : '/assets/images/dashboard/perfiles/agresivo.svg';
};

export const handleRiskProfilePortfolio = riskProfile => {
  if (riskProfile === 'conservador') {
    return '/assets/images/perfiles/portfolioRisk/conservador.svg';
  }
  if (riskProfile === 'moderado') {
    return '/assets/images/perfiles/portfolioRisk/moderado.svg';
  }
  if (riskProfile === 'agresivo') {
    return '/assets/images/perfiles/portfolioRisk/agresivo.svg';
  }
};
