import { Box, Button, FormLabel, Stack, TextField, Typography, styled } from '@mui/material';

export const ImgStyled = styled('img')({
  width: '30px',
});

export const Typography1 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  margin: '0px',
}));
export const Typography2 = styled(Typography)(({ theme }) => ({
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
}));

export const ContainerTypos = styled(Box)({
  width: '100%',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

export const FormLabelStyled = styled(FormLabel)({
  color: 'rgba(128, 128, 128, 1)',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '16px',
  flex: '1',
});

export const ContainerSimulation = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'relative',
  justifyContent: 'center',
  alignItems: 'center',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  width: '100%',
  maxWidth: '80%',
  padding: '3rem',
}));

export const SimulationTitleImg = styled('img')({
  height: '15px',
  width: '20px',
});

export const TextStyled = styled(Box)(({ theme }) => ({
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: { lg: '15px', md: '15px', sm: '15px', xs: '10px' },
}));

export const Button1 = styled(Button)({
  height: '60px',
  margin: '0px',
  color: 'white',
  backgroundColor: '2141aa',
  width: '100%',
  '&:hover': {
    backgroundColor: '#3f63e3',
  },
});

export const InputStyled = styled(TextField)`
  .MuiInputBase-input {
    font-size: 20px;
    color: #808080;
  }
`;

export const StarContainer = styled(Stack)({
  backgroundColor: 'rgba(90, 196, 177, 1)',
  borderRadius: '35px',
  position: 'relative',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 7px',
  boxSizing: 'border-box',
  height: '37.69px',
  left: '-7.5%',
  top: '53px',
  width: '37.69px',
});

export const StarIcons = styled('img')({
  height: '16px',
  width: '16px',
  margin: '0px',
});

export const ContainerResult = styled(Box)({
  borderRadius: '8px',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  backgroundColor: '#fff',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  alignContent: 'center',
  justifyContent: 'center',
  position: 'relative',
  boxSizing: 'border-box',
  width: '100%',
  top: 0,
});

export const ContainerResultText1 = styled(Box)({
  color: 'rgba(24, 40, 73, 1)',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '1.4px',
  textTransform: 'uppercase',
});

export const ContainerResultText2 = styled(Box)({
  color: 'rgba(30, 34, 170, 1)',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '18px',
});

export const ContainerScenari = styled(Box)({
  backgroundColor: 'rgba(90, 196, 177, 0.1)',
  border: '1px solid rgba(90, 196, 177, 1)',
  boxSizing: 'border-box',
  borderRadius: '8px',
  display: 'flex',
  flexDirection: 'column',
  padding: '16px',
  width: '82%',
});

export const ContainerScenariBody = styled(Box)({
  backgroundColor: 'rgba(255, 255, 255, 1)',
  borderRadius: '8px',
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 16px',
  boxSizing: 'border-box',
  margin: '14px 0px 0px 0px',
  width: '100%',
});

export const ContainerMessage = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  boxSizing: 'border-box',
  width: '100%',
});

export const ButtonResult = styled(Button)({
  width: '250px',
  height: '50px',
  fontSize: '15px',
  fontStyle: 'Poppins',
  textTransform: 'none',
});

export const BodyContributions = styled(Stack)(({ theme }) => ({
  width: '90%',
  maxWidth: '1380px',
  margin: ' 0 auto',
  marginTop: ' -80px',
  boxSizing: 'border-box',
  flexDirection: 'row',
  zIndex: 2,
  gap: '16px',
  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
  },
}));

export const MainBody = styled(Stack)(({ theme }) => ({
  width: '100%',
  flexDirection: 'column',
  minWidth: 'calc(30%-16px)',
  boxSizing: 'border-box',
  gap: '16px',
}));

export const ContainerForm = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: theme.palette.white.main,
  borderRadius: '16px',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
  padding: '16px 28px',
  width: '100%',
}));

export const ContentBody = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  width: '100%',
  minWidth: 'calc(70% - 16px)',
  height: '100%',
  backgroundColor: theme.palette.white.main,
  padding: '20px 0',
  borderRadius: '16px',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
}));

export const TituloContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  gap: '8px',
});

export const TitleHeadResult = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: '10px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const BoxContentScenary = styled(Box)(({ theme }) => ({
  boxSizing: 'border-box',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px',
}));

export const StageText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(52, 120, 86, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '14px',
  letterSpacing: '1.4px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  margin: '0px',
  width: '100%',
  textTransform: 'uppercase',
  [theme.breakpoints.down('sm')]: {
    textAlign: 'center',
  },
}));

export const ContentFormContributions = styled(Stack)(({ theme }) => ({
  width: '90%',
  margin: ' 0 auto',
  marginTop: ' -80px',
  boxSizing: 'border-box',
  flexDirection: 'row',
  zIndex: 2,
  gap: '16px',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
  },
}));

export const Container = styled(Stack)(({ theme }) => ({
  boxSizing: 'border-box',
  backgroundColor: 'white',
  borderRadius: '16px',
  boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.25)',
  padding: '32px 48px',
  width: '100%',
  flexDirection: 'row',
  gap: '16px',
  justifyContent: 'center',
  alignItems: 'center',
  [theme.breakpoints.down('sm')]: {
    flexDirection: 'column',
    padding: '32px 32px',
  },
}));

export const ChartComponent = styled(Box)(({ theme }) => ({
  width: '90%',
  height: '100%',
  margin: '0 auto',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
    margin: '0 auto',
  },
}));

export const TituloContentt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  gap: '8px',
});
