import { Button, Stack, Typography, styled } from '@mui/material';

export const ComponentRescateCont = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
});
export const ContentAvatarRescue = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

export const RescueAvatarIcon = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
});

export const RescueAvatarDescript = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
});

export const RescueIconsPorfolio = styled('div')({
  boxShadow:
    '-3.8514773845672607px 3.8514773845672607px 17.331645965576172px rgba(61, 64, 75, 0.15)',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  left: '0px',
  top: '0px',
  height: '131px',
  width: '131px',
});
export const IconosPerfiles = styled('img')({
  height: 'auto',
  width: '131px',
  margin: '0px',
});
export const RescueAvatarPorfilio = styled('img')({
  height: 'auto',
  width: '40px',
  position: 'absolute',
  left: '80px',
  bottom: '-5px',
});

export const RescueAvatarTitle = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.textLightBlue,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.signInWelcome.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '100%',
  textTransform: 'none',
  margin: '0px',
}));
export const RescueAvatarChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '4px 0px 0px 0px',
});

export const RescueQuotas = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.museoRegular.fontWeight,
  fontSize: theme.typography.availableBalance.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
}));

export const RescueQuotasSpan = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

export const RescueMunt = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
});
export const Tienes = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '1.8px',
  textDecoration: 'none',
  textTransform: 'uppercase',
  margin: '0px',
}));

export const TitlePortafolioValue = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.tertiary.main,
  fontWeight: theme.typography.button.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '99.99999403953552%',
  textTransform: 'none',
  margin: '0px',
}));

export const ExpectedReturnValue = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.tertiary.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const CreateDatePorfolio = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.tertiary.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '4px 0px 0px 0px',
}));

/***********Cards Content **********/

export const RescueContentContent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '32px',
  boxSizing: 'border-box',
  marginTop: '-45px',
}));
export const SelectContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
});
export const TitleSectionRescue = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.museoRegular.fontWeight,
  fontSize: theme.typography.headerText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
}));
export const SectionSelectRescue = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '16px 0px 0px 0px',
});

export const ButtonContained = styled(Button)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  boxShadow:
    '0px 1px 5px rgba(0, 0, 0, 0.12), 0px 2px 2px rgba(0, 0, 0, 0.14), 0px 3px 1px rgba(0, 0, 0, 0.2)',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '60px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  padding: '8px 32px',
  color: theme.palette.white.main,
}));
export const BaseBotton = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '30px 95px',
  boxSizing: 'border-box',
  margin: '0px',
  overflow: 'hidden',
  height: '86px',
  width: '456px',
});

export const MotivacionesGenerarl = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '14px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5px 8px',
  boxSizing: 'border-box',
  height: '22px',
  margin: '0px',
  width: '42px',
}));
export const HmcText = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
