import { Box, Container } from '@mui/material';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { BodyTransactions } from '../../../components/myTransactions/BodyTransactions';
import { HeaderTransactions } from '../../../components/myTransactions/HeaderTransactions';
import useModal from '../../../hooks/custom/useModal';
import { useGetClientTransactionPortfolio } from '../../../hooks/useGetClientTransactionPortfolio';

const MyTransactionsPages = () => {
  const { isShowing, toggle } = useModal();
  const [tap, setTap] = useState('Fondos');
  const [status, setStatus] = useState(true);
  const [dataTransactionsFiltered, setDataTransactionsFiltered] = useState([]);
  const { user } = useSelector(state => state.auth);

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;

  const handleTaps = tap => {
    const isTrue = tap === 'Fondos';
    setTap(tap);
    setStatus(isTrue);
  };

  const {
    data: dataPortfolioTransactions,
    error: errorGetClientTransactionsPortfolio,
    loading: loadingGetClientTransactionsPortfolio,
  } = useGetClientTransactionPortfolio({
    principalAccount: status,
  });

  const succesGetClientTransactionsPortfolio =
    dataPortfolioTransactions?.getClientTransactionPortfolio;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (!dataPortfolioTransactions) {
      return;
    }
    setDataTransactionsFiltered(succesGetClientTransactionsPortfolio);
  }, [tap, dataPortfolioTransactions, succesGetClientTransactionsPortfolio]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
      }}>
      <HeaderTransactions
        userRisk={userRisk}
        nameUser={nameUser}
        emailUser={emailUser}
        userDni={userDni}
        lastNameUser={lastNameUser}
      />
      <Container
        maxWidth='1380px'
        sx={{
          marginTop: { md: '-120px', xs: '-100px' },
          display: 'flex',
          justifyContent: 'center',
        }}>
        <BodyTransactions
          loadingGetClientTransactionsPortfolio={loadingGetClientTransactionsPortfolio}
          tap={tap}
          handleTaps={handleTaps}
          dataTransactions={dataTransactionsFiltered}
          errorGetClientTransactionsPortfolio={errorGetClientTransactionsPortfolio}
        />
      </Container>
    </Box>
  );
};

export default MyTransactionsPages;
