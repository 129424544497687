import { gql, useQuery } from '@apollo/client';

const GET_AVATAR_QUERY = gql`
  query GetAvatar {
  getAvatar {
    id
    image
  }
}
`;

export default () => useQuery(GET_AVATAR_QUERY, { fetchPolicy: 'network-only' });
