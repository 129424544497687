import { Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { printLabelDataDetailPortfolio } from '../../../utils/modelsPortfolioChart';

const DistributionValues = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.blueMid,
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  boxSizing: 'border-box',
}));
const TitelDistributionInvText = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  textAlign: 'center',
  color: theme.palette.white.main,
  fontSize: theme.typography.h1.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '101.49999856948853%',
  textTransform: 'none',
}));
const SectionDistribucion = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '13px 0px 0px 0px',
  height: '100%',
});

const DistributionsValue = ({ dataDetailsPortfolio }) => {
  const theme = useTheme();
  return (
    <DistributionValues>
      <TitelDistributionInvText fontWeight={theme.typography.littleText.fontWeight} component={'p'}>
        Distribución porcentual de tus{' '}
        <TitelDistributionInvText fontWeight={theme.typography.h1.fontWeight} component={'span'}>
          inversiones
        </TitelDistributionInvText>
      </TitelDistributionInvText>
      <SectionDistribucion gap={'6px'}>
        {printLabelDataDetailPortfolio(dataDetailsPortfolio[0].dataChart)}
      </SectionDistribucion>
    </DistributionValues>
  );
};

export default DistributionsValue;
