import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_BANKS_QUERY = gql`
    query getBanks{
        getBanks {
            id
            code
            name
        }
    }
`;
{
  /*Trae data de nombres de bancos, código y id*/
}
export default () => useQuery(GET_BANKS_QUERY);
