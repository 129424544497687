import emailjs from '@emailjs/browser';
import { Stack, Typography, styled } from '@mui/material';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import capitalize from '../../../utils/capitalize';
import { NotificationsHeaders } from './Layout/NotificationsHeaders';
import { TextBodyNotification } from './Layout/TextBodyNotification';

const SizeSmButtonsOneStat = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  border: `1.2875816822052002px solid ${theme.palette.primary.main}`,
  boxSizing: 'border-box',
  borderRadius: '12px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '90%',
  maxWidth: '800px',
  justifyContent: 'center',
  alignItems: 'flex-start',
  overflow: 'hidden',
  minHeight: '358.2px',
  margin: '0px 32px',
}));

const ContentOverlay = styled(Stack)({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100vh',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '999',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
});

export const NotificationsXcala = ({
  activation,
  toggle,
  typeNotification = 'fetch',
  titleMessage = '',
  message = '',
  nameBtnTwo = '',
  error,
  refresh,
  msg = {
    titleNotification: 'Notificaciones Tittle Success',
    iconsNotification: 'error',
    title: titleMessage,
    textContent:
      typeNotification === 'fetch'
        ? message
        : 'Tenemos un rescate en proceso, por lo que por ahora no podremos procesar uno nuevo desde este mismo portafolio. En cuanto tu rescate esté procesado, podrás realizar uno nuevo',
    btn1: refresh !== '' ? { url: '#', text: 'Intenta de nuevo', refresh } : '',
    btn2: { url: '#', text: nameBtnTwo },
  },
}) => {
  const { titleNotification, iconsNotification, title, textContent, btn1, btn2 } = msg;

  const { user } = useSelector(state => state.auth);
  const navigator = window.navigator.appVersion;
  const platform = window.navigator.platform;

  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;
  const navigate = useNavigate();
  const location = useLocation();
  const currentTime = new Date().toLocaleString();

  const report = {
    name: fullName || '',
    securityId: user?.username || 'error de login',
    email: user?.email || '',
    currentTime: currentTime,
    errorMessage: error,
    location: location.pathname,
    navigator: navigator,
    platform: platform,
  };

  const handleSubmitReport = () => {
    emailjs
      .send(
        'service_7utjird',
        'template_v0a6xsc',
        {
          message: JSON.stringify(report),
          from_name: fullName,
          reply_to: user?.email,
        },
        'EFAYuCkTVHLBFWEFQ',
      )
      .then(
        result => {
          toast.success('Reporte enviado con exito');
        },
        error => {
          toast.error('Ups ha ocurrido un error');
        },
      );

    toggle();
    navigate('/profilerisk');
  };

  return (
    <ContentOverlay sx={{ display: activation ? 'flex' : 'none' }}>
      <SizeSmButtonsOneStat>
        <NotificationsHeaders
          typeNotification={typeNotification}
          titleNotification={titleNotification}
          iconsNotification={iconsNotification}
          handlerClickCloses={toggle}
        />
        <Stack height={'100%'} padding={'1.30rem 1.30rem'}>
          <TextBodyNotification
            title={title}
            textContent={textContent}
            handlerClickCloses={toggle}
            btn1={btn1}
            btn2={{ ...btn2, handleSubmitReport }}
          />
        </Stack>
      </SizeSmButtonsOneStat>
    </ContentOverlay>
  );
};
