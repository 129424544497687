import { gql, useQuery } from '@apollo/client';

const GET_USER_PREFERENCES_QUERY = gql`
  query getUserPreferences {
    getUserPreferences {
      preference_detail_id
      text
    }
  }
`;
{
  /*Retorna un array que contiene las preferencias de un usuario, estas pueden ser motivaciones, objetivos o intereses*/
}
export default () => useQuery(GET_USER_PREFERENCES_QUERY, { fetchPolicy: 'no-cache' });
