import { gql, useQuery } from '@apollo/client';

const GET_SECURITY_PRICE_RANGE_PORTFOLIO = gql`
  query GetSecurityPriceRangePortfolio($data: securityPricePortfolio!) {
    getSecurityPriceRangePortfolio(data: $data) {
      id
      effectiveDate
      closePrice
    }
  }
`;
{
  /*Retorna un array de objetos que contienen el precio de las cuotas de un portfolio en un rango de fechas*/
}
export const useGetSecurityPriceRangePortfolio = variables => {
  return useQuery(GET_SECURITY_PRICE_RANGE_PORTFOLIO, { variables }, { fetchPolicy: 'no-cache' });
};
