import { gql, useLazyQuery } from '@apollo/client';

const GET_QUESTIONS_QUERY = gql`
    query getQuestions{
        getQuestions {
            questionId
            question
            answers{
                answerId
                answer
            }
        }
    }
`;
{
  /*Retorna un array de objetos que contienen las preguntas para definir el perfil de riesgo de un usuario.*/
}
export default () => useLazyQuery(GET_QUESTIONS_QUERY);
