import { Grid, Stack } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ButtonsPrimary, ButtonsSecondary } from '../../../components/ComponentApp/buttoms';
import { ErrorBoundaryXcala } from '../../../components/ErrorBoundaryXcala';
import {
  BackgroundDashboard,
  CarouselContent,
  DividerSectionText,
  GreetingDashboard,
  SectionHeader,
  WalletSection,
} from '../../../components/XcalaComponents';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import CarouselDashboard from '../../../components/carouselDashboard/CarouselDashboard';
import { Onboarding } from '../../../components/dashboard';
import SectionInteraction from '../../../components/dashboard/SectionInteraction';
import { WalletDashboard } from '../../../components/dashboard/WalletDashboard';
import CardPortfolioMobile from '../../../components/portfolio/cardMobile/CardPortfolioMobile';
import { CardFoundWrapper } from '../../../components/products/newProducts/CardFoundWrapper';
import useModal from '../../../hooks/custom/useModal';
import useRedirect from '../../../hooks/custom/useRedirect';
import { useGetCarousel } from '../../../hooks/useCarousel';
import { useGetCashBalancePortfolio } from '../../../hooks/useGetCashBalancePortfolio';
import { useGetCashBalanceWithFallback } from '../../../hooks/useGetCashBalanceWithFallback';
import useGetClientInvestments from '../../../hooks/useGetClientInvestments';
import useGetClientInvestmentsPortfolio from '../../../hooks/useGetClientInvestmentsPortfolio';
import { useGetClientProfitPortfolio } from '../../../hooks/useGetClientProfitPortfolio';
import useGetConstants from '../../../hooks/useGetConstants';
import useGetModelsPortfolio from '../../../hooks/useGetModelsPortfolio';
import useGetSecurityList from '../../../hooks/useGetSecurityList';
import useGetUserBank from '../../../hooks/useGetUserBank';
import useGetUserPreferences from '../../../hooks/useGetUserPreferences';
import { updateUserAtributes } from '../../../redux/auth';
import {
  setCivilStatus,
  setGenders,
  setMoneyIncomes,
  setMoneyOrigin,
  setMonths,
  setNationalities,
  setProfessions,
  setRegions,
  setcountryPhones,
} from '../../../redux/constants';
import { addCostEffectivenessFn } from '../../../utils/addCostEffectivemess';
import { onRefresh } from '../../../utils/refreshPage';
import { sendDataToTagManager } from '../../../utils/sendDataToTagManager';

const Dashboard = () => {
  const dispatch = useDispatch();
  const [securityList, setSecurityList] = useState([]);
  const { isShowing, toggle } = useModal();
  const { handleNavigateTo } = useRedirect();

  const user = useSelector(state => state.auth.user);
  const { loadingGetInvestments, investments } = useSelector(state => state.investments);

  const {
    data: dataSecurityList,
    error: errorGetSecurityList,
    loading: loadingGetSecurityList,
    refetch,
  } = useGetSecurityList();
  const successGetSecurityList = dataSecurityList?.getSecurityList;

  const { data: dataUserBank, error: errorUserBank, loading: loadingUserBank } = useGetUserBank();
  const successUserBankDetail = dataUserBank?.getUserBankDetail;

  const {
    data: dataInvesment,
    error: errorInvesments,
    loading: loadingGetClientInvestments,
  } = useGetClientInvestments();
  const successGetClientInvestments = dataInvesment?.getClientInvestments;

  const {
    data: dataUserPreferences,
    error: errorUserPreferences,
    loading: loadingUserPreferences,
  } = useGetUserPreferences();
  const successUserPreferences = dataUserPreferences?.getUserPreferences || null;

  const {
    data: dataProfit,
    error: errorProfit,
    loading: loadingProfit,
  } = useGetClientProfitPortfolio();
  const successGetClientProfit = dataProfit?.getClientProfitPortfolio;

  const {
    data: carouselImg,
    loading: isLoadingCarouselImg,
    error: errorCarousel,
  } = useGetCarousel();

  const { data: constants, error: errorConstants, loading: loadingConstants } = useGetConstants();

  const {
    data: dataModelsPortfolio,
    loading: loadingModelsPortfolio,
    error: errorModelsPortfolio,
  } = useGetModelsPortfolio();
  const successDataModelsPortfolio = dataModelsPortfolio?.getModels;

  const {
    data: dataInvestmentPortfolio,
    loading: loadingInvestmentPortfolio,
    error: errorInvestmentPortfolio,
  } = useGetClientInvestmentsPortfolio();
  const successDataInvestmentPortfolio = dataInvestmentPortfolio?.getClientInvestmentsPortfolios;

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
  } = useGetCashBalancePortfolio();
  const {
    data: balanceDataBackup,
    error: errorBalanceBackup,
    loading: loadingBalanceBackup,
  } = useGetCashBalanceWithFallback();

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (constants && !loadingConstants) {
      dispatch(setcountryPhones(constants.getCountryPhones));
      dispatch(setGenders(constants.getGenders));
      dispatch(setMonths(constants.getMonths));
      dispatch(setCivilStatus(constants.getCivilStatus));
      dispatch(setProfessions(constants.getProfessions));
      dispatch(setMoneyIncomes(constants.getMoneyIncomes));
      dispatch(setMoneyOrigin(constants.getMoneyOrigin));
      dispatch(setNationalities(constants.getNationalities));
      dispatch(setRegions(constants.getRegions));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [constants, loadingConstants]);

  useEffect(() => {
    sendDataToTagManager('myprofilerisk', '/myprofilerisk');
  }, []);

  const handleRiskProfileId = profile => {
    if (profile === 'conservador') {
      return 1;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (profile === 'moderado') {
      return 2;
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (profile === 'agresivo') {
      return 3;
    }
  };
  const riskIdProfile = handleRiskProfileId(user['custom:profile'].toLowerCase());

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successGetSecurityList && !loadingGetSecurityList && user && user['custom:profile']) {
      const securityMatchProfileFind = successGetSecurityList.find((security, i) => {
        const custom = JSON.parse(security.Custom);
        const risk = custom.preferences.riesgo.description;
        return risk.toLowerCase() === user['custom:profile'].toLowerCase();
      });

      const securityNotMatchProfile = successGetSecurityList
        ?.filter(item => item.Name !== securityMatchProfileFind?.Name)
        .find((security, i) => {
          const custom = JSON.parse(security.Custom);
          const riskFundID = custom.preferences.riesgo.id;

          if (riskFundID === 1 && riskIdProfile === 1) {
            return custom;
            // biome-ignore lint/style/noUselessElse: <explanation>
          } else if (riskFundID === 1 && riskIdProfile === 2) {
            return custom;
            // biome-ignore lint/style/noUselessElse: <explanation>
          } else if (riskFundID === 3 && riskIdProfile === 3) {
            return custom;
          }
        });
      const filtered = [securityMatchProfileFind, securityNotMatchProfile];
      setSecurityList(filtered);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successGetSecurityList, loadingGetSecurityList, user]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successUserBankDetail && !loadingUserBank) {
      dispatch(updateUserAtributes(successUserBankDetail));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [successUserBankDetail, loadingUserBank]);

  const addCostEffectiveness = addCostEffectivenessFn(securityList);

  if (errorConstants || errorUserBank || errorUserPreferences) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        typeNotification='fetch'
        titleMessage=''
        refresh={onRefresh}
        error={errorConstants || errorUserBank || errorUserPreferences}
      />
    );
  }

  return (
    <>
      <BackgroundDashboard>
        <Stack
          width={'90%'}
          alignItems={'center'}
          gap={2}
          marginBottom={'12px'}
          justifyContent={{
            xs: 'center',
            ms: 'center',
            md: 'flex-start',
            lg: 'flex-start',
          }}>
          <GreetingDashboard />

          <SectionHeader
            gap={'8px'}
            flexWrap={{ md: 'no-wrap', xs: 'wrap' }}
            width={'100%'}
            justifyContent={'space-between'}
            flexDirection={'row'}
            sx={{
              '@media (max-width: 1350px)': {
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
              },
            }}>
            <CarouselContent
              m={0}
              justifyContent={'center'}
              alignItems={'center'}
              minWidth={{ lg: '78%', md: '100%', sm: '100%', xs: '100%' }}
              maxWidth={{ md: '100%' }}
              height={'100%'}
              sx={{
                '@media (max-width: 1493px)': {
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                },
              }}>
              <Stack
                width={'100%'}
                justifyContent={'center'}
                alignItems={'center'}
                minHeight={{ md: '315px', sm: '315px', xs: '450px' }}
                bgcolor={'#fff'}
                borderRadius='8px'>
                {/* seccion carrusel */}

                <CarouselDashboard
                  errorCarousel={errorCarousel}
                  carouselImg={carouselImg}
                  isLoadingCarouselImg={isLoadingCarouselImg}
                />
              </Stack>
            </CarouselContent>

            {/*seccion caja */}
            <WalletSection
              flexBasis={'5'}
              minWidth={{ lg: '21%', md: '100%', sm: '100%', xs: '100%' }}
              maxWidth={{ md: '100%' }}
              sx={{
                marginTop: 0,
                '@media (max-width: 1493px)': {
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '100%',
                  minHeight: '315px',
                },
              }}>
              <ErrorBoundaryXcala>
                <WalletDashboard
                  loadingBalance={loadingBalanceBackup}
                  balanceData={balanceDataBackup}
                  errorBalance={errorBalanceBackup}
                />
              </ErrorBoundaryXcala>
            </WalletSection>
          </SectionHeader>

          <Stack width={'100%'}>
            <Onboarding />
          </Stack>

          <SectionInteraction
            successGetClientInvestments={successGetClientInvestments}
            loadingGetClientInvestments={loadingGetClientInvestments}
            successGetClientProfit={successGetClientProfit}
            loadingProfit={loadingProfit}
            successDataInvestmentPortfolio={successDataInvestmentPortfolio}
            loadingInvestmentPortfolio={loadingInvestmentPortfolio}
            errorProfit={errorProfit}
            errorInvesments={errorInvesments}
            errorInvestmentPortfolio={errorInvestmentPortfolio}
            balanceData={balanceDataBackup}
            loadingBalance={loadingBalanceBackup}
            errorBalance={errorBalanceBackup}
          />
          <DividerSectionText />

          <Grid
            container
            width={'100%'}
            rowSpacing={2}
            columnSpacing={2}
            columns={{ xs: 4, sm: 12, md: 6, lg: 12 }}
            marginBottom={8}
            sx={{
              '@media (max-width: 1724px)': {
                '& > *': {
                  flexBasis: '33.33%',
                  width: '33.33%',
                },
              },
              '@media (max-width: 1354px)': {
                '& > *': {
                  flexBasis: '50%',
                  width: '50%',
                },
              },
              '@media (max-width: 1050px)': {
                '& > *': {
                  flexBasis: '100%',
                  width: '100%',
                },
              },
            }}>
            <ErrorBoundaryXcala>
              <CardFoundWrapper
                loadingGetSecurityList={loadingGetSecurityList}
                errorGetSecurityList={errorGetSecurityList}
                addCostEffectiveness={addCostEffectiveness}
              />
            </ErrorBoundaryXcala>

            {/*  {loadingGetSecurityList ? (
              <Spinner />
            ) : (
              addCostEffectiveness.map((security, index) => {
                const { Name, currency, profit } = security;
                return (
                  <CardsFound
                    loadingGetSecurityList={loadingGetSecurityList}
                    errorGetSecurityList={errorGetSecurityList}
                    addCostEffectiveness={addCostEffectiveness}
                    key={security.ID}
                    security={security}
                    fundName={Name.slice(4)}
                    rentabilityFound={profit}
                    typeCurrency={currency}
                  />
                );
              })
            )} */}
            {/*    {loadingModelsPortfolio ? (
              <Spinner />
            ) : ( */}
            <ErrorBoundaryXcala>
              <CardPortfolioMobile
                successDataModelsPortfolio={successDataModelsPortfolio}
                loadingModelsPortfolio={loadingModelsPortfolio}
                errorModelsPortfolio={errorModelsPortfolio}
              />
            </ErrorBoundaryXcala>
            {/*    )} */}
          </Grid>

          <Stack
            width={{ xs: '90%', sm: '40%' }}
            justifyContent={'center'}
            alignItems={'center'}
            direction={{
              xs: 'column-reverse',
              sm: 'column-reverse',
              md: 'column-reverse',
              lg: 'row',
            }}
            spacing={3}>
            <ButtonsSecondary
              name='Ver más Fondos'
              onClick={() => handleNavigateTo('products')}
              fullWidth
              sx={{ whiteSpace: 'nowrap' }}
            />

            <ButtonsPrimary
              name='Ver más Portfolios'
              onClick={() => handleNavigateTo('portfolio')}
              fullWidth
              sx={{ whiteSpace: 'nowrap' }}
            />
          </Stack>
        </Stack>
      </BackgroundDashboard>
    </>
  );
};

export default Dashboard;
