import { Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import { cleanChainNumber, numberWithPoint, formatLossGainValue } from '../../../utils/utils';

export const ChipIconsComponents = ({ colorsItem = 'F57E7E' }) => {
  return (
    <Stack
      sx={{
        color: colorsItem,
        height: 'clamp(0.5rem, 0.0313rem + 1.5vw, 0.875rem)',
        width: 'clamp(0.5rem, 0.0313rem + 1.5vw, 0.875rem)',
        margin: '0px',
      }}>
      <svg
        width='10'
        height='11'
        viewBox='0 0 10 11'
        xmlns='http://www.w3.org/2000/svg'
        fill={colorsItem}>
        <g id='iconsCHips'>
          <circle cx='5' cy='5.5' r='4' fill={colorsItem} />
          <circle cx='5' cy='5.5' r='4.5' stroke={colorsItem} stroke-opacity='0.5' />
        </g>
      </svg>
    </Stack>
  );
};

const ItemDistributionContent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.action.hover,
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '14px 12px',
  boxSizing: 'border-box',
}));
const ItemContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
const ItemNameChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1 1 1 auto',
  margin: '0px',
  width: '100%',
});

const NameFundItem = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: 'clamp(0.75rem, 0.2813rem + 1.5vw, 1.125rem)',
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
  margin: '0px 0px 0px 10px',
});
const DetailPortfolioContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  flex: '1 1 1 auto',
  width: '100%',
});
const DescriptionDetailPortfolio = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});
const ContentDescription = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});
const MountInvesmentFund = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  width: '100%',
});
const MontoInvertido = styled(Typography)(({ theme }) => ({
  textAlign: { md: 'center', xs: 'left' },
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  width: '100%',
}));
const ValueMountInvesment = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.titleText.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
}));

/*const formatRowValue = (value) => {
  const roundedValue = Math.round(Number.parseFloat(value));
  return roundedValue < 0
    ? `- $ ${numberWithPoint(Math.abs(roundedValue))}`
    : `$ ${numberWithPoint(roundedValue)}`;
};*/

export const ItemDescription = ({ product, colores }) => {
  const theme = useTheme();
  return (
    <ItemDistributionContent>
      <ItemContainer flexDirection={{ md: 'row', xs: 'column' }}>
        <ItemNameChips>
          <ChipIconsComponents colorsItem={colores[product.security]} />
          <NameFundItem>{product.security}</NameFundItem>
        </ItemNameChips>
        <DetailPortfolioContent>
          <DescriptionDetailPortfolio>
            <ContentDescription
              maxWidth={{ md: '450px', xs: '100%' }}
              flexDirection={{ md: 'row', xs: 'column' }}>
              <MountInvesmentFund
                flexDirection={{ xs: 'row', md: 'column' }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                alignItems={{ xs: 'center', md: 'flex-end' }}>
                <MontoInvertido textAlign={{ md: 'center', xs: 'left' }}>Tienes</MontoInvertido>
                <ValueMountInvesment textAlign={{ md: 'center', xs: 'right' }}>
                  {formatLossGainValue(product.marketValue)}
                </ValueMountInvesment>
              </MountInvesmentFund>
              <Divider
                orientation={{ md: 'vertical', xs: 'horizontal' }}
                sx={{
                  border: '0.5px solid rgba(255,255,255,0.5)',
                  height: { md: '25px', xs: '100%' },
                  width: { xs: '100%', md: '' },
                }}
                variant='fullWidth'
              />
              <MountInvesmentFund
                flexDirection={{ xs: 'row', md: 'column' }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                alignItems={{ xs: 'center', md: 'flex-end' }}>
                <MontoInvertido textAlign={{ md: 'center', xs: 'left' }}>N° Cuotas</MontoInvertido>
                <ValueMountInvesment textAlign={{ md: 'center', xs: 'right' }}>
                  {numberWithPoint(Number.parseFloat(product.unitBalance).toFixed(4))}
                </ValueMountInvesment>
              </MountInvesmentFund>
              <Divider
                orientation={{ md: 'vertical', xs: 'horizontal' }}
                sx={{
                  border: '0.5px solid rgba(255,255,255,0.5)',
                  height: { md: '25px', xs: '100%' },
                  width: { xs: '100%', md: '' },
                }}
                variant='fullWidth'
              />
              <MountInvesmentFund
                flexDirection={{ xs: 'row', md: 'column' }}
                justifyContent={{ xs: 'space-between', md: 'flex-start' }}
                alignItems={{ xs: 'center', md: 'flex-end' }}>
                <MontoInvertido textAlign={{ md: 'center', xs: 'left' }}>Depositado</MontoInvertido>
                <ValueMountInvesment textAlign={{ md: 'center', xs: 'right' }}>
                  $ {numberWithPoint(cleanChainNumber(product.fmtBuy))}
                </ValueMountInvesment>
              </MountInvesmentFund>
            </ContentDescription>
          </DescriptionDetailPortfolio>
        </DetailPortfolioContent>
      </ItemContainer>
    </ItemDistributionContent>
  );
};

export const ContentTitleTable = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '10px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
});
export const TitleTableName = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.title.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const SectionGeneralCondit = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  height: '100%',
  minHeight: '400px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
});
export const GeneralConditionCard = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1',
  margin: '0px',
  width: '100%',
});
export const GeneralConditionHead = styled(Stack)(({ theme }) => ({
  background: theme.palette.primary.main,
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '20px 30px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '0px',
  height: '67px',
}));
export const CondicionesGenerales = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const GeneralConditionBody = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '12px 16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1',
  width: '100%',
  gap: '1rem',
});

export const ItemContentDescription = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '12px',
  boxSizing: 'border-box',
});
export const TitleItemContent = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const ValueItemContent = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  color: theme.palette.primary.dark,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

export const Divaider = styled(Divider)(({ theme }) => ({
  border: `1px solid ${theme.palette.primary.main}`,
  alignSelf: 'stretch',
  width: '100%',
}));

export const GeneralConditionFooter = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.main,
  borderRadius: '0px 0px 8px 8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '20px',
  boxSizing: 'border-box',
}));

export const TitleItemFooter = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
export const ValueItemFooter = styled(Typography)(({ theme }) => ({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.mediumText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));

/** DistributionValue */

export const DistributionValue = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.blueMid,
  border: '0.41rem solid transparent',
  borderImage: 'linear-gradient(to top, rgba(30,34,170,1) , rgba(112,151,217,1)) 1',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '16px',
  boxSizing: 'border-box',
}));

export const HeadContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1 0 1 auto',
  margin: '0px auto',
  height: '87.5px',
  width: '100%',
  textAlign: 'center',
});

export const HeadTitleDistribution = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.white.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
}));
export const HeadTitleDistributionSpan = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
}));

export const SectionDistribucion = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '13px 0px 0px 0px',
  gap: '1rem',
  width: '100%',
});
