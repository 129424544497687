import { Stack } from '@mui/material';
import React from 'react';
import { ButtonsPrimary } from '../../ComponentApp/buttoms';
import {
  RescueContentContent,
  SectionSelectRescue,
  SelectContent,
  TitleSectionRescue,
} from '../../portfolio/rescuePortfolio/RescueComponent';
import SelectRescueItemFund from './SelectRescueItemFund';

const CardsContainerRescueFund = ({
  rescueType,
  radiobtnChange,
  investmentSelected,
  amount2,
  addamount2,
  mountEqual,
  cuotas,
  disabled,
  handleGoToConfirm,
}) => {
  return (
    <Stack
      sx={{
        width: '90%',
        margin: '0 auto',
        alignItems: 'center',
        mb: '25px',
        mt: '-35px',
      }}>
      <RescueContentContent
        width={{ md: '50%', sx: '80%' }}
        minHeight={{ md: '380px', sx: '400' }}
        minWidth={{ md: '740px', sx: '' }}
        mb={9}>
        <SelectContent>
          <TitleSectionRescue>¿Cuánto deseas rescatar?</TitleSectionRescue>
          <SectionSelectRescue>
            <SelectRescueItemFund
              rescueType={rescueType}
              radiobtnChange={radiobtnChange}
              investmentSelected={investmentSelected}
              amount2={amount2}
              addamount2={addamount2}
              mountEqual={mountEqual}
              cuotas={cuotas}
              disabled={disabled}
              handleGoToConfirm={handleGoToConfirm}
            />
          </SectionSelectRescue>
        </SelectContent>

        <ButtonsPrimary
          disabled={disabled}
          onClick={handleGoToConfirm}
          sx={{
            mt: 3,
            mr: 1,
          }}
          name={'Rescate fondos de inversión'}
        />
      </RescueContentContent>
    </Stack>
  );
};

export default CardsContainerRescueFund;
