import { Stack } from '@mui/material';
import { useEffect } from 'react';
import { TypographyWallet, TypographyWalletAmount } from '../Layouts/StyledComponentsMenu';
import Spinner from '../../../Spinner';
import { useGetCashBalancePortfolio } from '../../../../hooks/useGetCashBalancePortfolio';
import { FormatCurrency } from '../../../../utils/numbers';
import { useSelector } from 'react-redux';

export const WalletOpenDrawer = ({
  subItem,
  //walletAvailability,
  //loadingBalance,
  //errorBalance,
}) => {
  const { user } = useSelector(state => state.auth);

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
    refetch: refetchBalance,
  } = useGetCashBalancePortfolio();

  const cashBalance = balanceData?.getCashBalancePortfolio;

  const walletAvailability = item => {
    if (item === 'Caja Disponible') {
      return cashBalance?.balance ? FormatCurrency(cashBalance?.balance) : '$0';
    }
    if (item === 'Dinero en Tránsito') {
      return cashBalance?.transitAmount ? FormatCurrency(cashBalance?.transitAmount) : '$0';
    }
  };

  useEffect(() => {
    if (user) {
      refetchBalance();
    }
  }, [user, refetchBalance]);

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component wallet drawer open');
  }

  return (
    <Stack>
      <TypographyWallet>
        {subItem.btnNameSub}
        <TypographyWalletAmount>
          {loadingBalance ? <Spinner /> : walletAvailability(subItem.btnNameSub)}
        </TypographyWalletAmount>
      </TypographyWallet>
    </Stack>
  );
};
