import { Stack, Typography, styled } from '@mui/material';
import { useSelector } from 'react-redux';
import capitalize from '../../utils/capitalize';

const TitleDashboard = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '12px 0px 0px 0px',
  boxSizing: 'border-box',
  width: '100%',
});

const TitleType = styled(Typography)(({ theme }) => ({
  ...theme.typography.h1,
  color: theme.palette.primary.dark,
  textAlign: 'left',
  whiteSpace: '`pre-wrap',
  lineHeight: '126.5%',
  textTransform: 'none',
}));

const NameSection = styled(Typography)(({ theme }) => ({
  ...theme.typography.museoRegular,
  color: theme.palette.primary.light,
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  lineHeight: '126.5%',
  textTransform: 'none',
  WebkitTextStroke: `1.24px ${theme.palette.primary.light}`,
  margin: '32px  0px',
  paddingTop: '32px',
}));

export const GreetingDashboard = () => {
  const { user } = useSelector(state => state.auth);

  return (
    <TitleDashboard>
      <TitleType>
        Hola <NameSection component='span'>{capitalize(user.name)},</NameSection> que bueno verte de
        vuelta
      </TitleType>
    </TitleDashboard>
  );
};
