import { Grid, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { StepTwoFormOmboarding } from './StepTwoFormOmboarding';

export const CompleteOmboardingTwo = ({ setstepper, stepper, setSteppercolor }) => {
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'stepper special condictions',
        path: '/completeregistration',
        link: {
          url: '/completeregistration',
          title: 'stepper special condictions',
        },
      },
    });
  }, []);
  return (
    <>
      <Grid container maxWidth='sm' spacing={2}>
        <Grid item md={12} className='stepper-grid-registration'>
          <Typography variant='h6' className='blue_text fw-600 font-lg-24'>
            Condiciones Especiales
          </Typography>
        </Grid>
      </Grid>
      <Stack spacing={2}>
        <StepTwoFormOmboarding
          setstepper={setstepper}
          stepper={stepper}
          setSteppercolor={setSteppercolor}
        />
      </Stack>
    </>
  );
};
