import { gql, useQuery } from '@apollo/client';

const GET_SECURITY_PRICE_RANGE_QUERY = gql`
  query getSecurityPriceRange($data: securityPrice!) {
    getSecurityPriceRange(data: $data) {
      id
      effectiveDate
      closePrice
      dividend
    }
  }
`;
export const useGetSecurityPriceRangeProducts = variables => {
  return useQuery(GET_SECURITY_PRICE_RANGE_QUERY, { variables }, { fetchPolicy: 'no-cache' });
};
