import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import BodyRechargeWallet from '../../../components/wallet/BodyRechargeWallet';
import HeaderWallet from '../../../components/wallet/HeaderWallet';
import { useGetCashBalancePortfolio } from '../../../hooks/useGetCashBalancePortfolio';
import { useGetInstitutions } from '../../../hooks/useGetInstitutions';
import capitalize from '../../../utils/capitalize';

const WalletRecharge = () => {
  const { user } = useSelector(state => state.auth);

  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';
  const dniUser = user['custom:dni'].replace(/[.-]/g, '');

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
    refetch: refetchBalanceData,
  } = useGetCashBalancePortfolio();
  const balanceClient = balanceData?.getCashBalancePortfolio;

  const {
    data: institutions,
    loading: loadingInstitutions,
    error: errorInstitutions,
  } = useGetInstitutions();

  if (loadingBalance || loadingInstitutions) {
    return <Spinner />;
  }
  if (errorBalance || errorInstitutions) {
    // error fetch modal
  }

  return (
    <>
      <HeaderWallet
        balanceClient={balanceClient}
        fullName={fullName}
        userRisk={userRisk}
        isWallet={false}
      />
      <BodyRechargeWallet institutions={institutions} dni={dniUser} />
    </>
  );
};

export default WalletRecharge;
