import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_SECURITY_TRANSACTIONS_QUERY = gql`
  query getSecurityTransactions($data: trxInput!) {
    getSecurityTransactions(data: $data) {
      id
      amount
      price
      units
      status
      subType
      subTypeDetail
      tradeDate
      securityId
      securityName
      accountId
      note
    }
  }
`;

export const useGetSecurityTransactionsProducts = variables => {
  return useQuery(GET_SECURITY_TRANSACTIONS_QUERY, { variables }, { fetchPolicy: 'no-cache' });
};
