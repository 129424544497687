import React from 'react';
import {
  DescriptionComponents,
  KeyDescription,
  KeyValue,
} from '../../XcalaComponents/PorfolioComponents';

const DescriptionComponentPortfolio = ({
  KeyDescriptions = 'Description',
  KeyValues = 'Value',
}) => {
  return (
    <>
      <DescriptionComponents>
        <KeyDescription>{KeyDescriptions}</KeyDescription>
        <KeyValue>{KeyValues}</KeyValue>
      </DescriptionComponents>
    </>
  );
};

export default DescriptionComponentPortfolio;
