import { Button, Stack, Typography, styled } from '@mui/material';

export const ComponentsSection = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  flexDirection: 'column',
  borderRadius: '8px',
  flex: '1 1 560px',
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '18px',
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: { md: '600px' },
  margin: '0px',
  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.081)',
}));
export const SectionFromTitle = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
}));
export const TitleSectionFrom = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  letterSpacing: '0.1px',
  textDecoration: 'none',
  lineHeight: '22px',
  textTransform: 'none',
  margin: '0px',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.description.fontSize,
  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.description.fontSize,
  },
}));

export const BtnEdit = styled(Button)(({ theme }) => ({
  textTransform: 'none',
  width: '80px',
  backgroundColor: theme.palette.transparent.main,
  fontWeight: theme.typography.titleText.fontWeight,
}));

export const SectionInteractive = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1 1 30%',
  margin: '0px',
  gap: '24px',
});

export const TextCuent = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0.13932833075523376px',
  textDecoration: 'none',
  lineHeight: '22.29254150390625px',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '20px 0px 0px 0px',
}));

export const ButtonsAcount = styled(Button)({
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  width: '100%',
  height: '55.74px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '8px 0px',
  boxSizing: 'border-box',
  textDecoration: 'none',
});

export const CardContent = styled(Stack)(({ theme }) => ({
  width: '100%',
  height: '100%',
  padding: 1.2,
  backgroundColor: theme.palette.white.main,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: '16px 32px',
  paddingBottom: '30px',
  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.081)',
}));

export const CardContentAvatar = styled(Stack)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '100%',
  padding: 1.2,
  backgroundColor: theme.palette.white.main,
  boxSizing: 'border-box',
  borderRadius: '8px',
  padding: '0px 32px',
  boxShadow: '0px 0px 10px 5px rgba(0, 0, 0, 0.081)',
}));

export const TitleSection = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.title.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

export const EditIcons = () => {
  return (
    <svg width='16' height='17' viewBox='0 0 16 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='icons'>
        <path
          id='Vector'
          d='M12.8666 6.44998L10.0333 3.64998L10.9666 2.71665C11.2222 2.46109 11.5362 2.33331 11.9086 2.33331C12.2811 2.33331 12.5948 2.46109 12.8499 2.71665L13.7833 3.64998C14.0388 3.90554 14.1722 4.21398 14.1833 4.57531C14.1944 4.93665 14.0722 5.24487 13.8166 5.49998L12.8666 6.44998ZM11.8999 7.43331L4.83327 14.5H1.99994V11.6666L9.06661 4.59998L11.8999 7.43331Z'
          fill='currentColor'
        />
      </g>
    </svg>
  );
};

export const DivisionSectionFrom = ({ isEdit, handleOpenEdit, textTitle }) => {
  return (
    <SectionFromTitle>
      <TitleSectionFrom>{textTitle}</TitleSectionFrom>
      <BtnEdit
        sx={{ display: isEdit ? 'none' : 'null' }}
        onClick={handleOpenEdit}
        startIcon={<EditIcons />}>
        Editar
      </BtnEdit>
    </SectionFromTitle>
  );
};
