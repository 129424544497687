import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelectOB } from './CustomSelectOB';

export const GenderSelectOB = ({ selected, setSelected }) => {
  const genders = useSelector(state => state.constants.genders);
  const [menuItems, setMenuItems] = useState(genders);
  const [defaultelect, setDefaultelect] = useState('');

  useEffect(() => {
    const aux = menuItems.find(item => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);
  return (
    <CustomSelectOB
      label='Género'
      Labelid='Género'
      placholder='Género'
      Selectid='Género'
      id='Género'
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};
