import { gql, useQuery } from '@apollo/client';

const GET_CONSTANTS_QUERY = gql`
  query getCountryPhones {
    getCountryPhones {
      id
      name
      value
    }
    getGenders {
      id
      name
      value
    }
    getMonths {
      id
      name
      value
    }
    getCivilStatus {
      id
      name
      value
    }
    getProfessions {
      id
      name
      value
    }
    getMoneyIncomes {
      id
      name
      value
    }
    getMoneyOrigin {
      id
      name
      value
    }
    getNationalities {
      id
      name
      value
    }
    getRegions {
      id
      name
      romanNumber
      number
      abbreviation
      communes {
        id
        name
        identifier
      }
    }
  }
`;

export default () => useQuery(GET_CONSTANTS_QUERY);
