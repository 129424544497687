import React from 'react';
import { CardsFound } from './CardsFound';
import { FilterWithoutData } from '../../XcalaComponents/FilterWithoutData';
import Spinner from '../../Spinner';

export const CardFoundViewWrapper = ({
  fundsFilteredToShow,
  loadingGetSecurityList,
  errorGetSecurityList,
}) => {
  if (loadingGetSecurityList) {
    return <Spinner />;
  }

  if (errorGetSecurityList) {
    throw new Error('Error cardFound');
  }

  if (!fundsFilteredToShow || fundsFilteredToShow.length < 1) {
    return <FilterWithoutData />;
  }

  return (
    <>
      {fundsFilteredToShow.map(security => {
        const { Name, currency, profit, ID } = security;

        return (
          <CardsFound
            key={ID}
            security={security}
            fundName={Name.slice(4)}
            rentabilityFound={profit}
            typeCurrency={currency}
          />
        );
      })}
    </>
  );
};
