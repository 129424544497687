import { Stack, Typography, useTheme } from '@mui/material';
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from 'chart.js/auto';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import React from 'react';
import { Doughnut } from 'react-chartjs-2';
ChartJS.register(ArcElement, Tooltip, Legend, ChartDataLabels);

const ChartDoughnutXcala = ({
  dataChart,
  height = '100%',
  width = '100%',
  fontSize = 14,
  tooltip = true,
}) => {
  const theme = useTheme();
  const miOptions = {
    maintainAspectRatio: true,
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: tooltip,
        callbacks: {
          label: context => {
            const value = context.parsed;
            return `${value.toLocaleString('es-ES', {
              maximumFractionDigits: 2,
            })} %`;
          },
        },
      },
      datalabels: {
        formatter: (value, context) => {
          return `${value.toLocaleString('es-ES', {
            maximumFractionDigits: 2,
          })} %`;
        },
        color: theme.palette.white.main,
        font: {
          weight: 'bold',
          size: fontSize,
        },
        anchor: 'center',
        align: 'center',
        textShadowColor: 'black',
        textShadowBlur: 5,
      },
    },
  };

  const formatChartData = data => {
    const formattedData = { ...data };

    if (formattedData.labels?.length) {
      formattedData.labels = formattedData.labels.map(label => {
        return label.replace('.', ',');
      });
    }

    return formattedData;
  };

  const formattedData = formatChartData(dataChart);
  return (
    <Stack height={height} width={width} justifyContent={'center'} alignItems={'center'}>
      <Doughnut height={100} width={100} data={formattedData} options={miOptions} />
    </Stack>
  );
};

export default ChartDoughnutXcala;
