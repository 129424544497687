import { gql, useQuery } from '@apollo/client';

const GET_USER_DIVIDEND = gql`
  query getUserDividendFinfolio {
    getUserDividendFinfolio {
      ID
      Custom {
        Name
        Description
        Value
      }
    }
  }
`;

export const useGetUserDividend = variables => {
  return useQuery(GET_USER_DIVIDEND, { variables }, { fetchPolicy: 'no-cache' });
};
