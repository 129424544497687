import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_USER_BANK = gql`
  query getUserBankDetail {
    getUserBankDetail {
      bank {
        bank_id
        bank_name
      }
      account_number
      bankType {
        bank_type_id
        bank_type_name
      }
    }
  }
`;
export const useGetBankClient = () => {
  return useLazyQuery(GET_USER_BANK);
};

const GET_BANK_ACCOUNT_TYPES_QUERY = gql`
  query getBankAccountTypes {
    getBankAccountTypes {
      id
      name
    }
  }
`;

export const useGetAccountTypes = () => {
  return useQuery(GET_BANK_ACCOUNT_TYPES_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
};

const GET_BANKS_QUERY = gql`
  query getBanks {
    getBanks {
      id
      code
      name
    }
  }
`;
{
  /*Retorna un array de objetos con las transacciones de un usuario*/
}
export const useGetBanks = () => {
  return useQuery(GET_BANKS_QUERY, {
    fetchPolicy: 'cache-and-network',
  });
};
