import useGetAvatar from '../../hooks/useGetAvatar';
import Spinner from '../Spinner';
import { Avatar } from '@mui/material';

export const AvatarGlobal = ({ width = 96, height = 96 }) => {
  const { data: dataAvatar, error: errorAvatar, loading: loadingAvatar } = useGetAvatar();

  const userAvatar = dataAvatar?.getAvatar;

  if (errorAvatar) {
    return <p>Error al cargar el avatar</p>;
  }

  return loadingAvatar ? (
    <Spinner />
  ) : (
    <Avatar
      alt='Avatar'
      src={userAvatar?.image || '/assets/images/default-avatar.svg'}
      sx={{ width: width, height: height }}
    />
  );
};
