import { gql, useMutation } from '@apollo/client';
import useGetAvatar from './useGetAvatar';

const UPDATE_AVATAR = gql`
  mutation UpdateAvatar($avatarId: Int!) {
    updateAvatar(avatarId: $avatarId)
  }
`;

export const useUpdateAvatar = () => {
  const { refetch } = useGetAvatar();
  return useMutation(UPDATE_AVATAR, {
    onCompleted: () => {
      refetch();
    },
  });
};
