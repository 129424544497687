import { gql, useQuery } from '@apollo/client';

const GET_MOTIVATIONS_QUERY = gql`
	query getMotivations {
		getMotivations{
			id
			text
			icon_name
		}
	}
`;
{
  /*Retorna un array de objetos con las motivaciones de un usuario, incluyendo el id, el icono, el texto y el tipo*/
}
export default () => useQuery(GET_MOTIVATIONS_QUERY);
