import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_CLIENT_INVESTMENTS_QUERY = gql`
  query getClientInvestments {
    getClientInvestments {
      id
      name
      fileAs
      fmtUnitBalance
      unitBalance
      fmtMarketValue
      marketValue
      fmtCapitalAppreciation
      capitalAppreciation
      fmtDividends
      dividends
      fmtBuy
      buy
      fmtSell
      sell
      fmtProfitPercent
      profitPercent
      currency
      unitValue
      custom
    }
  }
`;
{
  /*Retorna un array de objetos con las inversiones del usuario*/
}
export default () => useQuery(GET_CLIENT_INVESTMENTS_QUERY, { fetchPolicy: 'no-cache' });
