import {
  Button,
  Card,
  CardContent,
  FormControl,
  MenuItem,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { wait } from '@testing-library/user-event/dist/utils';
import { useFormik } from 'formik';
import React from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import useModal from '../../hooks/custom/useModal';
import { useCreateClientBank } from '../../hooks/useCreateClientBank';
import { onRefresh } from '../../utils/refreshPage';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';

const BankAccountForm = ({ bankDetail, accountTypes, banks, onClose }) => {
  const theme = useTheme();
  const { isShowing, toggle } = useModal();
  const formik = useFormik({
    initialValues: {
      bank: '' || bankDetail?.bank?.bank_id,
      typeAccount: '' || bankDetail?.bankType?.bank_type_id,
      accountNumber: '' || bankDetail?.account_number,
    },
    validationSchema: Yup.object({
      bank: Yup.string().required('Campo requerido'),
      typeAccount: Yup.string().required('Campo requerido'),
      accountNumber: Yup.string().required('Campo requerido'),
    }),
    validate: values => {
      const errors = {};
      if (values.accountNumber.length > 20) {
        errors.accountNumber = 'El número de cuenta no puede tener más de 20 dígitos';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      try {
        const { data: createBank } = await createClientBank({
          variables: {
            data: {
              accountNumber: values.accountNumber,
              accountTypeId: Number.parseInt(values.typeAccount),
              bankId: Number.parseInt(values.bank),
            },
          },
        });
        onClose();
        await wait(300);
        toast.success(bankDetail ? 'Actualización exitosa' : 'Creación exitosa');
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });
  const handleAccountNumberChange = event => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '').slice(0, 20);
    formik.setFieldValue('accountNumber', numericValue);
  };
  const [createClientBank, { loading: loadingCreateBank, error: errorCreateBank }] =
    useCreateClientBank();

  if (loadingCreateBank) {
    return <Spinner />;
  }
  if (errorCreateBank) {
    return (
      <ModalErrorFetch
        message='Me temo que tendras que hacerlo de nuevo.... algo salio mal'
        error={errorCreateBank}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <>
      <Card
        sx={{
          borderRadius: 4,
          width: 500,
          minHeight: 500,
          display: 'flex',
          flexDirection: 'column',
          padding: 3,
        }}>
        <form onSubmit={formik.handleSubmit}>
          <CardContent>
            <Stack direction='column' justifyContent='center' alignItems='center' spacing={4}>
              <Typography
                sx={{
                  color: theme.palette.primary.main,
                  fontSize: theme.typography.titleText.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                  lineHeight: 2,
                }}>
                Banco de depósito
              </Typography>
              <FormControl variant='standard' sx={{ m: 1, minWidth: 300 }}>
                <TextField
                  error={Boolean(formik.touched.bank && formik.errors.bank)}
                  fullWidth
                  helperText={formik.touched.bank && formik.errors.bank}
                  label='Banco'
                  name='bank'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.bank}>
                  {banks?.getBanks?.map(bank => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <FormControl variant='standard' sx={{ m: 1, minWidth: 300 }}>
                <TextField
                  error={Boolean(formik.touched.typeAccount && formik.errors.typeAccount)}
                  fullWidth
                  helperText={formik.touched.typeAccount && formik.errors.typeAccount}
                  label='Tipo de Cuenta'
                  name='typeAccount'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.typeAccount}>
                  {accountTypes?.getBankAccountTypes?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>
              <FormControl variant='standard' sx={{ m: 1, minWidth: 300 }}>
                <TextField
                  error={Boolean(formik.touched.accountNumber && formik.errors.accountNumber)}
                  fullWidth
                  helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                  label='N° de cuenta'
                  name='accountNumber'
                  onBlur={formik.handleBlur}
                  onChange={handleAccountNumberChange}
                  type='text'
                  value={formik.values.accountNumber}
                />
              </FormControl>

              <Stack justifyContent='center' alignItems='center'>
                <Button
                  disabled={formik.isSubmitting}
                  type='submit'
                  variant='contained'
                  sx={{
                    marginTop: 5,
                    borderRadius: '8px',
                    color: theme.palette.white.main,
                    border: 'none',
                    background: theme.palette.primary.main,
                    textTransform: 'none',
                    fontSize: theme.typography.button.fontSize,
                    ':hover': {
                      background: theme.palette.primary.main,
                    },
                  }}>
                  {bankDetail ? 'Actualizar Datos Bancarios' : 'Guardar Banco'}
                </Button>
                <Stack>
                  <Button
                    onClick={() => onClose()}
                    sx={{
                      marginTop: 2,
                      borderRadius: '8px',
                      color: theme.palette.primary.main,
                      border: 'none',
                      background: '#F4F4FB',
                      fontWeight: theme.typography.button.fontWeight,
                      textTransform: 'none',
                      fontSize: theme.typography.button.fontSize,
                      ':hover': {
                        background: '#F4F4FB',
                      },
                    }}>
                    Cancelar
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </CardContent>
        </form>
      </Card>
    </>
  );
};

export default BankAccountForm;
