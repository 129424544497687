import { gql, useQuery } from '@apollo/client';

const GET_CASH_BALANCE_PORTFOLIO = gql`
  query GetCashBalancePortfolio {
    getCashBalancePortfolio {
      totalAmount
      formattedAmount
      transitAmount
      balance
    }
  }
`;

const GET_CASH_BALANCE_BACKUP = gql`
  query GetCashBalanceBackup {
    getCashBalanceBackup {
      totalAmount
      formattedAmount
      transitAmount
      balance
    }
  }
`;

export const useGetCashBalanceWithFallback = () => {
  // Consulta principal
  const {
    data: mainData,
    loading: mainLoading,
    error: mainError,
    refetch: refetchMain,
    ...mainQueryProps
  } = useQuery(GET_CASH_BALANCE_PORTFOLIO, { fetchPolicy: 'no-cache' });

  // Consulta de respaldo
  const {
    data: backupData,
    loading: backupLoading,
    error: backupError,
    refetch: refetchBackup,
    ...backupQueryProps
  } = useQuery(GET_CASH_BALANCE_BACKUP, {
    fetchPolicy: 'no-cache',
    skip: !mainError, // Solo ejecutar si falla la consulta principal
  });

  // Determinar los datos y estados finales basados en cuál consulta fue exitosa
  const data = mainData?.getCashBalancePortfolio || backupData?.getCashBalanceBackup;
  const loading = mainLoading || backupLoading;
  const error = mainError && backupError; // Retorna error si ambos fallan

  // Refetch que intenta primero la consulta principal y luego la de respaldo si falla
  const refetch = mainError ? refetchBackup : refetchMain;

  // Combina todas las propiedades de Apollo en un solo objeto de retorno
  return {
    data,
    loading,
    error,
    refetch,
    mainQueryProps, // Acceso a propiedades de la consulta principal
    backupQueryProps, // Acceso a propiedades de la consulta de respaldo
  };
};
