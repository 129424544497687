import { gql, useMutation } from '@apollo/client';

export const REGISTER_USER_BANK = gql`
    mutation registerUserBank( $data: userBank! ) {
        registerUserBank (data: $data)
    }
`;
{
  /*Registra los datos de la cuenta bancaria de un usuario*/
}
export default () => useMutation(REGISTER_USER_BANK);
