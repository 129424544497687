import React from 'react';
import { DeteilValue, PorfolioStack, ValuePorfolio } from './PortfolioSectionComponent';
import { ExpectedReturnValue, TitlePortafolioValue } from './RescueComponent';

const PorfolioValueSection = ({ marketValue, typeProduct }) => {
  return (
    <PorfolioStack
      display={'flex'}
      alignItems={{ md: 'flex-end', xs: 'center' }}
      mb={{ md: '0px', xs: '48px' }}
      sx={{ marginTop: { md: '-31px', xs: '0px' } }}>
      <ValuePorfolio>
        <TitlePortafolioValue
          fontSize={{ md: '40px', xs: '32px' }}
          marginTop={{
            md: '0px',
            xs: '32px',
          }}>{`$${marketValue.toLocaleString('es-ES')}`}</TitlePortafolioValue>
      </ValuePorfolio>
      <DeteilValue alignItems={{ md: 'flex-end', xs: 'center' }}>
        <ExpectedReturnValue sx={{ textAlign: { md: 'rigth', xs: 'center' } }}>
          Monto disponible en el {typeProduct}
        </ExpectedReturnValue>
      </DeteilValue>
    </PorfolioStack>
  );
};

export default PorfolioValueSection;
