import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import PortraitIcon from '@mui/icons-material/Portrait';
import { Stack, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { ButtonsPrimary } from '../../../components/ComponentApp/buttoms';
import LayoutProfile from '../../../components/Layout/InternalLayout/LayoutProfile';
import { NewCompleteRegisterStepper, alreadyDone } from './NewCompleteRegisterStepper';

const ParagraphDetail = styled(Typography)({
  color: '182849',
  lineHeight: '1.5',
  fontWeight: '300',
});

export const NewCompleteRegistration = () => {
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const [start, setStart] = useState(true);
  const [back, setBack] = useState(true);

  const continueRegistration = () => {
    setStart(preState => !preState);
  };

  let step = 1;

  if (!user['custom:checkid-sign-doc']) {
    step = 4;
  }
  if (!user['custom:checkid-sign']) {
    step = 1;
  }

  const [stepper, setstepper] = useState(step ? step : 1);
  const [foreign, setForeign] = useState(false);
  const [steppercolor, setSteppercolor] = useState({
    comp1: alreadyDone(1, step),
    comp2: alreadyDone(2, step),
    comp3: alreadyDone(3, step),
    comp4: alreadyDone(4, step),
    comp5: alreadyDone(5, step),
  });

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'completeregistration',
        path: '/completeregistration',
        link: {
          url: '/completeregistration',
          title: 'onboarding completeregistration',
        },
      },
    });
  }, []);

  return (
    <>
      <LayoutProfile
        user={user}
        omboarding={true}
        selectedContent={
          'para continuar solo debes completar tu registro en Xcala. Sólo tardarás un par de minutos y ganarás mucho a cambio.'
        }
        title='Completa tu registro'
        stepper={stepper}
        stepperLong={[{}, {}, {}, {}]}
        foreign={foreign}>
        <Stack
          sx={{
            width: { md: `${start ? '60%' : '100%'}` },
            mx: 'auto',
            boxSizing: 'border-box',
            flexDirection: 'column',
            padding: 'clamp(1rem, -1.8571rem + 12.1905vw, 2rem);',
            bgcolor: theme.palette.white.main,
            borderRadius: '1rem',
            boxShadow: '0px 0px 1rem 0px rgba(0, 0, 0, 0.1)',
            justifyContent: 'center',
            alignItems: 'center',
            marginBottom: '10rem',
          }}
          gap={1}>
          {start ? (
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'column',
                gap: 3,
                marginBottom: '1rem',
                padding: { md: '0px 40px', xs: '0px 20px' },
              }}>
              <Typography variant='h6' className='blue_text fw-600 font-lg-24'>
                Ten en cuenta que
              </Typography>

              <Stack direction={'row'} gap={1}>
                <PortraitIcon
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
                <ParagraphDetail>
                  Debes tener tu cédula de identidad o pasaporte a la mano
                </ParagraphDetail>
              </Stack>

              <Stack direction={'row'} gap={1}>
                <PhoneAndroidIcon
                  sx={{
                    color: theme.palette.primary.main,
                  }}
                />
                <ParagraphDetail>
                  Es más fácil hacer este proceso con tu celular debido a que necesitarás tomar
                  fotos, por lo que también deberías tenerlo a mano.
                </ParagraphDetail>
              </Stack>

              <Stack
                width={'100%'}
                sx={{
                  justifyContent: 'center',
                  alignItems: 'center',
                }}>
                <Stack width={{ md: '40%', xs: '100%' }}>
                  <ButtonsPrimary
                    name='Continuar'
                    // disabled={disabled}
                    // onClick={secondStep}
                    onClick={continueRegistration}
                    fullWidth
                  />
                </Stack>
              </Stack>
            </Stack>
          ) : (
            <Stack
              sx={{
                width: '100%',
                flexDirection: 'column',
                gap: 3,
                marginBottom: '1rem',
                padding: '0px 20px',
              }}>
              <NewCompleteRegisterStepper
                setBack={setBack}
                step={step}
                stepper={stepper}
                steppercolor={steppercolor}
                setstepper={setstepper}
                setSteppercolor={setSteppercolor}
                foreign={foreign}
                setForeign={setForeign}
              />
            </Stack>
          )}
        </Stack>
      </LayoutProfile>
    </>
  );
};
