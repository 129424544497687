import { useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import ModalErrorFetch from '../../../components/UI/ModalErrorFetch';
import SimulationHeader from '../../../components/simulator/SimulationHeader';
import SimulationRenders from '../../../components/simulator/SimulationRenders';
import useModal from '../../../hooks/custom/useModal';
import { useGetModelsPortfolioById } from '../../../hooks/useGetModelsPortfolioById';
import useGetSecurityList from '../../../hooks/useGetSecurityList';
import { useGetUserDividend } from '../../../hooks/useGetUserDividend';
import { AddCurrency } from '../../../utils/addCostEffectivemess';
import { modelsPortfolioChartById } from '../../../utils/modelsPortfolioChart';
import { onRefresh } from '../../../utils/refreshPage';
import { getFirstElement } from '../../../utils/utils';

const NewSimulator = () => {
  const { isShowing, toggle } = useModal();
  const { id } = useParams();

  const securitySelected = useSelector(state => state.simulation.securitySelected);
  const user = useSelector(state => state.auth.user);
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  //Condition for path simulator funds
  const location = useLocation();
  let isSimulatorFund = location.pathname === '/simulator';

  const custom = isSimulatorFund ? JSON.parse(securitySelected.Custom) : null;

  const motivations = getFirstElement(custom?.preferences.motivaciones);
  const interests = getFirstElement(custom?.preferences.intereses);
  const motivationsId = custom?.preferences.motivaciones[0].id;
  const risk = custom?.preferences.riesgo.description;
  const interestsId = custom?.preferences.intereses[0].id;

  const fileAs = {
    currency: isSimulatorFund ? AddCurrency(securitySelected?.Name) : null,
    fileAs: 'FileAsName',
    fileAsComplete: securitySelected?.Name?.slice(4),
    risk: risk,
    motivation: motivations?.split('-')[0],
    motivationId: motivationsId,
    interests: interests,
    interestsId: interestsId,
    products: true,
    profit: securitySelected?.profit,
  };

  const fundProfileNames = {
    fundName: securitySelected?.Name,
    profileName: custom?.preferences?.riesgo?.description,
  };

  const {
    data: statusDividend,
    loading: loadingStatusDividend,
    error: errorStatusDividend,
    refetch,
  } = useGetUserDividend();

  const {
    data: dataSecurityList,
    error: errorGetSecurityList,
    loading: loadingGetSecurityList,
  } = useGetSecurityList();

  const {
    data: dataPortolioById,
    loading: loadingDataPortolioById,
    error: errorDataPortolioById,
  } = useGetModelsPortfolioById({
    modelId: id,
  });

  const dataPortfolioSimulationById = dataPortolioById?.getModelById;
  const chartModels = dataSecurityList?.getSecurityList;

  if (loadingDataPortolioById || loadingGetSecurityList || loadingStatusDividend) {
    return <Spinner />;
  }
  let dataPortfolioModels;
  if (isSimulatorFund) {
    dataPortfolioModels = null;
  } else {
    dataPortfolioModels = modelsPortfolioChartById(dataPortfolioSimulationById, chartModels);
  }

  const statusDividendFinfolio = statusDividend?.getUserDividendFinfolio?.Custom.find(item => {
    return item.Description === securitySelected?.ID?.toLowerCase();
  });

  const typePayment = isSimulatorFund
    ? securitySelected.currency
    : dataPortfolioSimulationById[0].currency;
  const dataChart = isSimulatorFund ? fundProfileNames : dataPortfolioModels[0];

  const dataSelected = isSimulatorFund ? securitySelected : dataPortfolioSimulationById;

  if (errorGetSecurityList || errorDataPortolioById || errorStatusDividend) {
    return (
      <ModalErrorFetch
        message='Lo sentimos, algo no salio bien acá....'
        error={errorGetSecurityList || errorDataPortolioById || errorStatusDividend}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <>
      <SimulationHeader
        fileAs={fileAs}
        isSimulatorFund={isSimulatorFund}
        typePayment={typePayment}
        userRisk={userRisk}
        dataChart={dataChart}
      />

      <SimulationRenders
        statusDividendFinfolio={statusDividendFinfolio}
        securitySelected={securitySelected}
        isSimulatorFund={isSimulatorFund}
        typePayment={typePayment}
        userRisk={userRisk}
        dataChart={dataChart}
        dataPortfolioSimulationById={dataSelected}
      />
    </>
  );
};

export default NewSimulator;
