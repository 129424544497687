import {
  EmailRounded,
  KeyRounded,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Box,
  CircularProgress,
  IconButton,
  Input,
  InputAdornment,
  Link,
  Stack,
  useTheme,
} from "@mui/material";
import { message } from "antd";
import React, { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonsPrimary } from "../../components/ComponentApp/buttoms/ButtonsXcala";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";
import {
  resendSignUp,
  resetSignIn,
  resetUserTemp,
  signIn,
  updateEmailTemp,
  updateTempUserAtributes,
} from "../../redux/auth";

const SignIn = () => {
  const [values, setValues] = useState({
    password: "",
    username: "",
    showPassword: false,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const theme = useTheme();

  const dispatch = useDispatch();
  let navigate = useNavigate();
  const { user, loadingSignIn, errorSignIn } = useSelector(
    (state) => state.auth
  );

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleClickShowPassword = () => {
    setValues({
      ...values,
      showPassword: !values.showPassword,
    });
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onFinish = () => {
    if (values.username.trim() === "" || values.password.trim() === "") {
      toast.error("Por favor, completa todos los campos.");
      return;
    }
    setIsLoading(true);
    dispatch(updateEmailTemp(values.username));
    dispatch(signIn(values));
  };

  useEffect(() => {
    if (user && !loadingSignIn) {
      if (user["custom:profile"]) {
        navigate("/myprofilerisk");
      } else {
        navigate("/profile");
      }
    }
  }, [user, loadingSignIn]);

  useEffect(() => {
    if (errorSignIn && !loadingSignIn) {
      if (errorSignIn === "Usuario no ha confirmado la cuenta.") {
        dispatch(resendSignUp({ username: values.username }));
        dispatch(
          updateTempUserAtributes({
            email: values.username,
          })
        );
        navigate("/signupconfirm");
      } else if (
        errorSignIn &&
        errorSignIn ===
          "La cantidad de intentos ha excedido el máximo.  Por favor, cambie su contraseña."
      ) {
        message.error(errorSignIn);
        navigate("/forgotpassword");
      } else if (errorSignIn && errorSignIn === "Usuario no existe.") {
        setIsLoading(false);
        toast.error("Usuario no existe.");
      } else if (
        errorSignIn &&
        errorSignIn === "Usuario o contraseña invalidas."
      ) {
        setIsLoading(false);
        toast.error("Usuario o contraseña invalidas.");
      } else if (isOnline === false) {
        toast.error("Hay un error en tu conexión a internet.");
        setIsLoading(false);
      } else {
        message.error(errorSignIn);
      }
      dispatch(resetSignIn());
    }
  }, [errorSignIn, loadingSignIn]);

  useEffect(() => {
    const handleOnline = () => {
      setIsOnline(true);
    };

    const handleOffline = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", handleOnline);
    window.addEventListener("offline", handleOffline);

    return () => {
      window.removeEventListener("online", handleOnline);
      window.removeEventListener("offline", handleOffline);
    };
  }, []);

  useEffect(() => {
    dispatch(resetUserTemp());
  }, []);

  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: "border-box",
            borderRadius: "24px",
            padding: "32px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          marginTop="clamp(0.875rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={{ xs: "90%", md: "400px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack
            sx={{
              width: "100%",
              background: theme.palette.white.main,
              justifyContent: "center",
              alignItems: "center",
              padding: "16px",
              gap: 5,
            }}
            minWidth={"80%"}
          >
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <EmailRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <Input
                fullWidth
                id="standard-basic"
                variant="standard"
                type="email"
                value={values.username}
                onChange={handleChange("username")}
                required
                placeholder="correo@correo.com"
              />
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-end",
                width: "100%",
              }}
            >
              <KeyRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <Input
                fullWidth
                id="standard-adornment-password"
                type={values.showPassword ? "text" : "password"}
                value={values.password}
                onChange={handleChange("password")}
                placeholder="********************"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                    >
                      {values.showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>

            <Stack
              flexDirection="row"
              justifyContent={"flex-start"}
              width="100%"
            >
              <Link
                component="button"
                variant="body2"
                onClick={() => navigate("/forgotpassword")}
              >
                ¿Olvidaste tu contraseña?
              </Link>
            </Stack>

            <ButtonsPrimary
              name={isLoading ? "Iniciando Sesión" : "Iniciar Sesión"}
              fullWidth
              onClick={onFinish}
              disabled={isLoading}
              endIcon={isLoading && <CircularProgress size={20} />}
            />

            <Link
              component="button"
              variant="body2"
              onClick={() => navigate("/signup")}
            >
              ¿Aún no estás registrado?
            </Link>
          </Stack>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default SignIn;
