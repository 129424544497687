import { Stack } from '@mui/material';
import React from 'react';
import {
  CondicionesGenerales,
  Divaider,
  GeneralConditionBody,
  GeneralConditionCard,
  GeneralConditionFooter,
  GeneralConditionHead,
  ItemContentDescription,
  SectionGeneralCondit,
  TitleItemContent,
  TitleItemFooter,
  ValueItemContent,
  ValueItemFooter,
} from '../../portfolio/portolioInvestment/PortfolioInvestmentComponents';

const SectionGeneralConditionDetail = ({ conditions }) => {
  return (
    <SectionGeneralCondit>
      <GeneralConditionCard>
        <GeneralConditionHead>
          <CondicionesGenerales>Condiciones Generales</CondicionesGenerales>
        </GeneralConditionHead>

        <GeneralConditionBody>
          {conditions.map((condition, index) => {
            return (
              <ItemContentDescription key={index}>
                <TitleItemContent>{condition.data}</TitleItemContent>
                <ValueItemContent>
                  {condition.data === 'Plazo de rescate:' ? `${condition.value}` : condition.value}
                </ValueItemContent>
              </ItemContentDescription>
            );
          })}
        </GeneralConditionBody>

        <GeneralConditionFooter>
          <Stack width={'100%'} height={'27px'} />
        </GeneralConditionFooter>
      </GeneralConditionCard>
    </SectionGeneralCondit>
  );
};

export default SectionGeneralConditionDetail;
