import { Stack, styled } from '@mui/material';
import {
  ButtonsPrimary,
  ButtonsSecondary,
  ButtonsTertiary,
} from '../ComponentApp/buttoms/ButtonsXcala';

const CardsButtonContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-end',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  gap: '8px',
  paddingBottom: '8px',
  height: '100%',
});

const BtnGroup = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
  gap: '8px',
});

export const CardsButtomGroup = ({ goToProductDetail, goToSimulator, goToInvestment }) => {
  return (
    <CardsButtonContainer>
      <BtnGroup>
        <ButtonsSecondary name='Simular' onClick={goToSimulator} sx={{ flex: '1' }} />

        <ButtonsPrimary name='Invertir' onClick={goToInvestment} sx={{ flex: '1' }} />
      </BtnGroup>
      <ButtonsTertiary name='Más Información' onClick={goToProductDetail} fullWidth />
    </CardsButtonContainer>
  );
};
