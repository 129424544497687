import {
  Alert,
  Box,
  Checkbox,
  FormGroup,
  FormLabel,
  InputAdornment,
  Radio,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import axios from 'axios';
import { useEffect, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import useModal from '../../hooks/custom/useModal';
import { useCreateBuyTransaction } from '../../hooks/useCreateBuyTransaction';
import { useCreateLonOutTransaction } from '../../hooks/useCreateLonOutTransaction';
import { useUpdateDividendOption } from '../../hooks/useUpdateDividendOption';
import { onRefresh } from '../../utils/refreshPage';
import { stringToBooleanInput } from '../../utils/stringToBoolean';
import { numberWithPoint } from '../../utils/utils';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import ModalActions, { SuccessfulInvestment } from '../UI/modalActions';
import {
  ContainerTypos,
  FormLabelStyled,
  ImgStyled,
  Typography1,
  Typography2,
} from './SimulationComponents/SimulationMuiComponents';

const SimulationInvestment = ({
  isSimulatorFund,
  simulationId,
  balanceData,
  loadingBalance,
  errorBalance,
  typePayment,
  profilePortfolio,
  profileName,
  userRiskCondition,
  dataPortfolioSimulationById,
  formData,
  handleBack,
  portfolioName,
  dataInvesment,
  dataInvestmentPortfolio,
  userRisk,
  errorInvesments,
  statusDividendFinfolio,
}) => {
  const { isShowing, toggle } = useModal();
  const [openModal, setOpenModal] = useState(false);
  const [sending, setSending] = useState(false);
  const [amount, setAmount] = useState({
    initialInvestment: formData?.initialInvestment || ' ',
  });
  const [checked, setChecked] = useState({
    checkbox1: false,
    checkbox2: false,
  });
  const [selectedRadio, setSelectedRadio] = useState(statusDividendFinfolio?.Value);

  const user = useSelector(state => state.auth.user);
  const theme = useTheme();
  const { id } = useParams();
  const idParam = id;

  //API and Key for referrals
  /*   const api = process.env.REACT_APP_URL_BACKEND_BACKOFFICE_REFERALS;
  const apiKey = process.env.REACT_APP_BACKOFFICE_API_KEY; */

  //Selected fund to investment
  const securitySelected = useSelector(state => state.simulation.securitySelected);
  //Hooks of mutations start here

  //fund
  const [
    createBuyTransaction,
    { loading: loadingCreateTransaction, error: errorCreatetransaction },
  ] = useCreateBuyTransaction();

  //portfolio
  const [
    createLongOutTransaction,
    { error: errorPorfolioInvestment, loading: isLoadingInvesment },
  ] = useCreateLonOutTransaction();
  //Hooks of mutations ends here

  const successDataInvestmentPortfolio =
    dataInvestmentPortfolio?.getClientInvestmentsPortfolios?.filter(
      item => item.id !== '' && !item.account.includes('@'),
    );

  let result;
  if (isSimulatorFund) {
    result = dataPortfolioSimulationById;
  } else {
    result = dataPortfolioSimulationById[0];
  }

  const { name: fundName, id: funId } = result;

  const custom = isSimulatorFund ? JSON.parse(result.Custom) : null;

  const openTC = () => {
    const { REACT_APP_CONTRACT } = process.env;
    const win = window.open(REACT_APP_CONTRACT, '_blank');
    win.focus();
  };

  /*   const referredCheckInvesment = async () => {
    try {
      return await axios.put(
        `${api}/updateReferralAttributes`,
        {
          referredEmail: user.email,
          attributes: {
            investor: true,
          },
        },
        {
          headers: {
            Authorization: apiKey,
          },
        },
      );
    } catch (err) {
      return toast.error('Error Servicio de Referidos');
    }
  }; */

  const getDisabled = () => {
    const { initialInvestment } = amount;
    const { checkbox1, checkbox2 } = checked;
    if (!initialInvestment) {
      return true;
    }
    const amountDynamic = isSimulatorFund ? 10000 : 200000;
    if (initialInvestment && userRiskCondition) {
      return (
        Number.parseInt(initialInvestment?.replace(/\D/g, '')) < amountDynamic ||
        balanceData?.getCashBalancePortfolio?.balance <
          Number.parseInt(initialInvestment?.replace(/\D/g, '')) ||
        initialInvestment[0] === '0' ||
        initialInvestment === null ||
        initialInvestment.trim() === '' ||
        !checkbox2 ||
        sending ||
        (typeof selectedRadio === 'object' && isSimulatorFund)
      );
      // biome-ignore lint/style/noUselessElse: <explanation>
    } else if (initialInvestment && !userRiskCondition) {
      return (
        Number.parseInt(initialInvestment?.replace(/\D/g, '')) < amountDynamic ||
        balanceData?.getCashBalancePortfolio?.balance <
          Number.parseInt(initialInvestment?.replace(/\D/g, '')) ||
        initialInvestment[0] === '0' ||
        initialInvestment === null ||
        initialInvestment.trim() === '' ||
        !(checkbox1 && checkbox2) ||
        sending ||
        (typeof selectedRadio === 'object' && isSimulatorFund)
      );
    }
  };

  const disable = getDisabled();

  const handleChangeRadio = event => {
    setSelectedRadio(event.target.value);
  };

  const handleChangeCheckBox = e => {
    const { name, checked: checkedInput } = e.target;
    setChecked({
      ...checked,
      [name]: checkedInput,
    });
  };

  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChangeAmount = e => {
    const { name, value } = e.target;

    const validAmount = value?.replace(/\D/g, '');
    if (validAmount?.toString()?.length <= 8) {
      const format = numberWithPoint(validAmount);

      setAmount(prevAmount => ({
        ...prevAmount,
        [name]: format,
      }));
    }
  };

  const validateAmount = value => {
    let errors;
    if (value.trim() === '') {
      errors = 'El campo es requerido';
    } else if (isSimulatorFund) {
      if (!value || Number.parseInt(value?.replace(/\D/g, '')) < 10000 || value[0] === '0') {
        errors = 'El monto mínimo para invertir en este fondo es de $10.000';
      }
    } else if (!value || Number.parseInt(value?.replace(/\D/g, '')) < 200000 || value[0] === '0') {
      errors = 'El monto mínimo para invertir en este portfolio es de $200.000';
    }
    return errors;
  };

  const errorAmount = validateAmount(amount.initialInvestment);

  const handleSubmit = async (amount, portfolioName, funId, fundName) => {
    setSending(true);

    //Submit fund data to invest
    if (isSimulatorFund) {
      const dataBuy = {
        amount: Number.parseInt(amount?.initialInvestment?.replace(/\D/g, '')),
        securityId: securitySelected?.ID,
        simulationId: simulationId,
        fund: securitySelected?.Name,
      };
      if (custom?.fund_details?.dividend_payment.includes('Si')) {
        if (selectedRadio === 'true') {
          dataBuy.dividendChoice = 'Reinvertir dividendos en el fondo';
        } else {
          dataBuy.dividendChoice = 'Depositar dividendos en la caja';
        }
      }

      try {
        const { data: responseCreateBuyTransaction } = await createBuyTransaction({
          variables: {
            data: dataBuy,
          },
        });
        /*  if (dataInvesment?.getClientInvestments?.length === 1) {
          await referredCheckInvesment();
        } */
        if (custom.fund_details.dividend_payment.includes('Si')) {
          const optionFound = {
            securityId: securitySelected.ID,
            selection: stringToBooleanInput(selectedRadio),
          };
          const { data: responseUpdateDividend } = await setDividendOption({
            variables: {
              data: optionFound,
            },
          });
        }

        setOpenModal(true);
      } catch (err) {}
    } else {
      //Submit Portfolio data to invest
      const dataInvestment = {
        FileAs: fundName,
        ModelID: funId,
        amount: Number.parseInt(amount?.initialInvestment?.replace(/\D/g, '')),
        note: fundName,
      };

      try {
        await createLongOutTransaction({
          variables: {
            data: dataInvestment,
          },
        });
        /*  if (successDataInvestmentPortfolio?.length === 1) {
          await referredCheckInvesment();
        } */
        setOpenModal(true);
      } catch (error) {
      } finally {
        setSending(false);
        setChecked({
          ...checked,
          checkbox1: false,
          checkbox2: false,
        });
      }
    }
  };

  const [setDividendOption, { loading, error }] = useUpdateDividendOption();

  useEffect(() => {
    if (
      userRiskCondition ||
      userRisk === 'agresivo' ||
      (userRisk === 'moderado' &&
        profilePortfolio
          ?.replace(/\bCLP\b\s*/g, '')
          ?.trim()
          ?.toLowerCase() === 'conservador') ||
      (userRisk === 'moderado' && profileName?.trim()?.toLowerCase() === 'conservador')
    ) {
      setChecked(prevChecked => ({
        ...prevChecked,
        checkbox1: true,
      }));
    }
  }, [userRisk, profilePortfolio, userRiskCondition, profileName]);

  if (errorBalance || errorPorfolioInvestment || errorCreatetransaction || errorInvesments) {
    return (
      <ModalErrorFetch
        message='Lo sentimos, algo no salio bien acá....'
        error={errorBalance || errorPorfolioInvestment || errorCreatetransaction || errorInvesments}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }
  if (isLoadingInvesment || loadingBalance || loadingCreateTransaction) {
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          position: 'relative',
          boxSizing: 'border-box',
          width: '100%',
          top: { md: '100px', xs: '50px' },
        }}>
        <Spinner />
      </Box>
    );
  }

  return (
    <Box
      sx={{
        borderRadius: '8px',
        marginTop: ' -80px',
        boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
        backgroundColor: theme.palette.white.main,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        position: 'relative',
        boxSizing: 'border-box',
        minHeight: '100%',
        marginBottom: '32px',
        width: { lg: '70%', md: '80%', sm: '80%', xs: '100%' },
      }}>
      <ModalActions open={openModal}>
        <SuccessfulInvestment
          amountInvestment={amount.initialInvestment}
          fundName={portfolioName}
          onClose={handleClose}
        />
      </ModalActions>
      <Stack pt={5} pb={5} width={'90%'}>
        <Box>
          <Link
            onClick={handleBack}
            to={isSimulatorFund ? '/simulator' : `/portfolio-simulation/${idParam}`}>
            <ImgStyled src='/assets/images/Arrow1.svg' alt='Arrow' />
          </Link>
        </Box>
        <Box
          sx={{
            pt: 5,
          }}>
          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.littleText.fontWeight,
                fontSize: theme.typography.mediumText.fontSize,
              }}>
              Inversión
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.button.fontWeight,
                fontSize: theme.typography.title.fontSize,
                padding: '12px 0px 0px 0px',
              }}>
              {portfolioName}
            </Typography>
          </Box>
          <Box>
            <Typography
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.littleText.fontWeight,
                fontSize: theme.typography.availableBalance.fontSize,
                textTransform: 'uppercase',
                margin: '12px 0px 0px 0px',
              }}>
              Elige cuanto deseas invertir
            </Typography>
          </Box>
        </Box>
      </Stack>
      <Stack
        sx={{
          display: 'flex',
          position: 'relative',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: '0px',
          boxSizing: 'border-box',
          margin: '9px 0px 0px 0px',
          height: '471px',
          width: '100%',
        }}>
        <Stack
          sx={{
            width: '90%',

            flexDirection: {
              lg: 'row',
              md: 'column',
              sm: 'column',
              xs: 'column',
            },
            justifyContent: 'space-between',
            alignItems: 'center',
            mb: { lg: 0, md: 5, sm: 10, xs: 5 },
          }}>
          <Stack width={{ lg: '50%', md: '90%', sm: '90%', xs: '100%' }} flexDirection={'column'}>
            <Box
              sx={{
                mb: '10px',
              }}>
              <Box display={'flex'} flexDirection={'column'} alignItems={'center'}>
                <FormLabel
                  sx={{
                    color: theme.palette.primary.main,
                    alignSelf: 'self-start',
                    ml: { lg: 0, md: 0, sm: 0, xs: 1.5 },
                  }}>
                  Inversión Inicial
                </FormLabel>

                <TextField
                  sx={{
                    color: theme.palette.primary.dark,
                    width: '100%',
                  }}
                  fullWidth
                  variant='standard'
                  type='text'
                  name='initialInvestment'
                  InputProps={{
                    startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                    fontSize: theme.typography.availableBalance.fontSize,
                  }}
                  onChange={handleChangeAmount}
                  value={amount.initialInvestment}
                  error={Boolean(errorAmount)}
                  helperText={errorAmount}
                />
              </Box>
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                fontSize: theme.typography.button.fontSize,
                color: theme.palette.secondary.text,
              }}>
              <img
                style={{
                  backgroundColor: '#edfcf9',
                  marginRight: '4px',
                }}
                width={'43px'}
                height={'auto'}
                src='/assets/images/cashBox.gif'
                alt='gift'
              />
              * Monto Disponible ${numberWithPoint(balanceData?.getCashBalancePortfolio?.balance)}
            </Box>
            {balanceData?.getCashBalancePortfolio?.balance <
            Number.parseInt(amount.initialInvestment?.replace(/\D/g, '')) ? (
              <Box
                sx={{
                  mt: '2px',
                  display: 'flex',
                  alignItems: 'center',
                  fontSize: theme.typography.button.fontSize,
                  color: theme.palette.red.main,
                }}>
                <img
                  style={{
                    backgroundColor: 'rgb(220, 53, 69,0.1)',
                    marginRight: '4px',
                  }}
                  width={'43px'}
                  height={'auto'}
                  src='/assets/images/cashBox.gif'
                  alt='gift'
                />
                *
                {`Monto por recargar $${numberWithPoint(
                  Number.parseInt(
                    amount.initialInvestment?.replace(/\D/g, '') -
                      balanceData.getCashBalancePortfolio.balance,
                  ),
                )}`}
              </Box>
            ) : null}
          </Stack>
          <Stack
            width={{ lg: '250px', md: '90%', sm: '75%', xs: '100%' }}
            mt={{ lg: 0, md: 2, sm: 3, xs: 3 }}>
            <Link to={'/payments'}>
              <ButtonsPrimary name='Recargar caja' fullWidth />
            </Link>
          </Stack>
        </Stack>
        <Stack
          sx={{
            width: '90%',
            height: '100%',
            background:
              'linear-gradient(-1deg, rgba(201, 211, 226, 0.45) -58.32439568844746%, rgba(212, 228, 255, 0) 184.9676467541254%)',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            borderRadius: '8px',
            mt: {
              lg: 3,
              md: 3,
              sm: 3,
              xs: 0,
            },
          }}>
          <Stack width={'90%'} height={'100%'} justifyContent={'space-around'}>
            {isSimulatorFund ? (
              <>
                <Box>
                  <Typography variant='h6' fontWeight={600} color='initial'>
                    Condiciones generales
                  </Typography>
                </Box>
                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>{'Monto mínimo de inversión(en pesos):'}</Typography1>
                  </Box>
                  <Box>
                    <Typography2>{'$10.000'} </Typography2>
                  </Box>
                </ContainerTypos>
                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>Plazo de Aporte: </Typography1>
                  </Box>
                  <Box>
                    <Typography2>{custom.fund_details.exit_fee}</Typography2>
                  </Box>
                </ContainerTypos>

                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>Paga dividendos: </Typography1>
                  </Box>
                  <Box>
                    <Typography2>{custom.fund_details.dividend_payment}</Typography2>
                  </Box>
                </ContainerTypos>
              </>
            ) : (
              <>
                <Box>
                  <Typography variant='h6' fontWeight={600} color='initial'>
                    Condiciones generales
                  </Typography>
                </Box>
                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>Moneda fondos: </Typography1>
                  </Box>
                  <Box>
                    <Typography2>{typePayment === 'clp' ? 'Pesos' : 'USD'}</Typography2>
                  </Box>
                </ContainerTypos>
                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>{'Monto mínimo de inversión(en pesos):'}</Typography1>
                  </Box>
                  <Box>
                    <Typography2>{'$200.000'} </Typography2>
                  </Box>
                </ContainerTypos>

                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>Plazo de rescate: </Typography1>
                  </Box>
                  <Box>
                    <Typography2>Según reglamentos de los fondos </Typography2>
                  </Box>
                </ContainerTypos>
                <ContainerTypos
                  sx={{
                    flexDirection: {
                      lg: 'row',
                      md: 'row',
                      sm: 'column',
                      xs: 'column',
                    },
                  }}>
                  <Box>
                    <Typography1>Comisión de salida: </Typography1>
                  </Box>
                  <Box>
                    <Typography2>Según reglamentos de los fondos </Typography2>
                  </Box>
                </ContainerTypos>
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        width={'90%'}
        sx={{
          mt: {
            md: 12,
            xs: isSimulatorFund && custom.fund_details.dividend_payment.includes('Si') ? 13 : 21,
          },
        }}>
        {isSimulatorFund && custom.fund_details.dividend_payment.includes('Si') ? (
          <>
            <Stack
              sx={{
                display: 'flex',
                flexDirection: 'column',
              }}
              mb={{ md: 5, xs: 0 }}>
              <Box>
                <Typography
                  fontWeight={theme.typography.littleText.fontWeight}
                  color={theme.palette.primary.main}
                  fontSize={theme.typography.availableBalance.fontSize}>
                  Este fondo genera dividendos mensuales.
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontWeight={theme.typography.littleText.fontWeight}
                  color={theme.palette.primary.darkBlue}
                  fontSize={theme.typography.button.fontSize}>
                  ¿Qué quieres hacer con estos dividendos?
                </Typography>
              </Box>
              <Box
                sx={{
                  mt: { md: '40px', xs: '50px' },
                  ml: '-10px',
                  display: 'flex',
                  flexDirection: { md: 'row', xs: 'column' },
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%',
                  mb: { lg: 0, md: 0, sm: 7, xs: 6 },
                  gap: { md: 0, xs: 7 },
                }}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.secondary.main,
                        '&.Mui-focusVisible': {
                          outline: '2px auto #49a197',
                        },
                      },
                    }}
                    checked={selectedRadio === 'true'}
                    onChange={handleChangeRadio}
                    value='true'
                    name='radio-buttons'
                  />
                  <FormLabelStyled
                    sx={{
                      color: theme.palette.secondary.main,
                      fontWeight: theme.typography.button.fontWeight,
                    }}>
                    Prefiero reinvertir en este fondo
                  </FormLabelStyled>
                </Box>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}>
                  <Radio
                    sx={{
                      '&.Mui-checked': {
                        color: theme.palette.secondary.main,
                        '&.Mui-focusVisible': {
                          outline: '2px auto #49a197',
                        },
                      },
                    }}
                    checked={selectedRadio === 'false'}
                    onChange={handleChangeRadio}
                    value='false'
                    name='radio-buttons'
                  />
                  <FormLabelStyled
                    sx={{
                      color: theme.palette.secondary.main,
                      fontWeight: theme.typography.button.fontWeight,
                    }}>
                    Depositar dividendos en la caja
                  </FormLabelStyled>
                </Box>
              </Box>
            </Stack>
          </>
        ) : (
          <>
            <Stack
              sx={{
                pt: { md: 0, xs: 10 },
              }}
              display={isSimulatorFund ? 'none' : null}>
              <Box>
                <Typography
                  fontWeight={theme.typography.littleText.fontWeight}
                  color={theme.palette.primary.main}
                  fontSize={theme.typography.availableBalance.fontSize}>
                  Este portfolio genera dividendos mensuales.
                </Typography>
              </Box>
              <Box>
                <Typography
                  fontWeight={theme.typography.littleText.fontWeight}
                  color={theme.palette.primary.darkBlue}
                  fontSize={theme.typography.button.fontSize}>
                  Los dividendos o disminuciones de capital generados por los fondos de este
                  portfolio, serán cargados directamente en caja cada vez que se paguen
                </Typography>
              </Box>
            </Stack>
          </>
        )}
      </Stack>

      <Stack mt={isSimulatorFund ? 0 : 5} width={'90%'}>
        <Stack mt={{ lg: 0, md: 0, sm: 4, xs: isSimulatorFund ? 0 : 4 }} width={'100%'}>
          <FormGroup>
            {userRiskCondition ||
            userRisk === 'agresivo' ||
            (userRisk === 'moderado' &&
              profilePortfolio
                ?.replace(/\bCLP\b\s*/g, '')
                ?.trim()
                ?.toLowerCase() === 'conservador') ||
            (userRisk === 'moderado' &&
              profileName?.trim()?.toLowerCase() === 'conservador') ? null : (
              <Box
                sx={{
                  ml: '-10px',
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  width: '100%',

                  mb: { lg: 0, md: 0, sm: 7, xs: 8 },
                }}>
                <Checkbox
                  checked={checked.checkbox1}
                  name='checkbox1'
                  onChange={handleChangeCheckBox}
                />
                <FormLabelStyled
                  sx={{
                    mt: '20px',
                  }}>
                  Declaro entender que la inversión que estoy realizando es de un riesgo superior a
                  lo sugerido por mi perfil inversionista.
                </FormLabelStyled>
              </Box>
            )}

            <Box
              sx={{
                ml: '-10px',
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                width: '100%',
              }}>
              <Checkbox
                checked={checked.checkbox2}
                name='checkbox2'
                onChange={handleChangeCheckBox}
              />
              <FormLabelStyled>
                {'Acepto que esta transacción se rigen bajo las normas del '}
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <span
                  onClick={openTC}
                  style={{
                    cursor: 'pointer',
                    color: theme.palette.secondary.main,
                    fontWeight: theme.typography.button.fontWeight,
                  }}>
                  contrato vigente.
                </span>
              </FormLabelStyled>
            </Box>
          </FormGroup>
        </Stack>
      </Stack>
      <Stack
        alignItems={'center'}
        justifyContent={'space-between'}
        mt={{ lg: 5, md: 5, sm: 12, xs: 15 }}
        width={'90%'}>
        <Box width={{ lg: '35%', md: '40%', sm: '60%', xs: '80%' }}>
          <ButtonsPrimary
            name='Invertir'
            onClick={() => handleSubmit(amount, portfolioName, funId, fundName)}
            disabled={disable}
            fullWidth
          />
        </Box>
        <Box mt={3}>
          {balanceData?.getCashBalancePortfolio?.balance <
          Number.parseInt(amount.initialInvestment?.replace(/\D/g, '')) ? (
            <Alert severity='info'>
              Por favor valida el monto ingresado, el monto es mayor al disponible en caja.
            </Alert>
          ) : isSimulatorFund &&
            Number.parseInt(amount.initialInvestment?.replace(/\D/g, '')) < 10000 ? (
            <Alert severity='info'>
              Por favor valida el monto ingresado, el monto minimo para invertir es de 10.000.
            </Alert>
          ) : !isSimulatorFund &&
            Number.parseInt(amount.initialInvestment?.replace(/\D/g, '')) < 200000 ? (
            <Alert severity='info'>
              Por favor valida el monto ingresado, el monto minimo para invertir es de $200.000.
            </Alert>
          ) : amount.initialInvestment.trim() === '' ? (
            <Alert severity='info'>Por favor ingrese un monto a invertir</Alert>
          ) : checked.checkbox1 &&
            checked.checkbox2 &&
            typeof selectedRadio === 'object' &&
            isSimulatorFund ? (
            <Alert severity='info'>Por favor seleccione que desea hacer con sus dividendos</Alert>
          ) : checked.checkbox1 && checked.checkbox2 ? (
            <Alert severity='success'>Todo esta listo para invertir</Alert>
          ) : null}
        </Box>

        <Box display={'flex'} justifyContent={'center'} width={'150px'} height={'40px'} mt={3}>
          <Link to={'/'}>
            <Typography
              sx={{
                width: '100%',
                textAlign: 'center',
                color: 'rgba(239, 140, 122, 1)',
                fontWeight: theme.typography.smallText.fontWeight,
                fontSize: theme.typography.button.fontSize,
                textDecoration: 'underline',
              }}>
              No por ahora
            </Typography>
          </Link>
        </Box>
      </Stack>
    </Box>
  );
};

export default SimulationInvestment;
