import { Button, Stack, Typography, useTheme, styled } from '@mui/material';

const IconsDone = styled('img')({
  width: '24px',
  height: '24px',
  margin: '0px',
});

const btnPadding = (size = 'md') => (size === 'md' ? '16px 32px' : size === 'xs' && '8px 16px');

export const ButtonsPrimary = ({
  name,
  sx,
  onClick,
  src,
  icon,
  fontSize,
  disabled = false,
  size = 'md',
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      disableElevation
      variant='contained'
      onClick={onClick}
      disabled={disabled}
      size={size}
      sx={{
        ...sx,
        padding: btnPadding(size),
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        {icon && icon}
        <Typography color='inherit' sx={{ fontSize: theme.typography.button.fontSize }}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};

export const ButtonsSecondary = ({
  name,
  sx,
  onClick,
  src,
  disabled = false,
  size = 'md',
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      disableElevation
      variant='outlined'
      onClick={onClick}
      disabled={disabled}
      size={size}
      sx={{
        ...sx,
        padding: btnPadding(size),
        ':hover': {
          backgroundColor: theme.palette.primary.light,
          color: theme.palette.common.white,
        },
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        <Typography color='inherit' sx={{ fontSize: theme.typography.button.fontSize }}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};

export const ButtonsTertiary = ({
  name,
  sx,
  onClick,
  src,
  disabled = false,
  size = 'md',
  imgUrl,
  style,
  ...props
}) => {
  const theme = useTheme();
  return (
    <Button
      disableElevation
      variant='text'
      onClick={onClick}
      disabled={disabled}
      size={size}
      sx={{
        ...sx,
        padding: btnPadding(size),
      }}
      {...props}>
      <Stack
        flexDirection={'row'}
        gap={2}
        sx={{
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        {src && <IconsDone src={src} />}
        {name === 'Reinvertir Dividendos' && <img src={imgUrl} alt={name} style={{ ...style }} />}
        <Typography color='inherit' sx={{ fontSize: theme.typography.button.fontSize }}>
          {name}
        </Typography>
      </Stack>
    </Button>
  );
};

export const ButtonXcalaBlue = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  marginTop,
  maxWidth,
  fontWeight = 'normal',
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Button
      {...rest}
      fullWidth={fullWidth}
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      sx={{
        marginTop: marginTop,
        maxWidth: maxWidth,
        paddingX: paddingX,
        paddingY: paddingY,
        fontWeight: fontWeight || theme.typography.button.fontWeight,
        color: theme.palette.white.main,
        background: theme.palette.primary.main,
        fontSize: theme.typography.button.fontSize,
        ':hover': {
          background: theme.palette.primary.main,
        },
      }}>
      {children}
    </Button>
  );
};

export const ButtonXcalaOutlineSecondary = ({
  children,
  onClick,
  disabled,
  paddingX,
  paddingY,
  fullWidth,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <Button
      {...rest}
      fullWidth={fullWidth}
      variant='contained'
      disabled={disabled}
      onClick={onClick}
      sx={{
        paddingX: paddingX,
        paddingY: paddingY,
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
        fontWeight: theme.typography.h1.fontWeight,
        fontSize: theme.typography.button.fontSize,
        background: theme.palette.transparent.main,
        ':hover': {
          background: theme.palette.transparent.main,
          border: `1px solid ${theme.palette.primary.main}`,
        },
      }}>
      {children}
    </Button>
  );
};
