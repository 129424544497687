import { ApolloClient, HttpLink, InMemoryCache, from } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { RetryLink } from '@apollo/client/link/retry';
import { Auth } from 'aws-amplify';
import jwt_decode from 'jwt-decode';
import { store } from './redux';

const httpLink = new HttpLink({
  uri: process.env.REACT_APP_URL_BACKEND_GRAPHQL,
});

export const getAccessTokenPromise = async () => {
  const tokenName = `token-${process.env.REACT_APP_CURRENT_STAGE}`;
  let token = localStorage.getItem(tokenName) || '';
  try {
    if (token === '') {
      store.dispatch({ type: 'auth/signOut/fulfilled' });
      return '';
    }
    const decoded = jwt_decode(token);
    const currentNumericDate = Math.round(Date.now() / 1000);
    if (currentNumericDate > decoded.exp) {
      const currentSession = await Auth.currentSession();
      token = currentSession.idToken.jwtToken;
      localStorage.setItem(tokenName, token);
    }
  } catch (error) {
    store.dispatch({ type: 'auth/signOut/fulfilled' });
  }

  return token;
};

const authLink = setContext(async (_, { headers }) => {
  const accessToken = await getAccessTokenPromise();
  return {
    headers: {
      ...headers,
      authorization: accessToken,
    },
  };
});

const retryLink = new RetryLink({
  delay: {
    initial: 300,
    max: Number.POSITIVE_INFINITY,
    jitter: true,
  },
  attempts: {
    max: 3,
    retryIf: (error, _operation) => {
      return !!error && _operation.query.definitions[0].operation === 'query';
    },
  },
});

const defaultOptions = {
  watchQuery: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'ignore',
  },
  query: {
    fetchPolicy: 'no-cache',
    errorPolicy: 'all',
  },
};

export const client = new ApolloClient({
  link: retryLink.concat(authLink.concat(httpLink)),
  cache: new InMemoryCache(),
  defaultOptions: defaultOptions,
});
