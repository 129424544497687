import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import Spinner from '../../Spinner';
import Charts from '../Chart';

const ChartnvestmentContainerProductsDetail = ({
  active,
  onChangeMonths,
  loadingGetPriceRange,
  currency,
  custom,
  chartData,
  dividends,
  showLabels,
  successGetPriceRange,
  setCurrency,
  updateChartData,
  successGetExchangeRateHistory,
  loadingGetExchangeRateHistory,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: theme.typography.title.fontSize,
            }}>
            Evolución del Fondo
          </Typography>
        }
      />
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box
          display='flex'
          sx={{
            ml: 1,
            flexDirection: { xs: 'column', md: 'row' },
            justifyContent: 'space-between',
          }}>
          <ButtonGroup
            sx={{
              width: { xs: '100%', md: '70%' },
              '& .MuiButton-outlined': {
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  borderColor: theme.palette.primary.main,
                },
                '&:focus': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              onClick={() => onChangeMonths(12)}
              style={{
                backgroundColor: active === 12 ? theme.palette.primary.main : 'initial',
                color: active === 12 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(3)}
              style={{
                backgroundColor: active === 3 ? theme.palette.primary.main : 'initial',
                color: active === 3 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(1)}
              style={{
                backgroundColor: active === 1 ? theme.palette.primary.main : 'initial',
                color: active === 1 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
          {custom.fund_details.Currency === 'Dólar' && (
            <ButtonGroup
              sx={{
                width: { xs: '100%', md: '70%' },
                justifyContent: 'end',
                marginTop: { xs: '12px', md: 0 },
                '& .MuiButton-outlined': {
                  borderColor: theme.palette.primary.main,
                  '&:hover': {
                    borderColor: theme.palette.primary.main,
                  },
                  '&:focus': {
                    borderColor: theme.palette.primary.main,
                  },
                },
              }}
              variant='outlined'
              aria-label='outlined primary button group'
              size='small'>
              <Button
                onClick={() => {
                  setCurrency('dolares');
                  updateChartData(successGetPriceRange, successGetExchangeRateHistory);
                }}
                style={{
                  backgroundColor: currency === 'dolares' ? theme.palette.primary.main : 'initial',
                  color: currency === 'dolares' ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  textTransform: 'none',
                }}>
                Dólares
              </Button>
              <Button
                onClick={() => {
                  setCurrency('pesos');
                  // Puedes decidir si quieres llamar a updateChartData aquí también
                }}
                style={{
                  backgroundColor: currency === 'pesos' ? theme.palette.primary.main : 'initial',
                  color: currency === 'pesos' ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  textTransform: 'none',
                }}>
                Pesos
              </Button>
            </ButtonGroup>
          )}
        </Box>
      </CardContent>
      <CardContent
        sx={{
          height: '100%',
          maxHeight: '425px',
          minHeight: { md: '', xs: '300px' },
        }}>
        {loadingGetPriceRange && loadingGetExchangeRateHistory ? (
          <Spinner />
        ) : (
          <Charts
            productdetail={'productdetail'}
            data={
              currency === 'dolares' && custom.fund_details.Currency === 'Dólar'
                ? chartData
                : successGetPriceRange
            }
            dividends={dividends}
            showLabels={showLabels}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ChartnvestmentContainerProductsDetail;
