import { gql, useQuery } from '@apollo/client';

const GET_INSTITUTIONS = gql`
query getInstitutions {
  getInstitutions {
    label
    value
    transaction
    products {
      name
      holder_type
    }
  }
}
`;
{
  /*Trae los bancos para la carga de caja*/
}
export const useGetInstitutions = () => {
  return useQuery(GET_INSTITUTIONS);
};
