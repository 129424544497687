import React from 'react';
import { useSelector } from 'react-redux';
import { Navigate, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import { TidioChat } from '../components/ComponentApp/TidioChat';
import SimulationInvestment from '../components/simulator/SimulationInvestment';
import SimulatorForm from '../components/simulator/SimulatorForm';
import RescueFunds from '../pages/private/Rescue/RescueFunds';
import RescuePortfolio from '../pages/private/Rescue/RescuePortfolio';
import NewChangePassword from '../pages/private/changePassword/NewChangePassword';
import { NewCompleteRegistration } from '../pages/private/completeRegistration/NewCompleteRegistration';
import SignedDocumentVerification from '../pages/private/completeRegistration/signedDocumentVerification';
import Dashboard from '../pages/private/dashboard/Dashboard';
import PortfolioInvestmentDetails from '../pages/private/investmentDetail/PortfolioInvestmentDetails';
import ProductsInvestmentDetail from '../pages/private/investmentDetail/ProductsInvestmentDetail';
import Logout from '../pages/private/logout';
import MyNewAccount from '../pages/private/myAccount/MyNewAccount';
import MyNewObjectivesInterests from '../pages/private/myObjectivesInterests/MyNewObjectivesInterests';
import MyTransactionsPages from '../pages/private/myTransactions/MyNewTransactions';
import DetailsPortfolio from '../pages/private/portfolio/DetailsPortfolio';
import Portfolio from '../pages/private/portfolio/Portfolio';
import NewProducts from '../pages/private/products/NewProducts';
import ProductsDetails from '../pages/private/products/ProductsDetails';
import Profile from '../pages/private/profile/Profile';
import ProfileQuestions from '../pages/private/profile/ProfileQuestions';
import ProfileResultQuestions from '../pages/private/profile/ProfileResultQuestions';
import ProfileSelect from '../pages/private/profile/ProfileSelect';
import NewSimulator from '../pages/private/simulator/NewSimulator';
import Wallet from '../pages/private/wallet/Wallet';
import WalletRecharge from '../pages/private/wallet/WalletRecharge';
import WalletWithdrawal from '../pages/private/wallet/WalletWithdrawal';
import ChangeForgetPasswordSuccess from '../pages/public/ChangeForgetPasswordSuccess';
import ConfirmAccountSuccess from '../pages/public/ConfirmAccountSuccess';
import ForgotPassword from '../pages/public/ForgotPassword';
import ForgotPasswordConfirm from '../pages/public/ForgotPasswordConfirm';
import Password from '../pages/public/Password';
import SignIn from '../pages/public/SignIn';
import SignUp from '../pages/public/SignUp';
import SignUpConfirm from '../pages/public/SignUpConfirm';
import ScrollToTop from './ScrollToTop';

const Router = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { user } = useSelector(state => state.auth);

  React.useEffect(() => {
    if (
      user &&
      !user['custom:profile'] &&
      location.pathname !== '/profile' &&
      location.pathname !== '/questions' &&
      location.pathname !== '/questionsresults' &&
      location.pathname !== '/profileselect'
    ) {
      navigate('/profile');
    }
  }, [location, user, navigate]);

  const shouldShowTidioChat = user || location.pathname === '/forgotpasswordconfirm';

  return (
    <>
      <ScrollToTop />
      <TidioChat show={shouldShowTidioChat} />
      <Routes>
        {user ? (
          <>
            <Route path='/myprofilerisk' element={<Dashboard />} />
            <Route path='/myaccount' element={<MyNewAccount />} />
            <Route path='/myobjectivesinterests' element={<MyNewObjectivesInterests />} />
            <Route path='/profileselect' element={<ProfileSelect />} />
            <Route path='/profile' element={<Profile />} />
            <Route path='/questions' element={<ProfileQuestions />} />
            <Route path='/questionsresults' element={<ProfileResultQuestions />} />
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/withdrawal' element={<WalletWithdrawal />} />
            <Route path='/payments' element={<WalletRecharge />} />
            <Route path='/wallet' element={<Wallet />} />
            <Route path='/completeregistration' element={<NewCompleteRegistration />} />
            <Route path='/changepassword' element={<NewChangePassword />} />
            <>
              <Route path='/productdetail' element={<ProductsDetails />} />
              <Route path='/logout' element={<Logout />} />
              <Route path='/simulator' element={<NewSimulator />} />{' '}
              <Route path='/newsimulator' element={<SimulatorForm />} />
              <Route path='/mytransactions' element={<MyTransactionsPages />} />
              <Route path='/rescue' element={<RescueFunds />} />
              <Route path='/myinvestmentdetail' element={<ProductsInvestmentDetail />} />
              <Route path='/signedDocument' element={<SignedDocumentVerification />} />
            </>
            <Route path='/products' element={<NewProducts />} />
            <Route path='/products/:fundName' element={<NewProducts />} />
            <Route path='/portfolio' element={<Portfolio />} />
            <Route path='/portfolio-details' element={<DetailsPortfolio />} />
            <Route path='/rescue-portfolio' element={<RescuePortfolio />} />
            <Route path='/details-investment-portfolio' element={<PortfolioInvestmentDetails />} />
            <Route path='/portfolio-simulation/:id' element={<NewSimulator />}>
              <Route path='investment' element={<SimulationInvestment />} />
            </Route>
          </>
        ) : (
          <>
            <Route path='/signin' element={<SignIn />} />
            <Route path='/signupcreatepassword/:referrerCodePassword/*' element={<Password />} />
            <Route path='/signupcreatepassword' element={<Password />} />
            <Route path='/forgotchangepassword' element={<Password />} />
            <Route path='/forgotpassword' element={<ForgotPassword />} />
            <Route path='/signup' element={<SignUp />} />
            <Route path='/signup/:referrerCode/*' element={<SignUp />} />
            <Route path='/forgotpasswordconfirm' element={<ForgotPasswordConfirm />} />
            <Route path='/signupconfirm' element={<SignUpConfirm />} />
            <Route path='/changeforgetpasswordsuccess' element={<ChangeForgetPasswordSuccess />} />
            <Route path='/confirmAccountSuccess' element={<ConfirmAccountSuccess />} />
          </>
        )}
        <>
          <Route path='/' element={<SignIn />} />
          <Route path='*' element={<Navigate to='/' />} />
        </>
      </Routes>
    </>
  );
};

export default Router;
