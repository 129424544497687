import { combineReducers, configureStore } from '@reduxjs/toolkit';
import {
  FLUSH,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
  REHYDRATE,
  persistReducer,
  persistStore,
} from 'reduxjs-toolkit-persist';
import storage from 'reduxjs-toolkit-persist/lib/storage'; // defaults to localStorage for web

import authReducer from './auth';
import constantsReducer from './constants';
import filterReducer from './filter';
import investmentsReducer from './investments';
import myinteresetReducer from './myinteresets';
import onboardingReducer from './onboarding';
import portfolioReducer from './portfolio';
import questionReducer from './questions';
import simulationReducer from './simulation';

const persistConfig = {
  key: 'rootPersistXcala',
  storage,
};

const reducers = combineReducers({
  auth: authReducer,
  question: questionReducer,
  myinteresets: myinteresetReducer,
  onboarding: onboardingReducer,
  simulation: simulationReducer,
  investments: investmentsReducer,
  filter: filterReducer,
  constants: constantsReducer,
  portfolio: portfolioReducer,
});

const rootReducer = (state, action) => {
  if (action.type === 'auth/signOut/fulfilled') {
    state = undefined;
  }

  return reducers(state, action);
};

const _persistReduser = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: _persistReduser,
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});

export const persistor = persistStore(store);
