import React from 'react';

export const IconsSuecces = ({ ...resProps }) => {
  return (
    <>
      <svg
        {...resProps}
        width='42'
        height='42'
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect width='41.2026' height='41.2026' rx='20.6013' fill='#22C55E' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.6013 9.87146C26.5272 9.87146 31.3312 14.6754 31.3312 20.6013C31.3312 26.5272 26.5272 31.3312 20.6013 31.3312C14.6754 31.3312 9.87146 26.5272 9.87146 20.6013C9.87146 14.6754 14.6754 9.87146 20.6013 9.87146ZM20.6014 12.0174C15.8606 12.0174 12.0175 15.8605 12.0175 20.6013C12.0175 25.342 15.8606 29.1852 20.6014 29.1852C25.3421 29.1852 29.1853 25.342 29.1853 20.6013C29.1853 15.8605 25.3421 12.0174 20.6014 12.0174ZM24.1346 16.6236L18.4554 22.3028L17.0682 20.9156C16.6491 20.4965 15.9698 20.4965 15.5507 20.9156C15.1317 21.3346 15.1317 22.014 15.5507 22.433L17.6967 24.579C18.1157 24.998 18.7951 24.998 19.2141 24.579L25.652 18.1411C26.0711 17.722 26.0711 17.0427 25.652 16.6236C25.233 16.2046 24.5536 16.2046 24.1346 16.6236Z'
          fill='white'
        />
      </svg>
    </>
  );
};
