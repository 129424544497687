import { gql, useMutation } from '@apollo/client';

const CREATE_WTHD_TRANSACTION = gql`
mutation createWithdrawTransaction( $data : withdrawInput!){
    createWithdrawTransaction (data : $data)
}`;
{
  /*Crea una transacción tipo withdrawal (retiro de caja) en finfolio*/
}
export const useCreateWithdrawTransaction = () => {
  return useMutation(CREATE_WTHD_TRANSACTION);
};
