import { Stack } from '@mui/material';
import useModal from '../../hooks/custom/useModal';
import useGetBankAccountTypes from '../../hooks/useGetBankAccountTypes';
import useGetBanks from '../../hooks/useGetBanks';
import { onRefresh } from '../../utils/refreshPage';
import { ButtonsPrimary, ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import {
  ButtonsAcount,
  CardContent,
  CardContentAvatar,
  DivisionSectionFrom,
  SectionInteractive,
  TextCuent,
} from '../XcalaComponents/MyNewAccount';
import { InputSelectBank } from './InputSelectBank';
import { AvatarsModal } from './AvatarsModal';
import { useState } from 'react';
import { Box } from '@mui/material';
import { AvatarGlobal } from '../UI/AvatarGlobal';

export const FormBankSection = ({
  isEditBankData,
  bankField,
  isBankData,
  loadingUserBank,
  handleEditWithoutBankData,
  handleOpenEditBankData,
  handleChangeBankFields,
  errorRegisterUserBank,
  loadingRegisterUserBank,
  //copyText,
  //handleCopyLink,
  accountNumberError,
}) => {
  const [openModal, setOpenModal] = useState(false);

  const avatar = '../../../assets/images/log-out.svg';

  const { data, error: errorGetBanks, loading: loadingGetBanks } = useGetBanks();

  const {
    data: dataBankTypes,
    error: errorGetBankAccountTypes,
    loading: loadingGetBankAccountTypes,
  } = useGetBankAccountTypes();

  const successGetBankAccountTypes = dataBankTypes?.getBankAccountTypes;

  const successGetBanks = data?.getBanks;

  const { isShowing, toggle } = useModal();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  if (loadingUserBank || loadingGetBanks || loadingGetBankAccountTypes) {
    return <Spinner />;
  }

  if (errorGetBanks || errorGetBankAccountTypes || errorRegisterUserBank) {
    return (
      <ModalErrorFetch
        message='Me temo que tendras que hacerlo de nuevo.... algo salio mal'
        error={errorGetBanks || errorGetBankAccountTypes || errorRegisterUserBank}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <SectionInteractive sx={{ width: { xs: '90%', md: '30%' } }}>
      <CardContent>
        <DivisionSectionFrom
          isEdit={isEditBankData}
          handleOpenEdit={handleOpenEditBankData}
          textTitle={'Datos bancarios'}
        />

        {loadingRegisterUserBank ? (
          <Spinner />
        ) : isBankData ? (
          <Stack
            sx={{
              gap: '32px',
            }}>
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'Banco'}
              selet={bankField.bankSelected}
              dataBanks={successGetBanks}
              funtion={handleChangeBankFields}
            />
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'Tipo de cuenta'}
              selet={bankField.accountTypeSelected}
              dataBanks={successGetBankAccountTypes}
              funtion={handleChangeBankFields}
            />
            <InputSelectBank
              isEdit={isEditBankData}
              nameFiled={'No. de cuenta'}
              selet={bankField.number}
              funtion={handleChangeBankFields}
              accountNumberError={accountNumberError}
            />
          </Stack>
        ) : (
          <Stack
            sx={{
              gap: '32px',
            }}>
            <TextCuent>Aun no registras tu cuenta</TextCuent>
            <ButtonsAcount onClick={handleEditWithoutBankData}>
              <ButtonsTertiary name='Agregar mi cuenta' />
            </ButtonsAcount>
          </Stack>
        )}
      </CardContent>
      <CardContentAvatar>
        <Stack
          direction='row'
          gap='28px'
          justifyContent='space-evenly'
          alignItems='center'
          sx={{
            '@media (max-width: 1370px)': {
              flexDirection: 'column',
              alignItems: 'center',
              whiteSpace: 'nowrap',
              justifyContent: 'space-evenly',
              gap: '28px',
              width: '100%',
            },
            '@media (max-width: 1199px) and (min-width: 1040px)': {
              flexDirection: 'column',
              padding: '16px',
              width: '100%',
            },
            '@media (max-width: 1039px)': {
              flexDirection: 'row',
              padding: '16px',
              width: '100%',
            },
            '@media (max-width: 600px)': {
              flexDirection: 'column',
              padding: '16px',
              width: '100%',
            },
          }}>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <AvatarGlobal />
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'center' }}>
            <ButtonsPrimary name='Actualizar avatar' onClick={handleOpenModal} />
          </Box>
          {openModal && (
            <AvatarsModal
              avatar={avatar}
              openModal={openModal}
              handleCloseModal={handleCloseModal}
            />
          )}
        </Stack>
        {/* <TitleSectionFrom sx={{ textAlign: { md: 'left', xs: 'center' }, marginTop: '8px' }}>
          Referidos Xcala
        </TitleSectionFrom> */}
        {/*<ButtonsTertiary
          name={copyText}
          onClick={handleCopyLink}
          name='Próximamente'
          fontSize='16px'
        />*/}
      </CardContentAvatar>
    </SectionInteractive>
  );
};
