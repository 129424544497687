import { gql, useMutation } from '@apollo/client';

const CREATE_CONTRIBUTION = gql`
mutation createContribution( $data : contributionTransaction!){
    createContribution (data : $data)
}`;
{
  /*Crea una transacción  por transferencia*/
}
export const useCreateContribution = () => {
  return useMutation(CREATE_CONTRIBUTION);
};
