/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "Auth": {
        "region": "us-east-1",
        "identityPoolRegion": "us-east-1",
        "userPoolId": "us-east-1_VHPEN8D9c",
        "userPoolWebClientId": "1lo9hhn86csd6um38cq8nlfj2s"
    }
};


export default awsmobile;
