import { Modal } from '@mui/material';
import AvatarSelection from '../../pages/public/AvatarSelection';

export const AvatarsModal = ({ openModal, handleCloseModal, handleAvatarSelected, avatar }) => {
  return (
    <>
      <Modal open={openModal} onClose={handleCloseModal}>
        <AvatarSelection
          avatar={avatar}
          handleCloseModal={handleCloseModal}
          handleAvatarSelected={handleAvatarSelected}
        />
      </Modal>
    </>
  );
};
