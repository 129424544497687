import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelectOB } from './CustomSelectOB';

export const CivilStatusSelectOB = ({ selected, setSelected }) => {
  const civilStatus = useSelector(state => state.constants.civilStatus);
  const [menuItems, setMenuItems] = useState(civilStatus);
  const [defaultelect, setDefaultelect] = useState('');

  useEffect(() => {
    const aux = menuItems.find(item => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);
  return (
    <CustomSelectOB
      label='Estado Civil'
      Labelid='Estado Civil'
      placholder='Estado Civil'
      Selectid='Estado Civil'
      id='Estado Civil'
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};
