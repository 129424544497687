const riskProfile = {
  1: 'conservador',
  2: 'moderado',
  3: 'agresivo',
};

export const getRiskWarningFunds = (userRisk, productRisk) => {
  let userRiskId = -1;
  Object.keys(riskProfile).forEach(key => {
    if (riskProfile[key] === userRisk.toLowerCase()) {
      userRiskId = key;
    }
  });
  return productRisk > userRiskId ? true : false;
};

export const getRiskWarningPortfolio = (userRisk, productRisk) => {
  let userRiskId;
  let productRiskId;

  Object.keys(riskProfile).forEach(key => {
    if (riskProfile[key] === userRisk.toLowerCase()) {
      userRiskId = parseInt(key);
    }
    if (riskProfile[key] === productRisk?.toLowerCase()) {
      productRiskId = parseInt(key);
    }
  });
  return productRiskId > userRiskId;
};
