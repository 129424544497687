import { gql, useQuery } from '@apollo/client';

const GET_OBJETIVES_QUERY = gql`
	query getObjetives {
		getObjetives{
			id
			text
			icon_name
		}
	}
`;
{
  /*Retorna un objeto con los objetivos de un usuario, incluyendo el Id, el icono, el texto y el tipo*/
}
export default () => useQuery(GET_OBJETIVES_QUERY);
