import { Typography, styled } from '@mui/material';

export const ReantabilityTitle = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
}));
export const RentabilityValue = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.secondary.text,
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.h3.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));
