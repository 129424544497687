import { Box, styled } from '@mui/material';

export const CarouselContent = styled(Box)({
  maxHeight: { xs: '100%', md: '315px' },
  maxWidth: { xs: '100%', md: '100%', lg: '1080px' },
  height: '100%',
  width: '78%',
  margin: '0px',
  borderRadius: '8px',
});
