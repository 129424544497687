import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import Spinner from '../../Spinner';
import Charts from '../Chart';

const ChartnvestmentContainerProducts = ({
  dataCharts,
  dividends,
  active,
  onChangeMonths,
  loadingGetSecurityPriceRange,
}) => {
  const theme = useTheme();
  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography
            sx={{
              fontSize: theme.typography.title.fontSize,
            }}>
            Evolución del Fondo
          </Typography>
        }
      />
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row' sx={{ ml: 1 }}>
          <ButtonGroup
            sx={{
              width: '100%',
              '& .MuiButton-outlined': {
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  borderColor: theme.palette.primary.main,
                },
                '&:focus': {
                  borderColor: theme.palette.primary.main,
                },
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              onClick={() => onChangeMonths(12)}
              style={{
                backgroundColor: active === 12 ? theme.palette.primary.main : 'initial',
                color: active === 12 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(3)}
              style={{
                backgroundColor: active === 3 ? theme.palette.primary.main : 'initial',
                color: active === 3 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              onClick={() => onChangeMonths(1)}
              style={{
                backgroundColor: active === 1 ? theme.palette.primary.main : 'initial',
                color: active === 1 ? theme.palette.white.main : 'initial',
              }}
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>

      <CardContent
        sx={{
          height: '100%',
          maxHeight: '425px',
          minHeight: { md: '', xs: '300px' },
        }}>
        {loadingGetSecurityPriceRange ? (
          <Spinner />
        ) : (
          <Charts
            productdetail={'productdetail'}
            data={dataCharts}
            dividends={dividends}
            serieName={'Saldo'}
            showLabels={true}
          />
        )}
      </CardContent>
    </Card>
  );
};

export default ChartnvestmentContainerProducts;
