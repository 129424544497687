import { FilterSection } from '../../XcalaComponents';
import { FilterShearch } from './FilterShearch';

export const SectionFilter = ({
  riskProfile,
  motivationsFilters,
  handleChangeProfileRisk,
  handleChangeMotivations,
  handleCleanFilters,
}) => {
  return (
    <FilterSection>
      <FilterShearch
        riskProfile={riskProfile}
        motivationsFilters={motivationsFilters}
        handleChangeProfileRisk={handleChangeProfileRisk}
        handleChangeMotivations={handleChangeMotivations}
        handleCleanFilters={handleCleanFilters}
      />
    </FilterSection>
  );
};
