import { useEffect, useState } from 'react';

export const TidioChat = ({ show }) => {
  const [tidioScriptAdded, setTidioScriptAdded] = useState(false);

  useEffect(() => {
    if (show && !tidioScriptAdded) {
      const script = document.createElement('script');
      script.src = 'https://code.tidio.co/m63ept2mlkexfti9gmyj2hrybqckzufc.js';
      script.async = true;
      script.id = 'tidio-chat-script';

      document.body.appendChild(script);
      setTidioScriptAdded(true);
    } else if (!show && tidioScriptAdded) {
      const script = document.getElementById('tidio-chat-script');
      if (script) {
        document.body.removeChild(script);
        script.remove();
        const frame = document.querySelector('#tidio-chat-code');
        const iFrame = document.querySelector('#tidio-chat');
        if (frame && iFrame) {
          frame.remove();
          iFrame.remove();
        } else {
        }
      }
      setTidioScriptAdded(false);
    }
  }, [show, tidioScriptAdded]);
  return null;
};
