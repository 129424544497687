import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import {
  Alert,
  Box,
  Button,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
  useTheme,
} from '@mui/material';
import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import useModal from '../../hooks/custom/useModal';
import { useCreateContribution } from '../../hooks/useCreateContribution';
import { useCreateInitialContribution } from '../../hooks/useCreateInitialContribution';
import { useCreatePaymentIntent } from '../../hooks/useCreatePaymentIntent';
import { useUpdateContribution } from '../../hooks/useUpdateContribution';
import Spinner from '../Spinner';
import { useNavigate } from 'react-router-dom';
import { openWidget } from '../../utils/fintocWidget';
// import Transfer from "../../../pages/private/cash/Transfers";
import { numberWithPoint } from '../../utils/numbers';
import { sendDataToTagManager, sendDataToTagManagerObject } from '../../utils/sendDataToTagManager';
import { ButtonsPrimary } from '../ComponentApp/buttoms';
// modales
import ModalActions, {
  ModalChooseTransfer,
  ReturnToForm,
  SuccessfulRechargeAllNo,
  SuccessfulRechargeAllYes,
} from '../UI/modalActions';
import { SectionPrintView } from '../XcalaComponents/ComponentsWallet';
import WalletTransfer from './WalletTransfer';

const BodyRechargeWallet = ({ institutions, dni }) => {
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { isShowing, toggle } = useModal();
  const [paymentError, setPaymentError] = useState(false);
  const [widgetClosed, setWidgetClosed] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [transferOption, setTransferOption] = useState(false);
  const [modalOption, setModalOption] = useState();
  const [amountError, setAmountError] = useState(false);
  const [contributionCreateResponse, setContributionCreateResponse] = useState({});
  const [contributionSuccess, setContributionSuccess] = useState(false);
  const [fintocFailed, setFintocFailed] = useState(false);
  const [tagManagerWallet, setTagManagerWallet] = useState({});
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();
  const publicKeyWidget = process.env.REACT_APP_PUBLIC_KEY_FINTOC;
  const userRut = process.env.REACT_APP_CURRENT_STAGE !== 'master' ? '415792638' : dni;

  const handleWidget = async (paymentStatus, exit) => {
    if (paymentStatus && exit) {
      setModalOption(3);
      setOpenModal(true);
      setWidgetClosed(false);
      setPaymentError(false);
    } else if (!paymentStatus && exit) {
      const { data: updateResponse } = await updateContribution({
        variables: {
          data: {
            txStatus: 'cont_pasarela_nok',
            amount: Number.parseInt(formik.values.amount.replace(/\D/g, '')),
            iv: contributionCreateResponse?.createInitialContribution?.iv,
            data: contributionCreateResponse?.createInitialContribution?.data,
          },
        },
      });
      setWidgetClosed(true);
    }
  };

  const handleClose = async () => {
    setOpenModal(false);
    setPaymentError(false);
    setWidgetClosed(false);
    setContributionSuccess(false);
  };

  const handleCloseModalTransfer = async (cancelTransfer, updateNote) => {
    if (cancelTransfer && updateNote) {
      setOpenModal(false);
      setPaymentError(false);
      setWidgetClosed(false);
      setContributionSuccess(false);
      const { data: updateResponse } = await updateContribution({
        variables: {
          data: {
            txStatus: 'cont_pasarela_nok',
            amount: Number.parseInt(formik.values.amount.replace(/\D/g, '')),
            iv: contributionCreateResponse?.createInitialContribution?.iv,
            data: contributionCreateResponse?.createInitialContribution?.data,
          },
        },
      });
    } else if (updateNote && !cancelTransfer) {
      const { data: updateResponse } = await updateContribution({
        variables: {
          data: {
            txStatus: 'cont_pasarela_transfer',
            amount: Number.parseInt(formik.values.amount.replace(/\D/g, '')),
            iv: contributionCreateResponse?.createInitialContribution?.iv,
            data: contributionCreateResponse?.createInitialContribution?.data,
          },
        },
      });
      setOpenModal(false);
      setPaymentError(false);
      setWidgetClosed(false);
      setTransferOption(true);
      setContributionSuccess(false);
      setFintocFailed(false);
    } else {
      setOpenModal(false);
      setPaymentError(false);
      setWidgetClosed(false);
      setTransferOption(true);
      setContributionSuccess(false);
    }
  };

  const handlerModals = value => {
    switch (value) {
      case 1:
        return <SuccessfulRechargeAllYes onClose={handleClose} />;
        break;
      case 2:
        return <SuccessfulRechargeAllNo onClose={handleClose} />;
        break;
      case 3:
        return (
          <ModalChooseTransfer fintocFailed={fintocFailed} onClose={handleCloseModalTransfer} />
        );
        break;
      case 4:
        return <ReturnToForm onClose={handleClose} />;
        break;
      case 5:
        return <ReturnToForm onClose={handleClose} />;
        break;
      default:
        return <p>No es una opcion valida</p>;
    }
  };

  const fintocHandlerSubmit = async (values, helpers) => {
    let amount = Number.parseInt(values.amount.replace(/\D/g, ''));
    try {
      const { data: paymentIntentData } = await createPaymentIntent({
        variables: {
          data: {
            amount: Number.parseInt(values.amount.replace(/\D/g, '')),
            currency: 'clp',
          },
        },
      });
      const { data: contributionResponse } = await createInitialContribution({
        variables: {
          data: { amount: amount, note: 'cont_pasarela_init' },
        },
      });
      setContributionCreateResponse(contributionResponse);
      setShowSpinner(false);
      await openWidget({
        holderType: 'individual',
        product: 'payments',
        publicKey: publicKeyWidget,
        webhookUrl: 'https://cqvjo042zj.execute-api.us-east-1.amazonaws.com/webhook',
        country: 'cl',
        institutionId: values.institution_id,
        widgetToken: paymentIntentData?.createPaymentIntent?.widget_token,
        // username: userRut,
        username: { value: userRut, editable: false },

        onSuccess: async success => {
          const listo = await success;
          try {
            const { data: updateResponse } = await updateContribution({
              variables: {
                data: {
                  txStatus: 'cont_pasarela_ok',
                  amount: Number.parseInt(values.amount.replace(/\D/g, '')),
                  iv: contributionResponse?.createInitialContribution?.iv,
                  data: contributionResponse?.createInitialContribution?.data,
                },
              },
            });
            setModalOption(1);
            setOpenModal(true);
            setTagManagerWallet({
              txStatus: 'cont_pasarela_ok',
              amount: Number.parseInt(values.amount.replace(/\D/g, '')),
              institutionId: values.institution_id,
              userId: user.username,
              contributionHash: contributionResponse?.createInitialContribution?.iv,
            });
          } catch (error) {
            setModalOption(2);
            setOpenModal(true);
          }
        },
        onExit: log => {
          setWidgetClosed(true);
        },
        onEvent: eventName => {
          if (eventName === 'payment_error') {
            setFintocFailed(prevState => !prevState);
            setPaymentError(prevState => !prevState);
          }
        },
      });
      helpers.setStatus({ success: true });
      helpers.setSubmitting(false);
    } catch (err) {
      const typeErrors = ['Invalid country', 'Invalid holderType', 'Invalid product'];
      const findError = typeErrors.find(item => {
        return err.message.includes(item);
      });
      setShowSpinner(false);
      if (contributionSuccess || findError !== undefined) {
        setModalOption(3);
        setFintocFailed(true);
        setOpenModal(true);
      } else {
        setModalOption(4);
        setOpenModal(true);
      }
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };
  const transferHandlerSubmit = async (values, helpers) => {
    let amount = Number.parseInt(values.amount.replace(/\D/g, ''));
    try {
      const { data: contributionResponse } = await createContribution({
        variables: {
          data: { amount: amount, note: 'cont_transfer' },
        },
      });
      setTransferOption(true);
      setShowSpinner(false);
      helpers.setStatus({ success: true });
      helpers.setSubmitting(false);
    } catch (err) {
      setModalOption(4);
      setShowSpinner(false);
      setOpenModal(true);
      helpers.setStatus({ success: false });
      helpers.setErrors({ submit: err.message });
      helpers.setSubmitting(false);
    }
  };
  const formik = useFormik({
    initialValues: {
      amount: '',
      currency: 'cl',
      institution_id: '',
    },
    validate: values => {
      const errors = {};
      if (
        !values.amount ||
        Number.parseInt(values.amount.replace(/\D/g, '')) < 10000 ||
        values.amount[0] === '0'
      ) {
        errors.amount = 'El monto debe ser mayor a $10.000';
      }
      if (!values.institution_id) {
        errors.institution_id = 'Debe seleccionar un banco';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      setShowSpinner(true);
      values.institution_id === 'other_banks'
        ? transferHandlerSubmit(values, helpers)
        : fintocHandlerSubmit(values, helpers);
    },
  });

  const [
    createPaymentIntent,
    { loading: loadingCreatePaymentIntent, error: errorCreatePaymentIntent },
  ] = useCreatePaymentIntent();
  const [
    createInitialContribution,
    { loading: loadingCreateInitialContribution, error: errorCreateInitialContribution },
  ] = useCreateInitialContribution();
  const [
    createContribution,
    { loading: loadingCreateContribution, error: errorCreateContribution },
  ] = useCreateContribution();
  const [
    updateContribution,
    { loading: loadingUpdateContribution, error: errorUpdateContribution },
  ] = useUpdateContribution();

  useEffect(() => {
    handleWidget(paymentError, widgetClosed);
    if (contributionCreateResponse?.createContribution?.iv) {
      setContributionSuccess(true);
    }
  }, [widgetClosed, contributionCreateResponse]);

  useEffect(() => {
    if (Object.keys(tagManagerWallet).length > 0) {
      sendDataToTagManagerObject('purchase', '/payments', tagManagerWallet);
    }
  }, [tagManagerWallet]);

  useEffect(() => {
    sendDataToTagManager('payments', '/payments');
  }, []);

  if (showSpinner) {
    return (
      <>
        <Stack gap={'64px'} marginX={'auto'} marginBottom={'60px'}>
          <Box
            sx={{
              marginX: 'auto',
              width: { xs: '90%', md: '60%' },
              maxWidth: '1280px',
              padding: '32px 32px',
              bgcolor: theme.palette.white.main,
              borderRadius: '16px',
              marginTop: '-80px',
              zIndex: 9,
              boxShadow: '0px 0px 32px rgba(61, 64, 75, 0.15)',
              display: 'flex',
              flexDirection: 'column',
              gap: '16px',
            }}>
            <SectionPrintView>
              <Typography
                sx={{
                  textAlign: 'center',
                  whiteSpace: 'pre-wrap',
                  fontSynthesis: 'none',
                  color: theme.palette.white.main,
                  fontStyle: 'inherit',
                  fontFamily: 'Museo',
                  fontWeight: theme.typography.titleText.fontWeight,
                  fontSize: 'clamp(1.1rem, 0.375rem + 2vw, 1.5rem)',
                }}>
                {formik.values.institution_id === 'other_banks'
                  ? 'Creando solicitud de recarga de caja'
                  : 'Te estamos redirigiendo a la pasarela de pago...'}
              </Typography>
            </SectionPrintView>
          </Box>
        </Stack>
        <Spinner />
      </>
    );
  }

  if (
    loadingUpdateContribution ||
    loadingCreateContribution ||
    loadingCreatePaymentIntent ||
    loadingCreateInitialContribution
  ) {
    return <Spinner />;
  }
  if (
    errorCreateContribution ||
    errorCreateInitialContribution ||
    errorCreatePaymentIntent ||
    errorUpdateContribution
  ) {
  }

  return (
    <>
      {/* modal */}
      <ModalActions open={openModal}>{handlerModals(modalOption)}</ModalActions>

      {transferOption ? (
        <WalletTransfer amount={formik.values.amount} />
      ) : (
        // <Transfer amount={formik.values.amount} />
        <form onSubmit={formik.handleSubmit}>
          <Stack gap={'64px'} marginX={'auto'} marginBottom={'60px'}>
            <Box
              sx={{
                marginX: 'auto',
                width: { xs: '90%', md: '60%' },
                maxWidth: '1280px',
                padding: '32px 32px',
                bgcolor: theme.palette.white.main,
                borderRadius: '16px',
                marginTop: '-80px',
                zIndex: 9,
                boxShadow: '0px 0px 32px rgba(61, 64, 75, 0.15)',
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
              }}>
              <Typography
                sx={{
                  textAlign: 'left',
                  whiteSpace: 'pre-wrap',
                  fontSynthesis: 'none',
                  color: theme.palette.primary.dark,
                  fontStyle: 'inherit',
                  fontFamily: 'Museo',
                  fontWeight: theme.typography.titleText.fontWeight,
                  fontSize: theme.typography.title.fontSize,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.mediumText.fontSize,
                  },
                }}>
                ¿Cuánto deseas recargar?
              </Typography>
              <TextField
                fullWidth
                label='Ingresa el monto'
                type='text'
                error={Boolean(formik.touched.amount && formik.errors.amount)}
                onBlur={formik.handleBlur}
                helperText={formik.touched.amount && formik.errors.amount}
                name='amount'
                InputProps={{
                  startAdornment: <InputAdornment position='start'>$</InputAdornment>,
                }}
                onChange={e => {
                  const value = e.target.value;
                  const validAmount = value.replace(/\D/g, '');
                  if (validAmount.toString().length <= 8) {
                    const format = numberWithPoint(validAmount);
                    formik.setFieldValue('amount', format);
                  }
                }}
                value={formik.values.amount}
                variant='standard'
              />

              <FormControl variant='standard' sx={{ width: '100%' }}>
                <InputLabel id='demo-simple-select-standard-label'>Seleccione Banco</InputLabel>
                <Select
                  error={Boolean(formik.touched.institution_id && formik.errors.institution_id)}
                  helperText={
                    formik.touched.institution_id && formik.errors.institution_id
                      ? formik.errors.institution_id
                      : ''
                  }
                  label='Bancos'
                  name='institution_id'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.institution_id}
                  labelId='demo-select-small-label'
                  id='demo-select-small'
                  sx={{ width: '100%' }}
                  variant='standard'>
                  {institutions?.getInstitutions?.map(institution => (
                    <MenuItem key={institution.value} value={institution.value}>
                      {institution.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              {formik.touched.institution_id && formik.errors.institution_id ? (
                <Typography
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    color: theme.palette.red.main,
                  }}>
                  {formik.errors.institution_id}
                </Typography>
              ) : (
                ''
              )}

              {formik.values.institution_id !== '' && (
                <Stack item md={12} xs={12} sx={{ mt: 3 }}>
                  {
                    <Alert
                      severity={
                        formik.values.institution_id === 'other_banks' ? 'info' : 'success'
                      }>
                      {formik.values.institution_id === 'other_banks'
                        ? 'En el siguiente paso tendrás los datos de transferencia'
                        : 'El proceso de pago se realizará sin salir de xcala'}
                    </Alert>
                  }
                </Stack>
              )}
              <Stack
                direction={{
                  xs: 'column',
                  sm: 'column',
                  md: 'row',
                  lg: 'row',
                  xl: 'row',
                }}
                justifyContent='center'
                alignItems='center'
                sx={{
                  mt: 1,
                  p: 2,
                  backgroundColor: '#0000001F',
                  borderRadius: 1,
                }}>
                <WarningAmberIcon
                  sx={{ ml: 1, color: theme.palette.textLight.main }}
                  fontSize='large'
                />
                <Typography
                  variant='body2'
                  sx={{ ml: 1, color: theme.palette.textLight.main, textAlign: 'justify' }}>
                  Recuerda que tu banco puede limitar la primera transferencia que realices a Xcala,
                  por lo que considéralo al momento de hacer tu recarga.
                </Typography>
              </Stack>
              <Stack
                sx={{
                  marginTop: 3,
                  marginX: 'auto',
                  maxWidth: { md: '40%', xs: '100%' },
                  width: '100%',
                  flexDirection: 'column',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                }}>
                <ButtonsPrimary
                  name='Recargar caja'
                  onClick={() => {
                    if (formik.values.amount === '') {
                      setAmountError(true);
                    }
                  }}
                  type='submit'
                  sx={{
                    maxWidth: { md: '416px' },
                    width: { xs: '100%' },
                    whiteSpace: 'nowrap',
                  }}
                />
              </Stack>
            </Box>
            <Stack
              sx={{
                marginX: 'auto',
                maxWidth: '50%',
                width: '100%',
                minHeight: '150px',
                flexDirection: 'column',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: '16px',
              }}>
              <Button
                variant='text'
                disableElevation
                sx={{ padding: '4px 16px', textTransform: 'none' }}
                onClick={() => navigate('/wallet')}>
                Volver
              </Button>
            </Stack>
          </Stack>
        </form>
      )}
    </>
  );
};

export default BodyRechargeWallet;
