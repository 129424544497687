import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { Auth } from 'aws-amplify';

const tokenName = `token-${process.env.REACT_APP_CURRENT_STAGE}`;

const initialState = {
  user: null,
  emailTemp: null,
  passwordTemp: null,
  userTemp: null,
  errorSignIn: null,
  loadingSignIn: false,

  successSignUp: null,
  errorSignUp: null,
  loadingSignUp: false,

  successSignOut: null,
  errorSignOut: null,
  loadingSignOut: false,

  successConfirmSignUp: null,
  errorConfirmSignUp: null,
  loadingConfirmSignUp: false,

  successResendSignUp: null,
  errorResendSignUp: null,
  loadingResendSignUp: false,

  successForgotPassword: null,
  errorForgotPassword: null,
  loadingForgotPassword: false,

  successForgotPasswordSubmit: null,
  errorForgotPasswordSubmit: null,
  loadingForgotPasswordSubmit: false,

  loadingUpdateUserAttributes: false,
  successUpdateUserAttributes: null,
  errorUpdateUserAttributes: null,

  loadingChangePassword: false,
  successChangePassword: null,
  errorChangePassword: null,

  loadingCurrentUserInfo: false,
  errorCurrentUserInfo: null,
};

export const signIn = createAsyncThunk(
  //action type string
  'auth/signIn',
  // callback function
  async ({ username, password }, { rejectWithValue }) => {
    try {
      const user = await Auth.signIn(username.toLowerCase(), password);
      const userInfo = { username: user.username, ...user.attributes };
      const currentSession = await Auth.currentSession();
      localStorage.setItem(tokenName, currentSession.idToken.jwtToken);
      return userInfo;
    } catch (err) {
      switch (err.message) {
        case 'Username should be either an email or a phone number.':
          return rejectWithValue('El correo no es correcto');
        case 'Password did not conform with policy: Password not long enough':
          return rejectWithValue('Contraseña no cumple las politicas');
        case 'User is not confirmed.':
          return rejectWithValue('Usuario no ha confirmado la cuenta.');
        case 'Incorrect username or password.':
          return rejectWithValue('Usuario o contraseña invalidas.');
        case 'User does not exist.':
          return rejectWithValue('Usuario no existe.');
        case 'PreAuthentication failed with error User has exceeded the number of attempts.':
          return rejectWithValue(
            'La cantidad de intentos ha excedido el máximo.  Por favor, cambie su contraseña.',
          );
        default:
          return rejectWithValue('Ha ocurrido un error.');
      }
    }
  },
);

export const currentSession = createAsyncThunk(
  //action type string
  'auth/currentSession',
  // callback function
  async (_, { rejectWithValue }) => {
    try {
      const currentSession = await Auth.currentSession();
      localStorage.setItem(tokenName, currentSession.idToken.jwtToken);
      return;
    } catch (err) {
      return rejectWithValue('Ha ocurrido un error.');
    }
  },
);

export const signUp = createAsyncThunk(
  //action type string
  'auth/signUp',
  // callback function
  async (
    { name, lastname, motherslastname, password, email, dni, prefix, phone },
    { rejectWithValue },
  ) => {
    try {
      await Auth.signUp({
        username: email,
        password,
        attributes: {
          email,
          'custom:dni': dni,
          phone_number: prefix + phone,
          name,
          'custom:paternal-surname': lastname,
          'custom:maternal-surname': motherslastname,
        },
      });
      return true;
    } catch (err) {
      switch (err.message) {
        case 'Invalid phone number format.':
          return rejectWithValue('El telefono es incorrecto.');
        case 'An account with the given email already exists.':
          return rejectWithValue('Un usuario con este correo ya existe.');
        case 'PostConfirmation failed with error El DNI ya estÃ¡ registrado..':
          return rejectWithValue('Un usuario con este RUT ya existe.');
        default:
          return rejectWithValue('Ha ocurrido un error.');
      }
    }
  },
);

export const signOut = createAsyncThunk(
  //action type string
  'auth/signOut',
  // callback function
  async (_, { rejectWithValue }) => {
    try {
      await Auth.signOut();
      localStorage.removeItem(tokenName);
      localStorage.removeItem('hasSeenAlert');
      return true;
    } catch (err) {
      return rejectWithValue('Ha ocurrido un error al intentar salir.');
    }
  },
);

export const confirmSignUp = createAsyncThunk(
  //action type string
  'auth/confirmSignUp',
  // callback function
  async ({ username, confirmationCode }, { rejectWithValue }) => {
    try {
      await Auth.confirmSignUp(username, confirmationCode);
      return true;
    } catch (err) {
      switch (err.message) {
        case 'Invalid verification code provided, please try again.':
          return rejectWithValue(
            'El código ingresado no es correcto. Por favor ingresalo nuevamente.',
          );
        case 'PostConfirmation failed with error El DNI ya está registrado..':
          return rejectWithValue('El dni ya está registrado');
        default:
          return rejectWithValue('Ha ocurrido un error.');
      }
    }
  },
);

export const resendSignUp = createAsyncThunk(
  //action type string
  'auth/resendSignUp',
  // callback function
  async ({ username }, { rejectWithValue }) => {
    try {
      await Auth.resendSignUp(username);
      return true;
    } catch (err) {
      return rejectWithValue('Ha ocurrido un error al intentar renviar un nuevo código.');
    }
  },
);

export const forgotPassword = createAsyncThunk(
  //action type string
  'auth/forgotPassword',
  // callback function
  async ({ username }, { rejectWithValue }) => {
    try {
      await Auth.forgotPassword(username);
      return true;
    } catch (err) {
      switch (err.message) {
        case 'Username/client id combination not found.':
          return rejectWithValue('No existe información para el correo ingresado');
        default:
          return rejectWithValue('Ha ocurrido un error al intentar recuperar su contraseña.');
      }
    }
  },
);

export const forgotPasswordSubmit = createAsyncThunk(
  //action type string
  'auth/forgotPasswordSubmit',
  // callback function
  async ({ username, code, password }, { rejectWithValue }) => {
    try {
      await Auth.forgotPasswordSubmit(username, code, password);
      return true;
    } catch (err) {
      switch (err.message) {
        case ' Invalid verification code provided, please try again.':
          return rejectWithValue(
            'El código ingresado no es correcto. Por favor ingresalo nuevamente.',
          );
        default:
          return rejectWithValue('Ha ocurrido un error al intentar cambiar tu contraseña.');
      }
    }
  },
);

export const updateUserAttributesSubmit = createAsyncThunk(
  //action type string
  'auth/updateUserAttributesSubmit',
  // callback function
  async ({ name, value }, { rejectWithValue }) => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      const data = { [name]: value.toLowerCase() };
      await Auth.updateUserAttributes(user, data);

      return data;
    } catch (err) {
      switch (err.message) {
        case ' Invalid verification code provided, please try again.':
          return rejectWithValue(
            'El código ingresado no es correcto. Por favor ingresalo nuevamente.',
          );
        default:
          return rejectWithValue('Ha ocurrido un error al intentar actualizar tus datos.');
      }
    }
  },
);

export const changePassword = createAsyncThunk(
  //action type string
  'auth/changePassword',
  // callback function
  async ({ oldPassword, newPassword }, { rejectWithValue }) => {
    try {
      let user = await Auth.currentAuthenticatedUser();
      const response = await Auth.changePassword(user, oldPassword, newPassword);
      return response;
    } catch (err) {
      switch (err.message) {
        case 'Incorrect username or password.':
          return rejectWithValue('La contraseña actual es incorrecta. Intentalo nuevamente.');
        case 'Attempt limit exceeded, please try after some time.':
          return rejectWithValue('Exediste el máximo de cambios. Por favor intentalo más tarde.');
        default:
          return rejectWithValue('Ha ocurrido un error al intentar actualizar tu contraseña.');
      }
    }
  },
);

export const currentUserInfo = createAsyncThunk(
  //action type string
  'auth/currentUserInfo',
  // callback function
  async (_, { rejectWithValue }) => {
    try {
      let user = await Auth.currentUserInfo();
      const userInfo = { username: user.username, ...user.attributes };
      return userInfo;
    } catch (err) {
      switch (err.message) {
        case 'Incorrect username or password.':
          return rejectWithValue('La contraseña actual es incorrecta. Intentalo nuevamente.');
        case 'Attempt limit exceeded, please try after some time.':
          return rejectWithValue('Exediste el máximo de cambios. Por favor intentalo más tarde.');
        default:
          return rejectWithValue('Ha ocurrido un error al intentar actualizar tu contraseña.');
      }
    }
  },
);

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetSignIn: state => {
      state.user = null;
      state.loadingSignIn = false;
      state.errorSignIn = null;
    },
    resetSignUp: state => {
      state.successSignUp = null;
      state.errorSignUp = null;
      state.loadingSignUp = false;
    },
    resetUserTemp: state => {
      state.userTemp = null;
    },
    resetConfirmSignUp: state => {
      state.successConfirmSignUp = null;
      state.errorConfirmSignUp = null;
      state.loadingConfirmSignUp = false;
    },
    resetResendSignUp: state => {
      state.successResendSignUp = null;
      state.errorResendSignUp = null;
      state.loadingResendSignUp = false;
    },
    resetForgotPassword: state => {
      state.successForgotPassword = null;
      state.errorForgotPassword = null;
      state.loadingForgotPassword = false;
    },
    resetForgotPasswordSubmit: state => {
      state.successForgotPasswordSubmit = null;
      state.errorForgotPasswordSubmit = null;
      state.loadingForgotPasswordSubmit = false;
    },
    resetSignOut: state => {
      state.successSignOut = null;
      state.errorSignOut = null;
      state.loadingSignOut = false;
    },
    resetUpdateUserAttributesSubmit: state => {
      state.loadingUpdateUserAttributes = false;
      state.successUpdateUserAttributes = null;
      state.errorUpdateUserAttributes = null;
    },
    updateEmailTemp: (state, { payload }) => {
      state.emailTemp = payload;
    },
    updatePasswordTemp: (state, { payload }) => {
      state.passwordTemp = payload;
    },
    updateTempUserAtributes: (state, { payload }) => {
      state.userTemp = {
        ...payload,
      };
    },
    updateUserAtributes: (state, { payload }) => {
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    resetChangePassword: state => {
      state.loadingChangePassword = false;
      state.successChangePassword = null;
      state.errorChangePassword = null;
    },
    resetCurrentUserInfo: state => {
      state.loadingCurrentUserInfo = false;
      state.errorCurrentUserInfo = null;
    },
  },
  extraReducers: {
    [signIn.pending]: state => {
      state.loadingSignIn = true;
      state.user = null;
      state.errorSignIn = null;
    },
    [signIn.fulfilled]: (state, { payload }) => {
      state.loadingSignIn = false;
      state.user = payload;
    },
    [signIn.rejected]: (state, { payload }) => {
      state.loadingSignIn = false;
      state.errorSignIn = payload;
    },

    [signUp.pending]: state => {
      state.loadingSignUp = true;
      state.successSignUp = null;
      state.errorSignUp = null;
    },
    [signUp.fulfilled]: (state, { payload }) => {
      state.loadingSignUp = false;
      state.successSignUp = payload;
    },
    [signUp.rejected]: (state, { payload }) => {
      state.loadingSignUp = false;
      state.errorSignUp = payload;
    },

    [signOut.pending]: state => {
      state.loadingSignOut = true;
      state.successSignOut = null;
      state.errorSignOut = null;
    },
    [signOut.fulfilled]: (state, { payload }) => {
      state.loadingSignOut = false;
      state.user = null;
      state.successSignOut = payload;
    },
    [signOut.rejected]: (state, { payload }) => {
      state.loadingSignOut = false;
      state.errorSignOut = payload;
    },

    [confirmSignUp.pending]: state => {
      state.loadingConfirmSignUp = true;
      state.successConfirmSignUp = null;
      state.errorConfirmSignUp = null;
    },
    [confirmSignUp.fulfilled]: (state, { payload }) => {
      state.loadingConfirmSignUp = false;
      state.successConfirmSignUp = payload;
    },
    [confirmSignUp.rejected]: (state, { payload }) => {
      state.loadingConfirmSignUp = false;
      state.errorConfirmSignUp = payload;
    },

    [resendSignUp.pending]: state => {
      state.loadingResendSignUp = true;
      state.successResendSignUp = null;
      state.errorResendSignUp = null;
    },
    [resendSignUp.fulfilled]: (state, { payload }) => {
      state.loadingResendSignUp = false;
      state.successResendSignUp = payload;
    },
    [resendSignUp.rejected]: (state, { payload }) => {
      state.loadingResendSignUp = false;
      state.errorResendSignUp = payload;
    },

    [forgotPassword.pending]: state => {
      state.loadingForgotPassword = true;
      state.successForgotPassword = null;
      state.errorForgotPassword = null;
    },
    [forgotPassword.fulfilled]: (state, { payload }) => {
      state.loadingForgotPassword = false;
      state.successForgotPassword = payload;
    },
    [forgotPassword.rejected]: (state, { payload }) => {
      state.loadingForgotPassword = false;
      state.errorForgotPassword = payload;
    },

    [forgotPasswordSubmit.pending]: state => {
      state.loadingForgotPasswordSubmit = true;
      state.successForgotPasswordSubmit = null;
      state.errorForgotPasswordSubmit = null;
    },
    [forgotPasswordSubmit.fulfilled]: (state, { payload }) => {
      state.loadingForgotPasswordSubmit = false;
      state.successForgotPasswordSubmit = payload;
    },
    [forgotPasswordSubmit.rejected]: (state, { payload }) => {
      state.loadingForgotPasswordSubmit = false;
      state.errorForgotPasswordSubmit = payload;
    },

    [updateUserAttributesSubmit.pending]: state => {
      state.loadingUpdateUserAttributes = true;
      state.successUpdateUserAttributes = null;
      state.errorUpdateUserAttributes = null;
    },
    [updateUserAttributesSubmit.fulfilled]: (state, { payload }) => {
      state.loadingUpdateUserAttributes = false;
      state.user = {
        ...state.user,
        ...payload,
      };
      state.successUpdateUserAttributes = payload;
    },
    [updateUserAttributesSubmit.rejected]: (state, { payload }) => {
      state.loadingUpdateUserAttributes = false;
      state.errorUpdateUserAttributes = payload;
    },

    [changePassword.pending]: state => {
      state.loadingChangePassword = true;
      state.successChangePassword = null;
      state.errorChangePassword = null;
    },
    [changePassword.fulfilled]: (state, { payload }) => {
      state.loadingChangePassword = false;
      state.successChangePassword = payload;
    },
    [changePassword.rejected]: (state, { payload }) => {
      state.loadingChangePassword = false;
      state.errorChangePassword = payload;
    },

    [currentUserInfo.pending]: state => {
      state.loadingCurrentUserInfo = true;
      state.errorCurrentUserInfo = null;
    },
    [currentUserInfo.fulfilled]: (state, { payload }) => {
      state.loadingCurrentUserInfo = false;
      state.user = {
        ...state.user,
        ...payload,
      };
    },
    [currentUserInfo.rejected]: (state, { payload }) => {
      state.loadingCurrentUserInfo = false;
      state.errorCurrentUserInfo = payload;
    },
  },
});

export const {
  resetSignIn,
  resetSignUp,
  resetConfirmSignUp,
  resetResendSignUp,
  resetForgotPassword,
  resetForgotPasswordSubmit,
  resetSignOut,
  resetUserTemp,
  updateUserAtributes,
  updateEmailTemp,
  updatePasswordTemp,
  resetUpdateUserAttributesSubmit,
  resetChangePassword,
  updateTempUserAtributes,
  resetCurrentUserInfo,
} = authSlice.actions;

export default authSlice.reducer;
