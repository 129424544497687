import TagManager from 'react-gtm-module';

export const sendDataToTagManager = (event, path) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      path: path,
    },
  });
};

export const sendDataToTagManagerObject = (event, path, data) => {
  TagManager.dataLayer({
    dataLayer: {
      event: event,
      purchase: {
        txStatus: data.txStatus,
        amount: data.amount,
        userId: data.userId,
        bank: data.institutionId,
        contributionHash: data.contributionHash,
      },
      path: path,
    },
  });
};
