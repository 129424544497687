import { Box, Stack, styled } from '@mui/material';

const ContainerWithoutData = styled(Stack)({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  flexDirection: 'column',
  backgroundColor: 'rgba(240, 243, 244, 1)',
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  height: '595px',
  width: '100%',
  margin: '33px 0px 0px 0px',
});

const BoxContainerBody = styled(Box)({
  display: 'flex',
  position: 'absolute',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '30%',
});

const FindUsImage = styled('img')({
  height: '406px',
  width: '406px',
  objectFit: 'cover',
  margin: '0px',
});
const ContainerText = styled(Box)(({ theme }) => ({
  textAlign: 'center',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.titleText.fontWeight,
  fontSize: theme.typography.h2.fontSize,
  letterSpacing: '0.32px',
  textDecoration: 'none',
  lineHeight: '48px',
  textTransform: 'none',
  margin: '24px 0px 0px 0px',
}));
export const FilterWithoutData = () => {
  return (
    <ContainerWithoutData>
      <BoxContainerBody sx={{ width: { md: '30%', xs: '100%' } }}>
        <Box>
          <FindUsImage
            sx={{
              width: { md: '406px', xs: '200px' },
              height: { md: '406px', xs: '200px' },
            }}
            src='/assets/images/FindUs.gif'
            alt=''
          />
        </Box>
        <ContainerText>No hay coincidencias, intentalo nuevamente por favor</ContainerText>
      </BoxContainerBody>
    </ContainerWithoutData>
  );
};
