import { Grid, useMediaQuery } from '@mui/material';
import { useSelector } from 'react-redux';
import { CardProductsComponent } from '../XcalaComponents';
import { BodyCardsContainerPortfolio } from './BodyCardContainerPortfolio';
import { HeadContainerCardPorfolio } from './HeadContainerCardPorfolio';

export const CardPorfolio = ({ dataChart, portfolioName, portfolioDetailsFileAs }) => {
  const user = useSelector(state => state.auth.user);

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';
  const { profilePortfolio } = dataChart;

  const isMediumScreen = useMediaQuery('(max-width:1407px) and (min-width:900px)');

  return (
    <Grid item xs={12} sm={12} md={isMediumScreen ? 6 : 4} style={{ maxWidth: '100%' }}>
      <CardProductsComponent spacing={2} alignItems={'center'}>
        <HeadContainerCardPorfolio
          portfolioDetailsFileAs={portfolioDetailsFileAs}
          fundName={portfolioName}
          fundPerfile={profilePortfolio}
          userRisk={userRisk}
        />
        <BodyCardsContainerPortfolio
          portfolioName={portfolioName}
          dataChart={dataChart}
          portfolioDetailsFileAs={portfolioDetailsFileAs}
        />
      </CardProductsComponent>
    </Grid>
  );
};
