import { Typography, useTheme } from '@mui/material';
import { Button } from 'antd';
import React, { useState } from 'react';

const TruncatedText = ({ text, limit, seeMore = 'Ver más', seeLess = 'Ver menos' }) => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);

  const toggleExpanded = () => {
    setExpanded(!expanded);
  };

  const truncatedText = expanded ? text : `${text.slice(0, limit)}...`;

  return (
    <>
      <Typography component='p'>
        {truncatedText}

        {!expanded && text.length > limit && (
          <Typography
            component='span'
            color={theme.palette.primary.dark}
            onClick={toggleExpanded}
            style={{ cursor: 'pointer' }}>
            {seeMore}
          </Typography>
        )}
        {expanded && (
          <Typography
            component='span'
            color='primary'
            onClick={toggleExpanded}
            style={{ cursor: 'pointer' }}>
            {seeLess}
          </Typography>
        )}
      </Typography>
    </>
  );
};

export default TruncatedText;
