import { gql, useQuery } from '@apollo/client';

const GET_CAROUSEL = gql`
 query GetAllCarousel {
  getAllCarousel {
    Items {
      buttonText
      buttonText2
      createdDate
      description
      desktopImg
      investment
      mobileImg
      order
      title
      url
      url2
      xcalaCarouselFileId
    }
  }
}`;

export const useGetCarousel = () => {
  const { data, loading, error } = useQuery(GET_CAROUSEL);
  return {
    data: data?.getAllCarousel?.Items || [],
    loading,
    error,
  };
};
