import { Button, Stack, Typography, styled, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

export const TextDescription = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  variant: 'body1',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.smallText.fontWeight,
  fontSize: theme.typography.button.fontSize,
}));

export const ErrorStepSign = ({ validationFailed }) => {
  const navigate = useNavigate();
  const theme = useTheme();
  const onClickConditionSign = () => {
    return validationFailed ? navigate('/completeregistration') : navigate('/myprofilerisk');
  };

  return (
    <Stack
      textAlign={'center'}
      width={'90%'}
      gap={1}
      justifyContent={'center'}
      alignItems={'center'}>
      <Stack width={'100px'} height={'100px'}>
        <img
          src='/assets/images/error.gif'
          alt='error'
          style={{ width: '100%', height: '100%', display: 'block' }}
        />
      </Stack>
      <Typography
        component='h3'
        sx={{
          fontSize: theme.typography.h1.fontSize,
          fontWeight: theme.typography.button.fontWeight,
          color: theme.palette.red.main,
        }}>
        ¡Oh no! Parece que algo salió mal
      </Typography>

      {validationFailed === 'validation_failed' ? (
        <TextDescription>
          El código ingresado parece ser inválido, por favor reintentalo
        </TextDescription>
      ) : (
        <TextDescription>
          Hemos tenido problemas para validar tu información, por favor escribenos un email a{' '}
          <Typography
            variant='inherit'
            component={'span'}
            sx={{ fontWeight: theme.typography.titleText.fontWeight }}>
            contacto@xcala.com
          </Typography>{' '}
          para poder solucionar este inconveniente
        </TextDescription>
      )}

      <Stack width={{ md: '80%', xs: '100%' }} marginTop={3}>
        <Button
          variant='contained'
          disableElevation
          sx={{
            padding: '12px 32px',
            textTransform: 'none',
          }}
          fullWidth
          onClick={() => onClickConditionSign()}>
          {validationFailed === 'validation_failed'
            ? 'Volver a Solicitar codigo'
            : ' Volver a inicio'}
        </Button>
      </Stack>
    </Stack>
  );
};
