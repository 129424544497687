import { gql, useLazyQuery } from '@apollo/client';

const GET_PRICE_RANGE_QUERY = gql`
  query getPriceRange($data: price!) {
    getPriceRange(data: $data) {
      ClosePrice
      EffectiveDate
      ID
    }
  }
`;
{
  /*Retorna un array de objetos que contienen el precio de cierre de un fondo en un rango de fechas*/
}
export default () => useLazyQuery(GET_PRICE_RANGE_QUERY, { fetchPolicy: 'no-cache' });
