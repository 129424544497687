import { PersonAddDisabledTwoTone } from '@mui/icons-material';
import { Box, MenuItem, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
  gridFilteredSortedRowIdsSelector,
  gridVisibleColumnFieldsSelector,
  useGridApiContext,
} from '@mui/x-data-grid';
import { formatChileanDate } from '../../utils/dates';
import { numberWithPoint } from '../../utils/numbers';
import { portfolioNameAndDetails } from '../../utils/portfolioNameAndDetails';
import Spinner from '../Spinner';

const getJson = apiRef => {
  const filteredSortedRowIds = gridFilteredSortedRowIdsSelector(apiRef);
  const visibleColumnsField = gridVisibleColumnFieldsSelector(apiRef);

  const data = filteredSortedRowIds.map(id => {
    const row = {};
    // biome-ignore lint/complexity/noForEach: <explanation>
    visibleColumnsField.forEach(field => {
      row[field] = apiRef.current.getCellParams(id, field).value;
    });
    return row;
  });

  return JSON.stringify(data, null, 2);
};

const exportBlob = (blob, filename) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = filename;
  a.click();

  setTimeout(() => {
    URL.revokeObjectURL(url);
  });
};

function JsonExportMenuItem(props) {
  const apiRef = useGridApiContext();

  const { hideMenu } = props;

  return (
    <MenuItem
      onClick={() => {
        const jsonString = getJson(apiRef);
        const blob = new Blob([jsonString], {
          type: 'text/json',
        });
        exportBlob(blob, 'data_xcala.json');
        hideMenu?.();
      }}>
      Export JSON
    </MenuItem>
  );
}

const csvOptions = { delimiter: ';' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem rintOptions={{ fileName: 'Descargar informe' }} options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

function CustomToolbar(props) {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
}

function NoRowsOverlay() {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center'>
      No hay transacciones para mostrar
    </Stack>
  );
}
export const TableTransactions = ({
  tap,
  loadingGetClientTransactionsPortfolio,
  dataTransactions,
  errorGetClientTransactionsPortfolio,
}) => {
  const theme = useTheme();
  const isXsRender = useMediaQuery(theme => theme.breakpoints.only('xs'));
  const column =
    tap === 'Fondos'
      ? [
          {
            field: 'fecha',
            headerName: 'Fecha',
            width: 120,
            flex: '0.5 0 100px',
            hide: true,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    {formatChileanDate(params.formattedValue)}
                  </Typography>
                </div>
              );
            },
          },

          {
            field: 'movimiento',
            headerName: 'Movimientos',
            flex: '0.6 0 80px',
            width: 200,
            cellClassName: 'super-app-theme--cell',
          },

          {
            field: 'producto',
            headerName: 'Productos',
            flex: '1 1 150px',
            width: !isXsRender ? 300 : 140,
          },
          {
            field: 'estado',
            headerName: 'Estado',
            flex: '0.6 0 auto',
            width: 160,
          },
          {
            field: 'cuota',
            headerName: 'Cuotas',
            flex: '0.6 0 auto',
            width: 140,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    {params.formattedValue === 0 ? '---' : params.formattedValue.toFixed(4)}
                  </Typography>
                </div>
              );
            },
            sortComparator: (v1, v2) => Number.parseFloat(v1) - Number.parseFloat(v2),
          },
          {
            field: 'monto',
            headerName: 'Monto',
            flex: '0.6 0 auto',
            width: 140,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    $ {params.formattedValue === '' ? '----------' : params.formattedValue}
                  </Typography>
                </div>
              );
            },
            sortComparator: (v1, v2) =>
              Number(v1.replace(/\./g, '')) - Number(v2.replace(/\./g, '')),
          },
        ]
      : [
          {
            field: 'fecha',
            headerName: 'Fecha',
            width: 120,
            flex: '0.5 0 100px',
            hide: true,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    {formatChileanDate(params.formattedValue)}
                  </Typography>
                </div>
              );
            },
          },

          {
            field: 'movimiento',
            headerName: 'Movimientos',
            flex: '0.6 0 80px',
            width: 160,
            cellClassName: 'super-app-theme--cell',
          },
          {
            field: 'portfolioName',
            headerName: 'Nombre de portfolio',
            flex: '1 1 150px',
            width: !isXsRender ? 300 : 200,
          },

          {
            field: 'producto',
            headerName: 'Productos',
            flex: '1 1 150px',
            width: !isXsRender ? 300 : 140,
          },
          {
            field: 'estado',
            headerName: 'Estado',
            flex: '0.6 0 auto',
            width: 160,
          },
          {
            field: 'cuota',
            headerName: 'Cuotas',
            flex: '0.6 0 auto',
            width: 140,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>{params.formattedValue.toFixed(4)}</Typography>
                </div>
              );
            },
            sortComparator: (v1, v2) => Number.parseFloat(v1) - Number.parseFloat(v2),
          },
          {
            field: 'monto',
            headerName: 'Monto',
            flex: '0.6 0 auto',
            width: 140,
            renderCell: params => {
              return (
                <div>
                  <Typography variant='subtitle2'>
                    $ {params.formattedValue === '' ? '----------' : params.formattedValue}
                  </Typography>
                </div>
              );
            },
            sortComparator: (v1, v2) =>
              Number(v1.replace(/\./g, '')) - Number(v2.replace(/\./g, '')),
          },
        ];

  const generateRowId = () => {
    return `_${Math.random().toString(36).substr(2, 9)}`;
  };

  const optionTransaction = {
    DIVR: 'Dividendo Reinvertido',
    CONT: 'Recarga de caja',
    BUY: 'Aporte',
    SELL: 'Rescate',
    WTHD: 'Retiro de dinero',
    DIV: 'Dividendo pagado',
  };
  const row =
    tap === 'Fondos'
      ? dataTransactions
          ?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
          ?.filter(item => item.SubType !== 'LO' && item.SubType !== 'LI')
          ?.map(item => {
            let montoValue;
            if (item.SubType === 'WTHD' || item.SubType === 'SELL') {
              montoValue =
                numberWithPoint(item.Amount) !== '0'
                  ? `${numberWithPoint(-Math.round(Number.parseFloat(item.Amount).toFixed(4)))}`
                  : '-';
            } else {
              montoValue =
                numberWithPoint(item.Amount) !== '0'
                  ? `${numberWithPoint(Math.round(Number.parseFloat(item.Amount).toFixed(4)))}`
                  : '-';
            }
            return {
              id: generateRowId(),
              fecha: item?.CreatedDate,
              movimiento: optionTransaction[item.SubType],
              producto: item.Security_Name,
              estado: item.Status === 4 ? 'Completado' : 'Pendiente',
              cuota: Number.parseFloat(item.Units),
              // numberWithPoint(item.Units) !== '0' ? numberWithPoint(item.Units.toFixed(4)) : '-',
              monto: montoValue,
              // monto:
              //   numberWithPoint(item.Amount) !== "0"
              //     ? `$ ${numberWithPoint(Math.round(parseFloat(item.Amount).toFixed(4)))}`
              //     : '-',
            };
          })
      : dataTransactions
          ?.sort((a, b) => new Date(b.CreatedDate) - new Date(a.CreatedDate))
          ?.filter(item => item.SubType !== 'LO' && item.SubType !== 'LI' && item.Status !== 0)
          ?.map(item => {
            const fileAs = portfolioNameAndDetails(item.Account_FileAs);

            let montoValue;
            if (item.SubType === 'WTHD' || item.SubType === 'SELL') {
              montoValue =
                numberWithPoint(item.Amount) !== '0'
                  ? `${numberWithPoint(-Math.round(Number.parseFloat(item.Amount).toFixed(4)))}`
                  : '-';
            } else {
              montoValue =
                numberWithPoint(item.Amount) !== '0'
                  ? `${numberWithPoint(Math.round(Number.parseFloat(item.Amount).toFixed(4)))}`
                  : '-';
            }
            return {
              id: generateRowId(),
              fecha: item?.CreatedDate,
              movimiento: optionTransaction[item.SubType],
              portfolioName: fileAs?.fileAsComplete.slice(10),
              producto: item.Security_Name,
              estado: item.Status === 4 ? 'Completado' : 'Pendiente',
              cuota: Number.parseFloat(item.Units),
              // numberWithPoint(item.Units) !== '0' ? numberWithPoint(item.Units.toFixed(4)) : '-',
              monto: montoValue,
              // monto:
              //   numberWithPoint(item.Amount) !== "0"
              //     ? `$ ${numberWithPoint(
              //         Math.round(parseFloat(item.Amount).toFixed(4))
              //       )}`
              //     : "-",
            };
          });

  if (loadingGetClientTransactionsPortfolio) {
    return <Spinner />;
  }

  if (errorGetClientTransactionsPortfolio) {
    throw new Error('Error component my transactions');
  }
  return (
    <Box
      sx={{
        height: '400px',
        width: { md: '100%' },
        '& .font-tabular-nums': {
          fontVariantNumeric: 'tabular-nums',
        },
      }}>
      <DataGrid
        rowHeight={50}
        columns={column}
        rows={row}
        sx={{
          '.MuiDataGrid-columnHeaders': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.white.main,
            fontSize: theme.typography.button.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.smallText.fontSize,
            },
          },
          '.MuiDataGrid-toolbarContaine, .css-128fb87-MuiDataGrid-toolbarContainer': {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'start',
          },
          '.MuiDataGrid-toolbarContainer>button': {
            color: theme.palette.textLight.main,
            backgroundColor: theme.palette.white.main,
            '&:hover': {
              backgroundColor: theme.palette.action.hover,
            },
          },

          '.MuiDataGrid-columnSeparator': {
            display: 'none',
          },
          '&.MuiDataGrid-root': {
            borderRadius: '8px',
          },
          '.MuiDataGrid-row': {
            fontSize: theme.typography.button.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.littleText.fontSize,
            },
            textAlign: 'left',
            justifyContent: 'center',
          },

          '& .super-app-theme--cell': {
            width: { md: '100%', xs: '100px' },
            color: theme.palette.primary.main,
            fontWeight: theme.typography.button.fontWeight,
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center',
          },
          '.MuiDataGrid-footerContainer': {
            fontSize: theme.typography.button.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.smallText.fontSize,
            },
            color: '#ddd',
          },
          '.MuiButtonBase-root': {
            color: theme.palette.white.main,
          },

          '.MuiDataGrid-footerContainer  .MuiButtonBase-root ': {
            color: theme.palette.avatar.border,
            width: { xs: '30px' },
            '&.Mui-disabled': {
              color: theme.palette.textLight.main,
            },
          },
        }}
        localeText={{
          toolbarDensity: 'Size',
          toolbarDensityLabel: 'Size',
          toolbarDensityCompact: 'Small',
          toolbarDensityStandard: 'Medium',
          toolbarDensityComfortable: 'Large',
          toolbarExportCSV: 'Exportar a CSV',
          toolbarExport: 'EXPORTAR',
        }}
        slots={{
          toolbar: CustomToolbar,
          noRowsOverlay: NoRowsOverlay,
        }}
        slotProps={{
          pagination: { labelRowsPerPage: 'Filas por página' },
        }}
      />
    </Box>
  );
};
