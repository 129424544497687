import React from 'react';
import WrapperBackgroundBanner from '../../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../../XcalaComponents/WrapperContentApp';
import AvatarRescue from './AvatarRescue';
import PorfolioValueSection from './PorfolioValueSection';

const $minHeigth = '407px';

const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};

const HeaderRescuePortfolio = ({
  marketValue = 2220,
  amountPortfolio,
  portfolioFileAsComplete,
  typeProduct,
}) => {
  return (
    <WrapperBackgroundBanner $minHeigth={$minHeigth}>
      <WrapperContentApp
        $minHeigth={$minHeigth}
        fileAs={fileAs}
        direction={{ md: 'row', xs: 'column' }}
        justifyContent='center'
        padding={'40px 0'}>
        <AvatarRescue
          mountPortfolio={amountPortfolio}
          portfolioFileAsComplete={portfolioFileAsComplete}
        />
        <PorfolioValueSection marketValue={marketValue} typeProduct={typeProduct} />
      </WrapperContentApp>
    </WrapperBackgroundBanner>
  );
};

export default HeaderRescuePortfolio;
