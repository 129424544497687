export function numberWithPoint(number) {
  if (isNaN(number) || number == null) {
    return '';
  }

  let parts = number.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, '.');
  return parts.join(',');
}
export function cleanChainNumber(fmtBuy) {
  const numberClean = fmtBuy.replace(/[^0-9,]/g, '');
  const numberWithPoint = numberClean.replace(',', '.');
  const number = Number.parseFloat(numberWithPoint);
  return number;
}

// funciones fechas
export const getCurrentDateFormatYYYYMMDD = () => {
  const currentDate = new Date();
  const endYear = currentDate.getFullYear();
  const endMonth =
    currentDate.getMonth() > 8 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
  const endDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
  const date = `${endYear}-${endMonth}-${endDay}`;
  return date;
};

export const setMonthsDateFormatYYMMDD = months => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + months));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startYear}-${startMonth}-${startDay}`;
  return date;
};

export const setDaysDateFormatYYMMDD = days => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + days));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startYear}-${startMonth}-${startDay}`;
  return date;
};

export const getCurrentDateFormatMMDDYYYY = () => {
  const currentDate = new Date();
  const endYear = currentDate.getFullYear();
  const endMonth =
    currentDate.getMonth() > 8 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
  const endDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
  const date = `${endMonth}/${endDay}/${endYear}`;
  return date;
};

export const setMonthsDateFormatMMDDYYYY = months => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + months));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startMonth}-${startDay}-${startYear}`;
  return date;
};

export const getFullDate = () => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString('es-CL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedDate;
};

export const formatChileanDate = strDate => {
  const date = new Date(strDate);
  const year = date.getFullYear();
  const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getUTCDate() > 9 ? date.getUTCDate() : `0${date.getUTCDate()}`;
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getFirstElement = data => {
  let text = '';
  if (data && data.length > 0) {
    text = data[0].description;
  }
  return text;
};

export const formatLossGainValue = value => {
  const roundedValue = Math.round(Number.parseFloat(value));
  return roundedValue < 0
    ? `- $ ${numberWithPoint(Math.abs(roundedValue))}`
    : `${numberWithPoint(roundedValue)}`;
};
