import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  countryPhones: [],
  genders: [],
  months: [],
  civilStatus: [],
  professions: [],
  moneyIncomes: [],
  moneyOrigin: [],
  nationalities: [],
  regions: [],
};

export const constantsSlice = createSlice({
  name: 'constants',
  initialState,
  reducers: {
    setcountryPhones: (state, { payload }) => {
      state.countryPhones = payload;
    },
    setGenders: (state, { payload }) => {
      state.genders = payload;
    },
    setMonths: (state, { payload }) => {
      state.months = payload;
    },
    setCivilStatus: (state, { payload }) => {
      state.civilStatus = payload;
    },
    setProfessions: (state, { payload }) => {
      state.professions = payload;
    },
    setMoneyIncomes: (state, { payload }) => {
      state.moneyIncomes = payload;
    },
    setMoneyOrigin: (state, { payload }) => {
      state.moneyOrigin = payload;
    },
    setNationalities: (state, { payload }) => {
      state.nationalities = payload;
    },
    setRegions: (state, { payload }) => {
      state.regions = payload;
    },
  },
});
export const {
  setcountryPhones,
  setGenders,
  setMonths,
  setCivilStatus,
  setProfessions,
  setMoneyIncomes,
  setMoneyOrigin,
  setNationalities,
  setRegions,
} = constantsSlice.actions;

export default constantsSlice.reducer;
