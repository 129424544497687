import axios from 'axios';

export const getSession = async () => {
  const {
    REACT_APP_CHECKID_BASE_URL,
    REACT_APP_CHECKID_USER,
    REACT_APP_CHECKID_COMPANY_ID,
    REACT_APP_CHECKID_PASSWORD,
    REACT_APP_CHECKID_API_KEY,
  } = process.env;

  const body = {
    code: 'USER',
    type: 'START',
    subtype: 'SESSION',
    createdAt: new Date().toISOString().replace('Z', ''),
    client: {
      companyId: REACT_APP_CHECKID_COMPANY_ID,
      username: REACT_APP_CHECKID_USER,
      password: REACT_APP_CHECKID_PASSWORD,
    },
  };

  const response = await axios.post(
    `${REACT_APP_CHECKID_BASE_URL}/api/v1/session`,
    JSON.stringify(body),
    {
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        'X-Auth-Token': REACT_APP_CHECKID_API_KEY,
      },
    },
  );

  return response.data;
};
