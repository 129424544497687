import { InputAdornment, TextField, Typography, useTheme } from '@mui/material';
import { numberWithPoint } from '../../../utils/utils';

const TextInput = ({
  name,
  label,
  helperText,
  error,
  value,
  formik,
  adornment = '$',
  onChange,
  ...props
}) => {
  const theme = useTheme();
  const handleChange = e => {
    const inputValue = e.target.value;
    const validAmount = inputValue.replace(/\D/g, '');
    if (validAmount.length <= 8) {
      const formattedValue = numberWithPoint(validAmount);
      formik.setFieldValue(name, formattedValue);
    }
  };

  return (
    <TextField
      name={name}
      label={
        <Typography
          sx={{
            fontSize: theme.typography.mediumText.fontSize,
            [theme.breakpoints.down('sm')]: {
              fontSize: theme.typography.customButton.fontSize,
            },
          }}>
          {label}
        </Typography>
      }
      variant='standard'
      fullWidth
      helperText={helperText}
      InputProps={{
        startAdornment: adornment ? (
          <InputAdornment position='start'>{adornment}</InputAdornment>
        ) : null,
      }}
      error={Boolean(error)}
      value={value}
      onBlur={formik?.handleBlur}
      onChange={handleChange}
      {...props}
    />
  );
};

export default TextInput;
