import { gql, useLazyQuery } from '@apollo/client';

export const SIGN_FETCH_TRANSACTIONS_QUERY = gql`
  query SignFetchTransactions($data: userFecthTransSignDoc!) {
    SignFetchTransactions(data: $data) {
      id
      createdAt
      updatedAt
      refId
      state
    }
  }
`;

export default () => useLazyQuery(SIGN_FETCH_TRANSACTIONS_QUERY);
