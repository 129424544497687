import { Stack, styled } from '@mui/material';
import { ButtonsPrimary, ButtonsSecondary } from '../../../ComponentApp/buttoms';

const BtnContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '50%',
  minWidth: '200px',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  height: '50px',
});

export const BtnGroup = ({ goToSimulator, goToInvestment }) => {
  return (
    <BtnContent gap={3}>
      <ButtonsSecondary name='Simular' onClick={goToSimulator} fullWidth />
      <ButtonsPrimary name='Invertir' onClick={goToInvestment} fullWidth />
    </BtnContent>
  );
};
