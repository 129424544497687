import { gql, useMutation } from '@apollo/client';

export const REGISTER_USER_QUESTION_WITH_ANSWER_MUTATION = gql`
    mutation registerUserQuestionsWithAnswer($data: [questionAnswer]!){
        registerUserQuestionsWithAnswer(data: $data)
    }
`;
{
  /*Registra las respuestas de un usuario cuando define su perfil de riesgo*/
}
export default () => useMutation(REGISTER_USER_QUESTION_WITH_ANSWER_MUTATION);
