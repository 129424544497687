import { Typography, styled } from '@mui/material';

export const ExpectedTime = styled(Typography)(({ theme }) => ({
  textAlign: 'end',
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.littleText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
}));
