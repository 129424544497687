import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import { NotificationsXcala } from '../../../components/XcalaComponents/Notifications/NotificationsXcala';
import BodyDetailsPorfolio from '../../../components/portfolio/detailsPortfolio/BodyDetailsPorfolio';
import HeaderDetailsPorfolio from '../../../components/portfolio/detailsPortfolio/HeaderDetailsPorfolio';
import useModal from '../../../hooks/custom/useModal';
import { useGetModelsPortfolioById } from '../../../hooks/useGetModelsPortfolioById';
import useGetSecurityList from '../../../hooks/useGetSecurityList';
import { modelsPortfolioChartById } from '../../../utils/modelsPortfolioChart';
import { portfolioNameAndDetails } from '../../../utils/portfolioNameAndDetails';
import { onRefresh } from '../../../utils/refreshPage';
import { getRiskWarningPortfolio } from '../../../utils/getRiskWarning';

const DetailsPortfolio = () => {
  const { isShowing, toggle } = useModal();
  const portfolioSelectedById = useSelector(state => state.portfolio.portfolioSelected);
  const user = useSelector(state => state.auth.user);
  const userRiskProfile = user['custom:profile'];

  const {
    data: dataPortolioById,
    loading: loadingDataPortolioById,
    error: errorDataPortolioById,
  } = useGetModelsPortfolioById({
    modelId: portfolioSelectedById,
  });

  const portfolioRiskProfile = dataPortolioById?.getModelById[0]?.code;

  const shouldShowWarning = getRiskWarningPortfolio(userRiskProfile, portfolioRiskProfile);

  const {
    data: dataSecurityList,
    error: errorGetSecurityList,
    loading: loadingGetSecurityList,
  } = useGetSecurityList();
  const successGetSecurityList = dataSecurityList?.getSecurityList;

  if (loadingDataPortolioById || loadingGetSecurityList) {
    return <Spinner />;
  }

  if (errorDataPortolioById || errorGetSecurityList) {
    return (
      <NotificationsXcala
        activation={!isShowing}
        message='Lo sentimos, ha ocurrido un error inesperado....'
        nameBtnTwo='Enviar error'
        toggle={toggle}
        refresh={onRefresh}
        error={errorDataPortolioById || errorGetSecurityList}
      />
    );
  }

  const detailsModelsPorfolio = dataPortolioById?.getModelById;

  const dataDetailsPortfolioId = modelsPortfolioChartById(
    detailsModelsPorfolio,
    successGetSecurityList,
  );

  const portfolioDetailsFileAs = portfolioNameAndDetails(dataDetailsPortfolioId[0].fundName);

  const fileAs = {
    currency: portfolioDetailsFileAs.currency,
    fileAs: portfolioDetailsFileAs.fileAs,
    fileAsComplete: portfolioDetailsFileAs.fileAsComplete,
    risk: detailsModelsPorfolio[0].code,
    profit: detailsModelsPorfolio[0].legacyKey,
  };
  return (
    <>
      <HeaderDetailsPorfolio
        detailsModelsPorfolio={detailsModelsPorfolio}
        dataDetailsPortfolioId={dataDetailsPortfolioId}
        portfolioDetailsFileAs={portfolioDetailsFileAs}
        fileAs={fileAs}
        simulation='Simular'
        investmentMore='Invertir'
      />
      <BodyDetailsPorfolio
        dataDetailsPortfolioId={dataDetailsPortfolioId}
        portfolioDetailsFileAs={portfolioDetailsFileAs}
        shouldShowWarning={shouldShowWarning}
      />
    </>
  );
};

export default DetailsPortfolio;
