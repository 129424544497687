import { gql, useLazyQuery } from '@apollo/client';

export const SIGN_CREATE_TRANSACTIONS_QUERY = gql`
  query SignCreateTransactions($data: userDataTransSignDoc!) {
    SignCreateTransactions(data: $data) {
      id
      createdAt
      updatedAt
      refId
      state
      redirectUrl
    }
  }
`;
{
  /*Se utiliza en el onboarding con checkId*/
}
export default () => useLazyQuery(SIGN_CREATE_TRANSACTIONS_QUERY);
