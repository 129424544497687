import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  securitySelected: null,
  simulationSelected: null,
  investmentData: null,
  profitability: null,
};

export const simulationSlice = createSlice({
  name: 'simulation',
  initialState,
  reducers: {
    setSecuritySelected: (state, { payload }) => {
      state.securitySelected = payload;
    },
    setSimulationSelected: (state, { payload }) => {
      state.simulationSelected = payload;
    },
    setInvestmentData: (state, { payload }) => {
      state.investmentData = payload;
    },
    setProfitability: (state, { payload }) => {
      state.profitability = payload;
    },
    resetSimulation: state => {
      state.securitySelected = null;
      state.simulationSelected = null;
      state.investmentData = null;
      state.profitability = null;
    },
  },
});

export const {
  setSecuritySelected,
  setSimulationSelected,
  setInvestmentData,
  setProfitability,
  resetSimulation,
} = simulationSlice.actions;
export default simulationSlice.reducer;
