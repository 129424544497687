import { gql, useMutation } from '@apollo/client';

const CREATE_CONTRIBUTION = gql`
mutation createInitialContribution( $data : contributionTransaction!){
    createInitialContribution (data : $data){
        iv
        data
    }
}`;
{
  /*Crea una transacción en finfolio estado none cuando se utiliza la pasarela de pago (“created before pasarela”)*/
}
export const useCreateInitialContribution = () => {
  return useMutation(CREATE_CONTRIBUTION);
};
