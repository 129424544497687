import { Stack, styled } from '@mui/material';

export const WalletSection = styled(Stack)(({ theme }) => ({
  width: { md: '100%', sm: '100%', lg: '100%' },
  height: '100%',
  borderRadius: '8px',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  overflow: 'hidden',
}));
