import { Stack, styled } from '@mui/material';

export const CardProductsComponent = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '16px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  width: '100%',
  height: '100%',
  transitionDelay: '-2000',
  transitionDuration: '3000',
  boxShadow: '0px 0px 12px rgba(80, 80, 80, 0.15)',
  '&:hover': {
    boxShadow: '0px 0px 20px rgba(80, 80, 80, 0.25)',
  },
}));
