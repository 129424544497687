import { Typography, useTheme } from '@mui/material';
import { ContainerForm, TituloContent } from './SimulationMuiComponents';

export const ContributionsSectionTitle = ({ textTitle }) => {
  const theme = useTheme();
  return (
    <ContainerForm>
      <TituloContent>
        <Typography
          sx={{
            color: theme.palette.primary.darkBlue,
            fontStyle: 'normal',
            fontFamily: 'Museo',
            fontWeight: '300',
            fontSize: 'clamp(1.375rem, 0.3333rem + 4.4444vw, 2rem)',
          }}>
          {textTitle}
        </Typography>
      </TituloContent>
    </ContainerForm>
  );
};
