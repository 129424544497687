import {
  CheckRounded,
  ClearRounded,
  HorizontalRuleRounded,
  InfoRounded,
  KeyRounded,
  Visibility,
  VisibilityOff,
} from '@mui/icons-material';
import {
  Box,
  Button,
  IconButton,
  Input,
  InputAdornment,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import { message } from 'antd';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import TagManager from 'react-gtm-module';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import SignInSignUpLayout from '../../components/Layout/SignInSignUpLayout/SignInSignUpLayout';
import useModal from '../../hooks/custom/useModal';
import { resetSignUp, signUp, updatePasswordTemp } from '../../redux/auth';

const Password = () => {
  const { isShowing, toggle } = useModal();
  const api = process.env.REACT_APP_URL_BACKEND_BACKOFFICE_REFERALS;
  const apiKey = process.env.REACT_APP_BACKOFFICE_API_KEY;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  /*   const { referrerCodePassword } = useParams(); */
  const { userTemp, successSignUp, loadingSignUp, errorSignUp } = useSelector(
    (state) => state.auth
  );
  const [showPassword, setShowPassword] = useState(false);
  const [showconfirmPassword, setShowconfirmPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const [containsUL, setContainsUL] = useState(false);
  const [containsLL, setContainsLL] = useState(false);
  const [containsN, setContainsN] = useState(false);
  const [contains8C, setContains8C] = useState(false);
  const [clickButton, setClickButton] = useState(0);
  const theme = useTheme();
  /*   const createRef = async () => {
    const emailDecoded = window.atob(referrerCodePassword || "");
    try {
      await axios.post(
        `${api}/registerReferral`,
        {
          referredId: userTemp.dni,
          referredEmail: userTemp.email,
          referrerEmail: emailDecoded,
        },
        {
          headers: {
            Authorization: apiKey,
          },
        }
      );
    } catch (err) {
      toast.error("Error Servicio de Referidos");
    }
  }; */

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (errorSignUp && !loadingSignUp) {
      message.error(errorSignUp);
      dispatch(resetSignUp());
    } else if (errorSignUp === 'Un usuario con este correo ya existe.') {
      toast.error('Usuario no existe.');
    }
  }, [loadingSignUp, errorSignUp]);

  const handleLogin = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setError('Password do not match');
      setInterval(() => setError(''), 3000);
    } else {
      if (
        (location.pathname === '/signupcreatepassword' && clickButton === 0) ||
        /^.*\/signupcreatepassword\/[\w=]+$/.test(location.pathname)
      ) {
        dispatch(
          signUp({
            name: userTemp?.name,
            password: password,
            email: userTemp?.email.toLowerCase(),
            dni: userTemp?.dni,
            prefix: userTemp?.prefix.value,
            phone: userTemp?.phone.replace(/\D/g, ''),
            lastname: userTemp.lastname,
            motherslastname: userTemp?.motherslastname,
          })
        );
      }
      if (location.pathname === '/forgotchangepassword') {
        dispatch(updatePasswordTemp(password));
        navigate('/forgotpasswordconfirm');
      }
    }
    setClickButton(clickButton + 1);
  };

  const handleConfirmPassword = (event) => {
    setConfirmPassword(event.target.value);
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowconfirmPassword = (event) => {
    setShowconfirmPassword(!showconfirmPassword);
  };

  const mustContainData = [
    ['Letras y números', containsN],
    ['Al menos una letra mayúscula', containsUL],
    ['Mínimo 8 caracteres (Máx. 16)', contains8C],
  ];

  const validatePassword = (event) => {
    let passwordOne = event.target.value;
    setPassword(passwordOne);

    let temp_all_valid = true;
    if (passwordOne.toLowerCase() !== passwordOne) {
      setContainsUL(true);
    } else {
      setContainsUL(false);
      temp_all_valid = false;
    }
    if (passwordOne.toUpperCase() !== passwordOne) {
      setContainsLL(true);
      setContainsN((vall) => vall);
    } else {
      setContainsLL(false);
      setContainsN((vall) => vall);
      temp_all_valid = false;
    }

    if (/\d/.test(passwordOne)) {
      setContainsN(true);
      setContainsLL((vall) => vall);
    } else {
      setContainsN(false);
      setContainsLL((vall) => vall);
      temp_all_valid = false;
    }

    if (passwordOne.length >= 8 && passwordOne.length <= 16) {
      setContains8C(true);
    } else {
      setContains8C(false);
      temp_all_valid = false;
    }

    if (temp_all_valid) {
    }
  };

  const disabled =
    !containsUL ||
    // !containsLL ||
    !containsN ||
    !contains8C ||
    password !== confirmPassword;

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (successSignUp && !loadingSignUp) {
      dispatch(resetSignUp());
      /*   if (referrerCodePassword) {
        createRef();
      } */
      navigate('/signupconfirm');
    }
  }, [successSignUp, loadingSignUp]);

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'create password',
        path: '/signUp',
        link: {
          url: '/signUp',
          title: 'create password',
        },
      },
    });
  }, []);

  return (
    <Stack
      sx={{
        boxSizing: 'border-box',
        width: '100%',
        minHeight: '100vh',
        position: 'relative',
        overflow: 'hidden',
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: 'border-box',
            //width: '80%',
            borderRadius: '24px',
            padding: '32px',
            position: 'relative',
            boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.15)',
            minHeight: '300px',
            zIndex: 4,
            justifyContent: 'center',
            alignItems: 'center',
          }}
          width={{ xs: '90%', sm: '80%', md: '80%' }}
          marginTop="clamp(8.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={{ xs: '90%', md: '400px' }}
          minWidth={{ xs: '40%' }}
        >
          <Stack
            textAlign={'center'}
            gap={1}
            sx={{
              width: '100%',
              background: '#96bde41a',
              padding: '20px',
              borderRadius: '8px',
              marginBottom: '16px',
              color: theme.palette.primary.darkBlue,
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-start',
                flexDirection: 'column',
                width: '100%',
                gap: 1,
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '90%',
                }}
              >
                <InfoRounded
                  sx={{
                    color: theme.palette.primary.main,
                    mr: 1,
                    width: '20px',
                    height: '20px',
                  }}
                />
                <Typography
                  component="p"
                  sx={{ fontWeight: theme.typography.titleText.fontWeight }}
                >
                  Asegúrate de que tu contraseña tenga:
                </Typography>
              </Box>

              {mustContainData.map((item, key) => {
                if (item[1] === false || item[2] === false) {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '90%',
                      }}
                    >
                      {password.length === 0 ? (
                        <HorizontalRuleRounded
                          sx={{
                            color: 'action.active',
                            mr: 1,
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      ) : (
                        <ClearRounded
                          sx={{
                            color: theme.palette.red.main,
                            mr: 1,
                            width: '20px',
                            height: '20px',
                          }}
                        />
                      )}

                      <Typography
                        sx={{
                          fontWeight: theme.typography.smallText.fontWeight,
                          fontSize: theme.typography.button.fontSize,
                        }}
                      >
                        {item[0]}
                      </Typography>
                    </Box>
                  );
                  // biome-ignore lint/style/noUselessElse: <explanation>
                } else {
                  return (
                    <Box
                      sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        width: '90%',
                      }}
                    >
                      <CheckRounded
                        sx={{
                          color: theme.palette.secondary.main,
                          mr: 1,
                          width: '20px',
                          height: '20px',
                        }}
                      />

                      <Typography className="p-14" variant="" component="">
                        {item[0]}
                      </Typography>
                    </Box>
                  );
                }
              })}

              {password !== confirmPassword && (
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                    width: '90%',
                  }}
                >
                  <ClearRounded
                    sx={{
                      color: theme.palette.red.main,
                      mr: 1,
                      width: '20px',
                      height: '20px',
                    }}
                  />

                  <Typography
                    sx={{
                      fontWeight: theme.typography.titleText.fontWeight,
                      fontSize: theme.typography.button.fontSize,
                    }}
                  >
                    Las contraseñas no coinciden
                  </Typography>
                </Box>
              )}
            </Box>
          </Stack>

          <Stack
            sx={{
              width: '100%',
              background: theme.palette.white.main,
              justifyContent: 'center',
              alignItems: 'center',
              padding: '16px 0px',
              gap: 5,
              //padding: '8px'
            }}
            minWidth={'80%'}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '90%',
              }}
            >
              <KeyRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <Input
                fullWidth
                id="standard-basic"
                variant="standard"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => validatePassword(e)}
                placeholder="Crear contraseña"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
            <Box
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
                width: '90%',
              }}
            >
              <KeyRounded sx={{ color: 'action.active', mr: 1, my: 0.5 }} />
              <Input
                fullWidth
                id="standard-basic"
                variant="standard"
                type={showconfirmPassword ? 'text' : 'password'}
                value={confirmPassword}
                onChange={handleConfirmPassword}
                placeholder="Confirmar contraseña"
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowconfirmPassword}
                    >
                      {showconfirmPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </Box>
          </Stack>
          <Stack
            direction={{ xs: 'column-reverse', md: 'row' }}
            width={'100%'}
            gap={3}
            marginTop={4}
          >
            <Button
              variant="outlined"
              disableElevation
              sx={{
                padding: '12px 32px',
                textTransform: 'none',
              }}
              fullWidth
              onClick={() => navigate(-1)}
            >
              Volver
            </Button>
            <Button
              variant="contained"
              disableElevation
              sx={{
                padding: '12px 32px',
                textTransform: 'none',
              }}
              fullWidth
              onClick={handleLogin}
              disabled={disabled || clickButton === 1}
            >
              Crear Contraseña
            </Button>
          </Stack>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default Password;
