import { Link, Stack, Typography, styled } from '@mui/material';
import React from 'react';

const ContentCardsItem = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px 16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  flex: '1',
  margin: '0px',
  height: '88px',
  width: '100%',
});
const ItemCardsDescription = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '24px',
  width: '100%',
});

const IconsItem = styled('img')({
  height: 'auto',
  width: '24px',
  margin: '0px',
});
const TitelItem = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.dark,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  flex: '1',
  margin: '0px 0px 0px 10px',
}));

const CardsItemDescription = ({ customData }) => {
  const customDataDocuments = JSON.parse(customData.Custom);
  const documents = customDataDocuments.documents;

  return (
    <ContentCardsItem>
      <Link
        textTransform={'none'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        href={documents.Folleto_Regulatorio}
        target='_blank'>
        <ItemCardsDescription>
          <IconsItem src='/assets/images/perfiles/pdf.svg' alt='icon' />
          <TitelItem>Folleto informativo</TitelItem>
        </ItemCardsDescription>
      </Link>
      <Link
        textTransform={'none'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        href={documents.Fact_Sheet}
        target='_blank'>
        <ItemCardsDescription>
          <IconsItem src='/assets/images/perfiles/pdf.svg' alt='icon' />
          <TitelItem>Brochure</TitelItem>
        </ItemCardsDescription>
      </Link>
      <Link
        textTransform={'none'}
        sx={{
          cursor: 'pointer',
          textDecoration: 'none',
        }}
        href={documents.Reglamento}
        target='_blank'>
        <ItemCardsDescription>
          <IconsItem src='/assets/images/perfiles/pdf.svg' alt='icon' />
          <TitelItem>Reglamento interno</TitelItem>
        </ItemCardsDescription>
      </Link>
    </ContentCardsItem>
  );
};

export default CardsItemDescription;
