import React from 'react';
import Spinner from '../Spinner';
import { AmountText, CardBody, CardBodyText, Icons, Title } from './SectionInteractionComponent';

export const MiInvestmentCardsDetails = ({ errorProfit, loadingProfit, icon, title, amount }) => {
  if (loadingProfit) {
    return <Spinner />;
  }
  if (errorProfit) {
    throw new Error('Error component my investment detail');
  }
  return (
    <CardBody gap={0.1} padding={1}>
      <Icons alt='Icons Monety' src={icon} />
      <CardBodyText>
        <Title>{title}</Title>
        <AmountText>${amount}</AmountText>
      </CardBodyText>
    </CardBody>
  );
};
