import React from 'react';
import { useDispatch } from 'react-redux';
import { signOut } from '../../redux/auth';

const Logout = () => {
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(signOut());
  }, []);

  return null;
};

export default Logout;
