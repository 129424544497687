import { Box, Stack, Typography, useTheme } from '@mui/material';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import { HeadTitle } from '../myTransactions/MyNewTransactionsMuiComponents';

const $minHeigth = '407px';

const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};

const HeaderChangePassword = ({ userRisk, user }) => {
  const theme = useTheme();
  const {
    name: nameUser,
    email: emailUser,
    'custom:dni': userDni,
    'custom:paternal-surname': lastNameUser,
  } = user;
  return (
    // <>
    //   <WrapperBackgroundBanner $minHeigth= {$minHeigth}>
    //     <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}
    //     direction={{ md: "row", xs: "column" }}
    //     justifyContent="center"
    //     padding={"40px 0"}>

    //     <Stack marginTop={"172px"}>
    //       <Stack
    //         direction={{ md: "row", xs: "column" }}
    //         justifyContent={"center"}
    //         alignItems={{ md: "center", xs: "center" }}
    //         sx={{ width: "100%" }}
    //         gap={1}
    //       >
    //         <AvatarSection userRisk={userRisk} />
    //         <Box>
    //           <HeadTitle
    //             textAlign={{ md: "left", xs: "center" }}
    //             fontSize={"26px"}
    //           >
    //             {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
    //               lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
    //             } `}
    //           </HeadTitle>
    //           <Typography
    //             color={"#fff"}
    //             fontWeight={"100"}
    //             textAlign={{ md: "left", xs: "center" }}
    //             fontSize={"18px"}
    //           >
    //             {emailUser}
    //           </Typography>
    //           <Typography
    //             color={"#fff"}
    //             fontWeight={"100"}
    //             textAlign={{ md: "left", xs: "center" }}
    //             fontSize={"18px"}
    //           >
    //             {hideNumbers(userDni)}
    //           </Typography>
    //         </Box>
    //       </Stack>
    //     </Stack>
    //   </WrapperContentApp>
    //   </WrapperBackgroundBanner>
    // </>

    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <Stack width={'100%'} direction={'column'}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent={'center'}
              alignItems={{ md: 'center', xs: 'center' }}
              width={{ md: '100%', xs: '100%' }}
              gap={1}>
              <AvatarSection userRisk={userRisk} />
              <Box>
                <HeadTitle textAlign={{ md: 'left', xs: 'center' }}>
                  {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                    lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                  } `}
                </HeadTitle>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {emailUser}
                </Typography>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {hideNumbers(userDni)}
                </Typography>
              </Box>
            </Stack>
          </Stack>
        </WrapperContentApp>
      </WrapperBackgroundBanner>
    </>
  );
};

export default HeaderChangePassword;
