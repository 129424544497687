import { FormControl, InputLabel, MenuItem, Select, TextField, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';

export const InputSelectBank = ({
  nameFiled,
  selet,
  funtion,
  isEdit,
  dataBanks,
  accountNumberError,
}) => {
  const theme = useTheme();
  const [menuItems, setMenuItems] = useState([]);
  const [defaulSelect, setDefaulSelect] = useState('');

  useEffect(() => {
    try {
      if (nameFiled === 'Banco') setMenuItems(dataBanks);
      if (nameFiled === 'Tipo de cuenta') setMenuItems(dataBanks);
    } finally {
      const aux = menuItems?.find(item => item.id === selet.id);
      setDefaulSelect(aux);
    }
  }, [selet, menuItems, nameFiled]);

  return (
    <FormControl
      variant='filled'
      sx={{
        boxSizing: 'border-box',
        bgcolor: theme.palette.white.main,
        'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
          {
            background: theme.palette.white.main,
          },
      }}>
      {nameFiled === 'Banco' ? (
        <>
          <InputLabel id='Banco' sx={{ fontSize: theme.typography.description.fontSize }}>
            {nameFiled}
          </InputLabel>
          <Select
            disabled={!isEdit}
            labelId='Banco'
            name='bankSelected'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}
            sx={{ fontSize: theme.typography.description.fontSize }}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'Tipo de cuenta' ? (
        <>
          <InputLabel id='Tipo de cuenta' sx={{ fontSize: theme.typography.description.fontSize }}>
            {nameFiled}
          </InputLabel>
          <Select
            disabled={!isEdit}
            labelId='Tipo de cuenta'
            name='accountTypeSelected'
            value={menuItems.length > 0 && defaulSelect ? defaulSelect : ''}
            onChange={e => funtion(e)}
            sx={{ fontSize: theme.typography.description.fontSize }}>
            {menuItems?.map((item, index) => (
              <MenuItem key={index} value={item}>
                {item.name}
              </MenuItem>
            ))}
          </Select>
        </>
      ) : nameFiled === 'No. de cuenta' ? (
        <TextField
          helperText={accountNumberError ? 'Ingresa tu número de cuenta' : ''}
          error={accountNumberError}
          disabled={!isEdit}
          label={nameFiled}
          variant='filled'
          name='number'
          value={selet}
          onChange={e => funtion(e)}
          sx={{
            fontSize: theme.typography.description.fontSize,
            boxSizing: 'border-box',
            bgcolor: theme.palette.action.hover,
            'label': {
              fontSize: theme.typography.description.fontSize, // Cambia el tamaño de la etiqueta
            },
            input: {
              fontSize: theme.typography.description.fontSize, // Cambia el tamaño de la entrada
            },
            'label[data-shrink=false]+.MuiInputBase-formControl .css-10botns-MuiInputBase-input-MuiFilledInput-input':
              {
                background: theme.palette.white.main,
              },
          }}
        />
      ) : null}
    </FormControl>
  );
};
