import { gql, useQuery } from '@apollo/client';

const GET_AVATARS_QUERY = gql`
  query GetAvatars {
    getAvatars {
      id
      image
    }
  }
`;

export default () => useQuery(GET_AVATARS_QUERY);
