import { Chip, styled, useTheme } from '@mui/material';

const ContentLabel = styled(Chip)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  padding: '2.421052932739258px 4.263158798217773px',
  height: '23px',
  fontWeight: theme.typography.button.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  lineHeight: '12px',
  '&:hover': {
    backgroundColor: theme.palette.tertiary.main,
    color: theme.palette.secondary.main,
  },
}));

const AvatarLabel = styled('img')({
  height: '16px',
  width: '16px',
  objectFit: 'contain',
});

export const InterestLabel = ({ intereses, interestsId }) => {
  const theme = useTheme();
  return (
    <>
      <ContentLabel
        label={intereses}
        avatar={
          <AvatarLabel
            src={`/assets/images/iconProducts/${interestsId}.svg`}
            sx={{ width: '16px', height: '16px', fontSize: theme.typography.microText.fontSize }}
            style={{ width: '16px', height: '16px' }}
          />
        }
      />
    </>
  );
};
