import { gql, useMutation } from '@apollo/client';

export const REGISTER_USER_SELECTED_PREFERENCES_MUTATION = gql`
	mutation registerUserSelectedPreferences ($data: [userSelectedPreference]! ){
		registerUserSelectedPreferences(data: $data)
	}
`;
{
  /*Registra los cambios en los intereses, motivaciones u objetivos de un usuario*/
}
export default () => useMutation(REGISTER_USER_SELECTED_PREFERENCES_MUTATION);
