import { gql, useQuery } from '@apollo/client';

const GET_CLIENT_TRANSACTION_DETAIL_PORTFOLIO = gql`
  query GetClientTransactionDetailPortfolio($accountId: String!) {
    getClientTransactionDetailPortfolio(accountId: $accountId) {
      Account_FileAs
      Amount
      Units
      Status
      SubType
      ActivityType
      CreatedDate
      Security_Name
    }
  }
`;

export const useGetTransactionsPortfolioInvestmentDetails = variables => {
  return useQuery(
    GET_CLIENT_TRANSACTION_DETAIL_PORTFOLIO,
    { variables },
    { fetchPolicy: 'no-cache' },
  );
};
