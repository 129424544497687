import { gql, useQuery } from '@apollo/client';

const GET_EXCHANGE_RATE = gql`
  query GetExchangeRate {
    getExchangeRate {
      ID
      Rate
      EffectiveDate
    }
  }
`;

export const useGetExchangeRate = () => {
  return useQuery(GET_EXCHANGE_RATE, { fetchPolicy: 'no-cache' });
};
