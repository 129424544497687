import { ApolloProvider } from '@apollo/client';
import Amplify from 'aws-amplify';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { PersistGate } from 'reduxjs-toolkit-persist/integration/react';
import 'antd/dist/antd.css';
import Schema from 'async-validator';
import './index.css';
import './App.css';
import { client } from './apolloConfig';
import { persistor, store } from './redux';
import reportWebVitals from './reportWebVitals';
import AppLayout from './router/layout';
import './Global/global.scss';
import './i18nextConf';
import { CssBaseline, ThemeProvider } from '@mui/material';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { Toaster } from 'react-hot-toast';
import { BrowserRouter } from 'react-router-dom';
import config from './aws-exports';
import FallbackCustom from './components/UI/FallbackCustom';
import { responsiveTheme } from './theme/theme';

Amplify.configure(config);
Schema.warning = () => {};

Sentry.init({
  enabled: process.env.NODE_ENV !== 'development',
  dsn: process.env.REACT_APP_SENTRY,
  integrations: [new Sentry.BrowserTracing()],
  tracesSampleRate: 1.0,
});
const queryClient = new QueryClient();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ApolloProvider client={client}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ThemeProvider theme={responsiveTheme}>
            <CssBaseline />
            <BrowserRouter>
              {/*  <ErrorBoundary FallbackComponent={FallbackCustom}> */}
              <AppLayout />
              <Toaster />
              {/* </ErrorBoundary> */}
            </BrowserRouter>
          </ThemeProvider>
        </PersistGate>
      </Provider>
    </ApolloProvider>
    <ReactQueryDevtools initialIsOpen={false} position='bottom-right' />
  </QueryClientProvider>,
  document.getElementById('root'),
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
