import { Typography, useTheme } from '@mui/material';
import { numberWithPoint } from '../../../utils/utils';
import { TitleHeadResult } from './SimulationMuiComponents';

export const TitleResultSimulator = ({ fullInvesment, formData }) => {
  const theme = useTheme();
  return (
    <TitleHeadResult>
      <Typography
        sx={{
          color: theme.palette.primary.dark,
          fontStyle: 'ligth',
          fontFamily: 'Poppins',
          fontWeight: theme.typography.smallText.fontWeight,
          fontSize: 'clamp(0.475rem, 0.3333rem + 4.4444vw, 1.1rem)',
          textTransform: 'uppercase',
        }}>
        Resultado de tu simulación
      </Typography>
      <Typography
        sx={{
          color: theme.palette.primary.main,
          fontStyle: 'normal',
          fontFamily: 'Poppins',
          fontWeight: theme.typography.titleText.fontWeight,
          fontSize: 'clamp(0.475rem, 0.3333rem + 4.4444vw, 1.1rem)',
        }}>
        Si inviertes{' '}
        <Typography variant='span' sx={{ fontWeight: 'bold' }}>
          {`${numberWithPoint(fullInvesment)} `}
        </Typography>
        en{' '}
        <Typography variant='span' sx={{ fontWeight: 'bold' }}>
          {`${formData?.months}`}
        </Typography>
      </Typography>
    </TitleHeadResult>
  );
};
