import { Button, Stack, Typography, useTheme } from "@mui/material";
import React from "react";
import { useNavigate } from "react-router-dom";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";

const ChangeForgetPasswordSuccess = () => {
  const theme = useTheme();
  let navigate = useNavigate();

  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: "border-box",
            //width: '80%',
            borderRadius: "24px",
            padding: "32px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          marginTop="clamp(8.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={{ xs: "90%", md: "400px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack
            textAlign={"center"}
            width={"90%"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack width={"100px"} height={"100px"}>
              <img
                src="/assets/images/sucess-gif.gif"
                alt="success"
                style={{ width: "100%", height: "100%", display: "block" }}
              />
            </Stack>
            <Typography
              component="h3"
              sx={{
                fontSize: "1.9rem",
                fontWeight: theme.typography.button.fontWeight,
                color: theme.palette.secondary.green,
              }}
            >
              ¡Contraseña creada exitosamente!
            </Typography>

            <Typography
              textAlign={"left"}
              variant="body1"
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.smallText.fontWeight,
                fontSize: theme.typography.button.fontSize,
              }}
            >
              Ahora pudes ingresar a tu cuenta de Xcala con tu nueva contraseña.
            </Typography>
          </Stack>

          <Button
            variant="contained"
            disableElevation
            sx={{
              padding: "12px 32px",
              textTransform: "none",
            }}
            fullWidth
            onClick={() => navigate("/signin")}
          >
            Ingresar a Xcala
          </Button>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default ChangeForgetPasswordSuccess;
