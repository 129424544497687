import { Stack, Typography, styled } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { numberWithPoint } from '../../../utils/numbers';
import { handleRiskProfile, handleRiskProfilePortfolio } from '../../../utils/riskProfile';
import { InterestLabel } from '../../products/newProducts/InterestLabel';
import { MotivationLabel } from '../../products/newProducts/MotivationLabel';
import {
  ComponentRescateCont,
  ContentAvatarRescue,
  HmcText,
  IconosPerfiles,
  MotivacionesGenerarl,
  RescueAvatarChips,
  RescueAvatarDescript,
  RescueAvatarIcon,
  RescueAvatarPorfilio,
  RescueAvatarTitle,
  RescueIconsPorfolio,
  RescueMunt,
  RescueQuotas,
  RescueQuotasSpan,
} from './RescueComponent';

const StarContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.secondary.text,
  borderRadius: '17px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 7px',
  boxSizing: 'border-box',
  height: '22px',
  width: '36px',
}));
const StarIcons = styled('img')({
  height: '16px',
  width: '16px',
  margin: '0px',
});
const CurrencyInvestment = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2px 0px',
  boxSizing: 'border-box',
});
const CurrencyInvestmentContent = styled(Stack)({
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '2px 4px',
  boxSizing: 'border-box',
  margin: '0px',
  gap: '4px',
});

const IconInvesment = styled('img')({
  height: '18px',
  width: '18px',
  margin: '0px',
});

const InversionesEnPesos = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.tertiary.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.button.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '119.49999332427979%',
  textTransform: 'none',
  margin: '0px 0px 0px 3px',
}));

const HeadChips = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  gap: '8px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  // height: "22.9px",
  width: '90%',
});
const GeneralMotivations = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.tertiary.main,
  borderRadius: '14px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '2.5px 8px',
  boxSizing: 'border-box',
  height: '22px',
  margin: '0px',
  width: '42px',
}));

const AvatarRescue = ({ mountPortfolio, portfolioFileAsComplete }) => {
  const user = useSelector(state => state.auth.user);
  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';
  return (
    <ComponentRescateCont>
      <ContentAvatarRescue flexDirection={{ md: 'row', xs: 'column' }}>
        <RescueAvatarIcon>
          <RescueIconsPorfolio>
            {portfolioFileAsComplete.products === true ? (
              <IconosPerfiles src={handleRiskProfile(portfolioFileAsComplete.risk.toLowerCase())} />
            ) : (
              <>
                <IconosPerfiles src={handleRiskProfilePortfolio(portfolioFileAsComplete.risk)} />

                <RescueAvatarPorfilio src={handleRiskProfile(portfolioFileAsComplete.risk)} />
              </>
            )}
          </RescueIconsPorfolio>
        </RescueAvatarIcon>
        <RescueAvatarDescript alignItems={{ md: 'flex-start', xs: 'center' }} gap={1}>
          <RescueAvatarTitle
            textAlign={{ md: 'left', xs: 'center' }}
            mt={{ md: '0px', xs: '16px' }}>
            {portfolioFileAsComplete.fileAsComplete}
          </RescueAvatarTitle>

          <HeadChips
            sx={{
              justifyContent: {
                md: 'start',
                xs: 'center',
              },
              flexWrap: 'wrap',
            }}>
            <GeneralMotivations>
              <HmcText>HMC</HmcText>
            </GeneralMotivations>

            {portfolioFileAsComplete.risk &&
              portfolioFileAsComplete.risk.toLowerCase() === userRisk && (
                <StarContainer>
                  <StarIcons src='/assets/images/start-icons.svg' alt='start-icons' />
                </StarContainer>
              )}

            {portfolioFileAsComplete.interests && (
              <Stack direction={'row'} gap={1} sx={{ flexWrap: 'wrap', justifyContent: 'center' }}>
                <InterestLabel
                  intereses={portfolioFileAsComplete.interests}
                  interestsId={portfolioFileAsComplete.interestsId}
                />
                <MotivationLabel
                  motivation={portfolioFileAsComplete.motivation}
                  motivationsId={portfolioFileAsComplete.motivationId}
                />
              </Stack>
            )}
          </HeadChips>

          {/* <RescueAvatarChips
            gap={1}
            flexWrap={"wrap"}
            alignItems={{ md: "flex-start", ms: "center" }}
            justifyContent={{ md: "flex-start", xs: "center" }}
          >
            <MotivacionesGenerarl>
              <HmcText>HMC</HmcText>
            </MotivacionesGenerarl>
            {portfolioFileAsComplete.risk &&
              portfolioFileAsComplete.risk.toLowerCase() === userRisk && (
                <StarContainer>
                  <StarIcons
                    src="/assets/images/start-icons.svg"
                    alt="start-icons"
                  />
                </StarContainer>
              )}
          </RescueAvatarChips> */}

          <RescueMunt>
            <RescueQuotas>
              <CurrencyInvestment>
                <CurrencyInvestmentContent>
                  <IconInvesment
                    src={`${
                      portfolioFileAsComplete.currency === 'USD'
                        ? '/assets/images/USD-symbol.svg'
                        : '/assets/images/icons-cpl.svg'
                    }`}
                  />
                  <InversionesEnPesos>{`Inversión en ${
                    portfolioFileAsComplete.currency === 'USD' ? 'USD' : 'Pesos'
                  }`}</InversionesEnPesos>
                </CurrencyInvestmentContent>
              </CurrencyInvestment>
            </RescueQuotas>
          </RescueMunt>
        </RescueAvatarDescript>
      </ContentAvatarRescue>
    </ComponentRescateCont>
  );
};

export default AvatarRescue;
