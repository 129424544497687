import { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import GridViewOutlinedIcon from '@mui/icons-material/GridViewOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
import ReceiptOutlinedIcon from '@mui/icons-material/ReceiptOutlined';
import MenuOutlinedIcon from '@mui/icons-material/MenuOutlined';
import { useTheme } from '@mui/material';

const subMenu = [
  { label: 'Portfolios', url: 'portfolio' },
  { label: 'Fondos', url: 'products' },
];

const myAccountBtn = {
  name: 'Mi cuenta',
  subMenu: [
    { btnNameSub: 'Mi perfil de riesgo', url: 'profileselect' },
    { btnNameSub: 'Mis objetivos e intereses', url: 'myobjectivesinterests' },
    { btnNameSub: 'Mis datos', url: 'myaccount' },
    { btnNameSub: 'Cambiar contraseña', url: 'changepassword' },
  ],
};

export const useMenuHandlerMobile = () => {
  const [value, setValue] = useState('dashboard');
  const [openMovil, setOpenMovil] = useState(false);
  const [open, setOpen] = useState(false);
  const [openReferidos, setOpenReferidos] = useState(false);
  const [copyText, setCopyText] = useState('Copiar mi link para referir');
  const { user } = useSelector(state => state.auth);
  const navigate = useNavigate();
  const [access, setAccess] = useState(false);
  const [accessWallet, setAccessWallet] = useState(false);
  const [activeNavbar, setActiveNavbar] = useState(1);
  const [userNotOB, setUserNotOB] = useState(false);
  const touchStartY = useRef(null);
  const host = process.env.REACT_APP_HOST;
  const encodedEmail = window.btoa(user?.email);

  const theme = useTheme();

  const handleChange = newValue => {
    setValue(newValue);
  };

  const handleOpen = () => {
    setOpen(!open);
  };

  const handleOpenMovil = open => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setOpenMovil(open);
    setValue('menu');
  };

  const handleNavigateDashboard = () => {
    navigate(access ? '/myprofilerisk' : 'profile');
    setValue('dashboard');
  };

  const handleNavigateWallet = () => {
    navigate(accessWallet ? 'wallet' : 'completeregistration');
    setValue('caja');
  };

  const handleNavigateTransactions = val => {
    const path = userNotOB ? '/mytransactions' : 'completeregistration';
    navigate(path);
    setActiveNavbar(val);
    setValue('Transacciones');
  };

  const handleNavigateSubMenu = url => {
    navigate(`${url}`);
    if (url === 'portfolio' || url === 'products') {
      setOpenMovil(false);
    }
  };

  const handleClickHome = () => {
    navigate(access ? '/myprofilerisk' : 'profile');
  };

  const handleNavigateMyAccount = url => {
    navigate(url);
    setOpenMovil(false);
    setOpenReferidos(false);
  };

  const handleTouchStart = e => {
    touchStartY.current = e.touches[0].clientY;
  };

  const handleTouchEnd = e => {
    const touchEndY = e.changedTouches[0].clientY;
    const distance = touchStartY.current - touchEndY;

    if (distance > 50) {
      setOpenMovil(false);
    }
  };

  const contentMenu = [
    {
      label: 'Inicio',
      value: 'dashboard',
      icon: <GridViewOutlinedIcon sx={{ color: theme.palette.primary.main }} />,
      action: handleNavigateDashboard,
    },
    {
      label: 'Caja',
      value: 'caja',
      icon: <AccountBalanceWalletOutlinedIcon sx={{ color: theme.palette.primary.main }} />,
      action: handleNavigateWallet,
    },
    {
      label: 'Transacciones',
      value: 'Transacciones',
      icon: <ReceiptOutlinedIcon sx={{ color: theme.palette.primary.main }} />,
      action: handleNavigateTransactions,
    },
    {
      label: 'Menu',
      value: 'menu',
      icon: <MenuOutlinedIcon sx={{ color: theme.palette.primary.main }} />,
      action: handleOpenMovil(true),
    },
  ];

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
      setUserNotOB(true);
    } else {
      setUserNotOB(false);
    }
  }, [user]);

  useEffect(() => {
    if (user?.['custom:profile']) {
      setAccess(true);
    }
  }, [user]);

  useEffect(() => {
    setOpenMovil(false);
  }, [navigate]);

  useEffect(() => {
    setOpenMovil(false);
    setOpen(false);
    setOpenReferidos(false);
  }, []);

  return {
    subMenu,
    contentMenu,
    myAccountBtn,
    value,
    openMovil,
    open,
    openReferidos,
    copyText,
    user,
    navigate,
    access,
    accessWallet,
    activeNavbar,
    userNotOB,
    touchStartY,
    setOpen,
    setOpenReferidos,
    handleChange,
    handleOpen,
    handleOpenMovil,
    handleNavigateDashboard,
    handleNavigateWallet,
    handleNavigateTransactions,
    handleNavigateSubMenu,
    handleClickHome,
    handleNavigateMyAccount,
    handleTouchStart,
    handleTouchEnd,
  };
};
