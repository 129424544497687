import { gql, useMutation } from '@apollo/client';

const CREATE_PAYMENT_INTENT = gql`
	mutation createPaymentIntent(
        $data:PaymentIntentInput!
    ) {
      createPaymentIntent(data: $data) {
            id
            object
            widget_token
            created_at
            recipient_account {
              holder_id
              number
              type
              institution_id
            }
              sender_account
            mode
            amount
            currency
            status
            reference_id
            transaction_date
      }
    }
`;
{
  /*Crea intento de pago, muestra información completa de la transacción*/
}
export const useCreatePaymentIntent = () => {
  return useMutation(CREATE_PAYMENT_INTENT);
};
