import { gql, useMutation } from '@apollo/client';

const CREATE_RESCUE_TRANSACTION = gql`
  mutation createRescueTransaction($data: rescueInput!) {
    createRescueTransaction(data: $data)
  }
`;
{
  /*Crea una transacción de rescate en finfolio*/
}
export const useCreateRescueTransaction = () => {
  return useMutation(CREATE_RESCUE_TRANSACTION);
};
