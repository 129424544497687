import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import BodyWhithdrawal from '../../../components/wallet/BodyWhithdrawal';
import HeaderWallet from '../../../components/wallet/HeaderWallet';
import { useGetCashBalancePortfolio } from '../../../hooks/useGetCashBalancePortfolio';
import capitalize from '../../../utils/capitalize';

const WalletWithdrawal = () => {
  const { user } = useSelector(state => state.auth);
  const paternalName = user?.['custom:paternal-surname'] || '';
  const maternalName = user?.['custom:maternal-surname'] || '';
  const fullName =
    user && `${capitalize(user.name)} ${capitalize(paternalName)} ${capitalize(maternalName)}`;

  const userRisk = user?.['custom:profile'] ? user['custom:profile'].toLowerCase() : '';

  const {
    data: balanceData,
    loading: loadingBalance,
    error: errorBalance,
  } = useGetCashBalancePortfolio();
  const balanceClient = balanceData?.getCashBalancePortfolio;

  if (loadingBalance) {
    return <Spinner />;
  }
  if (errorBalance) {
    // error fetch modal
  }
  return (
    <>
      <HeaderWallet
        balanceClient={balanceClient}
        fullName={fullName}
        userRisk={userRisk}
        isWallet={false}
      />

      <BodyWhithdrawal balanceClient={balanceClient} />
    </>
  );
};

export default WalletWithdrawal;
