import { Stack, styled } from '@mui/material';

export const FilterSection = styled(Stack)(({ theme }) => ({
  flexWrap: 'wrap',
  backgroundColor: theme.palette.white.main,
  boxShadow: '0px 0px 8px rgba(61, 64, 75, 0.15)',
  borderRadius: '8px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
}));

export const ContentFilter = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '50%',
  flex: '2 0 auto',
});

export const SearchFilter = styled('form')({
  position: 'relative',
  isolation: 'isolate',
  width: '50%',
  justifyContent: 'flex-start',
  padding: '8px',
  boxSizing: 'border-box',
});
