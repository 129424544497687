import React, { useEffect, useState } from 'react';
import { CustomSelectOB } from './CustomSelectOB';

export const CommuneSelectOB = ({ selected, setSelected, items }) => {
  const [menuItems, setMenuItems] = useState(items);
  const [defaultelect, setDefaultelect] = useState('');

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const aux = menuItems.find(item => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setMenuItems(items);
  }, [JSON.stringify(items)]);
  return (
    <CustomSelectOB
      label='Comuna'
      Labelid='Comuna'
      placholder='Comuna'
      Selectid='Comuna'
      id='Comuna'
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};
