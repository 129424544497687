export const IconsCloses = () => {
  return (
    <>
      <svg
        width='15'
        height='15'
        viewBox='0 0 15 15'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M9.12949 7.58197L14.4695 2.24199C14.864 1.84752 14.864 1.20992 14.4695 0.815445C14.075 0.420975 13.4374 0.420975 13.0429 0.815445L7.70293 6.15542L2.36293 0.815445C1.96846 0.420975 1.33084 0.420975 0.936372 0.815445C0.5419 1.20992 0.5419 1.84752 0.936372 2.24199L6.27637 7.58197L0.936372 12.9219C0.5419 13.3164 0.5419 13.954 0.936372 14.3485C1.1331 14.5452 1.39138 14.6441 1.64965 14.6441C1.90792 14.6441 2.1662 14.5452 2.36293 14.3485L7.70293 9.00852L13.0429 14.3485C13.2397 14.5452 13.4979 14.6441 13.7562 14.6441C14.0145 14.6441 14.2728 14.5452 14.4695 14.3485C14.864 13.954 14.864 13.3164 14.4695 12.9219L9.12949 7.58197Z'
          fill='white'
        />
      </svg>
    </>
  );
};
