import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: {},
};

export const onBoardingSlice = createSlice({
  name: 'onboarding',
  initialState,
  reducers: {
    updateAttributes: (state, { payload }) => {
      state.data = {
        ...state.data,
        ...payload,
      };
    },
    resetAttributes: state => {
      state.data = null;
    },
  },
});

export const { updateAttributes, resetAttributes } = onBoardingSlice.actions;
export default onBoardingSlice.reducer;
