import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_BANK_ACCOUNT_TYPES_QUERY = gql`
    query getBankAccountTypes {
        getBankAccountTypes {
            id
            name
        }
    }
`;
{
  /*Trae los tipos de cuentas bancarias y su id*/
}
export default () => useQuery(GET_BANK_ACCOUNT_TYPES_QUERY);
