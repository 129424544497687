export const IconsWarning = () => {
  return (
    <>
      <svg
        width='42'
        height='42'
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect x='1' y='1.20261' width='39.2026' height='39.2026' rx='19.6013' fill='#F7DB52' />
        <rect
          x='1'
          y='1.20261'
          width='39.2026'
          height='39.2026'
          rx='19.6013'
          stroke='#101820'
          strokeWidth='2'
        />
        <path
          d='M22.2512 10C22.5965 10 22.8666 10.2976 22.8332 10.6413L21.5105 24.2431C21.4814 24.5427 21.2296 24.7712 20.9286 24.7712H19.6756C19.3701 24.7712 19.1161 24.5359 19.0927 24.2313L18.0483 10.6294C18.0223 10.2899 18.2907 10 18.6313 10H22.2512ZM20.2967 30.7118C19.8023 30.7118 19.378 30.5345 19.0239 30.1799C18.6698 29.8254 18.4927 29.4006 18.4927 28.9055C18.4927 28.4105 18.6698 27.9857 19.0239 27.6311C19.378 27.2765 19.8023 27.0992 20.2967 27.0992C20.7911 27.0992 21.2153 27.2765 21.5694 27.6311C21.9235 27.9857 22.1006 28.4105 22.1006 28.9055C22.1006 29.2333 22.0171 29.5344 21.85 29.8086C21.6897 30.0829 21.4725 30.3037 21.1986 30.4709C20.9314 30.6315 20.6307 30.7118 20.2967 30.7118Z'
          fill='#101820'
        />
      </svg>
    </>
  );
};
