import { Stack, Typography, styled } from '@mui/material';

const CardsContainer = styled(Stack)(({ theme }) => ({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
}));

const TitleCards = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.darkBlue,
  fontWeight: theme.typography.h4.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px',
}));

const BodyCardsDescription = styled(Typography)(
  ({ theme }) => (
    {
      color: theme.palette.primary.darkBlue,
      fontWeight: theme.typography.littleText.fontWeight,
      fontSize: theme.typography.smallText.fontSize,
      letterSpacing: '0px',
      textDecoration: 'none',
      textTransform: 'none',
      alignSelf: 'stretch',
      width: '100%',
    },
    `component="p"`
  ),
);

export const CardsDescription = ({ funDescription }) => {
  return (
    <>
      <CardsContainer>
        <TitleCards>Descripción</TitleCards>
        <BodyCardsDescription>{funDescription}</BodyCardsDescription>
      </CardsContainer>
    </>
  );
};
