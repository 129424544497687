import { gql, useLazyQuery, useQuery } from '@apollo/client';

const GET_USER_BANK_QUERY = gql`
    query getUserBankDetail {
        getUserBankDetail {
            bank {
                bank_id
                bank_name
            },
            account_number
            bankType {
                bank_type_id
                bank_type_name
            }
        }
    }
`;
{
  /*Retorna un objeto que contiene el número de cuenta, id del tipo de cuenta y el id del banco del usuario*/
}
export default () => useQuery(GET_USER_BANK_QUERY, { fetchPolicy: 'no-cache' });
