import { gql, useMutation } from '@apollo/client';

const UPDATE_CONTRIBUTION = gql`
mutation updateContribution( $data : contributionInput!){
    updateContribution (data : $data)
}`;
{
  /*Cambia el estado de la contribución creada, de none a Done, contribución exitosa*/
}
export const useUpdateContribution = () => {
  return useMutation(UPDATE_CONTRIBUTION);
};
