const capitalize = text => {
  if (!text || text.length === 0) {
    return '';
  }
  if (text.length === 1) {
    return text.toUpperCase();
  }
  if (text.length > 1) {
    return text.charAt(0).toUpperCase() + text.slice(1);
  }
};

export default capitalize;
