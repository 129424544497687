import { Stack, Typography, styled } from '@mui/material';

export const ContentCheckBox = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

export const DeclarationSpan = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.secondary.main,
  fontWeight: theme.typography.h1.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

export const TermsAndConditions = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '22px 0px 0px 0px',
  width: '100%',
});
export const TextDeclaration = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));
