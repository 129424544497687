export const getCurrentDateFormatYYYYMMDD = () => {
  const currentDate = new Date();
  const endYear = currentDate.getFullYear();
  const endMonth =
    currentDate.getMonth() > 8 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
  const endDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
  const date = `${endYear}-${endMonth}-${endDay}`;
  return date;
};

export const setMonthsDateFormatYYMMDD = months => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + months));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startYear}-${startMonth}-${startDay}`;
  return date;
};

export const setDaysDateFormatYYMMDD = days => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + days));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startYear}-${startMonth}-${startDay}`;
  return date;
};

export const getCurrentDateFormatMMDDYYYY = () => {
  const currentDate = new Date();
  const endYear = currentDate.getFullYear();
  const endMonth =
    currentDate.getMonth() > 8 ? currentDate.getMonth() + 1 : `0${currentDate.getMonth() + 1}`;
  const endDay = currentDate.getDate() > 9 ? currentDate.getDate() : `0${currentDate.getDate()}`;
  const date = `${endMonth}/${endDay}/${endYear}`;
  return date;
};

export const setMonthsDateFormatMMDDYYYY = months => {
  const currentDate = new Date();
  const start = new Date(currentDate.setMonth(currentDate.getMonth() + months));
  const startYear = start.getFullYear();
  const startMonth = start.getMonth() > 8 ? start.getMonth() + 1 : `0${start.getMonth() + 1}`;
  const startDay = start.getDate() > 9 ? start.getDate() : `0${start.getDate()}`;
  const date = `${startMonth}-${startDay}-${startYear}`;
  return date;
};

export const getFullDate = () => {
  const date = new Date();
  const formattedDate = date.toLocaleDateString('es-CL', {
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
  });
  return formattedDate;
};

export const formatChileanDate = strDate => {
  const date = new Date(strDate);
  const year = date.getFullYear();
  const month = date.getMonth() > 8 ? date.getMonth() + 1 : `0${date.getMonth() + 1}`;
  const day = date.getUTCDate() > 9 ? date.getUTCDate() : `0${date.getUTCDate()}`;
  const formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
};

export const getMonthDifference = (startDate, finalDate) => {
  const startDateoObj = new Date(`${startDate}`);
  const finalDateObj = new Date(`${finalDate}`);
  const diferenceMilisecconds = finalDateObj - startDateoObj;
  const monthDifference = Math.floor(diferenceMilisecconds / (1000 * 60 * 60 * 24 * 30));

  return monthDifference;
};

export const getCurrentDateAmericanFormat = () => {
  const currentDate = new Date();

  const month = currentDate.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por eso sumamos 1
  const day = currentDate.getDate();
  const year = currentDate.getFullYear();

  // Asegurarse de que los valores del día y el mes tengan dos dígitos
  const monthFormatted = month.toString().padStart(2, '0');
  const dayFormatted = day.toString().padStart(2, '0');

  return `${monthFormatted}/${dayFormatted}/${year}`;
};

export const getFormattedDateDDMMYY = () => {
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear() % 100;
  const formattedDay = String(day).padStart(2, '0');
  const formattedMonth = String(month).padStart(2, '0');
  const formattedYear = String(year).padStart(2, '0');

  return `${formattedDay}/${formattedMonth}/${formattedYear}`;
};
