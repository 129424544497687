import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Spinner from '../Spinner';
import { ButtonsPrimary } from '../../components/ComponentApp/buttoms/ButtonsXcala';

const GoToCompleteObAndInvestment = ({ loadingBalance, balanceData }) => {
  const { user } = useSelector(state => state.auth);

  const [accessWallet, setAccessWallet] = useState(false);
  const navigate = useNavigate();
  const theme = useTheme();

  const handleRedirect = () => {
    if (!accessWallet) {
      return navigate('/completeregistration');
    }
    if (cashBalance?.marketValue === 0) {
      return navigate('/payments');
    }
    if (cashBalance?.marketValue >= 1) {
      return navigate('/wallet');
    }

    return navigate('/payments');
  };
  const cashBalance = balanceData?.balance;

  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setAccessWallet(true);
    }
  }, [user]);

  if (loadingBalance) {
    return <Spinner />;
  }

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        padding: '8px',
      }}>
      <CardHeader
        title={
          <Typography fontSize={theme.typography.title.fontSize} color={theme.palette.primary.dark}>
            Evolución de la Inversión
          </Typography>
        }
      />

      <CardContent>
        <Box
          display='flex'
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent='space-between'
          width='70%'
          padding={{ xs: '0px 16px', md: '16px' }}>
          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                display: 'inline',
                borderRadius: '100%',
                backgroundColor: theme.palette.secondary.main,
              }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontWeight: theme.typography.h1.fontWeight,
                fontSize: theme.typography.fontSize,
              }}>
              Balance
            </Typography>
          </Box>

          <Box
            display='flex'
            flexDirection='row'
            justifyContent='flex-start'
            alignItems='center'
            gap={1}>
            <Box
              style={{
                width: '7px',
                height: '7px',
                backgroundColor: theme.palette.violet.main,
                display: 'inline',
                borderRadius: '100%',
              }}
            />
            <Typography
              sx={{
                color: theme.palette.primary.dark,
                fontWeight: theme.typography.h1.fontWeight,
                fontSize: theme.typography.fontSize,
              }}>
              Depositado
            </Typography>
          </Box>
        </Box>
      </CardContent>
      <CardContent
        sx={{
          padding: '4px',
        }}>
        <Box display='flex' flexDirection='row'>
          <ButtonGroup
            disabled={true}
            sx={{
              width: '100%',
              borderColor: theme.palette.primary.main,
              '&:hover': {
                borderColor: theme.palette.primary.main,
              },
            }}
            variant='outlined'
            aria-label='outlined primary button group'
            size='small'>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              12 meses
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              3 meses
            </Button>
            <Button
              sx={{
                textTransform: 'none',
              }}>
              1 mes
            </Button>
          </ButtonGroup>
        </Box>
      </CardContent>
      <Box
        display='flex'
        padding={{ md: 2, xs: '4px' }}
        justifyContent='center'
        alignItems='center'
        alignContent='center'
        sx={{
          backdropFilter: 'blur(0px)',
          backgroundColor: theme.palette.white.main,
          backgroundImage: 'url(/assets/images/chart.png)',
          backgroundRepeat: 'no-repeat',
          zIndex: 2,
        }}>
        <Card
          height={{ xs: '200px' }}
          sx={{
            padding: '10px 20px',
          }}>
          <CardContent
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              padding: { xs: 0 },
            }}>
            <Stack width='50px' height='50px'>
              <img
                alt={'targetIcon'}
                src='/assets/images/targetIcon.svg'
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'inline-block',
                }}
              />
            </Stack>

            <Typography
              color={theme.palette.primary.dark}
              style={{
                textAlign: 'center',
                marginBottom: '10px',
                fontSize: theme.typography.description.fontSize,
              }}>
              Para poder seguir invirtiendo en Xcala por favor recarga tu caja
            </Typography>

            <ButtonsPrimary
              name='Realiza tu primera inversión'
              onClick={handleRedirect}
              style={{ margin: '10px' }}
            />
          </CardContent>
        </Card>
      </Box>
    </Card>
  );
};

export default GoToCompleteObAndInvestment;
