import { Box, Stack, styled, useTheme } from '@mui/material';
import React from 'react';

const SteperContent = styled(Stack)({
  borderRadius: '20px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: { xs: '300px', md: '444px' },
  height: '8px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  overflow: 'hidden',
  gap: '0.5rem',
});
const RectangleContent = styled(Box)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  width: '100%',
  height: '8px',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
});
const Rectangle = styled(Box)({
  width: '100%',
  height: '8px',
  position: 'absolute',
  left: '0px',
  top: '0px',
});
const StepItem = ({ active = false }) => {
  const theme = useTheme();
  return (
    <RectangleContent>
      <Rectangle
        active
        sx={{
          backgroundColor: !active ? 'rgba(255, 255, 255, 0.25)' : theme.palette.secondary.text,
        }}
      />
    </RectangleContent>
  );
};

const Step = styled(StepItem)({
  alignSelf: 'stretch',
  flex: '1',
  margin: '0px',
});
const StepperProgress = ({ stepper, stepperLong }) => {
  const steps = stepperLong.length;
  return (
    <SteperContent>
      {[...Array(steps)].map((_, index) => (
        <Step key={index} active={index < stepper} />
      ))}
    </SteperContent>
  );
};

export default StepperProgress;
