import Box from '@mui/material/Box';
import * as Sentry from '@sentry/react';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import Router from './index';
import RenderFooter from './renderFooter';
import { LayoutMenu } from '../components/Layout/Menu/Layouts/LayoutMenu';

const tagManagerArgs = {
  gtmId: 'GTM-M4P54VX',
};

TagManager.initialize(tagManagerArgs);

const AppLayout = () => {
  const user = useSelector(state => state.auth.user);
  const location = useLocation();
  const [current, setCurrent] = React.useState('home');

  useEffect(() => {
    if (!user) Sentry.setUser(null);
    if (user) {
      Sentry.setUser({ email: user.email });
      Sentry.setExtra('securityId', user.sub);
    }
  }, [user]);

  TagManager.dataLayer({
    dataLayer: {
      userID: user ? user.sub : '',
      event: 'login',
      path: '/login',
    },
  });

  React.useEffect(() => {
    const pathname = location.pathname.replace('/', '');
    setCurrent(pathname ? pathname : 'home');
  }, [location.pathname]);

  return (
    <>
      <LayoutMenu>
        <Box sx={{ width: '100%', height: '100%', position: 'relative' }}>
          <Router />
          <RenderFooter />
        </Box>
      </LayoutMenu>
    </>
  );
};

//mide el rendimiento de la web
export default Sentry.withProfiler(AppLayout);
