import { gql, useQuery } from '@apollo/client';

const USE_GET_PORFOLIO_SIMULATION = gql`
  query GetPortfolioSimulation($data: portfolioParameter!) {
    getPortfolioSimulation(data: $data) {
      simulationData {
        amount
        profit
        simulationType
      }
    }
  }
`;
{
  /*Escenarios de inversión de acuerdo a las condiciones de mercado: normal, pesimista y optimista*/
}
export const useGetPorfolioSimulation = variables => {
  return useQuery(USE_GET_PORFOLIO_SIMULATION, { variables }, { fetchPolicy: 'no-cache' });
};
