import React from "react";
import Customselect from "../../../components/UI/select";
import { countryPhones } from "../../../utils/constants";

const CivilStatusSelect = ({ selected, setSelected }) => {
  const [menuItems, setMenuItems] = React.useState(countryPhones);
  const [defaultelect, setDefaultelect] = React.useState("");

  React.useEffect(() => {
    const aux = menuItems.find((item) => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);

  return (
    <Customselect
      label="Celular"
      Labelid="Celular"
      placholder="Celular"
      Selectid="Celular"
      id="Celular"
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};

export default CivilStatusSelect;
