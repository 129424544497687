import {
  Checkbox,
  FormControl,
  Stack,
  TextField,
  Typography,
  styled,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import TagManager from "react-gtm-module";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import isEmail from "validator/lib/isEmail";
import RutValidator from "w2-rut-validator";
import {
  ButtonsPrimary,
  ButtonsSecondary,
} from "../../components/ComponentApp/buttoms/ButtonsXcala";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";
import {
  ContentCheckBox,
  DeclarationSpan,
  TermsAndConditions,
  TextDeclaration,
} from "../../components/SignInSignUp/ComponentsSignUpSingIn";
import { updateTempUserAtributes } from "../../redux/auth";
import { countryPhones } from "../../utils/constants";
import { formatRut } from "../../utils/validateRut";
import Helpertext from "./components/HelperText";
import CountrySelector from "./components/countrySelector";
import { RegisIcon1, RegisIcon2 } from "./components/icons";

const LabelTitle = ({ title }) => {
  const theme = useTheme();
  return (
    <Typography
      sx={{
        fontSize: theme.typography.mediumText.fontSize,
        [theme.breakpoints.down("sm")]: {
          fontSize: theme.typography.smallText.fontSize,
        },
      }}
    >
      {title}
    </Typography>
  );
};

const InfoContainerForm = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.primary.blueLight,
  borderRadius: "8px",
  position: "relative",
  isolation: "isolate",
  flexDirection: "column",
  width: "100%",
  justifyContent: "start",
  alignItems: "flex-start",
  padding: "16px",
  boxSizing: "border-box",
}));
const SectionMail = styled(Stack)({
  position: "relative",
  isolation: "isolate",
  flexDirection: "row",
  justifyContent: "flex-start",
  alignItems: "center",
  padding: "0px",
  boxSizing: "border-box",
  margin: "0px",
  width: "100%",
});

const IconsMail = styled(RegisIcon1)({
  width: "24px",
  height: "24px",
  margin: "0px",
});

const IconsInsigth = styled(RegisIcon2)({
  width: "24px",
  height: "24px",
  margin: "0px",
});

const TextTitleSection = styled(Typography)(({ theme }) => ({
  textAlign: "left",
  fontSynthesis: "none",
  fontWeight: "400",
  width: "100%",
  color: theme.palette.primary.dark,
  fontSize:theme.typography.button.fontSize,
  [theme.breakpoints.down("sm")]: {
    fontSize: theme.typography.smallText.fontSize,
  },  
}));

const SignUp = () => {
  const matches = useMediaQuery("(max-width:900px)");
  const spacingsGap = matches ? "1rem" : "1.3rem";
  const maxWidthContainer = "1200px";
  const theme= useTheme();
  const { referrerCode } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();
  const userTemp = useSelector((state) => state.auth.userTemp);
  const siteKeyCaptcha = "6Lc2LSckAAAAAMYh4BkLvGHCL-jrFjIYGutkl4XT";
  const captcha = useRef(null);
  const [captchaCheck, setCaptchaCheck] = useState(false);
  const [checked, setChecked] = useState(false);
  const [helpermsg, setHelpermsg] = useState({
    nameHelperText: null,
    isNameError: false,
    mothersHelperText: null,
    isMotherError: false,
    lastnameHelperText: null,
    isLastnameError: false,
    rutHelperText: null,
    isRutError: false,
    emailHelperText: null,
    isEmailError: false,
    phoneHelperText: null,
    isPhoneError: false,
  });

  const [name, setName] = useState(userTemp?.name ? userTemp.name : "");
  const [motherslastname, setMothername] = useState(
    userTemp?.motherslastname ? userTemp.motherslastname : ""
  );
  const [lastname, setLastname] = useState(
    userTemp?.lastname ? userTemp.lastname : ""
  );
  const [rut, setRut] = useState(userTemp?.dni ? userTemp.dni : "");
  const [email, setEmail] = useState(userTemp?.email ? userTemp.email : "");
  const [phone, setPhone] = useState(userTemp?.phone ? userTemp.phone : "");
  const [country, setCountry] = useState(
    userTemp?.prefix ? userTemp.prefix : countryPhones[0]
  );

  const [confirmEmail, setConfirmEmail] = useState("");
  const [confirmEmailError, setConfirmEmailError] = useState(false);

  const onChangeConfirmEmail = (e) => {
    const tempConfirmEmail = e.target.value;
    setConfirmEmail(tempConfirmEmail);

    if (tempConfirmEmail === email) {
      setConfirmEmailError(false);
      setHelpermsg({
        ...helpermsg,
        confirmEmailHelperText: (
          <Helpertext
            message={"¡Los correos electrónicos coinciden!"}
            errormessage={""}
          />
        ),
      });
    } else {
      setConfirmEmailError(true);
      setHelpermsg({
        ...helpermsg,
        confirmEmailHelperText: (
          <Helpertext
            errormessage={"Los correos electrónicos no coinciden"}
            message={""}
          />
        ),
      });
    }
  };

  const onFinish = async () => {
    dispatch(
      updateTempUserAtributes({
        name: name,
        email: email,
        dni: rut,
        prefix: country,
        phone: phone,
        lastname: lastname,
        motherslastname: motherslastname,
      })
    );
    navigate(`/signupcreatepassword${referrerCode ? `/${referrerCode}` : ""}`);
  };

  const onnamechange = (e) => {
    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ´\s]*$/;
    const nameAux = e.target.value;

    if (regex.test(nameAux)) {
      setName(nameAux);
    }
    if (nameAux.length > 1) {
      setHelpermsg({
        ...helpermsg,
        nameHelperText: <Helpertext message={"Perfecto"} errormessage={""} />,
        isNameError: false,
      });
    }
    if (nameAux.length < 2 || !regex.test(nameAux)) {
      setHelpermsg({
        ...helpermsg,
        nameHelperText: (
          <Helpertext
            errormessage={
              !regex.test(nameAux)
                ? "sin caracteres especiales"
                : "Tu nombre es muy corto"
            }
            message={""}
          />
        ),
        isNameError: true,
      });
    }
  };

  //this is for motherlastname textfiled (Apellido materno)
  const onmothernamechange = (e) => {
    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ´\s]*$/;
    const motherNameAux = e.target.value;

    if (regex.test(motherNameAux)) {
      setMothername(motherNameAux);
    }
    if (motherNameAux.length > 1) {
      setHelpermsg({
        ...helpermsg,
        mothersHelperText: (
          <Helpertext message={"Muy bien"} errormessage={""} />
        ),
        isMotherError: false,
      });
    }
    if (motherNameAux.length === 1 || !regex.test(motherNameAux)) {
      setHelpermsg({
        ...helpermsg,
        mothersHelperText: (
          <Helpertext
            errormessage={
              !regex.test(motherNameAux)
                ? "sin caracteres especiales"
                : "El apellido materno es muy corto"
            }
            message={""}
          />
        ),
        isMotherError: true,
      });
    }
  };

  //this is for lastname textfiled (Apellido paterno)
  const onlastnamechange = (e) => {
    const regex = /^[a-zA-ZñÑáéíóúÁÉÍÓÚ´\s]*$/;
    const lastNameAux = e.target.value;
    if (regex.test(lastNameAux)) {
      setLastname(lastNameAux);
    }

    if (lastNameAux.length > 1) {
      setHelpermsg({
        ...helpermsg,
        lastnameHelperText: (
          <Helpertext message={"Muy bien"} errormessage={""} />
        ),
        isLastnameError: false,
      });
    }
    if (lastNameAux.length === 1 || !regex.test(lastNameAux)) {
      setHelpermsg({
        ...helpermsg,
        lastnameHelperText: (
          <Helpertext
            errormessage={
              !regex.test(lastNameAux)
                ? "sin caracteres especiales"
                : "El apellido paterno es muy corto"
            }
            message={""}
          />
        ),
        isLastnameError: true,
      });
    }
  };

  const onChangeRut = (e) => {
    let regexRut = /[^0-9K]/gi;
    const tempRut = e.target.value.replace(regexRut, "");
    setRut(formatRut(tempRut));
    if (RutValidator.validate(tempRut)) {
      setHelpermsg({
        ...helpermsg,
        rutHelperText: <Helpertext message={"¡Eres real!"} errormessage={""} />,
        isRutError: false,
      });
    } else {
      setHelpermsg({
        ...helpermsg,
        rutHelperText: (
          <Helpertext errormessage={"Tu rut es incorrecto"} message={""} />
        ),
        isRutError: true,
      });
    }
  };

  const onChangeEmail = (e) => {
    const tempEmail = e.target.value;
    setEmail(tempEmail);
    if (isEmail(tempEmail)) {
      setHelpermsg({
        ...helpermsg,
        emailHelperText: (
          <Helpertext message={"¡Queda poco!"} errormessage={""} />
        ),
        isEmailError: false,
      });
    } else {
      setHelpermsg({
        ...helpermsg,
        emailHelperText: (
          <Helpertext
            errormessage={"Algo anda mal... Verifica tu email"}
            message={""}
          />
        ),
        isEmailError: true,
      });
    }
  };

  const onChangePhone = (event) => {
    const valuePhone = event.target.value;
    let format = "";
    if (country && country.value === "+1") {
      var x = valuePhone
        .replace(/\D/g, "")
        .match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
      format = !x[2] ? x[1] : `(${x[1]}) ${x[2]}${x[3] ? `-${x[3]}` : ""}`;
    } else {
      var x = valuePhone
        .replace(/\D/g, "")
        .match(/(\d{0,1})(\d{0,4})(\d{0,4})/);
      format = !x[2] ? x[1] : `${+x[1]} ${x[2]}${x[3] ? ` ${x[3]}` : ""}`;
    }

    setPhone(format);
    if (
      (country && country.value === "+1" && x.input.length >= 10) ||
      (country && country.value === "+56" && x.input.length >= 9)
    ) {
      setHelpermsg({
        ...helpermsg,
        phoneHelperText: (
          <Helpertext message={"Teléfono válido"} errormessage={""} />
        ),
        isPhoneError: false,
      });
    } else {
      setHelpermsg({
        ...helpermsg,
        phoneHelperText: (
          <Helpertext errormessage={"Completa tu teléfono"} message={""} />
        ),
        isPhoneError: true,
      });
    }
  };

  const onChangeRecaptcha = () => {
    if (captcha.current.getValue()) {
      setCaptchaCheck(true);
    }
  };

  const onExpired = () => {
    setCaptchaCheck(false);
  };

  const openTC = () => {
    var win = window.open("/assets/pdf/TyC.pdf", "_blank");
    win.focus();
  };

  const disabled =
    !checked ||
    !captchaCheck ||
    !name ||
    name.length < 2 ||
    !lastname ||
    lastname.length < 2 ||
    !motherslastname ||
    motherslastname.length < 2 ||
    !phone ||
    (country &&
      country.value === "+1" &&
      phone.replace(/\D/g, "").length < 10) ||
    (country &&
      country.value === "+56" &&
      phone.replace(/\D/g, "").length < 9) ||
    !email ||
    !isEmail(email) ||
    !rut ||
    !RutValidator.validate(rut) ||
    confirmEmailError;

  useEffect(() => {}, [captchaCheck]);
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "sign up",
        path: "/signUp",
        link: {
          url: "/signUp",
          title: "sign up",
        },
      },
    });
  }, []);

  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        backgroundColor: { sm: theme.palette.primary.main, md: theme.palette.white.main},
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: "border-box",
            borderRadius: "24px",
            padding: "24px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
            marginBottom: "2rem",
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          marginTop="clamp(1.375rem, 14.3617rem + -21.2766vw, 1rem)"
          maxWidth={maxWidthContainer}
        >
          <form
            style={{
              width: "100%",
              maxWidth: maxWidthContainer,
              display: "flex",
              flexDirection: "column",
              gap: spacingsGap,
              padding: "1.2rem 1.2rem",
              boxSizing: "border-box",
            }}
          >
            <InfoContainerForm gap={1}>
              <SectionMail gap={"1rem"}>
                <IconsMail />
                <TextTitleSection>
                  Recibirás en tu correo una clave temporal para ingresar a tu
                  cuenta de prueba.
                </TextTitleSection>
              </SectionMail>
              <SectionMail gap={"1rem"}>
                <IconsInsigth />
                <TextTitleSection>
                  Define tu perfil de riesgo y revisa nuestras recomendaciones
                  personalizadas.
                </TextTitleSection>
              </SectionMail>
            </InfoContainerForm>
            <Stack
              sx={{
                width: "100%",
                flexDirection: { md: "row" },
                gap: spacingsGap,
              }}
            >
              <TextField
                fullWidth
                value={name}
                onChange={onnamechange}
                id="name"
                label={<LabelTitle title="Nombre" />}
                variant="standard"
                type="text"
                error={helpermsg.isNameError}
                helperText={helpermsg.nameHelperText}
                inputProps={{ maxLength: 30 }}
                placeholder="Nombre"
              />
              <TextField
                fullWidth
                value={lastname}
                onChange={onlastnamechange}
                id="lastname"
                label={<LabelTitle title="Apellido Paterno" />}
                variant="standard"
                type="text"
                error={helpermsg.isLastnameError}
                helperText={helpermsg.lastnameHelperText}
                inputProps={{ maxLength: 30 }}
                placeholder="Apellido Paterno"
              />
            </Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: { md: "row" },
                gap: spacingsGap,
              }}
            >
              <TextField
                label={<LabelTitle title="Apellido Materno" />}
                placeholder="Apellido Materno"
                variant="standard"
                fullWidth
                value={motherslastname}
                onChange={onmothernamechange}
                id="mothername"
                type="text"
                error={helpermsg.isMotherError}
                helperText={helpermsg.mothersHelperText}
                inputProps={{ maxLength: 30 }}
              />
              <TextField
                label={<LabelTitle title="RUT" />}
                placeholder="N° 184565212"
                variant="standard"
                fullWidth
                value={rut}
                onChange={onChangeRut}
                id="dni"
                type="text"
                error={helpermsg.isRutError}
                helperText={helpermsg.rutHelperText}
                inputProps={{ maxLength: 12 }}
              />
            </Stack>
            <Stack
              sx={{
                width: "100%",
                flexDirection: { md: "row" },
                gap: spacingsGap,
                marginTop: { md: "0rem", lg: "0.5rem" },
              }}
            >
              <TextField
                label={<LabelTitle title="Email" />}
                placeholder="correo@correo.com"
                variant="standard"
                fullWidth
                value={email}
                onChange={onChangeEmail}
                id="email"
                type="email"
                error={helpermsg.isEmailError}
                helperText={helpermsg.emailHelperText}
                inputProps={{ maxLength: 50 }}
              />

              <TextField
                label={<LabelTitle title="Confirmar Email" />}
                placeholder="Repetir correo@correo.com"
                variant="standard"
                fullWidth
                value={confirmEmail}
                onChange={onChangeConfirmEmail}
                type="email"
                error={confirmEmailError}
                helperText={helpermsg.confirmEmailHelperText}
                inputProps={{ maxLength: 50 }}
              />
            </Stack>

            <Stack
              sx={{
                width: { sm: "49%", xs: "100%" },
                flexDirection: { md: "row" },
                gap: spacingsGap,
                marginTop: { md: "0rem", lg: "0.5rem" },
              }}
            >
              <Stack
                sx={{
                  flexDirection: "row",
                  width: "100%",
                  gap: 1,
                }}
              >
                <FormControl
                  sx={{
                    width: { sm: "20%", md: "40%" },
                    minWidth: "84px",
                    maxWidth: "86px",
                  }}
                >
                  <CountrySelector
                    selected={country}
                    setSelected={(value) => setCountry(value)}
                  />
                </FormControl>
                <TextField
                  label={<LabelTitle title="Celular" />}
                  placeholder="562332513"
                  fullWidth
                  value={phone}
                  onChange={onChangePhone}
                  length={country && country.value === "+1" ? 10 : 9}
                  id="phonenumber"
                  type="string"
                  variant="standard"
                  error={helpermsg.isPhoneError}
                  helperText={helpermsg.phoneHelperText}
                />
              </Stack>
            </Stack>

            <Stack>
              <TermsAndConditions>
                <ContentCheckBox
                  sx={{
                    alignItems: { md: "center" },
                    justifyContent: { md: "flex-start" },
                  }}
                  gap={1}
                >
                  <Checkbox
                    checked={checked}
                    onChange={(event) => setChecked(event.target.checked)}
                  />
                  <TextDeclaration fontSize="clamp(0.75rem, 0.617rem + 0.5674vw, 1rem)">
                    Acepto los{" "}
                    <DeclarationSpan
                      sx={{ cursor: "pointer" }}
                      variant="span"
                      component={"span"}
                      onClick={openTC}
                    >
                      términos y condiciones del servicio
                    </DeclarationSpan>{" "}
                    que estoy a punto de solicitar
                  </TextDeclaration>
                </ContentCheckBox>
              </TermsAndConditions>
              <Stack
                justifyContent="center"
                alignItems="flex-start"
                width={"100%"}
              >
                <ReCAPTCHA
                  sitekey={siteKeyCaptcha}
                  onChange={onChangeRecaptcha}
                  hl="es"
                  ref={captcha}
                  onExpired={onExpired}
                />
              </Stack>
            </Stack>

            <Stack>
              <Stack
                direction={{ xs: "column-reverse", md: "row" }}
                width={"100%"}
                marginTop={{ md: "16px", xs: "16px" }}
                gap={3}
              >
                <ButtonsSecondary
                  name="Volver"
                  onClick={() => navigate(-1)}
                  fullWidth
                />
                <ButtonsPrimary
                  name="Continuar"
                  onClick={onFinish}
                  disabled={disabled ? true : false}
                  fullWidth
                />
              </Stack>
            </Stack>
          </form>
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default SignUp;
