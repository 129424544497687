import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  suceessMyInteresets: [],
  loadingMyInteresets: false,
  errorMyInteresets: null,
};

export const myInteresetsSlice = createSlice({
  name: 'myinteresets',
  initialState,
  reducers: {
    updateMyInteresets: (state, { payload }) => {
      const aux = [...state.suceessMyInteresets];
      const index = aux.findIndex(item => item.preference_detail_id == payload);
      if (index < 0) {
        aux.push({ preference_detail_id: payload, text: '' });
      } else {
        aux.splice(index, 1);
      }
      state.suceessMyInteresets = aux;
    },
    initGetMyInteresets: state => {
      state.loadingMyInteresets = true;
      state.suceessMyInteresets = [];
      state.errorMyInteresets = null;
    },
    successGetMyInteresets: (state, { payload }) => {
      state.loadingMyInteresets = false;
      state.suceessMyInteresets = [...payload];
    },
    errorGetMyInteresets: state => {
      state.loadingMyInteresets = false;
    },
  },
});

export const {
  updateMyInteresets,
  initGetMyInteresets,
  successGetMyInteresets,
  errorGetMyInteresets,
} = myInteresetsSlice.actions;
export default myInteresetsSlice.reducer;
