export const ImgGreenComponent = ({ styles }) => {
  return (
    <svg
      height='clamp(6.3125rem, -9.8333rem + 68.8889vw, 16rem)'
      viewBox='0 0 219 256'
      fill='none'
      style={{ ...styles, position: 'absolute', top: '0', right: '0' }}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M219 252.882C207.979 254.93 196.614 256 185 256C82.8273 256 0 173.173 0 71C0 45.8449 5.0206 21.8625 14.1144 0H78.472C64.9037 20.3174 56.9933 44.7348 56.9933 70.9998C56.9933 141.696 114.304 199.006 185 199.006C196.772 199.006 208.173 197.417 219 194.442V252.882Z'
        fill='#5AC4B1'
      />
    </svg>
  );
};
