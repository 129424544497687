import { CircularProgress, Container, Grid } from '@mui/material';
import React from 'react';

const Spinner = () => {
  return (
    <Container maxWidth='lg'>
      <Grid container item spacing={5} md={12} xs={12} mb={3}>
        <Grid item md={12} xs={12} mt={3}>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <CircularProgress />
          </div>
        </Grid>
      </Grid>
    </Container>
  );
};

export default Spinner;
