import React from 'react';
import { SectionInfo, TextValue, TitleProperty } from './ComponentsWallet';

const InfoCardWallet = ({ Title, value }) => {
  return (
    <SectionInfo>
      <TitleProperty sx={{ fontSize: 'clamp(0.875rem, 0.6923rem + 0.7692vw, 1.125rem)' }}>
        {Title}
      </TitleProperty>
      <TextValue sx={{ fontSize: 'clamp(0.875rem, 0.6923rem + 0.7692vw, 1.125rem)' }}>
        {value}
      </TextValue>
    </SectionInfo>
  );
};

export default InfoCardWallet;
