import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelectOB } from './CustomSelectOB';

export const NationalitySelectOB = ({ selected, setSelected }) => {
  const nationalities = useSelector(state => state.constants.nationalities);
  const [menuItems, setMenuItems] = useState(nationalities);
  const [defaultelect, setDefaultelect] = useState('');

  useEffect(() => {
    const aux = menuItems.find(item => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);
  return (
    <CustomSelectOB
      label='Nacionalidad'
      Labelid='Nacionalidad'
      placholder='Nacionalidad'
      Selectid='Nacionalidad'
      id='Nacionalidad'
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};
