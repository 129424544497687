import { gql, useQuery } from '@apollo/client';

const GET_CLIENT_TRANSACTION_PORTFOLIO = gql`
  query GetClientTransactionPortfolio($principalAccount: Boolean) {
    getClientTransactionPortfolio(principalAccount: $principalAccount) {
      Account_FileAs
      Amount
      Units
      Status
      SubType
      ActivityType
      CreatedDate
      Security_Name
    }
  }
`;
{
  /*Retorna un array de objetos con las transacciones de un usuario (portfolio)*/
}
export const useGetClientTransactionPortfolio = variables => {
  return useQuery(GET_CLIENT_TRANSACTION_PORTFOLIO, {
    variables,
  });
};
