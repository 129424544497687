import { Stack, Typography, styled } from '@mui/material';

const ContainerDivider = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  alignItems: {
    xs: 'center',
    ms: 'center',
    md: 'flex-start',
    lg: 'flex-start',
  },
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  marginTop: '20px',
});
const TitleDividerContent = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  maxWidth: '627px',
  margin: '0px',
});

const IconSectionDivider = styled('img')({
  height: '20px',
  width: '20px',
  margin: '0px',
  marginRight: '16px',
});

const TextSectionFoundSpan = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.primary.dark,
  fontFamily: 'Museo',
  fontWeight: theme.typography.smallText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

const TextSectionFoundSpanResalt = styled(Typography)(({ theme }) => ({
  whiteSpace: 'pre-wrap',
  color: theme.palette.primary.main,
  fontFamily: 'Museo',
  fontWeight: theme.typography.smallText.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
}));

const DivideSectionText = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '17px 0px 0px 0px',
  height: '14px',
});

const TextPromo = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.textLight.main,
  fontWeight: theme.typography.littleText.fontWeight,
  fontSize: theme.typography.availableBalance.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '100%',
  margin: '0px',
  flex: '1 0',
}));

export const DividerSectionText = () => {
  return (
    <ContainerDivider>
      <TitleDividerContent
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}>
        <IconSectionDivider src='/assets/images/icon-section.svg' alt='icon-section' />
        <TextSectionFoundSpan component={'p'} fontSize={'clamp(0.6em, 4vw, 1.4em)'}>
          Te proponemos estas opciones{' '}
          <TextSectionFoundSpanResalt component={'span'} fontSize={'clamp(0.6em, 4vw, 1.3em)'}>
            para ti
          </TextSectionFoundSpanResalt>
        </TextSectionFoundSpan>
      </TitleDividerContent>
      <DivideSectionText
        flexDirection={{ xs: 'column', ms: 'column', md: 'row', lg: 'row' }}
        justifyContent={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}
        alignItems={{
          xs: 'center',
          ms: 'center',
          md: 'flex-start',
          lg: 'flex-start',
        }}
        mt={{ xs: '50px', ms: '0px', md: '0px', lg: '0px' }}
        spacing={{ xs: '0px', ms: '0px', md: '0px', lg: '0px' }}
        sx={{ marginBottom: '50px', marginTop: '0px' }}>
        <TextPromo>En Xcala, invierte como los que saben</TextPromo>
      </DivideSectionText>
    </ContainerDivider>
  );
};
