import { gql, useLazyQuery } from '@apollo/client';

const GET_REGISTRATION_TYPE_QUERY = gql`
    query getRegistrationType($data: String!) {
        getRegistrationType(data: $data) 
    }
`;
{
  /*Trae un objeto con el estatus del registro*/
}
export default () => useLazyQuery(GET_REGISTRATION_TYPE_QUERY, { fetchPolicy: 'no-cache' });
