import { useNavigate } from 'react-router-dom';

const useRedirect = () => {
  const navigate = useNavigate();

  const handleGoById = (path, id) => {
    navigate(`/${path}/${id}`);
  };

  const handleNavigateTo = path => {
    navigate(`/${path}`);
  };

  return { handleGoById, handleNavigateTo };
};

export default useRedirect;
