import { Box, Stack, Typography, styled } from '@mui/material';

const ItemValueDistributio = styled(Stack)({
  backgroundColor: 'rgba(255, 255, 255, 0.15)',
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'space-between',
  alignItems: 'start',
  padding: '16px',
  boxSizing: 'border-box',
});

const SelectorIndicator = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  flexWrap: 'wrap',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});
const InvertedChartsIndica = styled(Box)({
  height: '8px',
  width: '8px',
  margin: '0px',
  borderRadius: '100px',
});

const FondoNameItem = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  textDecoration: 'none',
  lineHeight: '105%',
  textTransform: 'none',
  flex: '1',
  margin: '0px 0px 0px 10px',
});

const IndicatorSection = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px 8px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});

const ValueFondo = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-end',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px',
  minWidth: '53px',
});
const ValuePymen = styled(Typography)({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '700',
  fontSize: '26px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
  lineHeight: '105%',
});
const DescriptionValue = styled(Typography)({
  textAlign: 'right',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(255, 255, 255, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '8px',
  letterSpacing: '-0.21px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px 0px 0px 0px',
  lineHeight: '101%',
});
export const modelsPortfolioChart = dataModelsPortfolio => {
  const colores = [
    '#8612FF',
    '#160BE3',
    '#0060FA',
    '#0BBAE3',
    '#0DFBBD',
    '#FFAF9C',
    '#90F0F5',
    '#A525FA',
    '#D98DEB',
    '#64FAEC',
  ];

  const excludeModelsNull = dataModelsPortfolio?.filter(portfolio => portfolio.Targets !== null);

  const newObjectChartPorfolio = excludeModelsNull?.map(portfolio => {
    const dataChartLabels = portfolio.Targets.map(target => target.name.slice(4));

    const dataChartLabelsID = portfolio.Targets.map(target => target);

    const dataChartValues = portfolio.Targets.map(target => target.percentage);
    const backgroundColor = colores.slice(0, dataChartValues.length);

    return {
      idModels: portfolio.id,
      currency: portfolio.currency,
      fundName: `Portafolio ${portfolio.fileAs}`,
      profilePortfolio: portfolio.code,
      amountOfFunds: dataChartLabels.length,
      rentabilityFound: portfolio?.legacyKey || '',
      fund: dataChartLabelsID,
      dataChart: {
        labels: dataChartLabels,
        datasets: [
          {
            label: 'Fondo',
            data: dataChartValues,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor.map(color => `${color}9E`),
            borderWidth: 0,
          },
        ],
      },
    };
  });

  return newObjectChartPorfolio;
};

export const printLabelData = ({ labels, datasets }) => {
  return datasets.map((item, index) => {
    const {
      label: titleLabel,
      data: dataLabel,
      backgroundColor: colorLabel,
      hoverBackgroundColor,
    } = item;

    return colorLabel.map((item, index) => {
      return (
        <Stack
          key={index}
          flexDirection={'row'}
          alignItems={'center'}
          justifyContent={'start'}
          gap={1}>
          <Typography variant='span'>
            {
              <Box
                display='inline-block'
                backgroundColor={item}
                borderRadius={100}
                width={'8px'}
                height={'8px'}>
                {''}
              </Box>
            }
            {'   '}
          </Typography>
          <Typography fontSize={12} key={labels[index]}>
            {`${labels[index]}`}
          </Typography>
        </Stack>
      );
    });
  });
};

export const printLabelDataDetailPortfolio = ({ labels, datasets }) => {
  return labels.map((label, index) => {
    const { data: dataLabel, backgroundColor: colorLabel } = datasets[0];
    let dataLabelsReplace = dataLabel[index].toString().replace(/\./g, ',');

    return (
      <ItemValueDistributio key={index}>
        <IndicatorSection>
          <SelectorIndicator>
            <InvertedChartsIndica style={{ backgroundColor: colorLabel[index] }} />
            <FondoNameItem>{label}</FondoNameItem>
          </SelectorIndicator>
          <ValueFondo>
            <ValuePymen>{`${dataLabelsReplace}%`}</ValuePymen>
            <DescriptionValue>del valor invertido</DescriptionValue>
          </ValueFondo>
        </IndicatorSection>
      </ItemValueDistributio>
    );
  });
};

export const modelsPortfolioChartById = (dataModelsPortfolio, securityList) => {
  const colores = [
    '#8612FF',
    '#160BE3',
    '#0060FA',
    '#0BBAE3',
    '#0DFBBD',
    '#FFAF9C',
    '#90F0F5',
    '#A525FA',
    '#D98DEB',
    '#64FAEC',
  ];

  return dataModelsPortfolio.map(portfolio => {
    const dataChartLabels = portfolio.Targets.map(target => target.name);
    const dataChartValues = portfolio.Targets.map(target => target.percentage);

    const targetFunds = portfolio.Targets;
    const targetCount = dataChartValues.length;
    const backgroundColor = colores.slice(0, targetCount);

    if (targetCount > colores.length) {
      const additionalColors = new Array(targetCount - colores.length).fill('#CCCCCC');
      backgroundColor.push(...additionalColors);
    }

    const selectedFundData = targetFunds
      .filter(targetFund => {
        return securityList.some(selectedFund => selectedFund.ID === targetFund.ID);
      })
      .map(selectedFund => {
        const matchedFund = securityList.find(
          selectedFundItem => selectedFundItem.ID === selectedFund.ID,
        );
        return {
          ...selectedFund,
          ...matchedFund,
        };
      });

    return {
      idModels: portfolio.id,
      currency: portfolio.currency,
      fundName: `Portafolio ${portfolio.fileAs}`,
      profilePortfolio: portfolio.code,
      amountOfFunds: targetCount,
      rentabilityFound: portfolio?.legacyKey || '',
      fund: selectedFundData,
      dataChart: {
        labels: dataChartLabels,
        datasets: [
          {
            label: 'Fondo',
            data: dataChartValues,
            backgroundColor: backgroundColor,
            hoverBackgroundColor: backgroundColor.map(color => `${color}9E`),
            borderWidth: 0,
          },
        ],
      },
    };
  });
};
