import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import { HeadTitle } from '../myTransactions/MyNewTransactionsMuiComponents';

const $minHeigth = '407px';
const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};
export const HeaderSignedVerification = ({
  userRisk,
  nameUser,
  emailUser,
  userDni,
  lastNameUser,
}) => {
  return (
    <WrapperBackgroundBanner $minHeigth={$minHeigth}>
      <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
        <Stack width={'100%'} direction={'column'}>
          <Stack
            direction={{ md: 'row', xs: 'column' }}
            justifyContent={'center'}
            alignItems={{ md: 'center', xs: 'center' }}
            width={{ md: '100%', xs: '100%' }}
            gap={1}>
            <AvatarSection userRisk={userRisk} />
            <Box>
              <HeadTitle textAlign={{ md: 'left', xs: 'center' }} fontSize={'26px'}>
                {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                  lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                } `}
              </HeadTitle>
              <Typography
                color={'#fff'}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={'18px'}>
                {emailUser}
              </Typography>
              <Typography
                color={'#fff'}
                fontWeight={'100'}
                textAlign={{ md: 'left', xs: 'center' }}
                fontSize={'18px'}>
                {hideNumbers(userDni)}
              </Typography>
            </Box>
          </Stack>
        </Stack>
      </WrapperContentApp>
    </WrapperBackgroundBanner>
  );
};
