import React from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../components/Layout/Footer/Footer';

const RenderFooter = () => {
  const location = useLocation();
  if (
    location.pathname === '/' ||
    location.pathname === '/signin' ||
    location.pathname === '/signup' ||
    location.pathname === '/forgotpassword' ||
    location.pathname === '/forgotchangepassword' ||
    location.pathname === '/signupcreatepassword' ||
    location.pathname === '/signupconfirm' ||
    location.pathname === '/changeforgetpasswordsuccess' ||
    location.pathname === '/confirmAccountSuccess' ||
    /^.*\/signup\/[\w=]+$/.test(location.pathname) ||
    /^.*\/signupcreatepassword\/[\w=]+$/.test(location.pathname)
  ) {
    return null;
  }
  return <Footer />;
};

export default RenderFooter;
