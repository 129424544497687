import { Box, Grid, Stack, Typography } from '@mui/material';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import useRegisterUserDataRegistration from '../../hooks/useRegisterUserDataRegistration';
import { updateUserAtributes } from '../../redux/auth';
import { updateAttributes } from '../../redux/onboarding';
import { getSession } from '../../services/checkid';
import { ButtonsPrimary, ButtonsTertiary } from '../ComponentApp/buttoms/ButtonsXcala';

export const CompleteOmboardingTree = ({ setstepper, setSteppercolor, setErrors }) => {
  const dispatch = useDispatch();
  const onBoardingData = useSelector(state => state.onboarding.data);

  const { user } = useSelector(state => state.auth);

  const [
    doRegisterUserDataRegistration,
    {
      data,
      error: errorRegisterUserDataRegistration,
      loading: loadingRegisterUserDataRegistration,
    },
  ] = useRegisterUserDataRegistration();

  const successRegisterUserDataRegistration = data?.registerUserDataRegistration;

  useEffect(() => {
    if (errorRegisterUserDataRegistration && !loadingRegisterUserDataRegistration) {
    }
  }, [errorRegisterUserDataRegistration, loadingRegisterUserDataRegistration]);

  useEffect(() => {
    if (successRegisterUserDataRegistration && !loadingRegisterUserDataRegistration) {
      dispatch(
        updateUserAtributes({
          'custom:checkid-sign': onBoardingData.validation.sign,
        }),
      );
      fourthStep();
    }
  }, [successRegisterUserDataRegistration, loadingRegisterUserDataRegistration]);

  const getSessionAsync = async () => {
    const {
      REACT_APP_CHECKID_BASE_URL,
      REACT_APP_CHECKID_API_KEY,
      REACT_APP_CHECKID_COMPANY_ID,
      REACT_APP_CHECKID_WEB_URL,
    } = process.env;

    const session = await getSession();
    const dni = user['custom:dni'].toUpperCase();
    window['display'](
      dni,
      session.client.token,
      callbackCheckId,
      REACT_APP_CHECKID_BASE_URL,
      REACT_APP_CHECKID_API_KEY,
      REACT_APP_CHECKID_COMPANY_ID,
      REACT_APP_CHECKID_WEB_URL,
    );
  };

  const getValue = name => {
    const obj = onBoardingData?.detail?.find(item => item.Name === name);
    return obj?.Value ? obj.Value : '';
  };

  const registerUserData = validation => {
    const validationData = {
      ...onBoardingData,
      validation,
    };
    dispatch(updateAttributes(validationData));

    const detail = onBoardingData.detail
      .map(item => {
        return {
          Name: item.Name,
          Value: item.Value?.name ? item.Value?.name : item.Value,
        };
      })
      .filter((_, index) => index !== 3);

    const AddressHome = {
      Address1: getValue('address'),
      City: getValue('custom:city').name,
      Country: getValue('custom:nationality').name,
    };

    const userData = {
      Name: user['name'],
      FederalTaxID: user['custom:dni'],
      First: user['custom:paternal-surname'],
      Last: user['custom:maternal-surname'],
      FileAs: user['email'],
      EMail: user['email'],
      Suffix: user['name'],
      JobTitle: getValue('custom:profession').name,
      AddressHome: AddressHome,
    };

    const data = {
      ...onBoardingData,
      detail,
      validation,
      userData,
    };

    doRegisterUserDataRegistration({
      variables: { data },
    });
  };

  const callbackCheckId = response => {
    if (response?.idTx && response.idTx !== '0') {
      const validation = {
        idTx: response.idTx,
        authenticated: response.authenticationResult.authenticated,
        sign: response.authenticationResult.sign,
      };
      registerUserData(validation);
    } else {
      setErrors({
        title: '',
        subtitle: response.message,
        routeName: '',
      });
      setstepper(5);
    }
  };

  const fourthStep = () => {
    setstepper(4);
    setSteppercolor(first => {
      return {
        ...first,
        comp4: true,
      };
    });
  };

  const goBefore = () => {
    setstepper(2);
    setSteppercolor(first => {
      return {
        ...first,
        comp1: true,
        comp2: true,
        comp3: false,
      };
    });
  };

  const retry = () => {
    if (user['custom:checkid-sign']) {
      fourthStep();
    } else {
      getSessionAsync();
    }
  };
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'stepper Validating your identity',
        path: '/completeregistration',
        link: {
          url: '/completeregistration',
          title: 'stepper Validating your identity',
        },
      },
    });
  }, []);

  return (
    <>
      <Grid container maxWidth='sm' spacing={2}>
        <Grid item md={12} className='stepper-grid-registration'>
          <Typography variant='h6' className='blue_text fw-600 font-lg-24'>
            Validando tu identidad
          </Typography>
        </Grid>
      </Grid>

      <Stack spacing={2}>
        <Box>
          <Typography sx={{ color: '#808080', marginBottom: 1 }}>
            ¡Excelente, nos queda poco!
          </Typography>
          <ul
            style={{
              color: '#808080',
            }}>
            <li>Necesitamos validar tu identidad, así que ten a mano tu carnet.</li>
            <li>
              Toda tu información es confidencial, esto debemos hacerlo para cumplir con la
              normativa.
            </li>
            <li>
              Al continuar, serás redirigido al portal de nuestro proveedor que nos ayuda con esto.
            </li>
            <li>
              Si tienes problemas, procura tener deshabilitado el bloqueo de ventanas emergentes.
            </li>
          </ul>
        </Box>

        <Stack
          width={'100%'}
          direction={'row'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Stack
            justifyContent={'center'}
            direction={{ md: 'row', xs: 'column-reverse' }}
            width={{ md: '60%', xs: '100%' }}
            gap={2}
            sx={{ margin: '26px 0px 0px 0px' }}>
            <ButtonsTertiary fullWidth name='Atrás' onClick={goBefore} />
            <ButtonsPrimary name='Continuar' onClick={retry} fullWidth />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
