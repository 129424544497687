import React from 'react';
import { formatChileanDate, numberWithPoint, formatLossGainValue } from '../../../utils/utils';
import {
  CondicionesGenerales,
  ContentTitleTable,
  Divaider,
  GeneralConditionBody,
  GeneralConditionCard,
  GeneralConditionFooter,
  GeneralConditionHead,
  ItemContentDescription,
  SectionGeneralCondit,
  TitleItemContent,
  TitleItemFooter,
  TitleTableName,
  ValueItemContent,
  ValueItemFooter,
} from '../../portfolio/portolioInvestment/PortfolioInvestmentComponents';

const SectionGeneralCondition = ({ valorCuota, myInvestment }) => {
  return (
    <SectionGeneralCondit>
      <GeneralConditionCard>
        <GeneralConditionHead>
          <CondicionesGenerales>Condiciones Generales</CondicionesGenerales>
        </GeneralConditionHead>

        <GeneralConditionBody>
          <ItemContentDescription>
            <TitleItemContent>Tienes:</TitleItemContent>
            <ValueItemContent>
              $ {numberWithPoint(Math.round(Number.parseFloat(myInvestment.marketValue)))}
            </ValueItemContent>
          </ItemContentDescription>
          <ItemContentDescription>
            <TitleItemContent>
              Valor cuota (al {valorCuota && formatChileanDate(valorCuota.EffectiveDate)}) :
            </TitleItemContent>
            <ValueItemContent>
              $ {valorCuota && numberWithPoint(Number.parseFloat(valorCuota.ClosePrice).toFixed(4))}
            </ValueItemContent>
          </ItemContentDescription>
          <ItemContentDescription>
            <TitleItemContent>N° de Cuotas:</TitleItemContent>
            <ValueItemContent>
              {numberWithPoint(Number.parseFloat(myInvestment.unitBalance).toFixed(4))}
            </ValueItemContent>
          </ItemContentDescription>
          <Divaider />
          <ItemContentDescription>
            <TitleItemContent>Total depósitos:</TitleItemContent>
            <ValueItemContent>$ {numberWithPoint(Math.round(myInvestment.buy))}</ValueItemContent>
          </ItemContentDescription>
          <ItemContentDescription>
            <TitleItemContent>Rescates:</TitleItemContent>
            <ValueItemContent>
              {myInvestment.sell === 0
                ? '$ 0'
                : `- $ ${numberWithPoint(Math.abs(Math.round(myInvestment.sell)))}`}
            </ValueItemContent>
          </ItemContentDescription>
          <ItemContentDescription>
            <TitleItemContent>Dividendos:</TitleItemContent>
            <ValueItemContent>
              {' '}
              $ {numberWithPoint(Math.round(myInvestment.dividends))}
            </ValueItemContent>
          </ItemContentDescription>
        </GeneralConditionBody>

        <GeneralConditionFooter>
          <ItemContentDescription>
            <TitleItemFooter color={'#fff'} fontSize={'clamp(0.75rem, 0.5813rem + 1.5vw, 1.125rem'}>
              Ganancia / Pérdida:
            </TitleItemFooter>
            <ValueItemFooter color={'#fff'} fontSize={'clamp(0.75rem, 0.5813rem + 1.5vw, 1.125rem'}>
              {formatLossGainValue(myInvestment.dividends + myInvestment?.capitalAppreciation)}
            </ValueItemFooter>
          </ItemContentDescription>
        </GeneralConditionFooter>
      </GeneralConditionCard>
    </SectionGeneralCondit>
  );
};

export default SectionGeneralCondition;
