export const addCostEffectivenessFn = securityList => {
  const config = {
    'HMC Balanceado Global': { profit: '10,00', currency: 'USD' },
    'HMC Deuda Privada Global': { profit: '10,00', currency: 'USD' },
    'HMC Deuda Privada Pesos': { profit: '10,00', currency: 'Pesos' },
    'HMC Renta Global Pesos': { profit: '14,00', currency: 'Pesos' },
    'HMC Renta Global USD': { profit: '14,00', currency: 'USD' },
    'HMC Global Real Estate Income': { profit: '8,00', currency: 'USD' },
    'HMC Sustentable': { profit: '6,00', isActive: false, currency: 'USD' },
    'HMC US Venture Opportunities': { profit: '17,00', currency: 'USD' },
    'HMC Portafolio Óptimo': { profit: '10,00', currency: 'USD' },
    // nuevos fondos
    'HMC Rendimiento Estratégico Dólar': { profit: '10,60', currency: 'USD' },
    'HMC Rendimiento Estratégico Pesos': { profit: '10,60', currency: 'Pesos' },

    'HMC Bitcoin': { profit: '48,70', currency: 'USD' },
  };

  return securityList.map(profit => {
    const configEntry = config[profit.Name];
    return configEntry ? { ...profit, ...configEntry } : profit;
  });
};

export const handleExpectedReturnFn = name => {
  const returns = {
    'HMC Balanceado Global': '10,00',
    'HMC Deuda Privada Global': '10,00',
    'HMC Deuda Privada Pesos': '10,00',
    'HMC Renta Global Pesos': '14,00',
    'HMC Renta Global USD': '14,00',
    'HMC Global Real Estate Income': '8,00',
    'HMC Sustentable': '6,00',
    'HMC US Venture Opportunities': '17,00',
    'HMC Portafolio Óptimo': '10,00',
    // nuevos fondos
    'HMC Rendimiento Estratégico Dólar': '10,60',
    'HMC Rendimiento Estratégico Pesos': '10,60',
    'HMC Bitcoin': '48.70',
  };

  return returns[name] || name;
};

export const AddCurrency = name => {
  const currencyMap = {
    'HMC Balanceado Global': 'USD',
    'HMC Deuda Privada Global': 'USD',
    'HMC Deuda Privada Pesos': 'Pesos',
    'HMC Renta Global Pesos': 'Pesos',
    'HMC Renta Global USD': 'USD',
    'HMC Global Real Estate Income': 'USD',
    'HMC Sustentable': 'USD',
    'HMC US Venture Opportunities': 'USD',
    'HMC Portafolio Óptimo': 'USD',
    // nuevos fondos
    'HMC Rendimiento Estratégico Dólar': 'USD',
    'HMC Rendimiento Estratégico Pesos': 'Pesos',
    'HMC Bitcoin': 'USD',
  };

  return currencyMap[name] || name;
};
