import { Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { useCreateWithdrawTransaction } from '../../hooks/useCreateWitdrawTransaction';
import { useGetAccountTypes, useGetBankClient, useGetBanks } from '../../hooks/useGetClientBank';
import Spinner from '../Spinner';
import ModalActions, { WithdrawSuccess } from '../UI/modalActions';
import ModalEditBank from '../XcalaComponents/Notifications/ModalEditBank';
import ModalWithdrawal from '../XcalaComponents/Notifications/ModalWithdrawal';
import {
  ButtonContained,
  RescueContentContent,
  SectionSelectRescue,
  SelectContent,
  TitleSectionRescue,
} from '../portfolio/rescuePortfolio/RescueComponent';
import BankAccountForm from './BankAccountForm';
import SelectRescueWithdrawal from './SelectRescueWithdrawal';

const BodyWhithdrawal = ({ balanceClient }) => {
  const [valueSelect, setValueSelect] = useState('50');
  const [amountRescue, setAmountRescue] = useState('');
  const [modalOpen, setModalOpen] = useState(false);
  const [modalOpenBank, setModalOpenBank] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [modalOption, setModalOption] = useState();
  const [updateBank, setUpdateBank] = useState(false);

  const [createWithdraw, { loading: loadingWithdraw, error: errorWithdraw }] =
    useCreateWithdrawTransaction();
  const [
    doGetClientBank,
    { data: clientBankDetail, loading: loadingClientBankDetail, error: errorClientBankDetail },
  ] = useGetBankClient();
  const successGetClientBank = clientBankDetail?.getUserBankDetail;

  const {
    data: accountTypes,
    loading: loadingAccountTypes,
    error: errorAccountTypes,
  } = useGetAccountTypes();
  const { data: banks, loading: loadingBanks, error: errorBanks } = useGetBanks();

  const handleOpenModal = () => {
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setModalOpen(false);
  };

  const handleOpenModalBank = () => {
    setModalOpenBank(true);
  };

  const handleCloseModalBank = () => {
    setModalOpenBank(false);
    setUpdateBank(true);
  };

  const handleSubmit = async () => {
    try {
      if (!isSubmitting) {
        setIsSubmitting(true);
        const withdrawresponse = await createWithdraw({
          variables: {
            data: {
              amount: Number.parseInt(amountRescue.replace(/\D/g, '')),
            },
          },
        });
        setIsSubmitting(false);
        handleCloseModal();
      }

      setModalOption(1);
      setOpenModal(true);
    } catch (err) {}
  };

  const handleClose = () => {
    setOpenModal(false);
    if (modalOption === 2) {
      setUpdateBank(true);
    }
  };

  const handlerModals = value => {
    switch (value) {
      case 1:
        return <WithdrawSuccess onClose={handleClose} />;
        break;
      case 2:
        return (
          <BankAccountForm
            onClose={handleClose}
            bankDetail={successGetClientBank}
            accountTypes={accountTypes}
            banks={banks}
          />
        );
        break;
      case 3:
        return <p>modal de error</p>;
        break;
      default:
        return <p>No es una opcion valida</p>;
    }
  };

  const handleChange = e => {
    const value = e.target.value;
    const validAmount = value.replace(/^0+|[^0-9]/g, '');
    if (validAmount.toString().length <= 8) {
      const format = validAmount;
      setAmountRescue(format);
    }
  };

  // const handleAmountRescue = (e) => {
  //   setValueSelect(e.target.value);
  //   if (e.target.value === "100") {
  //     setAmountRescue(balanceClient.balance.toString());
  //   } else {
  //     setAmountRescue("");
  //   }
  // };

  const handleAmountRescue = e => {
    const selectedValue = e.target.value;

    setValueSelect(selectedValue);

    if (selectedValue === '100' || balanceClient.balance === 500) {
      // Si el usuario selecciona retiro total o el saldo es igual a 500,
      // establece el monto de rescate en el saldo completo
      setAmountRescue(balanceClient.balance.toString());
    } else {
      // Si el usuario selecciona retiro parcial, deja el monto de rescate en blanco
      setAmountRescue('');
    }
  };

  useEffect(() => {
    doGetClientBank();
    setUpdateBank(false);
  }, [updateBank]);

  if (loadingClientBankDetail) {
    return <Spinner />;
  }
  if (errorClientBankDetail) {
    // error fetch modal
  }

  const bankDetail = {
    bank: successGetClientBank?.bank?.bank_name,
    accountNumber: successGetClientBank?.account_number,
    bankType: successGetClientBank?.bankType.bank_type_name,
  };
  return (
    <>
      <ModalActions open={openModal}>{handlerModals(modalOption)}</ModalActions>
      {modalOpen && (
        <ModalWithdrawal
          handleCloseModal={handleCloseModal}
          amountRescue={amountRescue}
          clientBankDetail={bankDetail}
          handleSubmit={handleSubmit}
        />
      )}

      {modalOpenBank && (
        <ModalEditBank
          handleCloseModal={handleCloseModalBank}
          bankDetail={clientBankDetail.getUserBankDetail}
          accountTypes={accountTypes}
          banks={banks}
        />
      )}

      <Stack
        sx={{
          width: '90%',
          margin: '0 auto',
          alignItems: 'center',
          mb: '25px',
        }}>
        <RescueContentContent
          width={{ md: '50%', sx: '80%' }}
          height={{ md: 'auto', sx: '400' }}
          minWidth={{ md: '740px', sx: '' }}
          mb={9}>
          <SelectContent>
            <TitleSectionRescue>¿Cuánto deseas retirar?</TitleSectionRescue>
            <SectionSelectRescue>
              <SelectRescueWithdrawal
                balanceClient={balanceClient}
                valueSelect={valueSelect}
                amountRescue={amountRescue}
                handleAmountRescue={handleAmountRescue}
                handleChange={handleChange}
                clientBankDetail={clientBankDetail}
                setModalOption={setModalOption}
                setOpenModal={setOpenModal}
                handleOpenModalBank={handleOpenModalBank}
                handleOpenModal={handleOpenModal}
                isSubmitting={isSubmitting}
              />
            </SectionSelectRescue>
          </SelectContent>
        </RescueContentContent>
      </Stack>
    </>
  );
};

export default BodyWhithdrawal;
