import { Check } from '@mui/icons-material';
import { Avatar, Grid, Stack, Typography, alpha } from '@mui/material';
import React, { useEffect } from 'react';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ButtonsPrimary } from '../ComponentApp/buttoms/ButtonsXcala';

export const CompleteOmboardingTreeForeign = () => {
  const navigate = useNavigate();

  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: 'stepper Validating your identity',
        path: '/completeregistration',
        link: {
          url: '/completeregistration',
          title: 'stepper Validating your identity',
        },
      },
    });
  }, []);
  return (
    <>
      <Grid container maxWidth='sm' spacing={2}>
        <Grid item md={12} className='stepper-grid-registration'>
          <Typography variant='h6' className='blue_text fw-600 font-lg-24'>
            Confirmación de Onboarding para extranjero
          </Typography>
        </Grid>
      </Grid>
      <Stack spacing={2}>
        <Stack
          direction={{ md: 'row', xs: 'column' }}
          alignItems={'center'}
          spacing={{ md: 2, xs: 3 }}>
          <Avatar
            sx={{
              backgroundColor: theme => alpha(theme.palette.success.main, 0.08),
              color: 'success.main',
              height: '40px',
              width: '40px',
            }}>
            <Check sx={{ fontSize: '40px' }} />
          </Avatar>

          <Typography variant='body1' color='GrayText' textAlign={'justify'} mt={2} mb={2}>
            El equipo de de soporte se pondrá en contacto contigo en las proximas 48 horas para
            verificar tus datos y completar tu registro.
          </Typography>
        </Stack>
        <Stack
          width={'100%'}
          sx={{
            justifyContent: 'center',
            alignItems: 'center',
          }}>
          <Stack width={{ md: '40%', xs: '100%' }}>
            <ButtonsPrimary
              name='Continuar'
              // disabled={disabled}
              // onClick={secondStep}
              onClick={() => navigate('/')}
              fullWidth
            />
          </Stack>
        </Stack>
      </Stack>
    </>
  );
};
