import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  BtnContainer,
  IconsWarnning,
  ModalContainer,
  MsnAlert,
  MuntCashContiner,
  MuntContainer,
  MuntContext,
  MuntContextRescue,
  ParrafoAlert,
  TextImportante,
  TitleAlert,
  ValueMunt,
  ValueMuntRescue,
  WarningAmber,
} from '../../products/rescueFund/ReceivedRescueFunds';
import { RescueContentContent } from './RescueComponent';
import { ButtonsPrimary } from '../../ComponentApp/buttoms';

const ReceivedRescuePortfolio = ({
  responseErrorRescue = 'error',
  cuotas = '2555',
  rescueAmount = '',
  days = '12',
  date = '12',
  investmentSelected = '454',
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  return (
    <>
      <Stack
        sx={{
          width: '100%',
          maxWidth: '1200px',
          margin: '0 auto',
          alignItems: 'center',
          mb: '25px',
        }}>
        <RescueContentContent
          width={{ md: '100%', sx: '80%' }}
          height={{ md: 'auto', sx: 'auto' }}
          minWidth={{ md: '740px', sx: '' }}
          mb={9}>
          <ModalContainer>
            <Stack direction={'row'} alignItems={'center'} alignSelf={'center'}>
              <Stack width={'70px'} height={'70px'}>
                <img
                  src='/assets/images/sucess-gif.gif'
                  alt='success'
                  style={{ width: '100%', height: '100%', display: 'block' }}
                />
              </Stack>
              <Typography
                sx={{
                  color: theme.palette.secondary.main,
                  fontSize: theme.typography.titleText.fontSize,
                  fontWeight: theme.typography.button.fontWeight,
                }}>
                ¡Hemos recibido tu solicitud!
              </Typography>
            </Stack>

            <MuntCashContiner>
              <MuntContainer>
                <MuntContextRescue
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    fontWeight: theme.typography.littleText.fontWeight,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                  }}>
                  Monto solicitado
                </MuntContextRescue>
                <ValueMuntRescue
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    fontWeight: theme.typography.button.fontWeight,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                  }}>
                  $ {rescueAmount}
                </ValueMuntRescue>
              </MuntContainer>

              {/* <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    fontSize: "clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)",
                  }}
                >
                  Equivalente en cuotas:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: "clamp(0.85rem, 0.0313rem + 1.5vw, 1.1rem)",
                  }}
                >
                  {cuotas}
                </ValueMunt>
              </MuntContainer> */}
            </MuntCashContiner>

            <MuntCashContiner sx={{ background: theme.palette.white.main }}>
              <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    fontWeight: theme.typography.littleText.fontWeight,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                    color: theme.palette.primary.dark,
                  }}>
                  Plazo de rescate:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                  }}>
                  {days}
                </ValueMunt>
              </MuntContainer>
              <MuntContainer sx={{ marginTop: 2 }}>
                <MuntContext
                  sx={{
                    color: theme.palette.primary.dark,
                    fontSize: theme.typography.mediumText.fontSize,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                    fontWeight: theme.typography.littleText.fontWeight,
                  }}>
                  Fecha y hora de solicitud:
                </MuntContext>
                <ValueMunt
                  sx={{
                    fontSize: theme.typography.mediumText.fontSize,
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.customButton.fontSize,
                    },
                  }}>
                  {date}
                </ValueMunt>
              </MuntContainer>
            </MuntCashContiner>

            <MsnAlert sx={{ marginTop: 3, marginBottom: 3 }}>
              <TitleAlert>
                <WarningAmber>
                  <IconsWarnning />
                </WarningAmber>
                <TextImportante>Importante</TextImportante>
              </TitleAlert>
              <ParrafoAlert
                sx={{
                  width: '100%',
                  margin: '0 auto',
                }}>
                Te informaremos por correo cuando el rescate esté disponible en tu caja.
              </ParrafoAlert>
            </MsnAlert>
            <BtnContainer
              gap={2}
              mt={50}
              sx={{
                flexDirection: { xs: 'column', md: 'row' },
              }}>
              <ButtonsPrimary
                name='Volver al inicio'
                disableElevation
                onClick={() => navigate('/myprofilerisk')}
                sx={{ md: '55%' }}
              />
            </BtnContainer>
          </ModalContainer>
        </RescueContentContent>
      </Stack>
    </>
  );
};

export default ReceivedRescuePortfolio;
