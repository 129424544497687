import {
  Box,
  Button,
  ButtonGroup,
  Card,
  CardContent,
  CardHeader,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useModal from '../../hooks/custom/useModal';
import { useGetInvestedAndMarketValue } from '../../hooks/useGetInvestedAndMarketValue';
import { onRefresh } from '../../utils/refreshPage';
import Spinner from '../Spinner';
import ModalErrorFetch from '../UI/ModalErrorFetch';
import ChartsDashboard from './ChartsDashboard';
import { ErrorBoundaryXcala } from '../ErrorBoundaryXcala';

const CardChartsDashboard = () => {
  const [startDate, setStartDate] = useState('01/01/2000');
  const { isShowing, toggle } = useModal();
  const [active, setActive] = useState(200);
  const [balanceMarketValue, setBalanceMarketValue] = useState();
  const theme = useTheme();
  const {
    data: dataInvestedAndMarketValue,
    loading: loadingDataInvestedAndMarketValue,
    error: errorDataInvestedAndMarketValue,
  } = useGetInvestedAndMarketValue({
    data: {
      startDate,
    },
  });
  const handleChartDataByMonths = (months, chartData) => {
    const copyBalanceMarketValue = [...chartData];
    setBalanceMarketValue(copyBalanceMarketValue.slice(-months));
  };
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (dataInvestedAndMarketValue) {
      handleChartDataByMonths(active, dataInvestedAndMarketValue?.getInvestedAndMarketValue);
    }
  }, [loadingDataInvestedAndMarketValue, active]);

  /*   if (errorDataInvestedAndMarketValue) {
    return (
      <ModalErrorFetch
        message="Lo sentimos, algo no salio bien acá...."
        error={errorDataInvestedAndMarketValue}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  } */

  return (
    <Card
      sx={{
        width: '100%',
        height: '100%',
        boxShadow: 'none',
        padding: '8px',
      }}>
      <ErrorBoundaryXcala>
        <CardHeader
          title={
            <Typography
              fontSize={theme.typography.title.fontSize}
              color={theme.palette.primary.dark}>
              Evolución de la Inversión
            </Typography>
          }
        />

        <CardContent>
          <Box
            display='flex'
            flexDirection={{ xs: 'column', md: 'row' }}
            justifyContent='space-between'
            width='70%'>
            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='center'
              gap={1}>
              <Box
                style={{
                  width: '7px',
                  height: '7px',
                  display: 'inline',
                  borderRadius: '100%',
                  backgroundColor: theme.palette.secondary.main,
                }}
              />
              <Typography
                sx={{
                  color: theme.palette.primary.dark,
                  fontWeight: theme.typography.h1.fontWeight,
                  fontSize: theme.typography.fontSize,
                }}>
                Balance
              </Typography>
            </Box>

            <Box
              display='flex'
              flexDirection='row'
              justifyContent='flex-start'
              alignItems='center'
              gap={1}>
              <Box
                style={{
                  width: '7px',
                  height: '7px',
                  display: 'inline',
                  borderRadius: '100%',
                  backgroundColor: theme.palette.violet.main,
                }}
              />
              <Typography
                sx={{
                  color: theme.palette.primary.dark,
                  fontWeight: theme.typography.h1.fontWeight,
                  fontSize: theme.typography.fontSize,
                }}>
                Depositado
              </Typography>
            </Box>
          </Box>
        </CardContent>
        <CardContent
          sx={{
            padding: '4px',
          }}>
          <Box display='flex' flexDirection='row' sx={{ ml: 1 }}>
            <ButtonGroup
              sx={{
                width: '100%',
                borderColor: theme.palette.primary.main,
                '&:hover': {
                  borderColor: theme.palette.primary.main,
                },
              }}
              variant='outlined'
              aria-label='outlined primary button group'
              size='small'>
              <Button
                onClick={() => setActive(200)}
                style={{
                  backgroundColor: active === 200 ? theme.palette.primary.main : 'initial',
                  color: active === 200 ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  borderRadius: '4px',
                  textTransform: 'none',
                }}>
                todo
              </Button>
              <Button
                onClick={() => setActive(12)}
                style={{
                  backgroundColor: active === 12 ? theme.palette.primary.main : 'initial',
                  color: active === 12 ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  textTransform: 'none',
                }}>
                12 meses
              </Button>
              <Button
                onClick={() => setActive(3)}
                style={{
                  backgroundColor: active === 3 ? theme.palette.primary.main : 'initial',
                  color: active === 3 ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  textTransform: 'none',
                }}>
                3 meses
              </Button>
              <Button
                onClick={() => setActive(1)}
                style={{
                  backgroundColor: active === 1 ? theme.palette.primary.main : 'initial',
                  color: active === 1 ? theme.palette.white.main : 'initial',
                }}
                sx={{
                  borderRadius: '4px',
                  textTransform: 'none',
                }}>
                1 mes
              </Button>
            </ButtonGroup>
          </Box>
        </CardContent>

        <ChartsDashboard
          data={balanceMarketValue}
          errorDataInvestedAndMarketValue={errorDataInvestedAndMarketValue}
          loadingDataInvestedAndMarketValue={loadingDataInvestedAndMarketValue}
          serieName={'Saldo'}
          showLabels={true}
        />
      </ErrorBoundaryXcala>
    </Card>
  );
};
export default CardChartsDashboard;
