import { gql, useQuery } from '@apollo/client';

const GET_MODELS_PORTFOLIO_BY_ID = gql`
  query GetClientInvestmentsPortfoliosById($accountId: String!) {
    getClientInvestmentsPortfoliosById(accountId: $accountId) {
      id
      name
      fileAs
      security
      fmtUnitBalance
      unitBalance
      fmtMarketValue
      marketValue
      fmtCapitalAppreciation
      capitalAppreciation
      fmtDividends
      dividends
      fmtBuy
      buy
      fmtSell
      sell
      fmtProfitPercent
      profitPercent
      currency
      unitValue
    }
  }
`;

export const useGetClientInvestmentPortfoliosById = variables => {
  return useQuery(GET_MODELS_PORTFOLIO_BY_ID, { variables }, { fetchPolicy: 'no-cache' });
};
