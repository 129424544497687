import { gql, useLazyQuery } from '@apollo/client';

const GET_SECURITY_DIVIDENDS_QUERY = gql`
  query getSecurityDividends($securityId: String!) {
    getSecurityDividends(securityId: $securityId) {
      dividendId
      payDate
      provisionDate
      quotaFactor
    }
  }
`;
{
  /*Trae data histórica de dividendos, cada uno con su security*/
}
export default () => useLazyQuery(GET_SECURITY_DIVIDENDS_QUERY, { fetchPolicy: 'no-cache' });
