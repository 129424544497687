import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  investments: null,
  loadingGetInvestments: true,
  investmentSelected: null,
  rescueType: 'Rescatartodo',
  investmentData: null,
  investmentValorCuota: null,
};

export const investmentsSlice = createSlice({
  name: 'investments',
  initialState,
  reducers: {
    setMyInvestments: (state, { payload }) => {
      state.investments = payload;
    },
    setMyInvestmentSelected: (state, { payload }) => {
      state.investmentSelected = payload;
    },
    setMyInvestmentData: (state, { payload }) => {
      state.investmentData = payload;
    },
    setRescueType: (state, { payload }) => {
      state.rescueType = payload;
    },
    initGetInvestments: state => {
      state.loadingGetInvestments = true;
    },
    finishGetInvestments: state => {
      state.loadingGetInvestments = false;
    },
    setInvestmentValorCuota: (state, { payload }) => {
      state.investmentValorCuota = payload;
    },
  },
});

export const {
  setMyInvestments,
  setMyInvestmentSelected,
  setMyInvestmentData,
  setRescueType,
  initGetInvestments,
  finishGetInvestments,
  setInvestmentValorCuota,
} = investmentsSlice.actions;
export default investmentsSlice.reducer;
