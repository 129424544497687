import { Stack, styled } from '@mui/material';

export const SectionHeader = styled(Stack)({
  flexDirection: { lg: 'row', md: 'column' },
  width: '100%',
  height: '100%',
  position: 'relative',
  justifyContent: { md: 'flex-start', xs: 'center' },
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  isolation: 'isolate',
});
