import React, { useState } from 'react';
import CardsContainerRescueFund from './CardsContainerRescueFund';
import CardsResultRescueFund from './CardsResultRescueFund';

const RenderRescueFund = ({
  rescueType,
  radiobtnChange,
  investmentSelected,
  amount2,
  addamount2,
  mountEqual,
  cuotas,
  disabled,
  handleGoToConfirm,
  showCardsResult,
  rescueAmount,
}) => {
  return (
    <>
      {showCardsResult ? (
        <CardsResultRescueFund
          rescueAmount={rescueAmount}
          cuotas={cuotas}
          handleGoToConfirm={handleGoToConfirm}
          investmentSelected={investmentSelected}
        />
      ) : (
        <CardsContainerRescueFund
          rescueType={rescueType}
          radiobtnChange={radiobtnChange}
          investmentSelected={investmentSelected}
          amount2={amount2}
          addamount2={addamount2}
          mountEqual={mountEqual}
          cuotas={cuotas}
          disabled={disabled}
          handleGoToConfirm={handleGoToConfirm}
        />
      )}
    </>
  );
};

export default RenderRescueFund;
