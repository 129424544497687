export const LogoXcalaWhite = ({ handleClickHome }) => {
  return (
    <>
      <svg
        onClick={handleClickHome}
        cursor='pointer'
        width='122'
        height='53'
        viewBox='0 0 123 53'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <g id='Xlogo'>
          <g id='X'>
            <path id='path44' d='M24.1338 35.2529H18.332V29.4511H24.1338V35.2529Z' fill='white' />
            <path id='path46' d='M53.0885 11.9043H41.5742V0.390012H53.0885V11.9043Z' fill='white' />
            <path id='path48' d='M10.5723 29.4434H18.3363V21.6794H10.5723V29.4434Z' fill='white' />
            <path id='path50' d='M24.1309 29.4434H31.8949V21.6794H24.1309V29.4434Z' fill='white' />
            <path id='path52' d='M31.8995 35.2423H24.1289V43.0127H31.8995V35.2423Z' fill='white' />
            <path id='path54' d='M41.5967 43.0029H31.8945V52.7051H41.5967V43.0029Z' fill='white' />
            <path id='path56' d='M10.582 35.2608H18.3466V43.0254H10.582V35.2608Z' fill='white' />
            <path id='path58' d='M0.890625 42.996H10.5792V52.6846H0.890625V42.996Z' fill='white' />
            <path id='path60' d='M0.890625 11.91H10.5792V21.5986H0.890625V11.91Z' fill='white' />
            <path id='path62' d='M31.9062 11.91H41.5949V21.5986H31.9062V11.91Z' fill='white' />
          </g>
          <g id='CALA'>
            <g id='g30'>
              <path
                id='path32'
                d='M52.0702 21.5308C57.2148 21.5308 60.5681 24.0841 61.7494 28.5046H57.0624C56.3383 26.4848 54.6615 25.1892 52.0702 25.1892C48.5645 25.1892 46.2399 27.7805 46.2399 32.3534C46.2399 36.9643 48.5645 39.5556 52.0702 39.5556C54.6615 39.5556 56.262 38.4124 57.0624 36.2401H61.7494C60.5681 40.3558 57.2148 43.2139 52.0702 43.2139C46.0494 43.2139 41.7812 38.9458 41.7812 32.3534C41.7812 25.7989 46.0494 21.5308 52.0702 21.5308Z'
                fill='white'
              />
            </g>
            <g id='g34'>
              <path
                id='path36'
                d='M76.8656 25.3035C73.6264 25.3035 70.5014 27.7422 70.5014 32.2771C70.5014 36.8117 73.6264 39.4413 76.8656 39.4413C80.1428 39.4413 83.2294 36.888 83.2294 32.3534C83.2294 27.8566 80.1428 25.3035 76.8656 25.3035ZM75.9508 21.5308C79.4949 21.5308 81.9337 23.2076 83.2294 24.9224V21.8739H87.6118V43.1472H83.2294V39.7461C81.8958 41.537 79.3806 43.2139 75.8747 43.2139C70.4253 43.2139 66.043 38.7552 66.043 32.2771C66.043 25.7989 70.4253 21.5308 75.9508 21.5308'
                fill='white'
              />
            </g>
            <path id='path38' d='M93.1309 13.4627H97.4749V43.1475H93.1309V13.4627Z' fill='white' />
            <g id='g40'>
              <path
                id='path42'
                d='M111.364 25.3035C108.124 25.3035 104.999 27.7422 104.999 32.2771C104.999 36.8117 108.124 39.4413 111.364 39.4413C114.641 39.4413 117.727 36.888 117.727 32.3534C117.727 27.8566 114.641 25.3035 111.364 25.3035ZM110.449 21.5308C113.993 21.5308 116.432 23.2076 117.727 24.9224V21.8739H122.11V43.1472H117.727V39.7461C116.394 41.537 113.879 43.2139 110.373 43.2139C104.923 43.2139 100.541 38.7552 100.541 32.2771C100.541 25.7989 104.923 21.5308 110.449 21.5308'
                fill='white'
              />
            </g>
          </g>
        </g>
      </svg>
    </>
  );
};
