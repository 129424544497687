import { Button, Stack, Typography, styled, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

const ItemListButton = styled(Button)(({ theme }) => ({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '2px 8px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
  textTransform: 'none',
  color: 'white',
  backgroundColor: theme.palette.transparent.main,
  '&:hover': {
    background: theme.palette.primary.light,
  },
  gap: '10px',
}));

const BodyItems = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '10px 20px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
const IconMenu = styled('div')(({ theme }) => ({
  height: '6px',
  width: '6px',
  margin: '0px',
  background: theme.palette.white.main,
  borderRadius: '50%',
}));
const Notification = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '0px 0px 0px 8px',
  height: '18px',
  width: '23px',
});

const Content = styled(Stack)({
  borderRadius: '4px',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '0px 8px',
  boxSizing: 'border-box',
  height: '18px',
  margin: '0px',
  width: '23px',
});
const Label = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.white.main,
  fontWeight: theme.typography.h1.fontWeight,
  fontSize: theme.typography.smallText.fontSize,
  letterSpacing: '0px',
  textDecoration: 'none',
  lineHeight: '16px',
  textTransform: 'none',
  margin: '0px',
}));

export const ItemListBtn = ({ btnNameSub, notificationValue, url }) => {
  const theme = useTheme();
  const navigate = useNavigate();

  const navigateProducts = () => {
    navigate(`${url}`);
  };

  return (
    <ItemListButton
      fullWidth
      sx={{
        height: '100%',
        with: '100%',
        textTransform: 'none',
        fontSize: theme.typography.smallText.fontSize,
        backgroundColor: theme.palette.transparent.main,
      }}>
      <BodyItems onClick={navigateProducts}>
        <Stack
          sx={{
            flexDirection: 'row',
            justifyContent: 'end',
            alignItems: 'center',
            gap: '8px',
          }}>
          <IconMenu />
          <Typography
            sx={{
              color: theme.palette.white.main,
              fontSize: theme.typography.customButton.fontSize,
            }}>
            {btnNameSub}
          </Typography>
        </Stack>
        {notificationValue && (
          <Notification>
            <Content backgroundColor={'#5AC4B1'}>
              <Label>{notificationValue}</Label>
            </Content>
          </Notification>
        )}
      </BodyItems>
    </ItemListButton>
  );
};
