import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import HeaderRescuePortfolio from '../../../components/portfolio/rescuePortfolio/HeaderRescuePortfolio';
import RenderRescueFund from '../../../components/products/rescueFund/RenderRescueFund';
import useGetPriceRange from '../../../hooks/useGetPriceRange';
import {
  setInvestmentValorCuota,
  setMyInvestmentData,
  setRescueType,
} from '../../../redux/investments';
import { AddCurrency } from '../../../utils/addCostEffectivemess';
import { numberWithPoint } from '../../../utils/numbers';
import {
  getCurrentDateFormatYYYYMMDD,
  getFirstElement,
  setMonthsDateFormatYYMMDD,
} from '../../../utils/utils';

const RescueFunds = () => {
  const [amount2, setAmount2] = useState('');
  const [cuotas, setCuotas] = useState(0);
  const [rescueAmount, setRescueAmount] = useState(0);
  const [showCardsResult, setShowCardsResult] = useState(false);

  const { investmentSelected, rescueType, investmentValorCuota } = useSelector(
    state => state.investments,
  );
  const dispatch = useDispatch();

  const [
    doGetPriceRange,
    { data, error: errorGetPriceRange, loading: loadingGetPriceRange, refetch },
  ] = useGetPriceRange();
  const successGetPriceRange = data?.getPriceRange || [];

  const addamount2 = event => {
    const value = event.target.value;
    const validAmount = value.replace(/\D/g, '');

    if (validAmount.toString().length <= 8) {
      const format = numberWithPoint(validAmount);
      setAmount2(format);
    }
  };
  const radiobtnChange = event => {
    dispatch(setRescueType(event.target.value));
  };

  let mountEqual = investmentSelected?.marketValue.toFixed(0) === amount2.replace(/\D/g, '');

  const handleGoToConfirm = () => {
    let recueAmountValue = 0.0;
    let cuotasValue = 0.0;

    if (rescueType === 'Rescatartodo') {
      recueAmountValue = Number.parseFloat(investmentSelected.marketValue).toFixed(0);
      cuotasValue =
        Number.parseFloat(investmentSelected.marketValue).toFixed(4) /
        investmentValorCuota.ClosePrice;
    }

    if (rescueType === 'Rescatarsólounaparte') {
      const amount2Aux = amount2.replace(/\D/g, '');
      recueAmountValue = Number.parseInt(amount2Aux, 10);
      cuotasValue = recueAmountValue / investmentValorCuota.ClosePrice;
    }
    dispatch(setMyInvestmentData({ recueAmountValue, cuotasValue }));
    setCuotas(numberWithPoint(Number.parseFloat(cuotasValue).toFixed(4)));
    setRescueAmount(recueAmountValue);
    setShowCardsResult(!showCardsResult);
  };

  const getDisabled = () => {
    if (rescueType === 'Rescatartodo') {
      const recueAmount = Number.parseInt(investmentSelected.marketValue, 10);
      return recueAmount <= 0;
    } else {
      const amount2Aux = amount2.replace(/\D/g, '');
      const recueAmount = Number.parseInt(amount2Aux, 10);

      return (
        Number.parseInt(amount2.replace(/\D/g, '')) < 10000 ||
        recueAmount > Number.parseFloat(investmentSelected.marketValue).toFixed(0) ||
        amount2 === '' ||
        Number.parseFloat(investmentSelected.marketValue).toFixed(0) <
          Number.parseInt(amount2.replace(/\D/g, '')) ||
        amount2[0] === '0'
      );
    }
  };

  const custom = JSON.parse(investmentSelected.custom);
  const risk = custom.preferences.riesgo.description;
  const motivations = getFirstElement(custom.preferences.motivaciones);
  const interests = getFirstElement(custom.preferences.intereses);
  const motivationsId = custom.preferences.motivaciones[0].id;
  const interestsId = custom.preferences.intereses[0].id;
  const disabled = getDisabled();

  useEffect(() => {
    if (successGetPriceRange && !loadingGetPriceRange) {
      const valoresCuota = successGetPriceRange.sort(
        (a, b) => new Date(b.EffectiveDate) - new Date(a.EffectiveDate),
      );
      dispatch(setInvestmentValorCuota(valoresCuota[0]));
    }
  }, [successGetPriceRange, loadingGetPriceRange]);

  useEffect(() => {
    dispatch(setInvestmentValorCuota(null));
    doGetPriceRange({
      variables: {
        data: {
          securityId: investmentSelected.id,
          from: setMonthsDateFormatYYMMDD(-12),
          to: getCurrentDateFormatYYYYMMDD(),
        },
      },
    });
  }, []);

  useEffect(() => {
    if (investmentValorCuota) {
      const value =
        rescueType === 'Rescatartodo' ? investmentSelected.marketValue : amount2.replace(/\D/g, '');
      const cuotasValue = value / investmentValorCuota.ClosePrice;
      setCuotas(numberWithPoint(Number.parseFloat(cuotasValue).toFixed(4)));
    }
  }, [
    investmentValorCuota,
    rescueType,
    amount2,
    investmentSelected.marketValue,
    rescueType,
    rescueAmount,
  ]);

  if (loadingGetPriceRange) {
    return <Spinner />;
  }
  if (errorGetPriceRange) {
  }

  const fileAs = {
    currency: AddCurrency(investmentSelected.name),
    fileAs: 'FileAsName',
    fileAsComplete: investmentSelected.name.slice(4),
    risk: risk,
    motivation: motivations.split('-')[0],
    motivationId: motivationsId,
    interests: interests,
    interestsId: interestsId,
    products: true,
    profit: 'profit',
  };

  return (
    <>
      <HeaderRescuePortfolio
        amountPortfolio={Number.parseFloat(investmentSelected.marketValue).toFixed(0)}
        portfolioFileAsComplete={fileAs}
        typeProduct={'fondo'}
        marketValue={numberWithPoint(Number.parseFloat(investmentSelected.marketValue).toFixed(0))}
      />

      <RenderRescueFund
        rescueType={rescueType}
        radiobtnChange={radiobtnChange}
        investmentSelected={investmentSelected}
        amount2={amount2}
        addamount2={addamount2}
        mountEqual={mountEqual}
        cuotas={cuotas}
        rescueAmount={rescueAmount}
        disabled={disabled}
        handleGoToConfirm={handleGoToConfirm}
        showCardsResult={showCardsResult}
      />
    </>
  );
};

export default RescueFunds;
