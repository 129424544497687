import { Stack, styled } from '@mui/material';

const WrapperContentApp = ({ $minHeigth, children, fileAs }) => {
  const ContentApp = styled(Stack)({
    position: 'relative',
    flexDirection: 'row',
    margin: '0 auto',
    width: '80%',
    maxWidth: '1380px',
    height: '100%',
    minHeight: $minHeigth,
    alignItems: 'center',
    paddingBottom: { md: '', xs: '3rem' },
  });

  return (
    <ContentApp
      justifyContent={fileAs.profit ? { md: 'space-evenly', xs: 'center' } : 'start'}
      padding={'40px 0'}
      sx={{ flexDirection: { md: 'row', xs: 'column' } }}>
      {children}
    </ContentApp>
  );
};

export default WrapperContentApp;
