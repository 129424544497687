import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";
import { Stack, Typography, useTheme } from "@mui/material";
import React from "react";

const Helpertext = ({ message, errormessage }) => {
  const theme = useTheme();
  if (errormessage) {
    return (
      <Stack
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"center"}
        sx={{ color: theme.palette.contrastThreshold.main }}
      >
        <CloseIcon sx={{ fontSize: theme.typography.button.fontSize }} />
        <Typography
          sx={{
            fontSize: theme.typography.customButton.fontSize,
            color: "inherit",
          }}
        >
          {errormessage}
        </Typography>
      </Stack>
    );
  }
  if (message) {
    return (
      <Stack
        justifyContent={"flex-start"}
        direction={"row"}
        alignItems={"center"}
        sx={{ color: theme.palette.secondary.main }}
      >
        <DoneIcon sx={{ fontSize: theme.typography.button.fontSize }} />
        <Typography
          sx={{
            fontSize: theme.typography.customButton.fontSize,
            color: "inherit",
          }}
        >
          {message}
        </Typography>
      </Stack>
    );
  }
  return null;
};

export default Helpertext;
