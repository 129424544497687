export const contentConservador =
  'Prefieres tener inversiones con un nivel de riesgo menor, es decir invertir el 100% de tu dinero en instrumentos más seguros aunque de menor rendimiento.';
export const contentModerado =
  'Eres un inversionista cuidadoso con tus decisiones, pero estás dispuesto a tolerar un riesgo moderado para aumentar tus ganancias. Prefieres mantener un balance entre rentabilidad y seguridad.';
export const contentAgresivo =
  'Buscas la mayor rentabilidad posible, por lo que estás dispuesto a asumir el riesgo que sea necesario. Cuentas con ingresos que te permiten contar con un excedente para invertir.';

export const countryPhones = [
  {
    // name: "Chile",
    name: '+56',
    value: '+56',
    // code: "CL",
  },
  {
    // name: "United States",
    name: '+1',
    value: '+1',
    // code: "US",
  },
];
