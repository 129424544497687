import { gql, useLazyQuery } from '@apollo/client';

const GET_PROFITABILITY_QUERY = gql`
    query getProfitability($data: profitability!) {
        getProfitability(data: $data){
            Calculator1_FormattedResult
            Calculator1_Result
        }
    }
`;
{
  /*Retorna la rentabilidad del usuario*/
}
export default () => useLazyQuery(GET_PROFITABILITY_QUERY, { fetchPolicy: 'no-cache' });
