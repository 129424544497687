import { gql, useMutation } from '@apollo/client';

const CREATE_BUY_TRANSACTION = gql`
mutation createBuyTransaction( $data : buyingInput!){
    createBuyTransaction (data : $data)
}`;
{
  /*Crea un buy cuando se hace una inversión*/
}
export const useCreateBuyTransaction = () => {
  return useMutation(CREATE_BUY_TRANSACTION);
};
