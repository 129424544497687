export const hideNumbers = dni => {
  if (typeof dni === 'string' && /^\d{2}\.\d{3}\.\d{3}-\d{1}$/.test(dni)) {
    const lastLetters = dni.slice(-3);

    const asterisk = '*'.repeat(dni.length - 3);

    return asterisk + lastLetters;
  } else {
    return null;
  }
};
