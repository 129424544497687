import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  data: [
    {
      id: 1,
      text: 'Clase de Activo',
      filters: [
        {
          id: 10,
          text: 'Retorno Absoluto',
          checked: false,
        },
        {
          id: 11,
          text: 'Sostenibilidad',
          checked: false,
        },
        {
          id: 12,
          text: 'Deuda Privada',
          checked: false,
        },
        {
          id: 13,
          text: 'Venture Capital/Startups',
          checked: false,
        },
        {
          id: 14,
          text: 'Capital Privado',
          checked: false,
        },
        {
          id: 15,
          text: 'Renta Inmobiliaria',
          checked: false,
        },
      ],
    },
    {
      id: 2,
      text: 'Motivaciones',
      filters: [
        {
          id: 1,
          text: 'Generar ingreso extra',
          checked: false,
        },
        {
          id: 2,
          text: 'Hacer crecer mi plata',
          checked: false,
        },
        {
          id: 3,
          text: 'Ganar con propósito',
          checked: false,
        },
      ],
    },
    {
      id: 3,
      text: 'Perfil de inversión',
      filters: [
        {
          id: 1,
          text: 'Conservador',
          checked: false,
        },
        {
          id: 2,
          text: 'Moderado',
          checked: false,
        },
        {
          id: 3,
          text: 'Agresivo',
          checked: false,
        },
      ],
    },
  ],
};

export const filterSlice = createSlice({
  name: 'filter',
  initialState,
  reducers: {
    updateCheck: (state, { payload }) => {
      state.data = state.data.map(item =>
        item.id === payload.mainId ? changeItem(item, payload.filterId, payload.value) : item,
      );
    },
  },
});

const changeItem = (mainFilter, filterId, value) => {
  mainFilter.filters = mainFilter.filters.map(item =>
    item.id === filterId ? { ...item, checked: value } : item,
  );
  return mainFilter;
};

export const { updateCheck } = filterSlice.actions;
export default filterSlice.reducer;
