import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  questions: null,
};

export const questionsSlice = createSlice({
  name: 'questions',
  initialState,
  reducers: {
    updateQuestions: (state, { payload }) => {
      state.questions = payload;
    },
  },
});

export const { updateQuestions } = questionsSlice.actions;
export default questionsSlice.reducer;
