import SettingsIcon from '@mui/icons-material/Settings';
import {
  Card,
  FormControl,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Stack,
  Typography,
  useTheme,
} from '@mui/material';
import React, { useState } from 'react';
import toast from 'react-hot-toast';
import useModal from '../../../hooks/custom/useModal';
import { useUpdateDividendOption } from '../../../hooks/useUpdateDividendOption';
import { onRefresh } from '../../../utils/refreshPage';
import { stringToBooleanInput } from '../../../utils/stringToBoolean';
import Spinner from '../../Spinner';
import { ButtonsPrimary } from '../../../components/ComponentApp/buttoms/ButtonsXcala';
import ModalErrorFetch from '../../UI/ModalErrorFetch';

const DividendDropDownProducts = ({ investment, statusFilter, handleClose, refetch }) => {
  const { isShowing, toggle } = useModal();
  const [valueRadio, setValueRadio] = useState(stringToBooleanInput(statusFilter[0].Value));
  const theme = useTheme();
  const handleChange = event => {
    let valueInput = event.target.value;
    setValueRadio(stringToBooleanInput(valueInput));
  };

  const onUpdateDividend = async () => {
    const data = {
      securityId: investment.id,
      selection: valueRadio,
    };

    try {
      const { data: responseUpdateDividend } = await setDividendOption({
        variables: {
          data: data,
        },
      });
      toast.success('Actualizado');
      refetch();
      handleClose();
    } catch (err) {}
  };

  const [setDividendOption, { loadingDividendOption, errorDividendOption }] =
    useUpdateDividendOption();

  if (loadingDividendOption) {
    return <Spinner />;
  }

  if (errorDividendOption) {
    return (
      <ModalErrorFetch
        message='Me temo que tendras que hacerlo de nuevo.... algo salio mal'
        error={errorDividendOption}
        open={!isShowing}
        onClose={toggle}
        refresh={onRefresh}
      />
    );
  }

  return (
    <Card sx={{ padding: 4 }}>
      <Grid item xs={12} style={{ paddingLeft: '5px' }}>
        <>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
              <Stack direction='row' alignItems='center' gap={1} sx={{ marginBottom: 2 }}>
                <SettingsIcon />
                <Typography
                  fontSize={theme.typography.title.fontSize}
                  fontWeight={theme.typography.fontWeightBold}
                  sx={{
                    [theme.breakpoints.down('sm')]: {
                      fontSize: theme.typography.mediumText.fontSize,
                    },
                  }}>
                  Pago de Dividendos
                </Typography>
              </Stack>

              {valueRadio === true ? (
                <Typography>
                  Este fondo genera dividendos que actualmente son reinvertidos en este fondo{' '}
                  <Typography sx={{ color: theme.palette.primary.main }} component='span'>
                    {investment.name}
                  </Typography>
                </Typography>
              ) : (
                <Typography>
                  Este fondo genera dividendos que actualmente son depositados en tu{' '}
                  <Typography sx={{ color: theme.palette.primary.main }} component='span'>
                    Caja Xcala.
                  </Typography>
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={9}>
              <FormControl>
                <RadioGroup
                  row
                  aria-labelledby='demo-row-radio-buttons-group-label'
                  name='row-radio-buttons-group'
                  value={valueRadio}
                  onChange={handleChange}>
                  <FormControlLabel
                    sx={{ color: theme.palette.secondary.main }}
                    value={true}
                    control={
                      <Radio
                        sx={{
                          color: theme.palette.secondary.main,
                          '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    }
                    label='Prefiero reinvertir en este fondo'
                  />
                  <FormControlLabel
                    sx={{ color: theme.palette.secondary.main }}
                    value={false}
                    control={
                      <Radio
                        sx={{
                          color: theme.palette.secondary.main,
                          '&.Mui-checked': {
                            color: theme.palette.secondary.main,
                          },
                        }}
                      />
                    }
                    label='Depositar dividendos en Caja'
                  />
                </RadioGroup>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={3}>
              <Stack>
                <ButtonsPrimary name='Confirmar' onClick={onUpdateDividend} />
              </Stack>
            </Grid>
          </Grid>
        </>
      </Grid>
    </Card>
  );
};

export default DividendDropDownProducts;
