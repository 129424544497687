import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, Input, InputLabel } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import { useState } from 'react';

const NewPasswordInput = ({ title, password, validatePassword }) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <FormControl sx={{ width: '100%', boxSizing: 'border-box' }} variant='outlined'>
      <InputLabel htmlFor={title}>{title}</InputLabel>
      <Input
        id={title}
        type={showPassword ? 'text' : 'password'}
        value={password}
        onChange={e => validatePassword(e.target.value)}
        endAdornment={
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        }
      />
    </FormControl>
  );
};

export default NewPasswordInput;
