import { Stack, styled } from '@mui/material';

export const CardsInfo = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-end',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px 0px 0px 13px',
  height: '72px',
  width: '280px',
});
