export const ImgComponent = ({ styles }) => {
  return (
    <svg
      height='clamp(6.3125rem, -12.8542rem + 81.7778vw, 17.8125rem)'
      viewBox='0 0 493 285'
      fill='none'
      style={{
        ...styles,
        position: 'absolute',
        bottom: 0,
        left: 0,
      }}
      xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M492.319 285C441.696 119.98 288.11 0 106.5 0C69.6365 0 33.9276 4.9434 0 14.2034V145.338C32.8189 131.785 68.786 124.307 106.5 124.307C218.327 124.307 314.799 190.053 359.37 285H492.319Z'
        fill='#F7DB52'
      />
    </svg>
  );
};
