import { gql, useMutation } from '@apollo/client';

const CREATE_LONG_OUT_TRANSACTION = gql`
  mutation Mutation($data: CreateLongOutInput!) {
    createLongOutTransaction(data: $data)
  }
`;

export const useCreateLonOutTransaction = () => {
  return useMutation(CREATE_LONG_OUT_TRANSACTION);
};
