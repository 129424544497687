import { gql, useMutation } from '@apollo/client';

export const CREATE_CLIENT_BANK = gql`
    mutation registerUserBank( $data: userBank! ) {
        registerUserBank (data: $data)
    }
`;

export const useCreateClientBank = () => {
  return useMutation(CREATE_CLIENT_BANK);
};
