import { createTheme } from '@mui/material';

export const theme = createTheme({
  palette: {
    primary: {
      dark: '#000000', //Negro
      main: '#1E22AA', //Azul xcala
      light: '#4449D8', //Azul claro
      blueLight: '#EFF6FC', //Azul casi blanco
      blueSky: '#94BFE1', //Azul cielo
      blueMid: '#5576D1', //Azul medio
      darkBlue: '#182849', //Azul oscuro
      textLightBlue: '#E7F3FF', //azul muy claro header
      //degradado del menú
      gradient:
        'linear-gradient(-175.2deg, rgba(112, 151, 217, 1) -36.92771575933705%, rgba(30, 34, 170, 1) 103.56996838617438%)', //Menú
      newGradient:
        'linear-gradient(106.49deg, rgba(112, 151, 217, 1) -29.020488665609555%, rgba(30, 34, 170, 1) 50.092131130721704%)',

      profileGradient:
        'linear-gradient(-101.95deg, rgba(30, 34, 170, 0.8) 0.27485124255511545%, rgba(88, 123, 200, 0.8) 38.15295076594133%, rgba(30, 34, 170, 0.8) 98.55316605257082%)',
      hoverGradient:
        'linear-gradient(-0.4200000000000017deg, rgba(242, 245, 248, 1) -63.40646376215654%, rgba(241, 244, 249, 0) 189.4310001607694%)',
    },
    secondary: {
      main: '#49A197', //turquesa
      text: '#5AC4B1', //turquesa claro
      light: '#EDFCF9FF', //turquesa muy claro
      lightIcon: '#D7FCF4',
      green: '#347856', //verde title modal avatar
    },
    tertiary: {
      main: '#E7F3FF', //color del logo HMC de la card
    },
    avatar: {
      border: '#2141AA', // Color del borde del avatar azul oscuro
      borderTransparent: 'transparent', // Borde transparente
    },
    white: {
      main: '#fff',
    },
    violet: {
      main: '#7F56D9',
    },
    textLight: {
      main: '#808080', //color gris de fuente
    },
    action: {
      hover: '#FFFFFF14', //color del hover
    },
    transparent: {
      main: 'transparent',
    },
    red: {
      main: '#D12D35',
    },
    customColors: {
      hmcPortafolioOptimo: '#8612FF',
      hmcDeudaPrivadaPesos: '#160BE3',
      hmcRentaGlobalPesos: '#0060FA',
      hmcGlobalRealEstateIncome: '#0BBAE3',
      hmcUSVentureOpportunities: '#0DFBBD',
      hmcDeudaPrivadaGlobal: '#D98DEB',
      hmcRentaGlobalUSD: '#FFAF9C',
    },
    warning: {
      main: '#FFF1F1',
    },
  },
  typography: {
    fontFamily: 'Poppins, Museo, sans-serif',
    fontSize: 16, // Tamaño de fuente base para el proyecto
    fontWeightRegular: 400, // Peso de fuente regular
    fontWeightBold: 600,

    museoRegular: {
      //Estilo nombre dashboard
      fontFamily: 'Museo-Regular',
      fontWeight: 400,
      fontSize: '1.75rem',
    },
    largeText: {
      fontSize: '2,75rem',
    },
    signInWelcome: {
      // Estilo para el texto de bienvenida
      fontFamily: 'Museo-Bold', // Asegúrate de que este nombre coincida con la fuente importada
      fontWeight: 500,
      fontSize: '2.5rem', // Tamaño de fuente para la bienvenida
    },
    h1: {
      //Saludo dashboard
      fontSize: '1.75rem',
      fontWeight: 600,
      fontFamily: 'Poppins',
    },
    h2: {
      fontSize: '2rem',
    },
    h3: {
      fontSize: '1.375rem',
    },
    h4: {
      // Title carousel
      fontSize: '2.428rem',
      fontWeight: 700,
    },
    headerText: {
      fontSize: '1.625rem',
    },
    profile: {
      fontSize: '1.6rem',
    },
    title: {
      //Card evolución de la Inversión
      fontSize: '1.5rem',
    },
    titleText: {
      fontSize: '1.3rem',
      fontWeight: 500,
    },
    availableBalance: {
      // Añade un estilo específico para AvailableBalance
      fontSize: '1.25rem',
    },
    description: {
      //descripción carousel
      fontSize: '1.2rem',
    },
    mediumText: {
      fontSize: '1.125rem',
    },
    button: {
      //Tamaño de fuente para todos los botones
      fontSize: '1rem',
      fontWeight: 600,
      textTransform: 'none',
    },
    returnText: {
      fontSize: '0.956rem',
    },
    customButton: {
      fontSize: '0.875rem',
    },
    smallText: {
      fontSize: '0.75rem',
      fontWeight: 300,
    },
    littleText: {
      fontSize: '0.625rem',
      fontWeight: 400,
    },
    microText: {
      fontSize: '0.5rem',
    },
  },
  shape: {
    borderRadius: 4, // Valor global de borderRadius
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: '#1E23AA',
          color: 'white',
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        //Mismos estilos para los tres botones principales de la app
        root: {
          borderRadius: '8px',
          textTransform: 'none',
          transition: 'all 0.5s ease-out',
          fontFamily: 'Poppins, Museo, sans-serif',
        },
        //Estilos independientes de los 3 botones principales de la app
        contained: {
          //primary
          backgroundColor: '#1E22AA',
          color: '#fff',
          '&:hover': {
            backgroundColor: '#00046d',
          },
        },
        outlined: {
          //secondary
          borderColor: '#1E22AA',
          color: '#1E22AA',
          '&:hover': {
            backgroundColor: '#5E62C4',
          },
        },
        text: {
          //tertiary
          color: '#1E22AA',
          backgroundColor: '#F4F4FB',
          '&:hover': {
            backgroundColor: '#DDDDF5',
          },
        },
      },
    },
  },
});

export const responsiveTheme = createTheme({
  ...theme,
  typography: {
    ...theme.typography,
    h1: {
      ...theme.typography.h1,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem', // Ajuste para pantallas pequeñas
      },
    },
    museoRegular: {
      ...theme.typography.museoRegular,
      [theme.breakpoints.down('sm')]: {
        fontSize: '1.5rem', // Ajuste para pantallas pequeñas
      },
    },
  },
});
