import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  portfolioSelected: null,
};

export const simulationPortfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {
    setPortfolioSelected: (state, { payload }) => {
      state.portfolioSelected = payload;
    },
  },
});

export const { setPortfolioSelected } = simulationPortfolioSlice.actions;
export default simulationPortfolioSlice.reducer;
