const arr = [
  {
    fileAs: 'crecimiento_usd_1',
    fileAsComplete: 'Portfolio Crecimiento USD',
    risk: 'agresivo',
    currency: 'USD',
  },
  {
    fileAs: 'equilibrio_usd_2',
    fileAsComplete: 'Portfolio Equilibrio USD',
    risk: 'moderado',
    currency: 'USD',
  },
  {
    fileAs: 'proteccion_usd_3',
    fileAsComplete: 'Portfolio Protección USD',
    risk: 'conservador',
    currency: 'USD',
  },
];

export const portfolioNameAndDetails = fileAs => {
  return arr.find(item => fileAs.includes(item.fileAs));
};
