import { Box, Button, Grid, MenuItem, Stack, TextField, Typography, styled } from '@mui/material';
import { wait } from '@testing-library/user-event/dist/utils';
import { useFormik } from 'formik';
import { useState } from 'react';
import toast from 'react-hot-toast';
import * as Yup from 'yup';
import { useCreateClientBank } from '../../../hooks/useCreateClientBank';
import {
  CheckBoxOutlineBlank,
  ContentCheckBox,
  TermsAndConditions,
  TextDeclaration,
} from '../../portfolio/rescuePortfolio/CardsResultRescue';

const ContainerModal = styled(Stack)({
  position: 'absolute',
  top: '0px',
  left: '0px',
  width: '100%',
  height: '100%',
  justifyContent: 'center',
  alignItems: 'center',
  zIndex: '999',
  backgroundColor: 'rgba(0, 0, 0, 0.8)',
  backdropFilter: 'blur(4px)',
  WebkitBackdropFilter: 'blur(4px)',
});
const MuntRescueModal = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  borderRadius: '16px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '34px',
  boxSizing: 'border-box',
}));

const ModalContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  flex: '1',
  margin: '0px',
  width: '100%',
});
const TitleContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  width: '100%',
});
const NameFundContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  //height: '75px',
  width: '100%',
});
const FundText = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  fontSize: '18px',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
const FundTitle = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
const MuntCashContiner = styled(Stack)({
  background:
    'linear-gradient(-125.87deg, rgba(68, 73, 216, 1) -4.395577123060462%, rgba(25, 28, 86, 1) 211.3228692253947%)',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '16px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '4px 0px 0px 0px',
  width: '100%',
});
const MuntContainer = styled(Stack)({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '17px',
  width: '100%',
});
const MuntContext = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(223, 224, 243, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
const ValueMunt = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(223, 224, 243, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '0px',
});
const TypeChange = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: '#D9D9D9',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  margin: '5px 0px 0px 0px',
});

const SubtitleModal = styled(Typography)(({ theme }) => ({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: theme.palette.primary.main,
  fontWeight: theme.typography.button.fontWeight,
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '8px 0px 0px 0px',
}));
const BankCantainer = styled('div')({
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'flex-start',
  padding: '0px',
  boxSizing: 'border-box',
  width: '100%',
  margin: '8px 0px 16px 0px',
});

const BtnContainer = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  justifyContent: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  margin: '8px 0px 0px 0px',
  width: '80%',
});

const MsnAlert = styled(Stack)({
  border: '1px solid rgb(229, 246, 253)',
  boxSizing: 'border-box',
  borderRadius: '8px',
  display: 'flex',
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '10px 8px',
  gap: '16px',
  backgroundColor: 'rgb(229, 246, 253)',
});

const TitleAlert = styled(Stack)({
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'row',
  justifyContent: 'flex-start',
  alignItems: 'center',
  padding: '0px',
  boxSizing: 'border-box',
  alignSelf: 'stretch',
  margin: '0px',
  height: '24px',
  width: '100%',
});

const WarningAmber = styled(Box)({
  width: '24px',
  height: '24px',
  margin: '0px',
});

const TextImportante = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgb(1, 67, 97)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '600',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  width: '115.5px',
  margin: '0px 0px 0px 16px',
});

const IconsWarnning = () => {
  return (
    // biome-ignore lint/a11y/noSvgWithoutTitle: <explanation>
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <g id='warning_amber'>
        <g id='Vector'>
          <path
            d='M12.3044 6.49L19.8344 19.5H4.77444L12.3044 6.49ZM12.3044 2.5L1.30444 21.5H23.3044L12.3044 2.5Z'
            fill='rgb(1, 67, 97)'
          />
          <path d='M13.3044 16.5H11.3044V18.5H13.3044V16.5Z' fill='rgb(1, 67, 97)' />
          <path d='M13.3044 10.5H11.3044V15.5H13.3044V10.5Z' fill='rgb(1, 67, 97)' />
        </g>
      </g>
    </svg>
  );
};
const ParrafoAlert = styled(Typography)({
  textAlign: 'left',
  whiteSpace: 'pre-wrap',
  fontSynthesis: 'none',
  color: 'rgba(24, 40, 73, 1)',
  fontStyle: 'normal',
  fontFamily: 'Poppins',
  fontWeight: '400',
  letterSpacing: '0px',
  textDecoration: 'none',
  textTransform: 'none',
  alignSelf: 'stretch',
  margin: '0px auto',
});

const ModalEditBank = ({
  handleCloseModal,
  clientBankDetail,
  handleSubmit,
  bankDetail,
  accountTypes,
  banks,
}) => {
  const [isChecked, setIsChecked] = useState(false);

  const [createClientBank, { loading: loadingCreateBank, error: errorCreateBank }] =
    useCreateClientBank();

  const formik = useFormik({
    initialValues: {
      bank: '' || bankDetail?.bank?.bank_id,
      typeAccount: '' || bankDetail?.bankType?.bank_type_id,
      accountNumber: '' || bankDetail?.account_number,
    },
    validationSchema: Yup.object({
      bank: Yup.string().required('Campo requerido'),
      typeAccount: Yup.string().required('Campo requerido'),
      accountNumber: Yup.string().required('Campo requerido'),
    }),
    validate: values => {
      const errors = {};
      if (values?.accountNumber.length > 20) {
        errors.accountNumber = 'El número de cuenta no puede tener más de 20 dígitos';
      }
      return errors;
    },
    onSubmit: async (values, helpers) => {
      try {
        const { data: createBank } = await createClientBank({
          variables: {
            data: {
              accountNumber: values.accountNumber,
              accountTypeId: Number.parseInt(values.typeAccount),
              bankId: Number.parseInt(values.bank),
            },
          },
        });
        handleCloseModal();
        await wait(300);
        toast.success(bankDetail ? 'Actualización exitosa' : 'Creación exitosa');
        helpers.setStatus({ success: true });
        helpers.setSubmitting(false);
      } catch (err) {
        helpers.setStatus({ success: false });
        helpers.setErrors({ submit: err.message });
        helpers.setSubmitting(false);
      }
    },
  });

  const handleAccountNumberChange = event => {
    const inputValue = event.target.value;
    const numericValue = inputValue.replace(/[^0-9]/g, '').slice(0, 20);
    formik.setFieldValue('accountNumber', numericValue);
  };

  const handleChangeCheckBox = e => {
    setIsChecked(e.target.checked);
  };

  if (loadingCreateBank) {
  }
  if (errorCreateBank) {
  }

  return (
    <ContainerModal>
      <MuntRescueModal
        sx={{
          width: {
            xs: 'clamp(90%, -23.0357rem + 160.9524vw, 50%)',
            sm: '50%',
          },
        }}>
        <ModalContainer>
          <SubtitleModal
            sx={{
              fontSize: 'clamp(1.1rem, 0.0313rem + 1.5vw, 2rem)',
            }}>
            Modificar datos bancarios
          </SubtitleModal>
          {/* <form onSubmit={formik.handleSubmit} width="100%"> */}

          <BankCantainer>
            <Grid container spacing={2} paddingLeft={0}>
              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.bank && formik.errors.bank)}
                  fullWidth
                  helperText={formik.touched.bank && formik.errors.bank}
                  label='Banco'
                  name='bank'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.bank}
                  variant='standard'>
                  {banks?.getBanks?.map(bank => (
                    <MenuItem key={bank.id} value={bank.id}>
                      {bank.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>

              <Grid item xs={12} md={6}>
                <TextField
                  error={Boolean(formik.touched.accountNumber && formik.errors.accountNumber)}
                  fullWidth
                  helperText={formik.touched.accountNumber && formik.errors.accountNumber}
                  label='N° de cuenta'
                  name='accountNumber'
                  onBlur={formik.handleBlur}
                  onChange={handleAccountNumberChange}
                  type='text'
                  value={formik.values.accountNumber}
                  variant='standard'
                />
              </Grid>
              <Grid item xs={12} md={12}>
                <TextField
                  error={Boolean(formik.touched.typeAccount && formik.errors.typeAccount)}
                  fullWidth
                  helperText={formik.touched.typeAccount && formik.errors.typeAccount}
                  label='Tipo de Cuenta'
                  name='typeAccount'
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  select
                  value={formik.values.typeAccount}
                  variant='standard'>
                  {accountTypes?.getBankAccountTypes?.map(type => (
                    <MenuItem key={type.id} value={type.id}>
                      {type.name}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </BankCantainer>

          <TermsAndConditions sx={{ margin: '0px 0px 10px 0px' }}>
            <ContentCheckBox gap={1}>
              <Stack marginTop={{ xs: '4px', md: '0px' }}>
                <CheckBoxOutlineBlank checked={isChecked} onChange={handleChangeCheckBox} />
              </Stack>
              <TextDeclaration>Aceptar</TextDeclaration>
            </ContentCheckBox>
          </TermsAndConditions>

          <BtnContainer
            gap={2}
            mt={50}
            sx={{
              flexDirection: { xs: 'column', md: 'row' },
            }}>
            <Button
              onClick={() => handleCloseModal()}
              sx={{
                width: { md: '45%' },
                padding: '16px',
                background: '#F4F4FB',
                color: 'white',
                textTransform: 'none',
              }}
              disableElevation>
              Cancelar
            </Button>
            <Button
              sx={{
                width: { md: '55%' },
                padding: '16px',
                textTransform: 'none',
              }}
              variant='contained'
              disableElevation
              disabled={!isChecked}
              onClick={formik.handleSubmit}>
              Actualizar
            </Button>
          </BtnContainer>

          {/* </form> */}
        </ModalContainer>
      </MuntRescueModal>
    </ContainerModal>
  );
};

export default ModalEditBank;
