import { Button, Stack, Typography, useTheme } from "@mui/material";
import React, { useEffect } from "react";
import TagManager from "react-gtm-module";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { ButtonsPrimary } from "../../components/ComponentApp/buttoms/ButtonsXcala";
import SignInSignUpLayout from "../../components/Layout/SignInSignUpLayout/SignInSignUpLayout";

const SignUpConfirm = () => {
  const { userTemp } = useSelector((state) => state.auth);
  const navigate = useNavigate();
  const theme = useTheme();
  useEffect(() => {
    TagManager.dataLayer({
      dataLayer: {
        event: "signupconfirm-email",
        email: userTemp.email,
        path: "/signupconfirm",
        link: {
          url: "/signupconfirm",
          title: "signupconfirm-email",
        },
      },
    });
  }, []);
  return (
    <Stack
      sx={{
        boxSizing: "border-box",
        width: "100%",
        minHeight: "100vh",
        position: "relative",
        overflow: "hidden",
        padding: 0,
        //overscrollBehaviorX: 'contain',
      }}
    >
      <SignInSignUpLayout>
        <Stack
          sx={{
            background: theme.palette.white.main,
            boxSizing: "border-box",
            borderRadius: "24px",
            padding: "32px",
            position: "relative",
            boxShadow: "0px 0px 16px rgba(0, 0, 0, 0.15)",
            minHeight: "300px",
            zIndex: 4,
            justifyContent: "center",
            alignItems: "center",
            gap: 5,
          }}
          width={{ xs: "90%", sm: "80%", md: "80%" }}
          maxWidth={{ xs: "90%", md: "400px" }}
          minWidth={{ xs: "40%" }}
        >
          <Stack
            textAlign={"center"}
            width={"90%"}
            gap={1}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <Stack width={"289px"} height={"289px"}>
              <img
                src="/assets/images/EmailNotification.gif"
                alt="success"
                style={{ width: "100%", height: "100%", display: "block" }}
              />
            </Stack>
            <Typography
              component="h3"
              sx={{
                fontSize: theme.typography.h1.fontSize,
                fontWeight: theme.typography.smallText.fontWeight,
                color: theme.palette.primary.main,
              }}
            >
              Revisa tu Correo
            </Typography>

            <Typography
              textAlign={"center"}
              variant="body1"
              sx={{
                color: theme.palette.primary.darkBlue,
                fontWeight: theme.typography.smallText.fontWeight,
                fontSize: theme.typography.button.fontSize,
              }}
            >
              Haz click en el link que acabamos de enviar a
              <Typography
                component={"span"}
                variant="body1"
                color={theme.palette.primary.main}
                sx={{ fontWeight:  theme.typography.titleText.fontWeight}}
              >
                {" "}
                {userTemp.email}
              </Typography>{" "}
              para que puedas confirmar tu cuenta.
            </Typography>
          </Stack>
          <ButtonsPrimary
            name="Ingresar"
            fullWidth
            onClick={() => navigate("/signin")}
          />
        </Stack>
      </SignInSignUpLayout>
    </Stack>
  );
};

export default SignUpConfirm;
