import { gql, useMutation } from '@apollo/client';

const UPDATE_DIVIDEND_OPTION = gql`
  mutation setDividendOption($data: DividendChoice!) {
    setDividendOption(data: $data)
  }
`;

export const useUpdateDividendOption = () => {
  return useMutation(UPDATE_DIVIDEND_OPTION);
};
