import React from 'react';
import { useSelector } from 'react-redux';
import Spinner from '../../../components/Spinner';
import HeaderRescuePortfolio from '../../../components/portfolio/rescuePortfolio/HeaderRescuePortfolio';
import RenderPortfolioRescue from '../../../components/portfolio/rescuePortfolio/RenderPortfolioRescue';
import { useGetClientInvestmentPortfoliosById } from '../../../hooks/useGetClientInvestmentPortfoliosById';
import { portfolioNameAndDetails } from '../../../utils/portfolioNameAndDetails';
import { numberWithPoint } from '../../../utils/utils';

const RescuePortfolio = () => {
  const portfolioSelectedById = useSelector(state => state.portfolio.portfolioSelected);

  const {
    data: dataPortfolioInvestmentId,
    loading: loadingGetPortfolioById,
    error: errorGetPortfolioById,
  } = useGetClientInvestmentPortfoliosById({
    accountId: portfolioSelectedById,
  });
  const successGetPortfolioById = dataPortfolioInvestmentId?.getClientInvestmentsPortfoliosById;

  if (loadingGetPortfolioById) {
    return <Spinner />;
  }
  if (errorGetPortfolioById) {
  }

  const porfolioDetails = successGetPortfolioById.find(
    porfolio => porfolio.id === '' && porfolio.name === '' && porfolio.security === '',
  );

  const amountMarketValueRescue = Math.round(Number.parseFloat(porfolioDetails?.marketValue));
  const porfolioDetailFileAs = successGetPortfolioById.find(
    portfolio => portfolio?.security.toLowerCase() === 'caja',
  );

  const portfolioFileAsComplete = portfolioNameAndDetails(porfolioDetailFileAs?.fileAs);

  const portfolioMarketValue = porfolioDetails?.marketValue;
  return (
    <>
      <HeaderRescuePortfolio
        amountPortfolio={amountMarketValueRescue}
        portfolioFileAsComplete={portfolioFileAsComplete}
        typeProduct={'portfolio'}
        marketValue={numberWithPoint(Number.parseFloat(portfolioMarketValue).toFixed(0))}
      />
      <RenderPortfolioRescue
        amountPortfolio={amountMarketValueRescue}
        portfolioFileAsComplete={portfolioFileAsComplete}
      />
    </>
  );
};

export default RescuePortfolio;
