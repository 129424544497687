import { Box, Stack, Typography, useTheme } from '@mui/material';
import { hideNumbers } from '../../utils/hideNumbers';
import WrapperBackgroundBanner from '../XcalaComponents/WrapperBackgroundBanner';
import WrapperContentApp from '../XcalaComponents/WrapperContentApp';
import AvatarSection from '../myTransactions/AvatarSection';
import { HeadTitle } from '../myTransactions/MyNewTransactionsMuiComponents';

const $minHeigth = '407px';

const fileAs = {
  currency: 'pesos',
  fileAs: 'file as',
  fileAsComplete: 'file as complete',
  risk: 'agresivo',
  profit: '10',
};

const HeaderObjectivesInterests = ({ userRisk, nameUser, emailUser, userDni, lastNameUser }) => {
  const theme = useTheme();
  return (
    <>
      <WrapperBackgroundBanner $minHeigth={$minHeigth}>
        <WrapperContentApp $minHeigth={$minHeigth} fileAs={fileAs}>
          <Stack width={'100%'} direction={'column'}>
            <Stack
              direction={{ md: 'row', xs: 'column' }}
              justifyContent={'center'}
              alignItems={{ md: 'center', xs: 'center' }}
              width={{ md: '100%', xs: '100%' }}
              gap={1}>
              <AvatarSection userRisk={userRisk} />
              <Box>
                <HeadTitle textAlign={{ md: 'left', xs: 'center' }}>
                  {`${nameUser[0]?.toUpperCase() + nameUser.slice(1)} ${
                    lastNameUser[0]?.toUpperCase() + lastNameUser.slice(1)
                  } `}
                </HeadTitle>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {emailUser}
                </Typography>
                <Typography
                  color={theme.palette.white.main}
                  fontWeight={'100'}
                  textAlign={{ md: 'left', xs: 'center' }}
                  fontSize={theme.typography.mediumText.fontSize}>
                  {hideNumbers(userDni)}
                </Typography>
              </Box>
            </Stack>
            <Stack
              sx={{
                position: 'absolute',
                bottom: 50,
                left: { md: 40, xs: 0 },
                boxSizing: 'border-box',
                width: '100%',
                isolation: 'isolate',
                alignItems: { md: 'start', xs: 'center' },
              }}>
              <Typography
                sx={{
                  fontFamily: theme.typography.museoRegular.fontFamily,
                  fontWeight: theme.typography.smallText.fontWeight,
                  color: theme.palette.white.main,
                  fontSize: theme.typography.h2.fontSize,
                  [theme.breakpoints.down('sm')]: {
                    fontSize: theme.typography.title.fontSize,
                  },
                }}
                component={'h3'}>
                Mis objetivos e intereses
              </Typography>
            </Stack>
          </Stack>
        </WrapperContentApp>
      </WrapperBackgroundBanner>
    </>
  );
};

export default HeaderObjectivesInterests;
