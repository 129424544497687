import { gql, useQuery } from '@apollo/client';

const GET_PRICE_RANGE_QUERY = gql`
  query getPriceRange($data: price!) {
    getPriceRange(data: $data) {
      ClosePrice
      EffectiveDate
      ID
    }
  }
`;
export const useGetPriceRangeProducts = variables => {
  return useQuery(GET_PRICE_RANGE_QUERY, { variables }, { fetchPolicy: 'no-cache' });
};
