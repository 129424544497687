import { gql, useQuery } from '@apollo/client';

const GET_CLIENT_TRANSACTIONS_QUERY = gql`
  query getClientTransactions($status: String!) {
    getClientTransactions(status: $status) {
      id
      amount
      price
      units
      status
      subTypeDetail
      subType
      tradeDate
      securityId
      securityName
      accountId
      note
    }
  }
`;

export const useGetClientTransactionsWallet = variables => {
  return useQuery(GET_CLIENT_TRANSACTIONS_QUERY, {
    variables,
  });
};
