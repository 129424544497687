import { Grid, Stack, Typography } from '@mui/material';
import React from 'react';
import { Link } from 'react-router-dom';

const SuccessStep = ({ Success_heading, Success_subheading, Success_btn, href }) => {
  return (
    <>
      <Grid container maxWidth='sm' style={{ textAlign: 'center' }}>
        <Grid item md={12} xs={12}>
          <Stack
            textAlign={'center'}
            width={'100%'}
            gap={1}
            justifyContent={'center'}
            alignItems={'center'}>
            <Stack width={'100px'} height={'100px'}>
              <img
                src='/assets/images/sucess-gif.gif'
                alt='success'
                style={{
                  width: '100%',
                  height: '100%',
                  display: 'block',
                }}
              />
            </Stack>
          </Stack>
        </Grid>
        <Grid item md={12} xs={12}>
          <Typography variant='h5' className='dark-green text-center fw-600 '>
            {Success_heading}
          </Typography>
        </Grid>
        {Success_subheading && (
          <Grid item md={12} xs={12}>
            <Typography className='font-lg-18 '>{Success_subheading}</Typography>
          </Grid>
        )}
      </Grid>
      <Grid container maxWidth='sm' spacing={2} mt={2} mb={3}>
        <Grid item md={12} xs={12}>
          <div style={{ margin: '0 auto' }}>
            <Link to={href} className='button-primary text-center'>
              {Success_btn}
            </Link>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default SuccessStep;
