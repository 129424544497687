export const IconsError = () => {
  return (
    <>
      <svg
        width='42'
        height='42'
        viewBox='0 0 42 42'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'>
        <rect y='0.405212' width='41.2026' height='41.2026' rx='20.6013' fill='#D12D35' />
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M20.6013 10.2767C26.5272 10.2767 31.3312 15.0806 31.3312 21.0065C31.3312 26.9324 26.5272 31.7364 20.6013 31.7364C14.6754 31.7364 9.87146 26.9324 9.87146 21.0065C9.87146 15.0806 14.6754 10.2767 20.6013 10.2767ZM20.6014 12.4226C15.8606 12.4226 12.0175 16.2657 12.0175 21.0065C12.0175 25.7472 15.8606 29.5904 20.6014 29.5904C25.3421 29.5904 29.1853 25.7472 29.1853 21.0065C29.1853 16.2657 25.3421 12.4226 20.6014 12.4226Z'
          fill='white'
        />
        <path
          d='M24.9964 18.0107C25.2745 17.7326 25.2745 17.2817 24.9964 17.0036C24.7183 16.7255 24.2674 16.7255 23.9893 17.0036L20.5 20.4928L17.0107 17.0036C16.7326 16.7255 16.2817 16.7255 16.0036 17.0036C15.7255 17.2817 15.7255 17.7326 16.0036 18.0107L19.4929 21.5L16.0036 24.9892C15.7255 25.2674 15.7255 25.7183 16.0036 25.9964C16.2817 26.2745 16.7326 26.2745 17.0107 25.9964L20.5 22.5071L23.9893 25.9964C24.2674 26.2745 24.7183 26.2745 24.9964 25.9964C25.2745 25.7183 25.2745 25.2674 24.9964 24.9892L21.5071 21.5L24.9964 18.0107Z'
          fill='white'
          stroke='white'
          strokeWidth='0.454545'
        />
      </svg>
    </>
  );
};
