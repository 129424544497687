import { Stack, Typography, useTheme } from '@mui/material';
import React from 'react';
import { FormatCurrency } from '../../utils/numbers';
import Spinner from '../Spinner';

export const HeaderTotalWallet = ({
  balance,
  loadingBalance,
  errorBalance,
  title = 'Total en Caja',
}) => {
  const theme = useTheme();

  if (loadingBalance) {
    return <Spinner />;
  }

  if (errorBalance) {
    throw new Error('Error component my wallet');
  }

  return (
    <Stack
      width={{ md: '100%', xs: '100%' }}
      justifyContent={{ xs: 'center', sm: 'center' }}
      textAlign='center'>
      <Typography
        variant='h4'
        color={theme.palette.primary.textLightBlue}
        fontSize={theme.typography.largeText.fontSize}
        fontWeight='bold'>
        {FormatCurrency(balance)}
      </Typography>
      <Typography fontWeight={500} textAlign='center' color={theme.palette.white.main}>
        {title}
      </Typography>
    </Stack>
  );
};
