import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import {
  DataGrid,
  GridCsvExportMenuItem,
  GridToolbarContainer,
  GridToolbarExportContainer,
} from '@mui/x-data-grid';
import { formatChileanDate, numberWithPoint } from '../../../utils/utils';
import { CircleIcon } from '../../UI/icons/CircleIcon';

const csvOptions = { delimiter: ';' };

function CustomExportButton(props) {
  return (
    <GridToolbarExportContainer {...props}>
      <GridCsvExportMenuItem rintOptions={{ fileName: 'Descargar informe' }} options={csvOptions} />
    </GridToolbarExportContainer>
  );
}

const CustomToolbar = props => {
  return (
    <GridToolbarContainer {...props}>
      <CustomExportButton />
    </GridToolbarContainer>
  );
};

const NoRowsOverlay = () => {
  return (
    <Stack height='100%' alignItems='center' justifyContent='center' white-space='nowrap'>
      No hay movimientos para mostrar
    </Stack>
  );
};

export const TabletInverted = ({ transactions, colores }) => {
  const theme = useTheme();
  const optionTransaction = {
    BUY: 'Aporte',
    SELL: 'Rescate',
    DIV: 'Dividendo',
    DIVR: 'Dividendo Reinvertido',
  };

  const isXsRender = useMediaQuery(theme => theme.breakpoints.only('xs'));

  const columns = [
    {
      field: 'fecha',
      headerName: 'Fecha',
      width: isXsRender ? 120 : 300,
      align: 'left',
      flex: '0.5 0 100px',
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>{formatChileanDate(params.formattedValue)}</Typography>
          </div>
        );
      },
    },
    {
      field: 'fondo',
      headerName: 'Fondo',
      width: isXsRender ? 300 : 700,
      align: 'left',
      flex: '0.6 0 80px',
      renderCell: params => (
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '6px' }}>
          <CircleIcon strokeColors={colores[params.value]} />
          {params.value}
        </Box>
      ),
    },
    {
      field: 'movimiento',
      headerName: 'Movimiento',
      width: isXsRender ? 160 : 350,
      align: 'left',
      flex: '1 1 150px',
    },
    {
      field: 'monto',
      headerName: 'Monto',
      width: isXsRender ? 120 : 150,
      align: 'left',
      flex: '0.6 0 auto',
      renderCell: params => {
        return (
          <div>
            <Typography variant='subtitle2'>
              $ {params.formattedValue === '' ? '----------' : params.formattedValue}
            </Typography>
          </div>
        );
      },
      sortComparator: (v1, v2) => Number(v1.replace(/\./g, '')) - Number(v2.replace(/\./g, '')),
    },
  ];

  const rows = transactions.map((transaction, index) => {
    let amountValue;
    if (transaction.SubType === 'WTHD' || transaction.SubType === 'SELL') {
      amountValue =
        numberWithPoint(transaction.Amount) !== '0'
          ? `${numberWithPoint(-Math.round(Number.parseFloat(transaction.Amount).toFixed(4)))}`
          : '-';
    } else {
      amountValue =
        numberWithPoint(transaction.Amount) !== '0'
          ? `${numberWithPoint(Math.round(Number.parseFloat(transaction.Amount).toFixed(4)))}`
          : '-';
    }

    return {
      id: index + 1,
      fecha: transaction.CreatedDate,
      fondo: transaction.Security_Name,
      movimiento: optionTransaction[transaction.SubType],
      monto: amountValue,
    };
  });

  return (
    <>
      <Box
        sx={{
          height: '400px',
          width: '100%',
          '& .font-tabular-nums': {
            fontVariantNumeric: 'tabular-nums',
          },
        }}>
        <DataGrid
          rowHeight={50}
          rows={rows}
          columns={columns}
          sx={{
            overflow: 'auto',
            '.MuiDataGrid-columnHeaders': {
              backgroundColor: theme.palette.primary.main,
              color: theme.palette.white.main,
              fontSize: theme.typography.button.fontSize,
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
            },
            '.MuiDataGrid-toolbarContaine, .css-128fb87-MuiDataGrid-toolbarContainer': {
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'start',
            },
            '.MuiDataGrid-toolbarContainer>button': {
              color: theme.palette.textLight.main,
              backgroundColor: theme.palette.white.main,
              '&:hover': {
                backgroundColor: '#f5f5f5',
              },
            },

            '.MuiDataGrid-columnSeparator': {
              display: 'none',
            },
            '&.MuiDataGrid-root': {
              borderRadius: '8px',
            },
            '.MuiDataGrid-row': {
              fontSize: theme.typography.button.fontSize,
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
              textAlign: 'left',
              justifyContent: 'center',
            },

            '& .super-app-theme--cell': {
              width: { md: '100%', xs: '100px' },
              color: theme.palette.primary.main,
              fontWeight: theme.typography.h1.fontWeight,
              display: 'flex',
              justifyContent: 'start',
              alignItems: 'center',
            },
            '.MuiDataGrid-footerContainer': {
              fontSize: theme.typography.button.fontSize,
              [theme.breakpoints.down('sm')]: {
                fontSize: theme.typography.customButton.fontSize,
              },
              color: theme.palette.textLight.main,
            },
            '.MuiButtonBase-root': {
              color: theme.palette.white.main,
            },

            '.MuiDataGrid-footerContainer  .MuiButtonBase-root ': {
              color: theme.palette.primary.main,
              width: { xs: '30px' },
              '&.Mui-disabled': {
                color: theme.palette.textLight.secondary,
              },
            },
          }}
          localeText={{
            toolbarDensity: 'Size',
            toolbarDensityLabel: 'Size',
            toolbarDensityCompact: 'Small',
            toolbarDensityStandard: 'Medium',
            toolbarDensityComfortable: 'Large',
            toolbarExportCSV: 'Exportar a CSV',
            toolbarExport: 'EXPORTAR',
          }}
          slots={{
            toolbar: CustomToolbar,
            noRowsOverlay: NoRowsOverlay,
          }}
          slotProps={{
            pagination: { labelRowsPerPage: 'Filas por página' },
          }}
        />
      </Box>
    </>
  );
};
