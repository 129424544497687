import { Box, Divider, Stack, Typography, styled, useTheme } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { setPortfolioSelected } from '../../redux/portfolio';
import { printLabelData } from '../../utils/modelsPortfolioChart';
import { handleRiskProfile } from '../../utils/riskProfile';
import {
  ButtonsIconsProfile,
  CardsInfo,
  ExpectedTime,
  ReantabilityTitle,
  RentabilityValue,
  TitleCardContainer,
} from '../XcalaComponents';
import { CardsButtomGroup } from '../XcalaComponents';
import ChartDoughnutXcala from '../XcalaComponents/ChartDoughnutXcala';

const BodyContainer = styled(Stack)(({ theme }) => ({
  backgroundColor: theme.palette.white.main,
  position: 'relative',
  isolation: 'isolate',
  flexDirection: 'column',
  width: '100%',
  justifyContent: 'flex-start',
  alignItems: 'flex-start',
  padding: '8px 16px 16px 16px',
  boxSizing: 'border-box',
  height: '100%',
}));

export const BodyCardsContainerPortfolio = ({
  dataChart,
  portfolioName,
  portfolioDetailsFileAs,
}) => {
  const { rentabilityFound, amountOfFunds, idModels } = dataChart;

  const [userNotOB, setUserNotOB] = useState(false);
  const { user } = useSelector(state => state.auth);
  const theme = useTheme();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const goToSimulator = id => {
    navigate(`/portfolio-simulation/${id}`);
  };
  const goToInvestment = id => {
    userNotOB
      ? navigate(`/portfolio-simulation/${id}/investment`)
      : navigate('/completeregistration');
  };

  const gotoPortfolioDetails = id => {
    dispatch(setPortfolioSelected(id));
    navigate('/portfolio-details');
  };
  useEffect(() => {
    if (
      user?.['custom:checkid-sign-doc'] &&
      user['custom:checkid-sign-doc'] === 'signature_success'
    ) {
      setUserNotOB(true);
    }
  }, [user]);
  return (
    <BodyContainer gap={2}>
      <Stack gap={1} width={'100%'}>
        <TitleCardContainer>
          <ButtonsIconsProfile src={handleRiskProfile(portfolioDetailsFileAs?.risk)} />
          <CardsInfo>
            <ExpectedTime>{`Participación de ${amountOfFunds} Fondos`}</ExpectedTime>
            <ReantabilityTitle fontSize={'clamp(0.96rem, 2vw, 1.2rem)'}>
              Retorno esperado
            </ReantabilityTitle>
            <RentabilityValue> {`${rentabilityFound}%`}</RentabilityValue>
            <ExpectedTime>Rentabilidad Proyectada</ExpectedTime>
          </CardsInfo>
        </TitleCardContainer>
        <Divider variant='fullWidth' width='100%' />
        <Stack flexDirection={'row'} gap={1} flexWrap={'wrap'} />
      </Stack>
      <Stack width={'100%'} borderRadius={2} backgroundColor={theme.palette.tertiary.main}>
        <Stack
          padding={2}
          flexDirection={{ sm: 'row', xs: 'column-reverse' }}
          justifyContent={'space-between'}
          alignItems={'center'}>
          <Stack
            height={'100%'}
            alignItems={'start'}
            justifyContent={'space-between'}
            marginTop={{ md: 0, xs: '12px' }}>
            <Typography
              fontWeight={theme.typography.h4.fontWeight}
              fontSize={theme.typography.button.fontSize}>
              Fondos del portafolio
            </Typography>
            <Stack height={'90%'} justifyContent={'space-around'}>
              {printLabelData(dataChart.dataChart)}
            </Stack>
          </Stack>
          <ChartDoughnutXcala
            width='190px'
            height='190px'
            dataChart={dataChart.dataChart}
            fontSize={11}
          />
        </Stack>
      </Stack>

      <Stack width={'100%'} paddingBottom={'16px'}>
        <CardsButtomGroup
          goToSimulator={() => goToSimulator(idModels)}
          goToProductDetail={() => gotoPortfolioDetails(dataChart.idModels)}
          goToInvestment={() => goToInvestment(idModels)}
        />
      </Stack>
    </BodyContainer>
  );
};
