import { gql, useQuery } from '@apollo/client';

const GET_EXCHANGE_RATE_HISOTRY = gql`
  query GetExchangeRateHistory($rowEnd: Int!) {
    getExchangeRateHistory(rowEnd: $rowEnd) {
      ID
      Rate
      EffectiveDate
    }
  }
`;

export const useGetExchangeRateHistory = variables => {
  return useQuery(GET_EXCHANGE_RATE_HISOTRY, { variables }, { fetchPolicy: 'no-cache' });
};
