import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { CustomSelectOB } from './CustomSelectOB';

export const ProfesionSelectOB = ({ selected, setSelected }) => {
  const professions = useSelector(state => state.constants.professions);
  const [menuItems, setMenuItems] = useState(professions);
  const [defaultelect, setDefaultelect] = useState('');

  useEffect(() => {
    const aux = menuItems.find(item => item.name === selected.name);
    setDefaultelect(aux);
  }, [selected]);
  return (
    <CustomSelectOB
      label='Profesión, ocupación u oficio'
      Labelid='Profesión, ocupación u oficio'
      placholder='Profesión, ocupación u oficio'
      Selectid='Profesión, ocupación u oficio'
      id='Profesión, ocupación u oficio'
      value={defaultelect}
      onChange={setSelected}
      menuItems={menuItems}
    />
  );
};
